import * as tslib_1 from "tslib";
import { InputBase } from '../input-base';
var InputCheckComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputCheckComponent, _super);
    function InputCheckComponent() {
        var _this = _super.call(this) || this;
        _this.align = 'center';
        _this.stylesService.set({
            '.checkin__input input[type="checkbox"]': {
                '&:checked + .checkin__input__checkbox': {
                    'border-color': _this.corporativeColor,
                    'background-color': _this.corporativeColor
                }
            },
            '.checkin__input__check > label a': {
                color: _this.corporativeColor,
                '&:hover, &:focus': {
                    'text-decoration': 'none'
                }
            }
        });
        return _this;
    }
    InputCheckComponent.prototype.toggleCheckbox = function () {
        this.form.get(this.control).setValue(!this.hasValue);
    };
    Object.defineProperty(InputCheckComponent.prototype, "isValid", {
        get: function () {
            return this.form.get(this.control).valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputCheckComponent.prototype, "alignClass", {
        get: function () {
            return this.align;
        },
        enumerable: true,
        configurable: true
    });
    return InputCheckComponent;
}(InputBase));
export { InputCheckComponent };

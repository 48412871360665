import { Component, AfterViewInit, OnDestroy, ChangeDetectorRef, Injector, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { EBookingStatus } from 'src/app/shared/enums/booking-status.enum';
import { BookingStatus } from './booking-status.class';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-booking-status',
  templateUrl: './booking-status.component.html',
  styleUrls: ['./booking-status.component.scss']
})

export class BookingStatusComponent implements OnInit, OnDestroy {
  public status: string;
  public infoStatus: any;

  private bookingStatus: BookingStatus;
  private subscriber: Subscription;
  private config: ConfigService;

  constructor(private injector: Injector, private cdRef: ChangeDetectorRef) {

    Object.assign(this, {
      config: this.injector.get(ConfigService),
      bookingStatus: new BookingStatus(),
    });

    this.status = this.config.booking.resume.status;

    this.infoStatus = {
      [EBookingStatus.CANCELLED]: {
        icon: 'avc-cross-circle',
        title: 'cancelled',
        description: 'booking-cancelled'
      },
      [EBookingStatus.PETICION_DISPONIBILIDAD]: {
        icon: 'avc-icon-alert',
        title: 'pending',
        description: 'booking-pending'
      },
      [EBookingStatus.PETICION_INFORMACION]: {
        title: 'info-request-title',
        description: 'info-request-text',
        class: 'request'
      },
      [EBookingStatus.CONFIRMADA]: {
        icon: 'avc-check-circle',
        title: 'hide-total-price-confirmed-booking-title',
        description: 'hide-total-price-confirmed-booking-description',
      },
      [EBookingStatus.CONFIRMED]: {
        icon: 'avc-check-circle',
        title: 'hide-total-price-confirmed-booking-title',
        description: 'hide-total-price-confirmed-booking-description',
      },
      [EBookingStatus.PAID]: {
        icon: 'avc-check-circle',
        title: 'hide-total-price-confirmed-booking-title',
        description: 'hide-total-price-confirmed-booking-description',
      },
      [EBookingStatus.UNPAID]: {
        icon: 'avc-check-circle',
        title: 'hide-total-price-confirmed-booking-title',
        description: 'hide-total-price-confirmed-booking-description',
      },
      [EBookingStatus.FACTURADA]: {
        icon: 'avc-check-circle',
        title: 'hide-total-price-confirmed-booking-title',
        description: 'hide-total-price-confirmed-booking-description',
      }
    };
  }


  private subscribe(): void {
    this.setPaymentsSizeConfig();

    this.subscriber = this.config.screen.onWindowResizeChanges().subscribe(() => this.setPaymentsSizeConfig());
  }

  private setPaymentsSizeConfig() {
    if (!this.config.screen.isLG) {

      const is2k = !this.config.screen.isXL;
      const height = this.bookingStatus.getHeightSidebarStatus(is2k);

      document
        .querySelector('.checkin__sidebar__wrapper')
        .setAttribute('style', `height: ${ height }px`);

      this.cdRef.detectChanges();
    }
  }

  public ngOnInit(): void {
    this.bookingStatus.setElements();

    this.subscribe();
  }

  public ngOnDestroy(): void {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

}

import { FormatDatePipe } from 'src/app/shared/common/pipes/format-date.pipe';

export interface IPaymentListItem {
  amount: number;
  currency: string;
  error: boolean;
  date: Date | string;
  isSecurityDeposit?: boolean;
  paid: boolean;
  selected?: boolean;
  text?: string;
  mcp?: {
    amount: number;
    currency: string;
  };
  momentoPago?: string;
}

export interface IPaymentsList {
  paymentsDetail: IPaymentListItem[];
  deposit: IPaymentListItem[];
}

export class PaymentListItem {
  public amount: number;
  public currency: string;
  public error: boolean;
  public date: string;
  public isSecurityDeposit: boolean;
  public paid: boolean;
  public selected: boolean;
  public text: string;
  public mcp: {
    amount: number;
    currency: string;
  };
  public momentoPago: string;

  constructor(private params: IPaymentListItem) {
    const { date, ...p } = this.params;
    Object.assign(this, p);

    this.date = `(${new FormatDatePipe().transform(date)})`;
  }
}

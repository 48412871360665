import { OccupantForm } from '../occupant-detail.interface';
import {
  CLIENT_NATIONALITY,
  AUTO_SUGGEST_CONTROLS,
  DATE_CONTROLS
} from './occupant-form-controls';

function findControlConfig(key: string, config: OccupantForm, validate: boolean) {
  return config.find((control) => (control.id === key) && (validate ? control.valid : true));
}

function parseAutoSuggestControls(form: OccupantForm, validate: boolean): void {
  for (const control of AUTO_SUGGEST_CONTROLS) {
    const controlConfig = findControlConfig(control, form, validate);

    if (!controlConfig) {
      continue;
    }

    const { value = { value: '' } } = controlConfig;

    if (!value.hasOwnProperty('value')) {
      value.value = '';
    }
  }
}

function parseDateControls(form: OccupantForm, validate: boolean): void {
  for (const control of DATE_CONTROLS) {
    const controlConfig = findControlConfig(control, form, validate);

    if (!controlConfig || !controlConfig.value) {
      continue;
    }

    controlConfig.value = new Date(controlConfig.value);
  }
}

function parseNationalityControls(form: OccupantForm, validate: boolean): void {
  const clientNationality = findControlConfig(CLIENT_NATIONALITY, form, validate);

  if (!clientNationality) {
    return;
  }

  const { value = '' } = clientNationality;
  clientNationality.value = typeof clientNationality.value !== 'number' ? '' : value;
}

export function parseCheckinForm(form: OccupantForm, validate: boolean = false) {
  parseAutoSuggestControls(form, validate);
  parseDateControls(form, validate);
  parseNationalityControls(form, validate);

  return form;
}

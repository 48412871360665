import { Component, Input } from '@angular/core';
import * as Color from 'color';
import { IconBase } from '../icon-base';
import { ConfigService } from 'src/app/shared/services/config/config.service';

@Component({
  selector: 'app-loader',
  templateUrl: 'loader.component.html',
  styleUrls: ['./loader.component.scss']
})

export class LoaderComponent extends IconBase {
  @Input() color: string;

  corporativeColor: Color;

  constructor(private config: ConfigService) {
    super();
    this.corporativeColor = this.config.checkin.corporativeColor;
    this.size = 50;
  }

  get loaderColor(): Color {
    return Color(this.color) || this.corporativeColor;
  }

  get loaderAlphaColor(): Color {
    return this.loaderColor.alpha(.4);
  }

  get loaderSize(): string {
    return `${this.size}px`;
  }

  get wrapperSize(): string {
    return `${this.size + 2}px`;
  }
}

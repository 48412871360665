import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var OccupantTravelerSignatureService = /** @class */ (function () {
    function OccupantTravelerSignatureService() {
        this.subject = new Subject();
    }
    OccupantTravelerSignatureService.prototype.emit = function (action) {
        this.subject.next(action);
    };
    OccupantTravelerSignatureService.prototype.signatureObservable = function () {
        return this.subject.asObservable();
    };
    OccupantTravelerSignatureService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OccupantTravelerSignatureService_Factory() { return new OccupantTravelerSignatureService(); }, token: OccupantTravelerSignatureService, providedIn: "root" });
    return OccupantTravelerSignatureService;
}());
export { OccupantTravelerSignatureService };

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-world-pattern',
  templateUrl: './world-pattern.component.html',
  styleUrls: ['./world-pattern.component.scss']
})

export class WorldPatternComponent {
  @Input() color: string;

  constructor() { }
}

import * as tslib_1 from "tslib";
import { RequestInterfaceBase } from '../request-interface-base';
var RequestInterfaceLoaderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RequestInterfaceLoaderComponent, _super);
    function RequestInterfaceLoaderComponent() {
        return _super.call(this) || this;
    }
    return RequestInterfaceLoaderComponent;
}(RequestInterfaceBase));
export { RequestInterfaceLoaderComponent };

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { InputBase } from '../input-base';
import { GeoService } from 'src/app/shared/common/services/geo.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
var ENTITIES_MAP = {
    city: 'idCity',
    province: 'idProvince',
    region: 'idRegion',
    country: 'idCountry'
};
var criteriaKey = {
    clientCity: 'city',
    clientProvince: 'province',
    clientRegion: 'region',
    clientCountry: 'country',
    country: 'country',
    region: 'region'
};
var InputAutosuggestComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputAutosuggestComponent, _super);
    function InputAutosuggestComponent(formBuilder, geoService) {
        var _a;
        var _this = _super.call(this) || this;
        _this.geoService = geoService;
        _this.autoSuggestControl = 'autoSuggestControl';
        _this.autoSuggestCursor = -1;
        _this.autoSuggestDebounceDelay = 400;
        _this.autoSuggestForm = formBuilder.group((_a = {},
            _a[_this.autoSuggestControl] = new FormControl(),
            _a));
        _this.autoSuggestOptions = [];
        return _this;
    }
    InputAutosuggestComponent.prototype.ngOnInit = function () {
        if (this.useControlValueInsteadValue) {
            this.value = this.form.get(this.control).value;
        }
        if (this.value) {
            var control = this.autoSuggestForm.get(this.autoSuggestControl);
            control.setValue(this.value, { onlySelf: true, emitEvent: false });
            var hasSpecialCharacter = /[!#$%^&*(),.?":{}|<>_-]/.test(this.value) && !/[+@]/.test(this.value);
            if (!hasSpecialCharacter && this.disabledWithValue) {
                control.disable();
            }
        }
        this.onControlChanges();
    };
    InputAutosuggestComponent.prototype.onControlChanges = function () {
        var _this = this;
        this.autoSuggestForm.get(this.autoSuggestControl).valueChanges.subscribe(function (v) {
            var value = v ? v.trim() : '';
            if (!value) {
                _this.resetAutoSuggestConfig();
                return;
            }
            _this.autoSuggestLoading = true;
            _this.showAutoSuggestList = false;
            _this.getAutoSuggestOptions(value);
        });
    };
    InputAutosuggestComponent.prototype.getAutoSuggestOptions = function (value) {
        var _this = this;
        if (this.autoSuggestDebounce) {
            clearTimeout(this.autoSuggestDebounce);
        }
        var criteriaCondition = (this.criteria) ? this.getCriteriaCondition() : '';
        this.autoSuggestDebounce = setTimeout(function () {
            _this.geoService.getEntityByKey(value, _this.entity, criteriaCondition).subscribe(function (res) {
                _this.autoSuggestCursor = -1;
                _this.autoSuggestOptions = res;
                _this.autoSuggestLoading = false;
                _this.showAutoSuggestList = true;
                if (!_this.autoSuggestFocused) {
                    _this.updateAutoSuggestValue();
                }
            });
        }, this.autoSuggestDebounceDelay);
    };
    InputAutosuggestComponent.prototype.onKeyDown = function (evt) {
        var arrowKeys = [13, 38, 40];
        if (arrowKeys.includes(evt.keyCode)) {
            var keysMap = new Map();
            keysMap.set(13, this.updateAutoSuggestValue.bind(this));
            keysMap.set(38, this.decreaseAutoSuggestCursor.bind(this));
            keysMap.set(40, this.increaseAutoSuggestCursor.bind(this));
            keysMap.get(evt.keyCode)();
        }
    };
    InputAutosuggestComponent.prototype.updateAutoSuggestValue = function () {
        var _this = this;
        this.showAutoSuggestList = false;
        var selectedOption = this.autoSuggestOptions[this.autoSuggestCursor];
        if (selectedOption) {
            var _a = this.entity, value = selectedOption[_a], _b = ENTITIES_MAP[this.entity], code = selectedOption[_b];
            this.autoSuggestForm.get(this.autoSuggestControl).setValue(value, { onlySelf: true, emitEvent: false });
            this.setFormValue(code, value, this.entity);
            return;
        }
        if (!this.autoSuggestValue) {
            this.setFormValue(null, '', '');
            return;
        }
        if (this.autoSuggestOptions.length === 0) {
            this.setFormValue(null, this.autoSuggestValue, '');
            return;
        }
        var criteriaCondition = (this.criteria) ? this.getCriteriaCondition() : '';
        this.geoService.getEntityByKey(this.autoSuggestValue, this.entity, criteriaCondition)
            .pipe(filter(function (res) { return res.length >= 1; }), map(function (res) { return res[0]; }))
            .subscribe(function (resultOption) {
            var _a = _this.entity, resultValue = resultOption[_a], _b = ENTITIES_MAP[_this.entity], resultCode = resultOption[_b];
            if (_this.autoSuggestValue.toLowerCase() !== resultValue.toLowerCase()) {
                _this.setFormValue(null, _this.autoSuggestValue, '');
                return;
            }
            _this.setFormValue(resultCode, resultValue, _this.entity);
        });
    };
    InputAutosuggestComponent.prototype.setAutoSuggestCursor = function (cursor) {
        this.autoSuggestCursor = cursor;
    };
    InputAutosuggestComponent.prototype.increaseAutoSuggestCursor = function () {
        var length = this.autoSuggestOptions.length;
        var max = length > 0 ? length : -1;
        this.setAutoSuggestCursor(Math.min(max, (this.autoSuggestCursor + 1)));
    };
    InputAutosuggestComponent.prototype.decreaseAutoSuggestCursor = function () {
        this.setAutoSuggestCursor(Math.max(-1, (this.autoSuggestCursor - 1)));
    };
    InputAutosuggestComponent.prototype.resetAutoSuggestConfig = function () {
        this.showAutoSuggestList = false;
        this.autoSuggestCursor = -1;
        this.autoSuggestForm.get(this.autoSuggestControl).reset(null, { onlySelf: true, emitEvent: false });
        this.autoSuggestOptions = [];
        this.form.get(this.control).reset();
    };
    InputAutosuggestComponent.prototype.toggleAutoSuggestFocused = function () {
        this.autoSuggestFocused = !this.autoSuggestFocused;
    };
    InputAutosuggestComponent.prototype.onAutoSuggestBlur = function () {
        this.toggleAutoSuggestFocused();
        this.updateAutoSuggestValue();
    };
    Object.defineProperty(InputAutosuggestComponent.prototype, "autoSuggestValue", {
        get: function () {
            var autoSuggestControl = this.autoSuggestForm.value.autoSuggestControl;
            return autoSuggestControl || '';
        },
        enumerable: true,
        configurable: true
    });
    InputAutosuggestComponent.prototype.setFormValue = function (code, value, entity) {
        this.form.get(this.control).setValue({ code: code, value: value, entity: entity });
    };
    InputAutosuggestComponent.prototype.getCriteriaCondition = function () {
        var criteriaCondition = '';
        var criteriaControl = this.form.get(this.criteria) || { value: '' };
        if (!criteriaControl.value) {
            return criteriaCondition;
        }
        // tslint:disable-next-line:max-line-length
        var criteriaValue = (this.criteria === 'clientCountry' || this.criteria === 'country') ? criteriaControl.value : criteriaControl.value.code;
        if (criteriaValue) {
            criteriaCondition = criteriaKey[this.criteria] + ":" + criteriaValue;
        }
        return criteriaCondition;
    };
    return InputAutosuggestComponent;
}(InputBase));
export { InputAutosuggestComponent };

import { Component, Input } from '@angular/core';
import { GuestControl } from 'src/app/checkin-app/modules/checkin/pages/wizard/models/form/wizard-form.model';
import { FormGroup } from '@angular/forms';
import { EControlsType } from 'src/app/checkin-app/modules/checkin/pages/wizard/enums/controls.enum';

@Component({
  selector: 'app-ho-input',
  templateUrl: './ho-input.component.html',
  styleUrls: ['./ho-input.component.scss']
})

export class HOInputComponent {
  @Input() config: GuestControl;
  @Input() form: FormGroup;

  public EControlsType: any;

  constructor() {
    this.EControlsType = EControlsType;
  }
}

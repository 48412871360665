import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss']
})

export class SectionTitleComponent {
  @Input() centered: boolean;
  @Input() description: {
    slug: string;
    inner?: any;
    params?: { [key: string]: any }
  };
  @Input() title: {
    slug: string;
    params?: { [key: string]: any }
  };

  constructor() { }
}

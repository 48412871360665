import {
  trigger,
  transition,
  style,
  animate,
  query,
  animateChild
} from '@angular/animations';

export const baseModalAnimation = [
  trigger('baseModalAnimation', [
    transition(':enter', [
      query('.guest__area__base__modal__overlay', animateChild()),
      query('.guest__area__base__modal__wrapper', animateChild())
    ]),
    transition(':leave', [
      query('.guest__area__base__modal__wrapper', animateChild()),
      query('.guest__area__base__modal__overlay', animateChild())
    ])
  ]),
  trigger('wrapper', [
    transition(':enter', [
      style({ opacity: 0, transform: '{{ enter.start }}' }),
      animate('300ms 200ms ease-out', style({ transform: '{{ enter.end }}', opacity: 1 }))
    ], {
      params: {
        enter: {
          start: 'translate(-50%, calc(-50% + 100px))',
          end: 'translate(-50%, -50%)'
        }
      }
    }),
    transition(':leave', [
      style({ opacity: 1, transform: '{{ leave.start }}' }),
      animate('300ms ease-out', style({ transform: '{{ leave.end }}', opacity: 0 }))
    ], {
      params: {
        leave: {
          start: 'translate(-50%, -50%)',
          end: 'translate(-50%, calc(-50% + 100px))'
        }
      }
    }),
  ]),
  trigger('overlay', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('300ms cubic-bezier(.35, 0, .25, 1)', style({ opacity: 1 }))
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('300ms 200ms cubic-bezier(.35, 0, .25, 1)', style({ opacity: 0 }))
    ])
  ]),
];

export const wrapperAnimationParams: { [key: string]: any; } = {
  default: {
    'enter.start': 'translate(-50%, calc(-50% + 100px))',
    'enter.end': 'translate(-50%, -50%)',
    'leave.start': 'translate(-50%, -50%)',
    'leave.end': 'translate(-50%, calc(-50% + 100px))'
  },
  responsive: {
    'enter.start': 'translateY(-100px))',
    'enter.end': 'translateY(0px)',
    'leave.start': 'translateY(0px)',
    'leave.end': 'translateY(100px)'
  }
};

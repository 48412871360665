export enum EModules {
  CHECKIN = 'checkin',
  INBOX = 'inbox',
  INTRANET = 'intranet',
  INTRANET_FULL = 'intranet_full',
  PAYMENTS = 'payments',
  PAYMENTS_BREAKDOWN = 'payments_breakdown',
  CHECKOUT = 'checkout'
}

export enum EModulesRoutes {
  CHECKIN = 'checkin',
  INBOX = 'inbox',
  PAYMENTS = 'payments',
  CHECKOUT = 'checkout'
}

export const CURRENCIES_MAP = {
  EUR: '€',
  GBP: '£',
  USD: '$',
  JPY: '¥',
  CLP: 'CL$',
  ARS: 'AR$',
  BRL: 'R$',
  SGD: 'S$',
  SEK: 'kr'
};

export const SIZE_MAP = {
  EUR: '50px',
  GBP: '50px',
  USD: '50px',
  JPY: '50px',
  CLP: '50px',
  ARS: '50px',
  BRL: '50px',
  SGD: '50px',
  SEK: '50px'
};

import { OccupantListArrivalForm } from 'src/app/shared/main/body/occupant-list/occupant-list-arrival-info/occupant-list-arrival-info.interfaces';
import {
  SidebarData,
  TravelInfoData,
  CommentsList
} from 'src/app/app.interfaces';
import { OccupantsData } from 'src/app/shared/main/body/occupant-list/occupant-list.interface';
import { Observable, Subject } from 'rxjs';
import { newDate } from 'src/app/shared/common/utils/dates';
import { PaymentsList } from 'src/app/shared/modules/sidebar/booking-detail/payments-list/payments-list.interfaces';
import { parsePaymentsList } from 'src/app/shared/modules/sidebar/booking-detail/payments-list/payments-list-utils';
import { Guests } from '../guests/guests.model';
import * as get from 'lodash.get';
import { TProject } from '../projects/project.model';
import { EBookingStatus } from 'src/app/shared/enums/booking-status.enum';
import { CreditCard } from 'src/app/shared/classes/credit-card/credit-card.class';
import { INav } from '../navbar/navbar.model';
import { ERoutes } from 'src/app/shared/enums/routes.enum';
import { EModules } from 'src/app/app.enum';

interface IBookingAcc {
  country: {
    code: string;
    name: string;
  };
}

interface IBooking {
  readonly id: number;
  readonly guests: Guests;
  readonly resume: SidebarData;
  readonly acc: IBookingAcc;
  readonly mainOccupantId: number;
  readonly showBookingPrice: boolean;
  readonly showCurrencySelector: boolean;
  readonly statusWithCustomTemplate: string[];

  readonly creditCard: CreditCard;
  readonly bk: string;

  arrivalFormInfo: OccupantListArrivalForm;
  comments: CommentsList;
  travelInfo: TravelInfoData;

  occupantsData?: OccupantsData;
  occupantsDataChanged?: () => Observable<OccupantsData>;
}

export class Booking implements IBooking {
  private _occupantsData: OccupantsData;
  private occupantsDataSubject: Subject<OccupantsData>;

  public readonly acc: IBookingAcc;
  public readonly id: number;
  public readonly guests: Guests;
  public readonly resume: SidebarData;
  public readonly creditCard: CreditCard;
  public readonly bk: string;
  public readonly mainOccupantId: number;
  public readonly showBookingPrice: boolean;
  public readonly showCurrencySelector: boolean;
  public readonly statusWithCustomTemplate: string[];

  public arrivalFormInfo: OccupantListArrivalForm;
  public comments: CommentsList;
  public travelInfo: TravelInfoData;

  constructor(params: any, private project: TProject, private module: EModules) {
    this.init(params);
  }

  private init(params: any): void {
    const {
      booking: {
        acc: {
          id: accId,
          city,
          img,
          name,
          type: accType,
          country
        },
        adults,
        children,
        babies,
        amount,
        amountMCP,
        currency,
        currencyBuyer,
        from,
        to,
        id,
        localizer,
        payments,
        paymentConfig,
        card,
        mainOccupantId,
        status,
        idCompany,
        code
      },
      extraConfig: {
        showBookingPrice,
        showCurrencySelector
      }
    } = params;

    const comments = get(params, 'booking.comments', []);
    const occupants = get(params, 'booking.occupants', null);
    const arrivalFormInfo = get(params, 'booking.arrivalForm', {});
    const travelInfo = get(params, 'travelInfo', {});

    Object.assign(this, {
      acc: {
        country
      },
      arrivalFormInfo,
      comments: comments.reverse(),
      id,
      guests: occupants ? new Guests({
        occupants,
        hash: this.project.hash
      }) : occupants,
      travelInfo,
      mainOccupantId,
      bk: code,
      resume: {
        accId,
        accType,
        adults,
        amount,
        amountMCP,
        children,
        babies,
        currency,
        currencyBuyer,
        dateFrom: newDate(from),
        dateTo: newDate(to),
        img,
        city,
        ...(country && country.name) && {
          country: country.name
        },
        localizer,
        name,
        payments: this.buildPayments(payments),
        paymentConfig,
        status,
        idCompany,
        bookingId: id
      },
      creditCard: new CreditCard({
        id: get(card, 'tokenId'),
        number: get(card, 'code'),
        firstDigits: get(card, 'firstDigits'),
        updatable: get(card, 'canChange'),
        type: get(card, 'type'),
        ...((get(card, 'name') || get(card, 'billingAddress')) && {
          owner: {
            ...get(card, 'billingAddress', {}),
            name: get(card, 'name')
          }
        })
      }),
      occupantsDataSubject: new Subject<OccupantsData>(),
      showBookingPrice: (this.module === EModules.CHECKOUT) ? true : showBookingPrice,
      showCurrencySelector,
      statusWithCustomTemplate: [
        EBookingStatus.CANCELLED,
        EBookingStatus.PETICION_INFORMACION,
        EBookingStatus.PETICION_DISPONIBILIDAD,
        ...(!showBookingPrice ? [
          EBookingStatus.CONFIRMADA,
          EBookingStatus.CONFIRMED,
          EBookingStatus.FACTURADA,
          EBookingStatus.PAID,
          EBookingStatus.UNPAID
        ] : [])
      ]
    });

    this.updateNavbarAdditionalInfo();
    this.project.loading = false;
  }

  private buildPayments(payments: PaymentsList): PaymentsList {
    if (payments) {
      const { paymentsDetail, deposit } = payments;

      return {
        paymentsDetail: parsePaymentsList(paymentsDetail),
        deposit: parsePaymentsList(deposit)
      };
    }

    return { paymentsDetail: [], deposit: [] };
  }

  public get occupantsData(): OccupantsData {
    return this._occupantsData;
  }

  public set occupantsData(occupantsData: OccupantsData) {
    this._occupantsData = occupantsData;
    this.occupantsDataSubject.next(occupantsData);
  }

  public occupantsDataChanged(): Observable<OccupantsData> {
    return this.occupantsDataSubject.asObservable();
  }

  private updateNavbarAdditionalInfo(): void {
    const card = this.project.navbarAditionalInfo.find((i: INav) => i.route === ERoutes.CARD);
    const update: (c: INav) => void = (c: INav) => c.label = { slug: 'see-card', params: {} };

    if (!card) {
      return;
    }

    if (this.creditCard.valid) {
      update(card);
      return;
    }

    this.creditCard.onChanges().subscribe(() => update(card));
  }
}

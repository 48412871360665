import { Subject } from 'rxjs';
import { OccupantIdentifyService } from '../occupant-identify-scan.service';
import * as i0 from "@angular/core";
import * as i1 from "../occupant-identify-scan.service";
var OccupantIdentifyScanActionsService = /** @class */ (function () {
    function OccupantIdentifyScanActionsService(occupantIdentifyService) {
        this.occupantIdentifyService = occupantIdentifyService;
        this.subject = new Subject();
    }
    OccupantIdentifyScanActionsService.prototype.sendAction = function (action) {
        this.subject.next(action);
    };
    OccupantIdentifyScanActionsService.prototype.getAction = function () {
        return this.subject.asObservable();
    };
    OccupantIdentifyScanActionsService.prototype.savePhoto = function (documentKey, image) {
        this.occupantIdentifyService.setDocument(documentKey, image);
    };
    OccupantIdentifyScanActionsService.prototype.clearPhoto = function (documentKey) {
        this.occupantIdentifyService.clearDocument(documentKey);
    };
    OccupantIdentifyScanActionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OccupantIdentifyScanActionsService_Factory() { return new OccupantIdentifyScanActionsService(i0.ɵɵinject(i1.OccupantIdentifyService)); }, token: OccupantIdentifyScanActionsService, providedIn: "root" });
    return OccupantIdentifyScanActionsService;
}());
export { OccupantIdentifyScanActionsService };

import { ConfigService } from 'src/app/shared/services/config/config.service';
import { EBookingStatus } from 'src/app/shared/enums/booking-status.enum';
import { StylesService } from 'src/app/shared/common/components/buttons/styles.service';
var StatusBoxComponent = /** @class */ (function () {
    function StatusBoxComponent(config, stylesService) {
        var _a;
        this.config = config;
        this.stylesService = stylesService;
        var _b = this.config, color = _b.checkin.corporativeColor, company = _b.company;
        this.params = {
            phone: "<b>" + company.phone + "</b>",
            email: "<b><a href=\"mailto:" + company.email + "\">" + company.email + "</a></b>"
        };
        this.stylesService.set({ '.checkin__status__box__description b, .checkin__status__box__description a': { color: color } });
        this.status = this.config.booking.resume.status;
        this.isExpired = this.config.checkin.expired;
        this.allowedStates = [EBookingStatus.CANCELLED, EBookingStatus.PETICION_DISPONIBILIDAD];
        if (this.isExpired && !this.allowedStates.includes(this.status)) {
            this.status = 'expired';
        }
        this.statusBoxInfo = (_a = {},
            _a[EBookingStatus.CANCELLED] = {
                title: 'cancelled-booking-title',
                description: 'cancelled-booking-description'
            },
            _a[EBookingStatus.PETICION_DISPONIBILIDAD] = {
                title: 'pending-booking-title',
                description: 'pending-booking-description'
            },
            _a[EBookingStatus.CONFIRMADA] = {
                title: 'pending-booking-title',
                description: 'pending-booking-description'
            },
            _a.expired = {
                title: 'expired-checkin-title',
                description: 'expired-checkin-description'
            },
            _a);
    }
    return StatusBoxComponent;
}());
export { StatusBoxComponent };

import { Injectable } from '@angular/core';

interface ICookieService {
  check: (key: string) => boolean;
  set: (key: string, value: string, expires?: number | Date) => void;
  get: (key: string) => string;
}

@Injectable()
export class CookieService implements ICookieService {
  constructor() {}

  public get(key: string): string {
    return this._get(key);
  }

  public set(key: string, value: string, expires?: Date): void {
    this._set(key, value, expires);
  }

  public check(key: string): boolean {
    return this._check(key);
  }

  private _get(name: string): string {
    const decodedCookie = decodeURIComponent(document.cookie).split(';');

    return decodedCookie.reduce((res, cookie) => {
      if (!cookie || res) {
        return res;
      }

      const [key, value] = cookie.trim().split('=');

      return name === key ? value : res;
    }, undefined);
  }

  private _check(key: string): boolean {
    return Boolean(this._get(key));
  }

  private _set(key: string, value: string, expires: Date = (new Date(new Date().setFullYear(new Date().getFullYear() + 1)))): void {
    document.cookie = `${key}=${value};expires=${expires.toUTCString()};path=/`;
  }
}

import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { InjectorService } from '../injector/injector.service';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { Observable, of } from 'rxjs';
import { switchMap, retryWhen, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private retrySeconds: number;
  private socket: WebSocketSubject<any>;

  public connection: Observable<WebSocketSubject<any>>;

  constructor() {
    const env: EnvService = InjectorService.get().get(EnvService);

    this.retrySeconds = 2;
    this.connect(env.wsEndpoint);
  }

  private connect(url: string): void {
    this.connection = of(url).pipe(
      switchMap((wsUrl) => {
        if (!this.socket) {
          this.socket = webSocket(`${wsUrl}`);
        }

        return this.socket.asObservable();
      }),
      retryWhen((errors) => errors.pipe(delay(this.retrySeconds)))
    );
  }

  public send(data: any) {
    this.socket.next(data);
  }

  public close(): void {
    this.socket.complete();
  }
}

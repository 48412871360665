import { AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[appear]'
})
export class AppearDirective implements  AfterViewInit {
  @Output() public deferLoad: EventEmitter<any> = new EventEmitter();

  private appearDebounce: any;
  appearDelay: number;

  private intersectionObserver?: IntersectionObserver;

  constructor(
    private element: ElementRef
  ) {
    this.appearDelay = 400;
  }

  ngAfterViewInit() {
    this.intersectionObserver = new IntersectionObserver(entries => {
        this.checkForIntersection(entries);
    }, {});
    this.intersectionObserver.observe((this.element.nativeElement) as Element);
  }

  private checkForIntersection = (entries: Array<IntersectionObserverEntry>) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (this.checkIfIntersecting(entry)) {
        if (this.appearDebounce) {
          clearTimeout(this.appearDebounce);
        }

        this.appearDebounce = setTimeout(() => {
          this.deferLoad.emit();
          this.intersectionObserver.unobserve((this.element.nativeElement) as Element);
          this.intersectionObserver.disconnect();
        }, this.appearDelay);
      }
    });
  }

  private checkIfIntersecting(entry: IntersectionObserverEntry) {
    return (entry as any).isIntersecting && entry.target === this.element.nativeElement;
  }
}


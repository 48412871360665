import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Modules
import { CommonModule } from '../common/common.module';
import { IconsModule } from '../common/components/icons/icons.module';
import { InputsModule } from '../common/components/inputs/inputs.module';
import { MainModule } from '../main/main.module';
import { LayoutsModule } from '../layouts/layouts.module';

// Components
import { LayoutComponent } from './layout.component';
import { LayoutErrorComponent } from './layout-error/layout-error.component';
import { LayoutLoaderComponent } from './layout-loader/layout-loader.component';
import { SharedModule } from '../shared.module';

const modules = [
  LayoutComponent,
  LayoutErrorComponent,
  LayoutLoaderComponent
];

@NgModule({
  declarations: modules,
  exports: modules,
  imports: [
    AngularCommonModule,
    SharedModule,
    CommonModule,
    IconsModule,
    InputsModule,
    LayoutsModule,
    MainModule,
    RouterModule
  ],
  providers: []
})
export class LayoutModule { }

import { slideOutDuration, overlayDuration } from 'src/app/shared/common/animations/slide-out';
var NavbarAditionalInfoAnimation = /** @class */ (function () {
    function NavbarAditionalInfoAnimation() {
        this._animationState = 'out';
        this._showSlideOut = false;
    }
    Object.defineProperty(NavbarAditionalInfoAnimation.prototype, "state", {
        get: function () {
            return this._animationState;
        },
        set: function (state) {
            this._animationState = state;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavbarAditionalInfoAnimation.prototype, "showSlideOut", {
        get: function () {
            return this._showSlideOut;
        },
        set: function (show) {
            this._showSlideOut = show;
        },
        enumerable: true,
        configurable: true
    });
    NavbarAditionalInfoAnimation.prototype.showAnimation = function () {
        var _this = this;
        this.showSlideOut = true;
        setTimeout(function () {
            _this.state = 'in';
        });
    };
    NavbarAditionalInfoAnimation.prototype.closeAnimation = function () {
        var _this = this;
        this.state = 'out';
        setTimeout(function () {
            _this.showSlideOut = false;
        }, (Math.max(slideOutDuration, overlayDuration) + 50));
    };
    return NavbarAditionalInfoAnimation;
}());
export { NavbarAditionalInfoAnimation };

import * as tslib_1 from "tslib";
import { Project } from 'src/app/shared/classes/projects/project.class';
import { CheckoutConfig } from './checkout-config.class';
import { EModules } from '../../../../app.enum';
import { ERoutes } from 'src/app/shared/modules/payments/enums/routes.enum';
var Checkout = /** @class */ (function (_super) {
    tslib_1.__extends(Checkout, _super);
    function Checkout(params, company) {
        var _this = _super.call(this, params, company, EModules.CHECKOUT) || this;
        Object.assign(_this, {
            config: {}
        });
        return _this;
    }
    Checkout.prototype.getNavbar = function () {
        return [{
                icon: 'avc-filled-house',
                label: {
                    slug: 'navbar-booking'
                },
                route: ERoutes.BOOKING
            },
            {
                icon: 'avc-filled-card',
                label: {
                    slug: 'payment.one',
                },
                route: '/'
            }];
    };
    Checkout.prototype.update = function (data, update, cb) {
        try {
            var extraConfig = data.extraConfig, _a = data.booking, locale = _a.locale, payments = _a.payments, companyName = data.company.name;
            var config = new CheckoutConfig(extraConfig, payments);
            this.metaTitle.setTitle("Secure Checkout |\u00A0" + companyName);
            Object.assign(this, {
                config: config,
                expired: false,
                navbar: this.getNavbar(),
                navbarAditionalInfo: [],
                tabs: []
            });
            update({ locale: locale });
            this.setTranslations(cb);
        }
        catch (error) {
            this.errorHandler.handler(error);
        }
    };
    Checkout.prototype.getModules = function () {
        return {
            CHANGE_CARD: false,
            RESPONSIVE: {
                HIDE_HEADER: false
            },
            SIDEBAR: {
                CUSTOM_STATUS: false
            }
        };
    };
    return Checkout;
}(Project));
export { Checkout };

var _a;
export var CLIENT_BIRTHDATE = 'clientBirthDate';
export var CLIENT_BIRTHPLACE = 'clientBirthPlace';
export var CLIENT_CITY = 'clientCity';
export var CLIENT_EMAIL = 'clientEmail';
export var CLIENT_ID_ISSUED_AT = 'clientIdIssuedAt';
export var CLIENT_ID_ISSUED_ON = 'clientIdIssuedOn';
export var CLIENT_ID_NUMBER = 'clientIdNumber';
export var CLIENT_ID_TYPE = 'clientIdType';
export var CLIENT_NATIONALITY = 'clientNationality';
export var CLIENT_PROVINCE = 'clientProvince';
export var CLIENT_SECOND_SURNAME = 'clientSecondSurname';
export var CLIENT_TAX_ID = 'clientTaxId';
export var CLIENT_SUPPORT_NUMBER = 'clientSupportNumber';
export var AUTO_SUGGEST_CONTROLS = [CLIENT_CITY, CLIENT_ID_ISSUED_AT, CLIENT_PROVINCE, CLIENT_BIRTHPLACE];
export var DATE_CONTROLS = [CLIENT_BIRTHDATE, CLIENT_ID_ISSUED_ON];
export var DEFAULT_VALUES = (_a = {},
    _a[CLIENT_BIRTHPLACE] = { value: '' },
    _a[CLIENT_PROVINCE] = { value: '' },
    _a[CLIENT_CITY] = { value: '' },
    _a);

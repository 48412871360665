import * as get from 'lodash.get';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
var OccupantDetailUtils = /** @class */ (function () {
    function OccupantDetailUtils() {
        var injector = InjectorService.get();
        this.occupants = [];
        this.occupantStep = {};
        this.activatedRoute = injector.get(ActivatedRoute);
        this.config = injector.get(ConfigService);
        this.router = injector.get(Router);
        this.initConfig();
    }
    OccupantDetailUtils.prototype.initConfig = function () {
        var _this = this;
        var _a = this.config, _b = _a.booking, occupants = _b.occupantsData.list, creditCard = _b.creditCard, _c = _a.checkin, corporativeColor = _c.corporativeColor, _d = _c.config, creditCardRequired = _d.isCreditCardRequired, creditCardActivated = _d.isCreditCardActivated, showTravelInfoOnWizard = _d.showTravelInfoOnWizard;
        Object.assign(this, {
            corporativeColor: corporativeColor,
            creditCardActivated: creditCardActivated,
            creditCardRequired: creditCardRequired,
            hasCreditCard: creditCard.valid,
            occupants: occupants,
            showTravelInfoOnWizard: showTravelInfoOnWizard
        });
        this.hasCreditCardSubscription = creditCard.onChanges().subscribe(function (card) { return _this.hasCreditCard = card; });
    };
    Object.defineProperty(OccupantDetailUtils.prototype, "occupantId", {
        get: function () {
            return parseInt(this.activatedRoute.snapshot.params.occupantId, 10);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OccupantDetailUtils.prototype, "activeOccupant", {
        get: function () {
            var _this = this;
            return this.occupants.find(function (occupant) { return occupant.id === _this.occupantId; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OccupantDetailUtils.prototype, "isMainOccupant", {
        get: function () {
            return get(this.activeOccupant, 'isMainOccupant', false);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OccupantDetailUtils.prototype, "isChild", {
        get: function () {
            return get(this.activeOccupant, 'isChild', false);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OccupantDetailUtils.prototype, "mainOccupant", {
        get: function () {
            return this.occupants.find(function (occupant) { return occupant.isMainOccupant; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OccupantDetailUtils.prototype, "isMainOccupantComplete", {
        get: function () {
            return get(this.mainOccupant, 'completed', false);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OccupantDetailUtils.prototype, "addCreditCard", {
        get: function () {
            return this.creditCardRequired && !this.hasCreditCard;
        },
        enumerable: true,
        configurable: true
    });
    OccupantDetailUtils.prototype.ngOnDestroy = function () {
        if (this.hasCreditCardSubscription) {
            this.hasCreditCardSubscription.unsubscribe();
        }
    };
    return OccupantDetailUtils;
}());
export { OccupantDetailUtils };

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PaymentListItem } from '../payments-detail/payment-list.model';

@Component({
  selector: 'app-payments-slideout',
  templateUrl: './payments-slideout.component.html',
  styleUrls: ['./payments-slideout.component.scss']
})

export class PaymentsSlideoutComponent {
  @Input() payments: PaymentListItem[];

  @Input() preventCloseSlideOut: boolean;
  @Output() closeSlideOut: EventEmitter<boolean>;
  @Output() preventCloseSlideOutChange: EventEmitter<boolean>;

  constructor() {
    this.closeSlideOut = new EventEmitter<boolean>();
    this.preventCloseSlideOutChange = new EventEmitter<boolean>();
  }

  public closeSlideOutHandler(): void {
    if (this.preventCloseSlideOut) {
      return;
    }

    this.closeSlideOut.emit();
  }
}

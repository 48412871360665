import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// Modules
import { CardModule } from './card/card.module';
import { CommonModule } from '../../common/common.module';
import { OccupantDetailModule } from './occupant-detail/occupant-detail.module';
import { OccupantListModule } from './occupant-list/occupant-list.module';

// Components
import { BodyComponent } from './body.component';
import { TermsAndConditionsModule } from 'src/app/shared/pages/terms-and-conditions/terms-and-conditions.module';
import { SharedModule } from 'src/app/shared/shared.module';

const allModules = [BodyComponent];

@NgModule({
  declarations: allModules,
  exports: allModules,
  imports: [
    AngularCommonModule,
    CardModule,
    SharedModule,
    CommonModule,
    FormsModule,
    OccupantDetailModule,
    OccupantListModule,
    ReactiveFormsModule,
    RouterModule,
    TermsAndConditionsModule
  ]
})
export class BodyModule { }

import { map, catchError } from 'rxjs/operators';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { parseCheckinForm } from './occupant-form.utils';
import { formatUrlQuery } from 'src/app/shared/common/utils/requests';
import { ConfigService } from 'src/app/shared/services/config/config.service';
var OccupantFormService = /** @class */ (function () {
    function OccupantFormService(config, http, env) {
        this.config = config;
        this.http = http;
        this.env = env;
        this.hash = this.config.checkin.hash;
        this.baseUrl = this.env.endpoint + "/v2/config/checkin/form/" + this.hash;
    }
    OccupantFormService.prototype.updateCheckinFormRequest = function (url, controls, close) {
        var action = close ? 'patch' : 'put';
        return this.http[action](url, controls).pipe(map(function (res) { return res; }), catchError(function (error) { throw error; }));
    };
    OccupantFormService.prototype.getCheckinForm = function (occupantId, inputs) {
        if (inputs === void 0) { inputs = []; }
        var url = this.baseUrl + "/" + occupantId;
        return this.http.get(formatUrlQuery(url, 'inputs', inputs)).pipe(map(function (res) { return res ? parseCheckinForm(res.form) : []; }), catchError(function (error) { throw error; }));
    };
    OccupantFormService.prototype.updateCheckinForm = function (occupantId, controls) {
        var url = this.baseUrl + "/" + occupantId;
        return this.updateCheckinFormRequest(url, controls, false);
    };
    OccupantFormService.prototype.closeCheckinForm = function (occupantId, controls) {
        var url = this.baseUrl + "/" + occupantId;
        return this.updateCheckinFormRequest(url, controls, true);
    };
    Object.defineProperty(OccupantFormService.prototype, "updatedFormValues", {
        get: function () {
            return Array.isArray(this._updatedFormValues) ? this._updatedFormValues : [];
        },
        set: function (values) {
            this._updatedFormValues = values;
        },
        enumerable: true,
        configurable: true
    });
    return OccupantFormService;
}());
export { OccupantFormService };

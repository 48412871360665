import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';

// Modules
import { SharedModule } from 'src/app/shared/shared.module';

// Components
import { TooltipComponent } from './tooltip.component';
import { AppearDirective } from '../../directives/appear.directive';

const allModules = [
  AppearDirective,
  TooltipComponent
];

@NgModule({
  declarations: allModules,
  exports: allModules,
  imports: [
    AngularCommonModule,
    SharedModule
  ]
})
export class TooltipModule { }

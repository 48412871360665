import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { LANGUAGES_MAP, SIZE_MAP } from './languages.static';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { Subscription } from 'rxjs';

interface LanguagesType extends Array<{ code: string; name: string }> {}

@Component({
  selector: 'app-input-language',
  templateUrl: './input-language.component.html',
  styleUrls: ['./input-language.component.scss']
})

export class InputLanguageComponent implements AfterViewInit, OnDestroy {
  private subscriptions: Subscription[];
  private checkinLanguages: string[];

  public form: FormGroup;
  public layout: { [key: string]: boolean; };
  public isResponsive: boolean;
  public options: LanguagesType;
  public selectedSize: string;
  public showSelector: boolean;

  constructor(
    private config: ConfigService,
    fb: FormBuilder
  ) {
    const {
      company: {
        languages
      },
      locale
    } = this.config;

    this.checkinLanguages = languages;
    this.options = this.getParsedLanguages();
    this.showSelector = this.options.length > 0;

    const appLocale = locale.get();
    const defaultLocale = locale.default();
    const localeText = LANGUAGES_MAP[appLocale] ? appLocale : defaultLocale;
    const companyHasSelectedLocale = this.options.find(({ code }) => code === appLocale);
    const formValue = Boolean(companyHasSelectedLocale) ? appLocale : defaultLocale;

    this.setSelectedSize(localeText);

    this.form = fb.group({
      language: new FormControl(formValue)
    });

    this.subscriptions = [];

    this.setLayoutConfig(this.config.screen.isLG);
    this.setResponsive();
  }

  public ngAfterViewInit(): void {
    this.onLanguageChange();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((e: Subscription) => e.unsubscribe());
  }

  private setSelectedSize(language: string): void {
    this.selectedSize = SIZE_MAP[language] || '135px';
  }

  private onLanguageChange(): void {
    this.form.get('language').valueChanges.subscribe((locale) => {
      this.config.locale.set(locale, true);
    });
  }

  private getParsedLanguages(): LanguagesType {
    return Object.entries(LANGUAGES_MAP).map((lang) => {
      const [code, name] = lang;

      if (this.checkinLanguages.includes(code)) {
        return { code, name };
      }
    }).filter(Boolean);
  }

  private setResponsive(): void {
    const s: Subscription = this.config.screen.onIsLGChanges().subscribe((i: boolean) => this.setLayoutConfig(i));

    this.subscriptions.push(s);
  }

  private setLayoutConfig(isResponsive: boolean): void {
    this.layout = {
      responsive: isResponsive && ((this.config[this.config.project].modules.RESPONSIVE as any).HIDE_HEADER)
    };
  }
}

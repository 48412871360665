import * as tslib_1 from "tslib";
import { CheckinConfig } from 'src/app/shared/classes/projects/checkin/checkin-config.class';
import { Project } from 'src/app/shared/classes/projects/project.class';
import { EModules } from '../../../../app.enum';
import { ERoutes as EGuestRoutes } from 'src/app/checkin-app/modules/checkin/pages/guests/enums/routes.enum';
import { ERoutes } from 'src/app/shared/enums/routes.enum';
import { EProjects } from 'src/app/shared/enums/projects.enum';
import { EBookingStatus } from 'src/app/shared/enums/booking-status.enum';
import { CreditCard } from 'src/app/shared/classes/credit-card/credit-card.class';
import * as get from 'lodash.get';
var Checkin = /** @class */ (function (_super) {
    tslib_1.__extends(Checkin, _super);
    function Checkin(params, company) {
        var _this = _super.call(this, params, company, EModules.CHECKIN) || this;
        Object.assign(_this, {
            tabs: {},
            config: {}
        });
        return _this;
    }
    Checkin.prototype.update = function (data, update, cb) {
        var _a = data.checkinDetails, _b = _a.completed, completed = _b === void 0 ? false : _b, _c = _a.delegated, delegated = _c === void 0 ? false : _c, extraConfig = data.extraConfig, _d = data.booking, locale = _d.locale, status = _d.status, companyName = data.company.name, secureUrl = data.secureUrl;
        var config = new CheckinConfig(tslib_1.__assign({}, extraConfig, { delegated: delegated }));
        var preventGuestOptions = (status === EBookingStatus.PETICION_INFORMACION);
        var creditCard = new CreditCard(tslib_1.__assign({ number: get(data.booking.card, 'code'), updatable: get(data.booking.card, 'canChange'), type: get(data.booking.card, 'type') }, ((get(data.booking.card, 'name') || get(data.booking.card, 'billingAddress')) && {
            owner: tslib_1.__assign({}, get(data.booking.card, 'billingAddress', {}), { name: get(data.booking.card, 'name') })
        })));
        this.metaTitle.setTitle("Online Check-in |\u00A0" + companyName);
        Object.assign(this, {
            completed: completed,
            delegated: delegated,
            expired: this.company.isExpiredModule(EModules.CHECKIN),
            config: config,
            tabs: this.getTabs(config, preventGuestOptions, data.booking.occupants),
            navbar: this.getNavbar(config, preventGuestOptions),
            creditCard: creditCard,
            navbarAditionalInfo: this.getNavbarAditionalInfo(data.company, config, creditCard),
            secureUrl: secureUrl
        });
        update({ locale: locale });
        // Set translations must be after LocaleLoader
        this.setTranslations(cb);
    };
    Checkin.prototype.getNavbarAditionalInfo = function (company, config, creditCard) {
        // tslint:disable-next-line:max-line-length
        var routes = ((config.isCreditCardActivated && (!config.isCreditCardRequired || creditCard.valid) && config.showCreditCardComponent) ? [{
                icon: 'avc-icon-card',
                label: {
                    slug: 'navbar-aditional-info-card'
                },
                route: ERoutes.CARD
            }] : []).concat([
            // {
            //   icon: 'avc-icon-booking',
            //   label: {
            //     slug: 'navbar-aditional-info-booking'
            //   },
            //   action: 'navigateToIntranet'
            // },
            {
                icon: 'avc-icon-language',
                component: 'INPUT_LANGUAGE'
            }
            // , {
            //   icon: 'avc-icon-about',
            //   label: {
            //     slug: 'navbar-aditional-info-company',
            //     params: { company: company.name }
            //   }
            // }
        ]);
        return routes;
    };
    Checkin.prototype.getNavbar = function (config, preventGuestOptions) {
        return [{
                icon: 'avc-filled-house',
                label: {
                    slug: 'navbar-booking'
                },
                route: ERoutes.BOOKING
            },
            {
                deactivated: preventGuestOptions,
                icon: 'avc-check-in',
                label: {
                    slug: 'navbar-checkin'
                },
                route: EGuestRoutes.GUESTS
            },
            {
                // TODO:: Replace this.getRouteComments() by this.inbox.enabled
                deactivated: (!config.showComments && this.getRouteComments() !== EGuestRoutes.INBOX),
                icon: 'avc-chat',
                label: {
                    slug: 'chat'
                },
                route: this.getRouteComments()
            },
            {
                deactivated: (!config.showTravelInfo || preventGuestOptions),
                icon: 'avc-info',
                label: {
                    slug: 'navbar-arrival-info'
                },
                route: EGuestRoutes.TRAVEL_INFO
            },
            {
                icon: 'avc-icon-hmb',
                action: 'showAditionalInfo',
                label: {
                    slug: 'menu'
                }
            }].filter(function (_a) {
            var deactivated = _a.deactivated;
            return !deactivated;
        });
    };
    Checkin.prototype.getTabs = function (config, preventGuestOptions, occupants) {
        var routeComments = this.getRouteComments();
        var inboxEnabled = this.company.isEnabledModule(EGuestRoutes.INBOX);
        var completado = false;
        var validado = false;
        for (var _i = 0, occupants_1 = occupants; _i < occupants_1.length; _i++) {
            var occupant = occupants_1[_i];
            if (occupant.idComprador) {
                completado = true;
            }
            if (occupant.validated) {
                validado = true;
            }
        }
        var guestOptions = !preventGuestOptions ? [
            {
                label: 'guests',
                route: EGuestRoutes.GUESTS
            }
        ].concat((config.showTravelInfo && !config.delegated ? [{
                label: 'organize-your-trip',
                route: EGuestRoutes.TRAVEL_INFO
            }] : [])) : [];
        var chatOrCommentsTab = (config.showComments || inboxEnabled) && (!config.delegated || (completado && validado)) ? [{
                label: inboxEnabled ? 'chat' : 'comments',
                route: routeComments
            }] : [];
        return guestOptions.concat(chatOrCommentsTab);
    };
    Checkin.prototype.getRouteComments = function () {
        return this.company.isEnabledModule(EProjects.INBOX) ? EGuestRoutes.INBOX : EGuestRoutes.COMMENTS;
    };
    return Checkin;
}(Project));
export { Checkin };

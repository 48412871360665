import { NgModule } from '@angular/core';
import { CookieService } from './cookie/cookie.service';
import { CheckinHttpClient } from './http/http-client.service';
import { WebsocketService } from './websocket/websocket.service';

@NgModule({
  providers: [
    CookieService,
    CheckinHttpClient,
    WebsocketService
  ]
})
export class ServicesModule { }

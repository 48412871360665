import * as tslib_1 from "tslib";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { whiteSpaceValidator } from 'src/app/shared/validators';
var CreditCard = /** @class */ (function () {
    function CreditCard(params) {
        Object.assign(this, {
            injector: InjectorService.get(),
            onChangesSubject: new Subject()
        });
        this.set(params, { emitEvent: false });
    }
    Object.defineProperty(CreditCard.prototype, "updatable", {
        get: function () {
            return this._updatable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreditCard.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreditCard.prototype, "number", {
        get: function () {
            return this._number;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreditCard.prototype, "firstDigits", {
        get: function () {
            return this._firstDigits;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreditCard.prototype, "lastDigits", {
        get: function () {
            return ("" + this._number).replace(/[^A-Z0-9]+/ig, '');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreditCard.prototype, "type", {
        get: function () {
            return this._type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreditCard.prototype, "owner", {
        get: function () {
            return this._owner;
        },
        enumerable: true,
        configurable: true
    });
    CreditCard.prototype.getAddress = function (owner) {
        if (!owner) {
            return '';
        }
        var values = (owner.postalCode ? [owner.postalCode] : []).concat((owner.city ? [owner.city] : []), (owner.province ? [owner.province] : []), (owner.country ? [owner.country] : []));
        return "" + (owner.street ? owner.street + " <br />" : '') + (values.length > 0 ? values.join(', ') : '');
    };
    CreditCard.prototype.set = function (params, options) {
        if (options === void 0) { options = { emitEvent: true }; }
        var _a = Object.entries(params || {})
            .reduce(function (res, _a) {
            var _b;
            var key = _a[0], value = _a[1];
            return (tslib_1.__assign({}, res, (_b = {}, _b["_" + key] = value, _b)));
        }, {
            valid: Boolean(params.number)
        }), _owner = _a._owner, _updatable = _a._updatable, config = tslib_1.__rest(_a, ["_owner", "_updatable"]);
        Object.assign(this, tslib_1.__assign({}, config, { _updatable: _.has(params, 'updatable') ? _updatable : true, _owner: tslib_1.__assign({}, _owner, { address: this.getAddress(_owner) }) }));
        if (options.emitEvent) {
            this.onChangesSubject.next(true);
        }
    };
    CreditCard.prototype.onChanges = function () {
        return this.onChangesSubject.asObservable();
    };
    CreditCard.prototype._form = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var getControlValue = function (controlName) {
            if (!options.filled) {
                return null;
            }
            return _.get({
                // name: this._owner.name,
                // pan: this._number,
                address: _this._owner.street,
                postalCode: _this._owner.postalCode,
                phoneNumber: _this._owner.phoneNumber,
                region: _this._owner.province,
                city: _this._owner.city,
                country: _this._owner.idCountry,
                firstname: _this._owner.firstname,
                lastname: _this._owner.lastname,
                email: _this._owner.email
            }, controlName, null);
        };
        var getControlValidators = function (controlName) {
            switch (controlName) {
                case 'month':
                case 'year':
                case 'country':
                    return [Validators.required];
                case 'city':
                case 'region':
                    return [
                        Validators.required,
                        whiteSpaceValidator
                    ];
                case 'email':
                    return [
                        Validators.required,
                        Validators.email
                    ];
                default:
                    return [
                        Validators.required,
                        whiteSpaceValidator
                    ];
            }
        };
        return [
            'name',
            'pan',
            'cvc',
            'month',
            'year',
            'address',
            'postalCode',
            'phoneNumber',
            'region',
            'city',
            'country',
            'firstname',
            'lastname',
            'email'
        ].reduce(function (formGroup, control) {
            formGroup.addControl(control, new FormControl(getControlValue(control), getControlValidators(control)));
            return formGroup;
        }, new FormGroup({}, {
            validators: [
                function (fg) {
                    var today = new Date();
                    var _a = fg.value, month = _a.month, year = _a.year;
                    if (month && year && (moment(today.getMonth() + 1 + "/" + today.getFullYear(), 'MM/YYYY').isAfter(moment(month + "/" + year, 'MM/YYYY')))) { // tslint:disable-line
                        fg.controls.month.setErrors({ invalidDate: true });
                    }
                    return null;
                }
            ]
        }));
    };
    Object.defineProperty(CreditCard.prototype, "form", {
        get: function () {
            return this._form();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreditCard.prototype, "filledForm", {
        get: function () {
            return this._form({ filled: true });
        },
        enumerable: true,
        configurable: true
    });
    CreditCard.prototype.setBillingAddress = function (owner) {
        Object.assign(this, {
            _owner: tslib_1.__assign({}, this._owner, owner, { address: this.getAddress(owner) })
        });
        this.onChangesSubject.next(true);
    };
    return CreditCard;
}());
export { CreditCard };

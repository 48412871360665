import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { idRegexp, passportRegexp } from './occupant-form-regexp';
import { CLIENT_EMAIL } from './occupant-form-controls';

export function genericIdValidator(fc: FormControl) {
  return idRegexp.test(fc.value) ? null : { documentIdPatternError: true };
}

const CODES = 'TRWAGMYFPDXBNJZSQVHLCKET';
const REGEX = new RegExp('^([xyzZYX])?([0-9]{7,8})([a-zA-Z])$');
export function spanishIdValidator(fc: FormControl) {
  const { value = '' } = fc;
  const match = value.trim().match(REGEX);

  if (match) {
    const [_, prefix = '0', n, letter = ''] = match;
    const pre = prefix.replace('X', '0').replace('Y', '1').replace('Z', '2');
    const index = Number(`${pre}${n}`) % 23;

    if (CODES[index] === letter.toUpperCase()) {
      return null;
    }
  }

  return { documentIdPatternError: true };
}

export function passportValidator(fc: FormControl) {
  return passportRegexp.test(fc.value) ? null : { documentPassportPatternError: true };
}

export const customControlValidators: { [id: string]: ValidatorFn[] } = {
  [CLIENT_EMAIL]: [Validators.email]
};

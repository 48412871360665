import * as get from 'lodash.get';
import * as Color from 'color';
import { ActivatedRoute, Router } from '@angular/router';
import { Injector, OnDestroy } from '@angular/core';

import { OccupantsList, OccupantListDetailItem } from '../occupant-list/occupant-list.interface';
import { OccupantStep } from './occupant-detail.interface';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { Subscription } from 'rxjs';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';

export class OccupantDetailUtils implements OnDestroy {
  protected activatedRoute: ActivatedRoute;
  protected config: ConfigService;
  protected router: Router;

  creditCardActivated: boolean;
  creditCardRequired: boolean;
  isSignatureActivated: boolean;
  showTravelInfoOnWizard: boolean;

  occupants: OccupantsList;
  occupantStep: OccupantStep;

  stepsLinks: { prev?: string, next?: string };

  corporativeColor: Color;

  public hasCreditCard: boolean;
  private hasCreditCardSubscription: Subscription;

  constructor() {
    const injector: Injector = InjectorService.get();

    this.occupants = [] as OccupantsList;
    this.occupantStep = {} as OccupantStep;
    this.activatedRoute = injector.get(ActivatedRoute);
    this.config = injector.get(ConfigService);
    this.router = injector.get(Router);

    this.initConfig();
  }

  initConfig() {
    const {
      booking: {
        occupantsData: {
          list: occupants
        },
        creditCard
      },
      checkin: {
        corporativeColor,
        config: {
          isCreditCardRequired: creditCardRequired,
          isCreditCardActivated: creditCardActivated,
          showTravelInfoOnWizard
        }
      }
    } = this.config;

    Object.assign(this, {
      corporativeColor,
      creditCardActivated,
      creditCardRequired,
      hasCreditCard: creditCard.valid,
      occupants,
      showTravelInfoOnWizard
    });

    this.hasCreditCardSubscription = creditCard.onChanges().subscribe((card) => this.hasCreditCard = card);
  }

  get occupantId(): number {
    return parseInt(this.activatedRoute.snapshot.params.occupantId, 10);
  }

  get activeOccupant(): OccupantListDetailItem {
    return this.occupants.find((occupant) => occupant.id === this.occupantId);
  }

  get isMainOccupant(): boolean {
    return get(this.activeOccupant, 'isMainOccupant', false);
  }

  get isChild(): boolean {
    return get(this.activeOccupant, 'isChild', false);
  }

  get mainOccupant(): OccupantListDetailItem {
    return this.occupants.find((occupant) => occupant.isMainOccupant);
  }

  get isMainOccupantComplete(): boolean {
    return get(this.mainOccupant, 'completed', false);
  }

  get addCreditCard(): boolean {
    return this.creditCardRequired && !this.hasCreditCard;
  }

  public ngOnDestroy(): void {
    if (this.hasCreditCardSubscription) {
      this.hasCreditCardSubscription.unsubscribe();
    }
  }
}

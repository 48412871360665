export const ErrorMessages = {
  occupantMustBeLegalAge: 'age-of-mayority-info',
  minimumAge: 'age-of-mayority-info',
  required: 'input-required',
  whitespace: 'input-required',
  validCC: 'input-valid-cc',
  email: 'invalid-email',
  invalidDate: 'invalid-date',
  invalidLocalizer: 'invalid-localizer',
  dateLowerCurrentDate: 'date-lower-current-date',
  documentIdPatternError: 'document-id-pattern-error',
  documentPassportPatternError: 'passport-pattern-error',
  pattern: 'only-text-pattern-error'
};

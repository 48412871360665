import { Injectable } from '@angular/core';
import { NavBarPagesType } from './main.enum';
import { ECookies } from '../enums/cookie.enum';

@Injectable()
export class MainService {
  public activePageValue: NavBarPagesType;
  public showCardResponsive: boolean;

  constructor() { }

  toogleCardResponsive() {
    this.showCardResponsive = !this.showCardResponsive;
  }

  get activePage(): NavBarPagesType {
    return this.activePageValue;
  }

  set activePage(page: NavBarPagesType) {
  }

  initActivePage(defaultPage: NavBarPagesType): void {
  }
}

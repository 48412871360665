var LogoManager = /** @class */ (function () {
    function LogoManager(url, ranges) {
        if (ranges === void 0) { ranges = []; }
        this._url = url;
        this._ranges = [
            { break: 320, default: { height: 'auto', width: 200 }, square: { height: 45, width: 'auto' }, vertical: { height: 110, width: 'auto' } },
            { break: 576, default: { height: 'auto', width: 200 }, square: { height: 50, width: 'auto' }, vertical: { height: 110, width: 'auto' } },
            { break: 992, default: { height: 'auto', width: 200 }, square: { height: 60, width: 'auto' }, vertical: { height: 110, width: 'auto' } }
        ].concat(ranges);
        this._size = {};
        this.getImageAspectRatio();
    }
    Object.defineProperty(LogoManager.prototype, "url", {
        get: function () {
            return this._url;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LogoManager.prototype, "height", {
        get: function () {
            return this._size.height;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LogoManager.prototype, "width", {
        get: function () {
            return this._size.width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LogoManager.prototype, "size", {
        set: function (size) {
            this._size = {
                height: (typeof size.height === 'string') ? size.height : size.height + "px",
                width: (typeof size.width === 'string') ? size.width : size.width + "px"
            };
        },
        enumerable: true,
        configurable: true
    });
    LogoManager.prototype.getImageAspectRatio = function () {
        var _this = this;
        var img = new Image();
        img.onload = function () {
            var _a = _this._getNewSizeImage({ height: img.height, width: img.width }), type = _a.type, dimensions = _a.dimensions;
            var newSize = function () {
                if (type !== 'default') {
                    return {
                        height: (img.height > dimensions.height) ? dimensions.height : img.height,
                        width: dimensions.width
                    };
                }
                return {
                    height: dimensions.height,
                    width: (img.width > dimensions.width) ? dimensions.width : img.width,
                };
            };
            _this.size = newSize();
        };
        img.src = this.url;
    };
    LogoManager.prototype._getNewSizeImage = function (size) {
        var height = size.height, width = size.width;
        var rest = (+height - +width);
        var type = rest >= 0 && rest <= 10 ? 'square' : rest > 10 ? 'vertical' : 'default';
        // TODO:: Use config.screen
        var range = this._ranges.find(function (r) { return (window.innerWidth <= r.break); }) || this._ranges[this._ranges.length - 1];
        return {
            type: type,
            dimensions: range[type]
        };
    };
    return LogoManager;
}());
export { LogoManager };

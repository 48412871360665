import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { ArrivalIconComponent } from './arrival-icon/arrival-icon.component';
import { DepartureIconComponent } from './departure-icon/departure-icon.component';
import { LoaderComponent } from './loader/loader.component';

// Services

const allModules = [
  ArrivalIconComponent,
  DepartureIconComponent,
  LoaderComponent
];

@NgModule({
  declarations: allModules,
  exports: allModules,
  imports: [
    CommonModule
  ]
})
export class IconsModule { }

import {
  ErrorHandler,
  Injectable,
  Injector
} from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import * as _ from 'lodash';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { CookieService } from 'src/app/shared/services/cookie/cookie.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { environment } from 'src/environments/environment';
import { VERSION } from 'src/environments/version';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  isProduction: boolean;
  showErrors = false;
  sentErrors: any;

  cookieService: CookieService;
  config: ConfigService;

  constructor() {
    this.initConfig();
  }

  private initConfig(): void {
    this.sentErrors = {};
    this.isProduction = environment.production;

    setTimeout(() => {
      const injector: Injector = InjectorService.get();

      this.config = injector.get(ConfigService);
      this.cookieService = injector.get(CookieService);
      this.showErrors = Boolean(this.cookieService.get('show-errors'));
    });

    if (this.isProduction) {
      Sentry.init({
        dsn: environment.sentryDSN,
        release: VERSION
      });
    }
  }

  public handleError(err: any): void {
    const error = err.originalError || err;
    const { message } = error;

    if (err instanceof HttpErrorResponse) {
      this.onHttpErrorResponse(err);
      return;
    }

    if (!message) {
      return;
    }

    if (/Loading chunk [\d]+ failed/.test(err.message)) {
      const hash: string = _.get(this.config, `${this.config.project}.hash`, '');

      if (hash && window.location.href.includes(hash)) {
        window.location.reload();
        return;
      }

      window.location.href = window.location.origin;
      return;
    }

    if (!this.isProduction || this.showErrors) {
      console.log(err);
    }

    if (this.isProduction && !this.sentErrors[message]) {
      this.sentErrors[message] = true;
      Sentry.captureException(error);
    }
  }

  private onHttpErrorResponse(err: HttpErrorResponse): void {
    if (this.isProduction) {
      Sentry.captureException(err);
    }

    if (err.status === 403) {
      const { error: { reasons } } = err as any;
      const url = window.location.origin;

      if (reasons.includes('STATUS_NOT_ALLOWED')) {
        window.location.href = url;
        return;
      }

      const project = this.config.project;
      const projectConfig = this.config[project];
      if (projectConfig.expired) {
        window.location.href = projectConfig.fallbackUrl;
      }
    }
  }
}

import {
  Component,
  AfterViewInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';
import * as _ from 'lodash';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { StylesService } from 'src/app/shared/common/components/buttons/styles.service';
import { Subject, Subscription } from 'rxjs';

type TPosition = 'bottom' | 'top' | 'static-top';

@Component({
  selector: 'app-page-up',
  templateUrl: './page-up.component.html',
  styleUrls: ['./page-up.component.scss']
})

export class PageUpComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
  @ViewChild('fullEl', { static: false }) fullEl: ElementRef;
  @Input() positionSub: Subject<TPosition>;
  @Input() type: 'middle' | 'full' | 'top';
  @Input() maxHeight: number;
  @Input() minHeight: number;
  @Input() scroll: boolean;
  @Output() scrollEvent: EventEmitter<boolean> = new EventEmitter<boolean>();


  private subscriber: Subscription;
  public position: TPosition;
  public bigWidth: boolean;

  constructor(
    private cdRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.subscribe();
  }

  public ngOnDestroy(): void {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

  public ngAfterViewInit(): void {
    if (this.type === 'full') {
      this.setFullElHeight();
    }

    this.position = _.get(
      { middle: 'bottom' },
      this.type,
      this.type
    );

    const width = window.innerWidth;
    this.bigWidth = width > 475;

    this.cdRef.detectChanges();
  }

  public ngOnChanges(): void {
    this.setCustomHeight();
  }

  private subscribe(): void {
    if (!this.positionSub) {
      return;
    }

    this.subscriber = this.positionSub.asObservable().subscribe((p) => this.position = p);
  }

  private setFullElHeight(): void {
    const height: number = this.fullEl.nativeElement.offsetHeight;

    InjectorService.get().get(StylesService).set({
      '.checkin__page__up__top--full--top': {
        'min-height': `${height}px`,
      }
    });
  }

  private setCustomHeight(): void {
    InjectorService.get().get(StylesService).set({
      '.checkin__page__up__top--full': {
        'max-height': `${this.minHeight}px`
      },
      '.checkin__page__up__top--full--top': {
        'min-height' : `${this.maxHeight}px !important`
      }
    });
  }

  public onScroll(): void {
    this.position = 'top';
    this.scrollEvent.emit(true);
  }
}

import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { InputBase } from '../input-base';
import { SelectDataOptionsType } from 'src/app/shared/common/common.interfaces';
import { SelectDataService } from 'src/app/shared/common/services/select-data.service';
import { iconEnter } from './input-select.animation';
import { ConfigService } from 'src/app/shared/services/config/config.service';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  animations: [iconEnter]
})

export class InputSelectComponent extends InputBase implements OnInit, OnChanges {
  @Input() options: SelectDataOptionsType[];
  @Input() service: string;
  @Input() serviceOption: string;
  @Input() showPlaceholder = true;
  @Input() disabledOption = false;

  public iOS: boolean;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private dataService: SelectDataService,
    public config: ConfigService
  ) {
    super();

    this.options = [];
    this.iOS = this.config.screen.isIOS;
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.hasOptionsByDefault()) {
      this.getServiceOptions();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.serviceOption) {
      this.getServiceOptions();
    }
  }

  private hasOptionsByDefault(): boolean {
    return Array.isArray(this.options) && this.options.length > 0;
  }

  setLoading(loading: boolean) {
    super.setLoading(loading);

    this.changeDetector.detectChanges();
    this.form.get(this.control)[this.loadingSave ? 'disable' : 'enable']();
  }

  getServiceOptions() {
    this.dataService.get(this.service, this.serviceOption).subscribe(
      (options: SelectDataOptionsType[]) => {
        this.options = options;

        if (this.control === 'hour') {
          const currentValue = this.form.get(this.control).value;
          const optionExists = this.options.some((option) => option.code === currentValue);

          if (!optionExists && this.options.length > 0) {
            this.form.get(this.control).setValue(this.options[0].code);
          }
        }

        if (this.control === 'clientIdIssuedAt') {
          const optionToSet = this.options.find((option) => option.code === this.form.get(this.control).value.code);
          this.form.get(this.control).setValue(optionToSet);
        }
      }, (err: Error) => {
        console.log(err);
      }
    );
  }
}

import * as Sentry from '@sentry/browser';
import { Injector } from '@angular/core';
import { EnvService } from '../common/services/env.service';
import { of, Observable } from 'rxjs';
import { InjectorService } from '../services/injector/injector.service';

export class ErrorHandler {
  private env: EnvService;

  constructor() {
    const injector: Injector = InjectorService.get();

    this.env = injector.get(EnvService);
  }

  public handler(err: Error): Observable<boolean> {
    if (this.env.isProduction) {
      Sentry.captureException(err);
    }

    window.location.href = '//' + ((this.env.defaultDomain === 'secure.avantio.com') ? this.env.defaultDomain : this.env.defaultDomain.replace('secure.', ''));

    return of(false);
  }
}

export const LANGUAGES_MAP = {
  en_GB: 'English',
  es_ES: 'Español',
  de_DE: 'Deutsch',
  it_IT: 'Italiano',
  da_DK: 'Dansk',
  nl_NL: 'Nederlands',
  pt_PT: 'Português',
  pl_PL: 'Poland',
  fr_FR: 'Français',
  zh_ZH: '中文',
  // ko_KR: 'Korean',
  // ca_ES: 'Catalán',
  // ru_RU: 'Ruso',
  // tr_TR: 'Turkish',
  en_US: 'English (US)',
  pt_BR: 'Português (BR)',
  // ja_JP: 'Japanese',
  sv_SV: 'Svenska',
  es_MX: 'Español (MX)'
};

export const SIZE_MAP = {
  es_ES: '73px',
  en_GB: '69px',
  de_DE: '76px',
  it_IT: '70px',
  da_DK: '63px',
  nl_NL: '61px',
  pt_PT: '92px',
  pl_PL: '68px',
  fr_FR: '77px',
  zh_ZH: '75px',
  ko_KR: '67px',
  ca_ES: '71px',
  ru_RU: '53px',
  tr_TR: '68px',
  en_US: '103px',
  pt_BR: '125px',
  ja_JP: '84px',
  sv_SV: '74px',
  es_MX: '110px'
};

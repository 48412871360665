import { OnDestroy, ChangeDetectorRef, Injector, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { EBookingStatus } from 'src/app/shared/enums/booking-status.enum';
import { BookingStatus } from './booking-status.class';
var BookingStatusComponent = /** @class */ (function () {
    function BookingStatusComponent(injector, cdRef) {
        var _a;
        this.injector = injector;
        this.cdRef = cdRef;
        Object.assign(this, {
            config: this.injector.get(ConfigService),
            bookingStatus: new BookingStatus(),
        });
        this.status = this.config.booking.resume.status;
        this.infoStatus = (_a = {},
            _a[EBookingStatus.CANCELLED] = {
                icon: 'avc-cross-circle',
                title: 'cancelled',
                description: 'booking-cancelled'
            },
            _a[EBookingStatus.PETICION_DISPONIBILIDAD] = {
                icon: 'avc-icon-alert',
                title: 'pending',
                description: 'booking-pending'
            },
            _a[EBookingStatus.PETICION_INFORMACION] = {
                title: 'info-request-title',
                description: 'info-request-text',
                class: 'request'
            },
            _a[EBookingStatus.CONFIRMADA] = {
                icon: 'avc-check-circle',
                title: 'hide-total-price-confirmed-booking-title',
                description: 'hide-total-price-confirmed-booking-description',
            },
            _a[EBookingStatus.CONFIRMED] = {
                icon: 'avc-check-circle',
                title: 'hide-total-price-confirmed-booking-title',
                description: 'hide-total-price-confirmed-booking-description',
            },
            _a[EBookingStatus.PAID] = {
                icon: 'avc-check-circle',
                title: 'hide-total-price-confirmed-booking-title',
                description: 'hide-total-price-confirmed-booking-description',
            },
            _a[EBookingStatus.UNPAID] = {
                icon: 'avc-check-circle',
                title: 'hide-total-price-confirmed-booking-title',
                description: 'hide-total-price-confirmed-booking-description',
            },
            _a[EBookingStatus.FACTURADA] = {
                icon: 'avc-check-circle',
                title: 'hide-total-price-confirmed-booking-title',
                description: 'hide-total-price-confirmed-booking-description',
            },
            _a);
    }
    BookingStatusComponent.prototype.subscribe = function () {
        var _this = this;
        this.setPaymentsSizeConfig();
        this.subscriber = this.config.screen.onWindowResizeChanges().subscribe(function () { return _this.setPaymentsSizeConfig(); });
    };
    BookingStatusComponent.prototype.setPaymentsSizeConfig = function () {
        if (!this.config.screen.isLG) {
            var is2k = !this.config.screen.isXL;
            var height = this.bookingStatus.getHeightSidebarStatus(is2k);
            document
                .querySelector('.checkin__sidebar__wrapper')
                .setAttribute('style', "height: " + height + "px");
            this.cdRef.detectChanges();
        }
    };
    BookingStatusComponent.prototype.ngOnInit = function () {
        this.bookingStatus.setElements();
        this.subscribe();
    };
    BookingStatusComponent.prototype.ngOnDestroy = function () {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
    };
    return BookingStatusComponent;
}());
export { BookingStatusComponent };

import { Component } from '@angular/core';
import { ButtonBase } from '../button-base';
import { StylesService } from '../styles.service';
import * as Color from 'color';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';

@Component({
  selector: 'app-button-tertiary',
  templateUrl: './button-tertiary.component.html',
  styleUrls: ['./button-tertiary.component.scss']
})

export class ButtonTertiaryComponent extends ButtonBase {
  hoverColor: Color;
  activeColor: Color;

  private stylesService: StylesService;

  constructor() {
    super();

    this.stylesService = InjectorService.get().get(StylesService);
    this.hoverColor = this.corporativeColor.alpha(.25);
    this.activeColor = this.corporativeColor.alpha(.15);

    this.stylesService.set({
      'button.checkin__btn__tertiary': {
        'background-color': 'white',
        'border-color': this.corporativeColor,
        color: this.corporativeColor,

        '&:hover, &:focus': {
          'background-color': this.hoverColor,
          'border-color': this.hoverColor,
        },

        '&:active': {
          'background-color': this.activeColor,
          'border-color': this.activeColor
        }
      }
    });
  }
}

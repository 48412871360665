import { webSocket } from 'rxjs/webSocket';
import { InjectorService } from '../injector/injector.service';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { of } from 'rxjs';
import { switchMap, retryWhen, delay } from 'rxjs/operators';
import * as i0 from "@angular/core";
var WebsocketService = /** @class */ (function () {
    function WebsocketService() {
        var env = InjectorService.get().get(EnvService);
        this.retrySeconds = 2;
        this.connect(env.wsEndpoint);
    }
    WebsocketService.prototype.connect = function (url) {
        var _this = this;
        this.connection = of(url).pipe(switchMap(function (wsUrl) {
            if (!_this.socket) {
                _this.socket = webSocket("" + wsUrl);
            }
            return _this.socket.asObservable();
        }), retryWhen(function (errors) { return errors.pipe(delay(_this.retrySeconds)); }));
    };
    WebsocketService.prototype.send = function (data) {
        this.socket.next(data);
    };
    WebsocketService.prototype.close = function () {
        this.socket.complete();
    };
    WebsocketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WebsocketService_Factory() { return new WebsocketService(); }, token: WebsocketService, providedIn: "root" });
    return WebsocketService;
}());
export { WebsocketService };

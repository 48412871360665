import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { useFunctionIfExists } from '../shared/common/utils/functions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.addGoToTopEvent();

    this.router.events.subscribe((event) => {
      const loader = document.querySelector('.checkin__pre__loader') as HTMLElement;

      if (event instanceof NavigationEnd && loader) {
        loader.style.display = 'none';
      }
    });
  }

  private addGoToTopEvent(): void {
    // Go to top on route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      // document.body.classList.add('checkin__scroll__top--enabled');

      useFunctionIfExists(
        window,
        'scroll',
        () => { window.scroll(0, 0); }
      );
    });
  }
}

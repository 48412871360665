import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GeoService } from './geo.service';
import { pad } from '../utils/pad';
import get from 'lodash.get';
import { OccupantDocument } from 'src/app/shared/main/body/occupant-detail/occupant-identify/occupant-identify-documents/occupant-documents.enum';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { ETravelByOptions } from 'src/app/checkin-app/modules/checkin/pages/shared/travel-info/enums/travel-by-options.enum';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { EnvService } from './env.service';
import { convertTimeToAmPm } from 'src/app/shared/common/utils/dates';
import { EKinshipOptions } from 'src/app/checkin-app/modules/checkin/pages/wizard/pages/form/enums/kinship.enum';
var SERVICES_MAP = {
    countries: 'countries',
    documentType: 'documentTypeSelector',
    expiryMonth: 'expiryMonthSelector',
    expiryYear: 'expiryYearSelector',
    gender: 'genderSelector',
    hour: 'hour',
    arrivalHour: 'arrivalHour',
    departureHour: 'departureHour',
    prefixes: 'prefixes',
    travelBy: 'travelBy',
    kinship: 'clientKinship',
    typeOfGuest: 'typeOfGuestSelector',
    idealPaymentMethodBankOptions: 'idealPaymentMethodBankOptions',
    sofortPaymentMethodBankOptions: 'sofortPaymentMethodBankOptions'
};
var SelectDataService = /** @class */ (function () {
    function SelectDataService(env, config, geoService, translateService) {
        this.env = env;
        this.config = config;
        this.geoService = geoService;
        this.translateService = translateService;
        this.httpClient = InjectorService.get().get(CheckinHttpClient);
    }
    SelectDataService.prototype.get = function (service, option) {
        var _this = this;
        if (SERVICES_MAP[service] && this[SERVICES_MAP[service]]) {
            // Even if we don't use this translation, this way we securely can call `.instant`
            return this.translateService.get('data-service-init').pipe(switchMap(function () {
                return _this[SERVICES_MAP[service]](option);
            }));
        }
        console.warn("service-data: Service " + (service || '<no service>') + " not found");
        return of([]);
    };
    SelectDataService.prototype.countries = function () {
        return this.geoService.countries();
    };
    SelectDataService.prototype.prefixes = function () {
        return this.geoService.prefixes();
    };
    SelectDataService.prototype.typeOfGuestSelector = function () {
        return of([
            { name: this.translateService.instant('travelling-alone'), code: 1 },
            { name: this.translateService.instant('family'), code: 2 },
            { name: this.translateService.instant('group'), code: 3 }
        ]);
    };
    SelectDataService.prototype.documentTypeSelector = function (nationality) {
        var countryCode = this.config.booking.acc.country.code;
        var spanish = (String(nationality) === '1');
        var options = spanish ?
            [{ name: this.translateService.instant(OccupantDocument.D), code: 'D' }] :
            [{ name: this.translateService.instant(OccupantDocument.I), code: 'I' }];
        options.push({ name: this.translateService.instant(OccupantDocument.P), code: 'P' });
        if (countryCode === 'ES' && !spanish) {
            options.push.apply(options, [
                { name: this.translateService.instant(OccupantDocument.N), code: 'N' },
                { name: this.translateService.instant(OccupantDocument.X), code: 'X' }
            ]);
        }
        return of(options);
    };
    SelectDataService.prototype.genderSelector = function () {
        return of([
            { name: this.translateService.instant('female'), code: 'F' },
            { name: this.translateService.instant('male'), code: 'M' },
        ]);
    };
    SelectDataService.prototype.travelBy = function () {
        return of([
            { name: this.translateService.instant('plane'), code: ETravelByOptions.PLANE },
            { name: this.translateService.instant('car'), code: ETravelByOptions.CAR },
            { name: this.translateService.instant('train'), code: ETravelByOptions.TRAIN },
            { name: this.translateService.instant('bus'), code: ETravelByOptions.BUS },
            { name: this.translateService.instant('boat'), code: ETravelByOptions.BOAT }
        ]);
    };
    SelectDataService.prototype.clientKinship = function () {
        return of([
            { name: this.translateService.instant('grandpa-grandma'), code: EKinshipOptions.GRANDPA_GRANDMA },
            { name: this.translateService.instant('great-grandpa-grandma'), code: EKinshipOptions.GREAT_GRANDPA_GRANDMA },
            { name: this.translateService.instant('great-grandson-granddaughter'), code: EKinshipOptions.GREAT_GRANDSON_GRANDDAUGHTER },
            { name: this.translateService.instant('brother-sister-in-law'), code: EKinshipOptions.BROTHER_SISTER_IN_LAW },
            { name: this.translateService.instant('spouse'), code: EKinshipOptions.SPOUSE },
            { name: this.translateService.instant('son-daughter'), code: EKinshipOptions.SON_DAUGHTER },
            { name: this.translateService.instant('brother-sister'), code: EKinshipOptions.BROTHER_SISTER },
            { name: this.translateService.instant('grandson-granddaughter'), code: EKinshipOptions.GRANDSON_GRANDDAUGHTER },
            { name: this.translateService.instant('father-mother'), code: EKinshipOptions.FATHER_MOTHER },
            { name: this.translateService.instant('nephew-niece'), code: EKinshipOptions.NEPHEW_NIECE },
            { name: this.translateService.instant('father-mother-in-law'), code: EKinshipOptions.FATHER_MOTHER_IN_LAW },
            { name: this.translateService.instant('uncle-aunt'), code: EKinshipOptions.UNCLE_AUNT },
            { name: this.translateService.instant('son-daughter-in-law'), code: EKinshipOptions.SON_DAUGHTER_IN_LAW },
            { name: this.translateService.instant('tutors'), code: EKinshipOptions.TUTORS },
            { name: this.translateService.instant('other'), code: EKinshipOptions.OTHER }
        ]);
    };
    SelectDataService.prototype.expiryMonthSelector = function () {
        var months = [];
        for (var i = 1; i <= 12; i++) {
            var num = pad(i, 2);
            months.push({ name: num, code: num });
        }
        return of(months);
    };
    SelectDataService.prototype.expiryYearSelector = function () {
        var months = [];
        var year = (new Date().getFullYear());
        for (var i = year; i <= (year + 10); i++) {
            months.push({ name: String(i), code: i });
        }
        return of(months);
    };
    SelectDataService.prototype.generateHours = function (shouldInsertFn, splitHours) {
        if (!shouldInsertFn) {
            shouldInsertFn = function (s) { return true; };
        }
        var result = [];
        var prevInserted = true; // Previous element was inserted, not skipped
        var insertedSplitter = true; // The last element is a splitter
        for (var i = 0; i <= 24; i++) {
            for (var z = 0; z <= (splitHours ? 45 : 1); z += 15) {
                var hour = pad(i, 2) + ":" + pad(z, 2);
                if (i === 24) {
                    if (z > 0) {
                        continue;
                    }
                    prevInserted = false;
                    if (shouldInsertFn(hour)) {
                        hour = '00:00';
                        result.push({ code: hour, name: hour });
                        prevInserted = true;
                        insertedSplitter = false;
                    }
                }
                else {
                    prevInserted = false;
                    if (shouldInsertFn(hour)) {
                        result.push({ code: hour, name: hour });
                        prevInserted = true;
                        insertedSplitter = false;
                    }
                }
                if (!prevInserted && !insertedSplitter) {
                    insertedSplitter = true;
                    result.push({ code: '-', name: '-', disabled: true });
                }
            }
        }
        var lastElem = result[result.length - 1];
        if (lastElem && lastElem.disabled) {
            result.pop();
        }
        if (this.config.locale.get() === 'en_US') {
            result.map(function (value) {
                value.name = convertTimeToAmPm(value.name);
                return value;
            });
        }
        return result;
    };
    SelectDataService.prototype.hour = function () {
        return of(this.generateHours(function (s) { return true; }, true));
    };
    SelectDataService.prototype.arrivalHour = function () {
        var arrivalFormInfo = this.config.booking.arrivalFormInfo;
        var times = get(arrivalFormInfo, 'penalties.times');
        var extraAmount = get(arrivalFormInfo, 'penalties.extraAmount', null);
        if (times) {
            var hours = this.generateHours(function (hour) {
                return times.some(function (_a) {
                    var from = _a.from, to = _a.to;
                    to = to === '00:00' ? '24:00' : to;
                    return (from <= hour && hour <= to) || (extraAmount && hour > to);
                });
            }, this.splitHours(times));
            return of(hours);
        }
        return of(this.generateHours());
    };
    SelectDataService.prototype.departureHour = function () {
        var arrivalFormInfo = this.config.booking.arrivalFormInfo;
        var max = get(arrivalFormInfo, 'checkOut.max', '24:00');
        var hours = this.generateHours(function (hour) {
            var _a = max.split(':'), mHour = _a[0], mMin = _a[1];
            return hour <= pad(mHour, 2) + ":" + pad(mMin, 2);
        }, this.splitHours(max));
        return of(hours);
    };
    SelectDataService.prototype.splitHours = function (value) {
        var validate = function (min) { return min !== '00'; };
        if (Array.isArray(value)) {
            return value.filter(function (_a) {
                var from = _a.from, to = _a.to;
                var _b = from.split(':'), fMin = _b[1];
                var _c = to.split(':'), tMin = _c[1];
                return validate(fMin) || validate(tMin);
            }).length > 0;
        }
        if (typeof value === 'string') {
            var _a = value.split(':'), mMin = _a[1];
            return validate(mMin);
        }
    };
    SelectDataService.prototype.idealPaymentMethodBankOptions = function () {
        return this.httpClient.get(this.env.endpoint + "/v2/payments/ideal/bank-options");
    };
    SelectDataService.prototype.sofortPaymentMethodBankOptions = function () {
        return this.httpClient.get(this.env.endpoint + "/v2/payments/sofort/country-options");
    };
    return SelectDataService;
}());
export { SelectDataService };

export enum NavBarPagesType {
  CHECKIN = 'checkin',
  BOOKING = 'booking',
  NOTES = 'notes',
  ARRIVAL_INFO = 'arrival-info'
}

export enum ConfirmationRequestResultType {
  ERROR = 'error',
  SUCCESS = 'success',
  LOADER = 'loader',
  ACTION = 'action'
}

export enum ArrivalInfoModeType {
  UPDATE = 'update',
  CREATE = 'create'
}

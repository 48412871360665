export enum EPaymentMethodTypes {
  CREDITCARD = 'CREDITCARD',
  RETENTION = 'RETENTION',
  IDEAL = 'IDEAL',
  SOFORT = 'SOFORT',
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  PAYPAL = 'PAYPAL',
  KLARNA = 'KLARNA'
}

import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { AutoSuggestResponse } from '../input-autosuggest.interface';
import { HighLightPipe } from 'src/app/shared/common/pipes/highlight.pipe';

const entityBelongs = {
  province : 'country',
  region : 'country',
  city : 'province'
};

@Component({
  selector: 'app-input-autosuggest-list',
  templateUrl: './input-autosuggest-list.component.html',
  styleUrls: ['./input-autosuggest-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [HighLightPipe]
})

export class InputAutoSuggestListComponent {
  @Input() cursor: number;
  @Input() entity: string;
  @Input() list: AutoSuggestResponse;
  @Input() value: string;

  @Output() hover: EventEmitter<number>;
  @Output() select: EventEmitter<boolean>;

  constructor() {
    this.hover = new EventEmitter();
    this.select = new EventEmitter();
  }

  setAutoSuggestCursor(index: number): void {
    this.hover.emit(index);
  }

  onClick(): void {
    this.select.emit();
  }

  getItemText(item): string {
    if (this.entity === 'country') {
      return `${item[this.entity]}`;
    }

    return `${item[this.entity]} (${item[entityBelongs[this.entity]]})`;
  }
}

import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { ELocale } from 'src/app/shared/enums/locale.enum';
import { CookieService } from 'src/app/shared/services/cookie/cookie.service';
import { LocaleLoader } from 'src/app/shared/loaders/locale/locale.loader';
export class Locale {
  private locale: string;
  private loader: LocaleLoader;
  private service: CookieService;

  constructor(private key: string, private companyLanguages: string[]) {
    this.service = InjectorService.get().get(CookieService);

    Object.assign(this, {
      locale: this.service.get(this.key),
      loader: new LocaleLoader(
        this.key,
        this.companyLanguages,
        (l: string) => {
          this.locale = l;
        }
      )
    });

    this.set(this.locale);
  }

  public get(): string {
    return this.locale;
  }

  public set(locale: string, update?: boolean): void {
    this.loader.load(locale, update);

    if (update) {
      window.location.reload();
    }
  }

  public default(): string {
    return ELocale.DEFAULT;
  }
}

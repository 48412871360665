import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';

// Modules
import { ButtonsModule } from 'src/app/shared/common/components/buttons/buttons.module';
import { RequestInterfaceModule } from '../../../common/components/request-interface/request-interface.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from 'src/app/shared/common/common.module';
import { InputsModule } from 'src/app/shared/common/components/inputs/inputs.module';

// Components
import { CardComponent } from './card.component';
import { CardFormComponent } from './card-form/card-form.component';
import { ReactiveFormsModule } from '@angular/forms';

// Services
import { SelectDataService } from 'src/app/shared/common/services/select-data.service';
import { CardService } from './card.service';

@NgModule({
  declarations: [
    CardComponent,
    CardFormComponent
  ],
  imports: [
    AngularCommonModule,
    ButtonsModule,
    SharedModule,
    CommonModule,
    InputsModule,
    ReactiveFormsModule,
    RequestInterfaceModule
  ],
  providers: [
    CardService,
    SelectDataService
  ],
  exports: [
    CardComponent
  ]
})
export class CardModule { }

import { Component, Input, Injector, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { IBookingDetailDate } from '../booking-detail-dates.component';

@Component({
  selector: 'app-booking-detail-dates-item',
  templateUrl: './booking-detail-dates-item.component.html',
  styleUrls: ['./booking-detail-dates-item.component.scss']
})

export class BookingDetailDatesItemComponent implements OnInit {
  @Input() date: IBookingDetailDate;
  @ViewChild('chineseTemplate', { static: true }) chineseTemplate: TemplateRef<any>;
  @ViewChild('englishUSTemplate', { static: true }) englishUSTemplate: TemplateRef<any>;

  private configService: ConfigService;
  public customTemplate: TemplateRef<any>;

  ngOnInit(): void {
    this.customTemplate = this.getCustomTemplate();
  }

  constructor(private injector: Injector) {
    this.configService = this.injector.get(ConfigService);
  }

  private getCustomTemplate(): TemplateRef<any> {
    const localeTemplates = {
      zh_ZH: this.chineseTemplate,
      en_US: this.englishUSTemplate
    };
    return localeTemplates[this.configService.locale.get()];
  }
}

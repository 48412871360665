/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./fallback-domain.component";
import * as i2 from "../../services/env.service";
var styles_FallbackDomainComponent = [];
var RenderType_FallbackDomainComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FallbackDomainComponent, data: {} });
export { RenderType_FallbackDomainComponent as RenderType_FallbackDomainComponent };
export function View_FallbackDomainComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_FallbackDomainComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_FallbackDomainComponent_0, RenderType_FallbackDomainComponent)), i0.ɵdid(1, 114688, null, 0, i1.FallbackDomainComponent, [i2.EnvService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FallbackDomainComponentNgFactory = i0.ɵccf("ng-component", i1.FallbackDomainComponent, View_FallbackDomainComponent_Host_0, {}, {}, []);
export { FallbackDomainComponentNgFactory as FallbackDomainComponentNgFactory };

import { query } from '@angular/animations';
import { Injectable } from '@angular/core';
import { IBaseGuard } from 'src/app/shared/guards/base/base.guard';
import { Company } from 'src/app/shared/models/company/company.model';
import { Screen } from 'src/app/shared/models/screen/screen.model';
import { IConfig } from 'src/app/app.interfaces';
import { Booking } from 'src/app/shared/models/booking/booking.model';
import * as Color from 'color';
import { Navbar } from 'src/app/shared/models/navbar/navbar.model';
import { Config } from './config.model';
import { Locale } from 'src/app/shared/models/locale/locale.model';
import { Currency } from 'src/app/shared/models/currency/currency.model';
import { ELocale } from 'src/app/shared/enums/locale.enum';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { Checkin } from 'src/app/shared/classes/projects/checkin/checkin.class';
import { Inbox } from 'src/app/shared/classes/projects/inbox/inbox.class';
import { Websocket } from 'src/app/shared/classes/websocket/websocket.class';
import { TProjectModules } from 'src/app/shared/models/projects/project.model';
import { Payments } from 'src/app/shared/classes/projects/payments/payments.class';
import { enumIncludesValue } from 'src/app/shared/enums';
import { EProjects } from 'src/app/shared/enums/projects.enum';
import { Checkout } from '../../classes/projects/checkout/checkout.class';

@Injectable()
export class ConfigService implements Config {
  // TODO:: Improve this. Caused by ArrivalInfoDetailComponent.spec.ts
  public booking: Booking;
  public checkin: Checkin = ({
    corporativeColor: new Color('#dc3776'),
    config: {},
    hash: 'test'
  } as Checkin);
  public payments: Payments = ({
    corporativeColor: new Color('#dc3776'),
    config: {},
    hash: 'test'
  } as Payments);
  public inbox: Inbox = ({
    corporativeColor: new Color('#dc3776'),
    hash: 'test'
  } as Inbox);
  public checkout: Checkout = ({
    corporativeColor: new Color('#dc3776'),
    hash: 'test'
  } as Checkout);
  public company: Company;
  public locale: Locale;
  public currency: Currency;
  public navbar: Navbar;
  public screen: Screen;
  public project: TProjectModules;
  public ws: Websocket;
  public return_url: string;

  constructor() { }

  public init(project: string, params: IBaseGuard): void {
    const navbar = new Navbar();

    if (!enumIncludesValue(EProjects, project)) {
      new ErrorHandler().handler(new Error('Unavailable project path'));

      return;
    }

    this.company = new Company(params);

    Object.assign(this, {
      checkin: new Checkin(params, this.company),
      inbox: new Inbox(params, this.company),
      locale: new Locale(ELocale[project], this.company.languages),
      navbar,
      payments: new Payments(params, this.company),
      checkout: new Checkout(params, this.company),
      project,
      screen: new Screen({ navbar })
    });
  }

  public update(data: IConfig, onFinish: () => void): void {
    this.company.update(data);
    this.checkin.updateWebconfig(data);
    this[this.project].update(
      data,
      (params: any) => {
        this.locale.set(params.locale);
      },
      () => {
        this.booking = new Booking(data, this[this.project], this.project);

        const {
          resume: {
            currency,
            currencyBuyer,
            paymentConfig: {
              mcp
            }
          }
        } = this.booking;

        const currencyUsed = mcp.enabled ? currencyBuyer : currency;
        this.currency = new Currency(currencyUsed),
        onFinish();
      }
    );
  }
}

import { get } from 'lodash.get';
import { Input, Component, OnDestroy, Injector } from '@angular/core';
import { ConfigService } from '../../../services/config/config.service';
import { LogoManager } from './logo-manager';
import { Subscription } from 'rxjs';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnDestroy {
  @Input() showLang: boolean;
  public logo: LogoManager;
  public layout: { [key: string]: any; };
  public webDomain: string;

  private subscriptions: Subscription[];

  constructor(private config: ConfigService) {
    const { logo } = this.config.checkin;
    const injector: Injector = InjectorService.get();
    const env: EnvService = injector.get(EnvService);

    this.showLang = true;
    this.logo = new LogoManager(logo, [
      {
        break: 1440,
        default: { height: 'auto', width: 200 },
        square: { height: 100, width: 'auto'},
        vertical: { height: 110, width: 'auto'}
      }
    ]);

    this.webDomain = '//' + ((env.defaultDomain === 'secure.avantio.com') ? env.defaultDomain : env.defaultDomain.replace('secure.', ''));
    this.subscriptions = [];
    this.setSubscriptions();
    this.setLayoutConfig(this.config.screen.isLG);
  }

  private setLayoutConfig(isResponsive: boolean): void {
    // FIX: Esto es un fix aunque deberia de coger el valor por defecto que hay en config, que es true.
    const val = this.config[this.config.project].modules ?
      (this.config[this.config.project].modules.RESPONSIVE as any).HIDE_HEADER :
      true;
    const showCurrencySelector = (this.config.booking !== undefined) ? this.config.booking.showCurrencySelector : false;
    this.layout = {
      header: {
        // TODO:: Inicialmente el checkin no mostraba el header en responsive. Posteriormente se añadieron unas
        // páginas en las que si que debia aparecer. En payments si que se debe mostrar el header en responsive,
        // incluido el input-language puesto que al tener dos items en el navbar el input-language
        // no aparecería en el bloque de additional-info. Durante el desarrollo de payments se ha detectado
        // que páginas como por ejemplo terms-and-conditions no estan mostrando el header y el footer en responsive,
        // por tanto, se va a añadir una clase nueva para dejar el header como debería aparecer en payments, quedando pendiente
        // implementarlo en el resto de páginas con los mismos estilos.
        responsive: !val
      },
      language: {
        show: (!(val) || !isResponsive || this.showLang) && this.config.company.languages.length > 1
      },
      currency: {
        show: (!(val) || !isResponsive) && showCurrencySelector
      }
    };
  }

  private setSubscriptions() {
    this.setScreenSubscription();
    this.setResponsiveSubscription();
  }

  private setResponsiveSubscription(): void {
    const s: Subscription = this.config.screen.onIsLGChanges().subscribe((i: boolean) => this.setLayoutConfig(i));

    this.subscriptions.push(s);
  }

  private setScreenSubscription(): void {
    const s: Subscription = this.config.screen.onWindowResizeChanges().subscribe(() => {
      this.logo.getImageAspectRatio();
    });

    this.subscriptions.push(s);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((e: Subscription) => e.unsubscribe());
  }
}

export enum EKinshipOptions {
    GRANDPA_GRANDMA = 'GRANDPA_GRANDMA',
    GREAT_GRANDPA_GRANDMA = 'GREAT_GRANDPA_GRANDMA',
    GREAT_GRANDSON_GRANDDAUGHTER = 'GREAT_GRANDSON_GRANDDAUGHTER',
    BROTHER_SISTER_IN_LAW = 'BROTHER_SISTER_IN_LAW',
    SPOUSE = 'SPOUSE',
    SON_DAUGHTER = 'SON_DAUGHTER',
    BROTHER_SISTER = 'BROTHER_SISTER',
    GRANDSON_GRANDDAUGHTER = 'GRANDSON_GRANDDAUGHTER',
    FATHER_MOTHER = 'FATHER_MOTHER',
    NEPHEW_NIECE = 'NEPHEW_NIECE',
    FATHER_MOTHER_IN_LAW = 'FATHER_MOTHER_IN_LAW',
    UNCLE_AUNT = 'UNCLE_AUNT',
    SON_DAUGHTER_IN_LAW = 'SON_DAUGHTER_IN_LAW',
    TUTORS = 'TUTORS',
    OTHER = 'OTHER'
}

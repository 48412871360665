import { Component, Injector, Input } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';

export interface IBookingDetailDate {
  day: string;
  year: string;
  date: string;
  monthDay: string;
  slug: string;
}

@Component({
  selector: 'app-booking-detail-dates',
  templateUrl: './booking-detail-dates.component.html',
  styleUrls: ['./booking-detail-dates.component.scss']
})

export class BookingDetailDatesComponent {
  @Input() hasPayments: boolean;
  private configService: ConfigService;
  public dates: IBookingDetailDate[];

  constructor(private injector: Injector) {
    this.configService = this.injector.get(ConfigService);

    this.initDates();
  }

  private initDates(): void {
    const locale: string = this.configService.locale.get();
    const { dateFrom, dateTo } = this.configService.booking.resume;
    const options = { year: 'numeric', month: 'short' };
    const optionsUS = { month: 'short', day: 'numeric' };

    this.dates = [{
      date: new Intl.DateTimeFormat(this.parseLocale(`${locale}`), options).format(dateFrom as Date),
      day: new Intl.DateTimeFormat(this.parseLocale(`${locale}`), {day: 'numeric'}).format(dateFrom as Date),
      monthDay: new Intl.DateTimeFormat(this.parseLocale(`${locale}`), optionsUS).format(dateFrom as Date),
      year: new Intl.DateTimeFormat(this.parseLocale(`${locale}`), {year: 'numeric'}).format(dateFrom as Date),
      slug: 'booking-detail-checkin'
    }, {
      date: new Intl.DateTimeFormat(this.parseLocale(`${locale}`), options).format(dateTo as Date),
      day: new Intl.DateTimeFormat(this.parseLocale(`${locale}`), {day: 'numeric'}).format(dateTo as Date),
      monthDay: new Intl.DateTimeFormat(this.parseLocale(`${locale}`), optionsUS).format(dateTo as Date),
      year: new Intl.DateTimeFormat(this.parseLocale(`${locale}`), {year: 'numeric'}).format(dateTo as Date),
      slug: 'booking-detail-checkout'
    }];
  }

  private parseLocale(locale: string): string {
    return locale.replace('_', '-');
  }
}

import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { LandingComponent } from './landing.component';
import { BookingLocalizerComponent } from './booking-localizer/booking-localizer.component';
import { ButtonsModule } from 'src/app/shared/common/components/buttons/buttons.module';
import { CommonModule } from 'src/app/shared/common/common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from 'src/app/shared/common/components/inputs/inputs.module';
import { LayoutModule } from 'src/app/shared/layout/layout.module';
import { BookingLocalizerService } from './booking-localizer/booking-localizer.service';
import { RequestInterfaceModule } from 'src/app/shared/common/components/request-interface/request-interface.module';
import { LandingBgComponent } from './landing-bg/landing-bg.component';
import { ComponentsModule } from 'src/app/shared/modules/components/components.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    LandingComponent,
    BookingLocalizerComponent,
    LandingBgComponent
  ],
  imports: [
    AngularCommonModule,
    ComponentsModule,
    SharedModule,
    ButtonsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    LayoutModule,
    RequestInterfaceModule
  ],
  providers: [
    BookingLocalizerService
  ]
})
export class LandingModule { }

import { Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { FormGroup, Validators, AbstractControl } from '@angular/forms';
import * as Color from 'color';
import { StylesService } from '../buttons/styles.service';
import { ErrorMessages } from '../../error/error-messages';
import { ConfirmationRequestResultType } from '../../../main/main.enum';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';

export class InputBase implements OnInit, OnChanges {
  @Input() control: string;
  @Input() customClass: string;
  @Input() customErrorMsg: string;
  @Input() disabled: boolean;
  @Input() form: FormGroup;
  @Input() label: string;
  @Input() maxLength: number;
  @Input() placeholder = '';
  @Input() requestSave: ConfirmationRequestResultType;
  @Input() requestSaveDelay: number;
  @Input() required: boolean;
  @Input() showErrors: boolean;
  @Input() showErrorText = true;
  @Input() tooltip: string;
  @Input() translateParams: any;

  @Output() blur = new EventEmitter<boolean>();
  @Output() focus = new EventEmitter<boolean>();

  config: ConfigService;
  stylesService: StylesService;

  borderColor: string;
  corporativeColor: Color;
  loadingSave: boolean;
  requestSaveDebounce: any;
  requestSaveType: ConfirmationRequestResultType;
  showLoadingSave: boolean;

  constructor() {
    const injector: Injector = InjectorService.get();
    this.config = injector.get(ConfigService);
    this.stylesService = injector.get(StylesService);

    this.borderColor = '#d8d8d8';

    this.corporativeColor = this.config.checkin.corporativeColor;

    this.maxLength = 1000;
    this.requestSaveDelay = 1500;
    this.translateParams = {};

    const inputStyles = {
      border: `1px solid ${this.borderColor}`,
      '&:focus': {
        'border-color': this.corporativeColor
      }
    };

    this.stylesService.set({
      '.checkin__input__container input.checkin__input': { ...inputStyles },
      '.checkin__input__container select.checkin__input': { ...inputStyles }
    });
  }

  ngOnInit() {
    this.initInputBase();
  }

  initInputBase() {
    if (!this.form) {
      console.warn(`InputBase: No 'form' provided`);
      return;
    }

    if (!this.form.get(this.control)) {
      console.warn(`InputBase: No formControl (${this.control}) provided`);
      return;
    }

    if (this.required) {
      const control = this.form.get(this.control);
      this.setFormRequired([control]);
    }
  }

  setFormRequired(controls: AbstractControl[]) {
    controls.forEach((fc) => {
      let validator = [Validators.required];

      if (fc.validator) {
        validator = [...validator, fc.validator];
      }

      fc.setValidators(validator);
      fc.updateValueAndValidity();
    });
  }

  onFormStatusChange(internalForm: FormGroup) {
    if (this.disabled) {
      internalForm.disable();
    }

    this.form.get(this.control).statusChanges.subscribe((status) => {
      if (status === 'DISABLED' && internalForm.enabled) {
        return internalForm.disable();
      }

      if (internalForm.disabled) {
        internalForm.enable();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.requestSave && changes.requestSave.currentValue) {
      const { requestSave } = changes;
      const currentType = requestSave.currentValue;

      if (this.requestSaveDebounce) {
        clearTimeout(this.requestSaveDebounce);
      }

      this.requestSaveType = currentType;
      this.loadingSave = this.requestSaveType === ConfirmationRequestResultType.LOADER;
      this.setLoading(true);

      if (this.requestSaveType !== ConfirmationRequestResultType.LOADER) {
        this.requestSaveDebounce = setTimeout(() => {
          this.setLoading(false);
        }, this.requestSaveDelay);
      }
    }
  }

  setLoading(loading: boolean) {
    this.showLoadingSave = loading;
  }

  onFocus() {
    return this.focus.emit(true);
  }

  onBlur() {
    return this.blur.emit(true);
  }

  get hasValue(): boolean {
    const { value = null } = this.form.get(this.control) || {};
    return Boolean(value);
  }

  getErrorMessage(errors: { [key: string]: any }): string {
    const errorKeys = Object.keys(errors || {});
    if (errorKeys.length > 0) {
      if (errors.required) {
        return ErrorMessages.required;
      }

      const [error] = errorKeys.sort();

      return ErrorMessages[error] || this.customErrorMsg || '';
    }

    return '';
  }

  get errorMsg(): string {
    const control = this.form.get(this.control);
    const { errors } = control;

    return this.getErrorMessage(errors);
  }

  get formErrorMsg(): string {
    const { errors } = this.form;

    return this.getErrorMessage(errors);
  }
}

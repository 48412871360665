import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OccupantIdentifyDocumentsType } from '../../occupant-identify.interfaces';
import { OccupantIdentifyService } from '../occupant-identify-scan.service';

@Injectable({ providedIn: 'root' })
export class OccupantIdentifyScanActionsService {
  private subject: Subject<string>;

  constructor(private occupantIdentifyService: OccupantIdentifyService) {
    this.subject = new Subject<any>();
  }

  public sendAction(action: string) {
    this.subject.next(action);
  }

  public getAction(): Observable<any> {
    return this.subject.asObservable();
  }

  public savePhoto(documentKey: OccupantIdentifyDocumentsType, image: string): void {
    this.occupantIdentifyService.setDocument(documentKey, image);
  }

  public clearPhoto(documentKey: OccupantIdentifyDocumentsType): void {
    this.occupantIdentifyService.clearDocument(documentKey);
  }
}

import { Component, Input } from '@angular/core';
import { FeedbackModal } from './feedback-modal.class';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})

export class FeedbackModalComponent {
  @Input() modal: FeedbackModal;

  constructor() {}
}

import { TravelInfoData, IConfig } from 'src/app/app.interfaces';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';

// TODO:: REFACTOR THIS
export class ArrivalInfoUtils {
  extraConfigData: IConfig['extraConfig'];

  specifyArrivalHour: boolean;
  specifyDepartureHour: boolean;
  showTravelInfoOnWizard: boolean;

  private config: ConfigService;

  constructor() {
    this.config = InjectorService.get().get(ConfigService);

    const {
      specifyArrivalTime: specifyArrivalHour,
      specifyCheckoutTime: specifyDepartureHour,
      showTravelInfoOnWizard
    } = this.config.checkin.config;

    Object.assign(this, {
      specifyArrivalHour,
      specifyDepartureHour,
      showTravelInfoOnWizard
    });
  }

  get travelInfoData(): TravelInfoData {
    return this.config.booking.travelInfo;
  }

  get isArrivalFulfilled(): boolean {
    return Boolean(this.travelInfoData && this.travelInfoData.arrivalInfo);
  }

  get isDepartureFulfilled(): boolean {
    return Boolean(this.travelInfoData && this.travelInfoData.departureInfo);
  }

  get shouldFullfilArrivalHour(): boolean {
    return this.specifyArrivalHour && !this.isArrivalFulfilled;
  }

  get shouldFullfilDepartureHour(): boolean {
    return this.specifyDepartureHour && !this.isDepartureFulfilled;
  }

  get showAdditionalInfo(): boolean {
    return this.shouldFullfilArrivalHour || this.shouldFullfilDepartureHour;
  }

  get isTravelInfoCompleted(): boolean {
    return !this.shouldFullfilArrivalHour || !this.shouldFullfilDepartureHour;
  }

  get isTravelInfoFulfilled(): boolean {
    return this.showTravelInfoOnWizard && !this.isTravelInfoCompleted;
  }
}

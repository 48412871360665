import * as tslib_1 from "tslib";
import { ButtonBase } from '../button-base';
import { StylesService } from '../styles.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
var ButtonRoundComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ButtonRoundComponent, _super);
    function ButtonRoundComponent() {
        var _a;
        var _this = _super.call(this) || this;
        _this.stylesService = InjectorService.get().get(StylesService);
        _this.hoverColor = _this.corporativeColor.alpha(.25);
        _this.activeColor = _this.corporativeColor.alpha(.15);
        var baseClass = 'button.checkin__btn__round';
        _this.stylesService.set((_a = {},
            _a[baseClass] = {
                'background-color': _this.activeColor,
                '&:hover, &:focus': {
                    'background-color': _this.hoverColor
                },
                '&:active': {
                    'background-color': _this.activeColor
                }
            },
            _a[baseClass + " span, " + baseClass + " i"] = {
                color: _this.corporativeColor
            },
            _a));
        return _this;
    }
    return ButtonRoundComponent;
}(ButtonBase));
export { ButtonRoundComponent };

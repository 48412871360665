import { Component } from '@angular/core';
import { ButtonBase } from '../button-base';
import { StylesService } from '../styles.service';
import * as Color from 'color';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';

@Component({
  selector: 'app-button-round',
  templateUrl: './button-round.component.html',
  styleUrls: ['./button-round.component.scss']
})

export class ButtonRoundComponent extends ButtonBase {
  hoverColor: Color;
  activeColor: Color;

  private stylesService: StylesService;

  constructor() {
    super();

    this.stylesService = InjectorService.get().get(StylesService);

    this.hoverColor = this.corporativeColor.alpha(.25);
    this.activeColor = this.corporativeColor.alpha(.15);

    const baseClass = 'button.checkin__btn__round';

    this.stylesService.set({
      [baseClass]: {
        'background-color': this.activeColor,

        '&:hover, &:focus': {
          'background-color': this.hoverColor
        },

        '&:active': {
          'background-color': this.activeColor
        }
      },
      [`${baseClass} span, ${baseClass} i`]: {
        color: this.corporativeColor
      }
    });
  }
}

export enum EBookingStatus {
  BAJO_PETICION = 'BAJO_PETICION',
  CANCELLED = 'CANCELLED',
  CONFIRMADA = 'CONFIRMADA',
  CONFIRMED = 'CONFIRMED',
  CONFLICTED = 'CONFLICTED',
  FACTURADA = 'FACTURADA',
  GARANTIA = 'GARANTIA',
  PAID = 'PAID',
  PETICION_DISPONIBILIDAD = 'PETICION_DISPONIBILIDAD',
  PETICION_INFORMACION = 'PETICION_INFORMACION',
  PETICION_TPV = 'PETICION_TPV',
  PROPIETARIO = 'PROPIETARIO',
  REQUEST_TO_BOOK = 'REQUEST_TO_BOOK',
  SIN_ALTA_CRS = 'SIN_ALTA_CRS',
  UNAVAILABLE = 'UNAVAILABLE',
  UNPAID = 'UNPAID'
}

/**
 * Adds a zeros in front front of n until gets the size s
 * @param n Number Number to pad
 * @param s Number Size
 */
export function pad(n: number | string, s: number) {
  n = String(n);

  while (n.length < s) {
    n = `0${n}`;
  }

  return n;
}

export class PaymentsDetail {
  private elements: {
    body: any;
    footer: any;
    payment: any;
    sidebar: any;
  };

  private showing: number;

  constructor() {}

  private getElementsSize(): {
    body: { height: number; top: number };
    footer: { height: number; top: number };
    payment: { height: number; top: number };
    sidebar: { height: number; top: number };
  } {
    return Object.entries(this.elements).reduce((res, [key, value]) => ({
      ...res,
      [key]: (typeof value.getBoundingClientRect === 'function') ? value.getBoundingClientRect() : value
    }), {}) as any;
  }

  public setElements(): void {
    this.elements = {
      body: document.querySelector('body'),
      footer: document.querySelector('app-footer'),
      payment: document.querySelector('app-payments-detail-item').getBoundingClientRect(),
      sidebar: document.querySelector('.checkin__sidebar__wrapper')
    };
  }

  public getSizeConfig(paymentsLength: number): {
    showing: number;
    maxHeight: number | string;
    showToggle: boolean;
    paymentHeight?: number;
  } {
    if (!this.elements) {
      return { maxHeight: 'initial', showToggle: false, showing: paymentsLength };
    }

    const length = typeof this.showing === 'number' ? this.showing : paymentsLength;
    const { body, footer, payment, sidebar } = this.getElementsSize();
    const bodyOffset: number = body.height - footer.height - (sidebar.top + sidebar.height - (payment.height * (length)));
    const paymentsListOffset: number = Math.round(bodyOffset / payment.height - 1);

    this.showing = (paymentsListOffset > 0 && paymentsListOffset <= paymentsLength) ? paymentsListOffset :
      (paymentsListOffset <= 0) ? 0 : paymentsLength;

    return {
      showing: this.showing,
      paymentHeight: payment.height,
      showToggle: this.showing < paymentsLength
    } as any;
  }
}

import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { EnvService } from '../../common/services/env.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getRes } from 'src/app/shared/common/utils/res';
import { InjectorService } from '../injector/injector.service';
import * as i0 from "@angular/core";
var CheckinHttpClient = /** @class */ (function () {
    function CheckinHttpClient() {
        var injector = InjectorService.get();
        var env = injector.get(EnvService);
        this.http = injector.get(HttpClient);
        this.headers = {
            'X-API-Domain': env.domain || ''
        };
    }
    CheckinHttpClient.prototype.setLocale = function (locale) {
        this.headers['X-API-Locale'] = locale;
    };
    CheckinHttpClient.prototype.setRes = function () {
        var res = getRes();
        this.headers['X-API-Res'] = "W/\"960-" + btoa(res).replace(/=/gi, '') + "\"";
    };
    CheckinHttpClient.prototype.getRequestOptions = function (withHeaders) {
        this.setRes();
        return tslib_1.__assign({}, (withHeaders) && {
            headers: new HttpHeaders(this.headers)
        }, { withCredentials: true });
    };
    CheckinHttpClient.prototype.get = function (url, sendHeaders) {
        if (sendHeaders === void 0) { sendHeaders = true; }
        return this.http.get(url, this.getRequestOptions(sendHeaders));
    };
    CheckinHttpClient.prototype.patch = function (url, data, sendHeaders) {
        if (sendHeaders === void 0) { sendHeaders = true; }
        return this.http.patch(url, data, this.getRequestOptions(sendHeaders));
    };
    CheckinHttpClient.prototype.post = function (url, data, sendHeaders) {
        if (sendHeaders === void 0) { sendHeaders = true; }
        return this.http.post(url, data, this.getRequestOptions(sendHeaders));
    };
    CheckinHttpClient.prototype.put = function (url, data, sendHeaders) {
        if (sendHeaders === void 0) { sendHeaders = true; }
        return this.http.put(url, data, this.getRequestOptions(sendHeaders));
    };
    CheckinHttpClient.prototype.delete = function (url, sendHeaders) {
        if (sendHeaders === void 0) { sendHeaders = true; }
        return this.http.delete(url, this.getRequestOptions(sendHeaders));
    };
    CheckinHttpClient.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CheckinHttpClient_Factory() { return new CheckinHttpClient(); }, token: CheckinHttpClient, providedIn: "root" });
    return CheckinHttpClient;
}());
export { CheckinHttpClient };

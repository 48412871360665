import * as tslib_1 from "tslib";
import { ProjectConfig as IInboxConfig } from 'src/app/shared/classes/projects/project-config.class';
var InboxConfig = /** @class */ (function (_super) {
    tslib_1.__extends(InboxConfig, _super);
    function InboxConfig(params) {
        return _super.call(this, params) || this;
    }
    return InboxConfig;
}(IInboxConfig));
export { InboxConfig };

import * as _ from 'lodash';
import { Injectable, Injector } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ICompanyData } from 'src/app/app.interfaces';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { EProjects } from 'src/app/shared/enums/projects.enum';
import { enumIncludesValue } from 'src/app/shared/enums';
import { CookieService } from 'src/app/shared/services/cookie/cookie.service';
import { ELocale } from 'src/app/shared/enums/locale.enum';
import { Title } from '@angular/platform-browser';

export interface IBaseGuard {
  webConfig: {
    colors: { corporative: string };
    domain: string;
    favicon: string;
    footer: {
      copyright: string;
    };
    logo: string;
    title: string;
  };
  languages: string[];
  idCompany: number;
  company: ICompanyData;
}

@Injectable({
  providedIn: 'root'
})
export class BaseGuard implements CanActivate {
  private cookieService: CookieService;
  private config: ConfigService;
  private env: EnvService;
  private error: ErrorHandler;
  private http: CheckinHttpClient;
  private metaTitle: Title;

  constructor() {
    const injector: Injector = InjectorService.get();

    this.config = injector.get(ConfigService);
    this.cookieService = injector.get(CookieService);
    this.env = injector.get(EnvService);
    this.http = injector.get(CheckinHttpClient);
    this.metaTitle = injector.get(Title);

    this.error = new ErrorHandler();
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    /**
     * TODO:: Esto debe ser una petición que se lance a un endpoint similar a este ${this.env.endpoint}/v2/config
     * Y se enviará como parametro la key asociada al pathname desde el que nos están llamando
     * route: ActivatedRouteSnapshot desde aquí podremos obtenerlo
     */

    const project: string = this.getProjectPath(route);

    try {
      if (!project || !enumIncludesValue(EProjects, project)) {
        const r = _.get(route, 'firstChild.url', []).map(({ path }) => path).join('#');

        throw new Error(`Unavailable project path for: ${window.location.href} - ${r}`);
      }

      // TODO:: Improve this
      const locale = this.cookieService.get(ELocale.GUEST_AREA);
      const url = `${this.env.endpoint}/v2/config/${project}${locale ? `?locale=${locale}` : ''}`;
      this.setProjectTitle(project);
      return this.http.get(url).pipe(
        map((data: IBaseGuard) => {
          // TODO:: Change to observable
          this.config.init(project, data);
          // TODO:: Check with data and init later
          // TODO:: Maybe throw error if not enabled
          return this.config[project].enabled;
        }),
        catchError((err: Error) => this.error.handler(err))
      );
    } catch (error) { this.error.handler(error); }
  }

  private getProjectPath(route: ActivatedRouteSnapshot): string {
    return _.get(route, 'firstChild.routeConfig.path',  '');
  }

  private setProjectTitle(project) {
    if ( /payments|checkout/.test(project)) {
      const capitalizeProject = project[0].toUpperCase() + project.slice(1).toLowerCase();
      this.metaTitle.setTitle(`Secure ${capitalizeProject}`);
      return;
    }

    this.metaTitle.setTitle(`Online Check-in`);
  }
}

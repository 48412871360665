export enum ETranslations {
  CVV = 'cvv',
  MONTH = 'month',
  MONTH_SHORT = 'mm',
  NAME_AND_SURNAME = 'name-and-surname',
  YEAR = 'year',
  YEAR_SHORT = 'yy'
}

export const translations = {
  en_GB: {
    [ETranslations.CVV]: 'cvv',
    [ETranslations.MONTH]: 'month',
    [ETranslations.MONTH_SHORT]: 'mm',
    [ETranslations.YEAR]: 'year',
    [ETranslations.YEAR_SHORT]: 'yy',
    [ETranslations.NAME_AND_SURNAME]: 'Name and surname'
  },
  es_ES: {
    [ETranslations.CVV]: 'cvv',
    [ETranslations.MONTH]: 'mes',
    [ETranslations.MONTH_SHORT]: 'mm',
    [ETranslations.YEAR]: 'año',
    [ETranslations.YEAR_SHORT]: 'yy',
    [ETranslations.NAME_AND_SURNAME]: 'Nombre y apellidos'
  }
};

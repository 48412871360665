import { ErrorHandler, Injector, NgModule } from '@angular/core';

import {
  APP_BASE_HREF,
  CommonModule as AngularCommonModule
} from '@angular/common';
import { AppRouterModule } from './app-route.module';

// Modules
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '../shared/common/common.module';
import { LayoutModule } from '../shared/layout/layout.module';
import { BodyModule } from '../shared/main/body/body.module';
import { SharedModule } from '../shared/shared.module';

// Components
import { AppComponent } from './app.component';

// Services
import { ConfigService } from '../shared/services/config/config.service';
import { InjectorService } from '../shared/services/injector/injector.service';

// Handlers
import { SentryErrorHandler } from '../shared/common/error/sentry-error-handler';
import { LandingModule } from '../shared/pages/landing/landing.module';

// Guards
import { CardGuard } from '../shared/guards/card/card.guard';
import { ExpiredGuard } from '../shared/guards/expired/expired.guard';

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    BrowserModule,
    AppRouterModule,
    AngularCommonModule,
    BodyModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    LayoutModule,
    LandingModule,
    SharedModule.forRoot()
  ],
  providers: [
    ExpiredGuard,
    CardGuard,
    ConfigService,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/guest-area/'
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private injector: Injector) {
    InjectorService.set(this.injector);
  }
}

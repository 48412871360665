import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
var Screen = /** @class */ (function () {
    function Screen(params) {
        Object.assign(this, {
            navbar: params.navbar,
            windowOrientationSubject: new Subject(),
            windowResizeSubject: new Subject(),
            windowEvents: [
                { event: 'resize', cb: this.onWindowResize.bind(this) }
            ]
        });
        this.setBreakpointsConfig();
        this.setWidth();
        this.setWindowEvents();
        this.setIsMobileDevice();
        this.setIsIOS();
    }
    Screen.prototype.ngOnDestroy = function () {
        this.removeWindowEvents();
    };
    Object.defineProperty(Screen.prototype, "isMobileDevice", {
        get: function () {
            return this._isMobileDevice;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Screen.prototype, "isIOS", {
        get: function () {
            return this._isIOS;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Screen.prototype, "isXL", {
        get: function () {
            return this._isXL;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Screen.prototype, "isLG", {
        get: function () {
            return this._isLG;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Screen.prototype, "isSM", {
        get: function () {
            return this._isSM;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Screen.prototype, "isMD", {
        get: function () {
            return this._isMD;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Screen.prototype, "isXS", {
        get: function () {
            return this._isXS;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Screen.prototype, "isXXS", {
        get: function () {
            return this._isXXS;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Screen.prototype, "isXXXS", {
        get: function () {
            return this._isXXXS;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Screen.prototype, "height", {
        get: function () {
            return this._height;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Screen.prototype, "width", {
        get: function () {
            return this._width;
        },
        enumerable: true,
        configurable: true
    });
    Screen.prototype.setWindowEvents = function () {
        this.windowEvents.forEach(function (_a) {
            var event = _a.event, cb = _a.cb;
            window.addEventListener(event, function (evt) { return cb(evt); });
        });
    };
    Screen.prototype.removeWindowEvents = function () {
        this.windowEvents.forEach(function (_a) {
            var event = _a.event, cb = _a.cb;
            window.removeEventListener(event, function (evt) { return cb(evt); });
        });
    };
    Screen.prototype.onWindowResize = function (event) {
        Object.assign(this, {
            _height: event.target.innerHeight,
            _width: event.target.innerWidth
        });
        this.windowResizeSubject.next({ height: this._height, width: this._width });
        this.windowOrientationSubject.next(this.getWindowOrientation());
        this.updateBreakpointsConfig();
    };
    Screen.prototype.setBreakpointsConfig = function () {
        var _this = this;
        this.breakpoints = {
            XXXS: 375,
            XXS: 475,
            XS: 576,
            SM: 768,
            MD: 992,
            LG: 1200,
            XL: 1920
        };
        Object.entries(this.breakpoints).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            var k = "is" + key;
            _this["_" + k] = window.innerWidth <= value;
            _this.updateHandler(k);
            _this[k + "Subject"] = new Subject();
        });
    };
    Screen.prototype.setWidth = function () {
        this._width = window.innerWidth;
    };
    Screen.prototype.updateHandler = function (key) {
        switch (key) {
            case 'isLG':
                this.navbar.show = this._isLG;
                break;
            default:
                break;
        }
    };
    Screen.prototype.updateBreakpointsConfig = function () {
        var _this = this;
        Object.entries(this.breakpoints).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            var k = "is" + key;
            _this["_" + k] = window.innerWidth <= value;
            _this.updateHandler(k);
            _this[k + "Subject"].next(_this[k]);
        });
    };
    Screen.prototype.onIsXXXSChanges = function () {
        return this.isXXXSSubject.asObservable().pipe(distinctUntilChanged());
    };
    Screen.prototype.onIsXXSChanges = function () {
        return this.isXXSSubject.asObservable().pipe(distinctUntilChanged());
    };
    Screen.prototype.onIsXSChanges = function () {
        return this.isXSSubject.asObservable().pipe(distinctUntilChanged());
    };
    Screen.prototype.onIsSMChanges = function () {
        return this.isSMSubject.asObservable().pipe(distinctUntilChanged());
    };
    Screen.prototype.onIsMDChanges = function () {
        return this.isMDSubject.asObservable().pipe(distinctUntilChanged());
    };
    Screen.prototype.onIsLGChanges = function () {
        return this.isLGSubject.asObservable().pipe(distinctUntilChanged());
    };
    Screen.prototype.onIsXLChanges = function () {
        return this.isXLSubject.asObservable().pipe(distinctUntilChanged());
    };
    Screen.prototype.onWindowResizeChanges = function () {
        return this.windowResizeSubject.asObservable().pipe(distinctUntilChanged());
    };
    Screen.prototype.onWindowOrientationChanges = function () {
        return this.windowOrientationSubject.asObservable().pipe(distinctUntilChanged());
    };
    Screen.prototype.getWindowOrientation = function () {
        return this._height > this._width ? 'portrait' : 'landscape';
    };
    Screen.prototype.setIsMobileDevice = function () {
        var mobileDevices = /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i;
        this._isMobileDevice = Boolean(navigator.userAgent.match(mobileDevices)) ||
            (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2); // iPadOS
    };
    Screen.prototype.setIsIOS = function () {
        this._isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    };
    return Screen;
}());
export { Screen };

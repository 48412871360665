import { Component, Input } from '@angular/core';
import { InputBase } from '../input-base';
import { StylesService } from '../../buttons/styles.service';

@Component({
  selector: 'app-input-check',
  templateUrl: './input-check.component.html',
  styleUrls: ['./input-check.component.scss']
})

export class InputCheckComponent extends InputBase {
  @Input() align: string;
  @Input() reverseLabel: boolean;
  @Input() translateParams: { [key: string]: any };

  constructor() {
    super();

    this.align = 'center';

    this.stylesService.set({
      '.checkin__input input[type="checkbox"]': {
        '&:checked + .checkin__input__checkbox': {
          'border-color': this.corporativeColor,
          'background-color': this.corporativeColor
        }
      },
      '.checkin__input__check > label a': {
        color: this.corporativeColor,
        '&:hover, &:focus': {
          'text-decoration': 'none'
        }
      }
    });
  }

  toggleCheckbox(): void {
    this.form.get(this.control).setValue(!this.hasValue);
  }

  get isValid(): boolean {
    return this.form.get(this.control).valid;
  }

  public get alignClass(): string {
    return this.align;
  }
}

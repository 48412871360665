import { Injector } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { whiteSpaceValidator } from 'src/app/shared/validators';

export interface ICreditCardValue  {
  name: string;
  pan: string;
  type: string;
  valid: boolean;
}

export class CreditCard {
  public valid: boolean;

  private _id: number;
  private _number: string;
  private _firstDigits: string;
  private _updatable: boolean;
  private  _owner: {
    address?: string;
    name: string;
    country?: string;
    street?: string;
    city?: string;
    postalCode?: string;
    province?: string;
    idCountry?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    phoneNumber?: string;
  };
  private _type: string;
  private onChangesSubject: Subject<boolean>;

  constructor(params?: {
    id?: CreditCard['_id'];
    number?: CreditCard['_number'];
    firstDigits?: CreditCard['_firstDigits'];
    updatable?: CreditCard['_updatable'];
    owner?: CreditCard['_owner'];
    type?: CreditCard['_type'];
  }) {
    Object.assign(this, {
      injector: InjectorService.get(),
      onChangesSubject: new Subject<boolean>()
    });

    this.set(params, { emitEvent: false });
  }

  public get updatable(): boolean {
    return this._updatable;
  }

  public get id(): number {
    return this._id;
  }

  public get number(): string {
    return this._number;
  }

  public get firstDigits(): string {
    return this._firstDigits;
  }

  public get lastDigits(): string {
    return `${this._number}`.replace(/[^A-Z0-9]+/ig, '');
  }

  public get type(): string {
    return this._type;
  }

  public get owner(): CreditCard['_owner'] {
    return this._owner;
  }

  private getAddress(owner: CreditCard['_owner']): string {
    if (!owner) {
      return '';
    }

    const values: string[] = [
      ...(owner.postalCode ? [owner.postalCode] : []),
      ...(owner.city ? [owner.city] : []),
      ...(owner.province ? [owner.province] : []),
      ...(owner.country ? [owner.country] : []),
    ];

    return `${owner.street ? `${owner.street} <br />` : ''}${values.length > 0 ? values.join(', ') : ''}`;
  }

  public set(
    params?: {
      id?: CreditCard['_id'];
      number?: CreditCard['_number'];
      firstDigits?: CreditCard['_firstDigits'];
      updatable?: CreditCard['_updatable'];
      owner?: CreditCard['_owner'];
      type?: CreditCard['_type'];
    },
    options: {
      emitEvent: boolean;
    } = { emitEvent: true }
  ): void {
    const { _owner, _updatable, ...config } = Object.entries(params || {})
      .reduce((res, [key, value]) => ({ ...res, [`_${key}`]: value }), {
        valid: Boolean(params.number)
      } as any);

    Object.assign(this, {
      ...config,
      _updatable: _.has(params, 'updatable') ? _updatable : true,
      _owner: {
        ..._owner,
        address: this.getAddress(_owner)
      }
    });

    if (options.emitEvent) {
      this.onChangesSubject.next(true);
    }
  }

  public onChanges(): Observable<boolean> {
    return this.onChangesSubject.asObservable();
  }

  private _form(options: { filled: boolean; } = {} as any): FormGroup {
    const getControlValue = (controlName: string) => {
      if (!options.filled) {
        return null;
      }

      return _.get({
        // name: this._owner.name,
        // pan: this._number,
        address: this._owner.street,
        postalCode: this._owner.postalCode,
        phoneNumber:  this._owner.phoneNumber,
        region: this._owner.province,
        city: this._owner.city,
        country: this._owner.idCountry,
        firstname: this._owner.firstname,
        lastname: this._owner.lastname,
        email: this._owner.email
      }, controlName, null);
    };

    const getControlValidators = (controlName: string) => {
      switch (controlName) {
        case 'month':
        case 'year':
        case 'country':
          return [Validators.required];
        case 'city':
        case 'region':
          return [
            Validators.required,
            whiteSpaceValidator
          ];
        case 'email':
          return [
            Validators.required,
            Validators.email
          ];
        default:
          return [
            Validators.required,
            whiteSpaceValidator
          ];
      }
    };

    return [
      'name',
      'pan',
      'cvc',
      'month',
      'year',
      'address',
      'postalCode',
      'phoneNumber',
      'region',
      'city',
      'country',
      'firstname',
      'lastname',
      'email'
    ].reduce((formGroup, control) => {
      formGroup.addControl(control, new FormControl(getControlValue(control), getControlValidators(control)));

      return formGroup;
    }, new FormGroup({},  {
      validators: [
        (fg: FormGroup): ValidationErrors | null => {
          const today: Date = new Date();
          const { value: { month, year } } = fg;

          if (month && year && (moment(`${today.getMonth() + 1}/${today.getFullYear()}`, 'MM/YYYY').isAfter(moment(`${month}/${year}`, 'MM/YYYY')))) { // tslint:disable-line
            fg.controls.month.setErrors({ invalidDate: true });
          }

          return null;
        }
      ]
    }));
  }

  public get form(): FormGroup {
    return this._form();
  }

  public get filledForm(): FormGroup {
    return this._form({ filled: true });
  }

  public setBillingAddress(owner: CreditCard['_owner']): void {
    Object.assign(this, {
      _owner: {
        ...this._owner,
        ...owner,
        address: this.getAddress(owner)
      }
    });

    this.onChangesSubject.next(true);
  }
}

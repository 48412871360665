import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';

// Modules
import { ButtonsModule } from 'src/app/shared/common/components/buttons/buttons.module';
import { RequestInterfaceModule } from '../../common/components/request-interface/request-interface.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from 'src/app/shared/common/common.module';
import { InputsModule } from 'src/app/shared/common/components/inputs/inputs.module';
import { ReactiveFormsModule } from '@angular/forms';

// Components
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { WorldPatternComponent } from './components/world-pattern/world-pattern.component';

const components: any[] = [
  CreditCardComponent,
  WorldPatternComponent
];

@NgModule({
  declarations: components,
  imports: [
    AngularCommonModule,
    ButtonsModule,
    SharedModule,
    CommonModule,
    InputsModule,
    ReactiveFormsModule,
    RequestInterfaceModule
  ],
  providers: [],
  exports: components
})
export class CreditCardModule { }

export enum OccupantDocument {
  // Spanish ID
  D = 'spanish-document-id',
  // Passport
  P = 'passport',
  // Other state's ID
  I = 'other-document-id',
  // Spanish residence permit
  N = 'spanish-residence-permit',
  // European residence permit
  X = 'european-residence-permit'
}

import { Component, Input } from '@angular/core';
import { ICreditCardParams } from '../../../credit-card/components/credit-card/credit-card.class';

@Component({
  selector: 'app-credit-card-preview',
  templateUrl: './credit-card-preview.component.html',
  styleUrls: ['./credit-card-preview.component.scss']
})

export class CreditCardPreviewComponent {
  @Input() creditCard: ICreditCardParams;

  constructor() { }
}

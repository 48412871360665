import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Modules
import { BodyModule } from './body/body.module';
import { CommonModule } from '../common/common.module';
import { OccupantListModule } from './body/occupant-list/occupant-list.module';
import { SidebarModule } from '../modules/sidebar/sidebar.module';

// Components
import { MainComponent } from './main.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NavbarItemComponent } from './navbar/navbar-item/navbar-item.component';
import { PageWrapperComponent } from './page-wrapper/page-wrapper.component';

// Services
import { MainService } from './main.service';
import { CardModule } from './body/card/card.module';
import { RequestInterfaceModule } from '../common/components/request-interface/request-interface.module';
import { SharedModule } from '../shared.module';

const allModules = [
  MainComponent,
  NavbarComponent,
  NavbarItemComponent,
  PageWrapperComponent
];

@NgModule({
  declarations: allModules,
  exports: allModules,
  imports: [
    AngularCommonModule,
    BodyModule,
    CardModule,
    SharedModule,
    CommonModule,
    FormsModule,
    OccupantListModule,
    ReactiveFormsModule,
    RequestInterfaceModule,
    SidebarModule
  ],
  providers: [
    MainService
  ]
})
export class MainModule { }

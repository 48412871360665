<ng-container *ngIf="showSelector">
  <ng-container *ngTemplateOutlet="desktop"></ng-container>
</ng-container>

<ng-template #responsive>
  <div class="checkin__input__select checkin__input__select--responsive" [formGroup]="form">
    {{ 'navbar-aditional-info-currency' | translate }}
    <select class="checkin__input" [ngStyle]="{ 'width' : selectedSize }" [formControlName]="'currency'">
      <option disabled value></option>
      <option
        *ngFor="let option of options"
        [value]="option.code"
        [disabled]="option.disabled">({{ option.code }})</option>
    </select>
  </div>
</ng-template>
<ng-template #desktop>
  <div class="checkin__input__select" [formGroup]="form">
    <select [ngStyle]="{ 'width' : selectedSize }" class="checkin__input" [formControlName]="'currency'">
      <option disabled value></option>
      <option
        *ngFor="let option of options"
        [value]="option.code"
        [disabled]="option.disabled">{{ option.code }}</option>
    </select>
    <i class="avc-dropdown"></i>
  </div>
</ng-template>

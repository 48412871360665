import * as Color from 'color';
import { ConfigService } from 'src/app/shared/services/config/config.service';
var LoaderComponent = /** @class */ (function () {
    function LoaderComponent(config) {
        this.config = config;
        this.corporativeColor = this.config.checkin.corporativeColor;
        this.size = 50;
    }
    Object.defineProperty(LoaderComponent.prototype, "loaderColor", {
        get: function () {
            return Color(this.color) || this.corporativeColor;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaderComponent.prototype, "loaderAlphaColor", {
        get: function () {
            return this.loaderColor.alpha(.4);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaderComponent.prototype, "loaderSize", {
        get: function () {
            return this.size + "px";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaderComponent.prototype, "wrapperSize", {
        get: function () {
            return this.size + 2 + "px";
        },
        enumerable: true,
        configurable: true
    });
    return LoaderComponent;
}());
export { LoaderComponent };

var _a, _b;
export var ETranslations;
(function (ETranslations) {
    ETranslations["CVV"] = "cvv";
    ETranslations["MONTH"] = "month";
    ETranslations["MONTH_SHORT"] = "mm";
    ETranslations["NAME_AND_SURNAME"] = "name-and-surname";
    ETranslations["YEAR"] = "year";
    ETranslations["YEAR_SHORT"] = "yy";
})(ETranslations || (ETranslations = {}));
export var translations = {
    en_GB: (_a = {},
        _a[ETranslations.CVV] = 'cvv',
        _a[ETranslations.MONTH] = 'month',
        _a[ETranslations.MONTH_SHORT] = 'mm',
        _a[ETranslations.YEAR] = 'year',
        _a[ETranslations.YEAR_SHORT] = 'yy',
        _a[ETranslations.NAME_AND_SURNAME] = 'Name and surname',
        _a),
    es_ES: (_b = {},
        _b[ETranslations.CVV] = 'cvv',
        _b[ETranslations.MONTH] = 'mes',
        _b[ETranslations.MONTH_SHORT] = 'mm',
        _b[ETranslations.YEAR] = 'año',
        _b[ETranslations.YEAR_SHORT] = 'yy',
        _b[ETranslations.NAME_AND_SURNAME] = 'Nombre y apellidos',
        _b)
};

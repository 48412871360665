import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';

// Pipes
import { HighLightPipe } from './highlight.pipe';
import { LocaleLowercasePipe } from './locale-lowercase.pipe';

const allModules = [
  HighLightPipe,
  LocaleLowercasePipe
];

@NgModule({
  declarations: allModules,
  exports: allModules,
  imports: [
    AngularCommonModule
  ],
  providers: [allModules]
})
export class PipesModule { }

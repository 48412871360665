export function isLeapYear(year: number): boolean {
  return ((year % 4 === 0) && (year % 100 !== 0 )) || (year % 400 === 0);
}

export function isDateLessThanMinAge(date: Date, minAge: number): boolean {
  const today = new Date(Date.now());
  const dateBack = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());

  return date > dateBack;
}

export function newDate(date: string): Date {
  return new Date(date.replace(/-/g, '\/').replace(/T.+/, ''));
}

export function _buildAge(birthdate) {
  if (!birthdate) {
    return;
  }

  const today = new Date();
  let age = today.getFullYear() - birthdate.getFullYear();
  const m = today.getMonth() - birthdate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
    age--;
  }

  return age;
}

export function convertTimeToAmPm(time: string): string {
  const hour = +time.substring(0, 2);
  const convertedHour = hour % 12;
  const ampm = (hour < 12 || hour === 24) ? 'AM' : 'PM';
  return convertedHour + time.substring(2) + ampm;
}

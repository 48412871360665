import * as tslib_1 from "tslib";
import { map, catchError } from 'rxjs/operators';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { EnvService } from 'src/app/shared/common/services/env.service';
var DOCUMENT_POSITION_MAP = {
    obverse: 'frontDocument',
    reverse: 'backDocument',
    selfie: 'selfie'
};
var OccupantIdentifyService = /** @class */ (function () {
    function OccupantIdentifyService(http, env) {
        this.http = http;
        this.env = env;
        this.baseUrl = this.env.endpoint + "/v2/scan/";
        this.documentsValue = {};
        this.mrz = [];
    }
    OccupantIdentifyService.prototype.sanitizeMrzValues = function (values) {
        if (!Array.isArray(values) || values.length === 0) {
            return [];
        }
        return values.slice().map(function (mrz) {
            switch (mrz.id) {
                case 'clientGender':
                    return tslib_1.__assign({}, mrz, { value: ['M', 'F'].includes(mrz.value) ? mrz.value : '' });
                default:
                    return mrz;
            }
        });
    };
    Object.defineProperty(OccupantIdentifyService.prototype, "mrz", {
        get: function () {
            return this.mrzValues;
        },
        set: function (values) {
            this.mrzValues = this.sanitizeMrzValues(values);
        },
        enumerable: true,
        configurable: true
    });
    OccupantIdentifyService.prototype.setDocument = function (document, value) {
        this.documentsValue[DOCUMENT_POSITION_MAP[document]] = value;
    };
    OccupantIdentifyService.prototype.getDocument = function (document) {
        return this.documentsValue[DOCUMENT_POSITION_MAP[document]];
    };
    OccupantIdentifyService.prototype.clearDocument = function (document) {
        delete this.documentsValue[DOCUMENT_POSITION_MAP[document]];
    };
    Object.defineProperty(OccupantIdentifyService.prototype, "documents", {
        get: function () {
            return this.documentsValue;
        },
        set: function (documents) {
            this.documentsValue = documents;
        },
        enumerable: true,
        configurable: true
    });
    OccupantIdentifyService.prototype.scanDocument = function (image) {
        return this.http.post(this.baseUrl, { image: image }).pipe(map(function (res) { return res; }), catchError(function (error) {
            throw new Error(error || 'Server error');
        }));
    };
    return OccupantIdentifyService;
}());
export { OccupantIdentifyService };

import * as tslib_1 from "tslib";
import { RequestInterfaceBase } from '../request-interface-base';
var RequestInterfaceModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RequestInterfaceModalComponent, _super);
    function RequestInterfaceModalComponent() {
        return _super.call(this) || this;
    }
    return RequestInterfaceModalComponent;
}(RequestInterfaceBase));
export { RequestInterfaceModalComponent };

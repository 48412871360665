import { Injectable } from '@angular/core';
import { OccupantStep } from './occupant-detail.interface';
import { Subject, Observable } from 'rxjs';
import { ConfigService } from 'src/app/shared/services/config/config.service';

@Injectable()
export class OccupantDetailService {
  private activeStepValue: number;
  private checkinInitializedValue: boolean;

  occupantCompletedId: number;
  showCheckinCompletedTooltip: boolean;

  stepsValue: OccupantStep[];

  isSignatureActivated: boolean;
  creditCardActivated: boolean;
  addCreditCard: boolean;
  travelInfoRequired: boolean;

  public signatureAdded: boolean;

  private _occupantCompletedName: string; // tslint:disable-line:variable-name

  private disableProgressBarSubject: Subject<boolean>;

  private _activeStepSubject: Subject<number>;
  private _stepsSubject: Subject<number[]>;

  constructor(
    private config: ConfigService
  ) {
    this._activeStepSubject = new Subject<number>();
    this._stepsSubject = new Subject<number[]>();

    this.steps = [];
    this.disableProgressBarSubject = new Subject();
  }

  set activeStep(step: number) {
    this.activeStepValue = step;

    this.setActiveStep(step);
  }

  get activeStep(): number {
    return this.activeStepValue;
  }

  set checkinInitialized(initialized: boolean) {
    this.checkinInitializedValue = initialized;
  }

  get checkinInitialized(): boolean {
    return this.checkinInitializedValue;
  }

  get steps(): any {
    return this.stepsValue;
  }

  set steps(steps: any) {
    this.stepsValue = steps;

    this.setSteps(steps);
  }

  get occupantCompletedName(): string {
    return this._occupantCompletedName;
  }

  set occupantCompletedName(name: string) {
    this._occupantCompletedName = name;
  }

  markOccupantAsCompleted(occupantId: number): void {
    this.occupantCompletedId = occupantId;
    const { occupantsData: { list = [] } } = this.config.booking;

    const completedOccupant = list.find((occupant) => occupant.id === occupantId);
    completedOccupant.completed = true;
    completedOccupant.name = this.occupantCompletedName || completedOccupant.name;
    completedOccupant.nameUpdated = true;
    completedOccupant.showInvalidAlert = false;

    this.showCheckinCompletedTooltip = list.filter((occ) => !occ.completed).length === 0;
  }

  public isProgressBarDisabled(): Observable<boolean> {
    return this.disableProgressBarSubject.asObservable();
  }

  public disableProgressBar(disabled: boolean): void {
    this.disableProgressBarSubject.next(disabled);
  }

  public setSteps(steps: OccupantStep[]) {
    this._stepsSubject.next(steps.map((_, i) => (i + 1)));
  }

  public getSteps(): Observable<number[]> {
    return this._stepsSubject.asObservable();
  }

  public setActiveStep(step: number) {
    this._activeStepSubject.next(step);
  }

  public getActiveStep(): Observable<number> {
    return this._activeStepSubject.asObservable();
  }
}

import { Injector } from '@angular/core';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { ConfigService } from 'src/app/shared/services/config/config.service';

export class Currency {
  private currency: string;
  private http: CheckinHttpClient;
  private urlCurrency: string;
  private config: ConfigService;

  constructor(private buyerCurrency: string) {
    const injector: Injector = InjectorService.get();
    const env: EnvService = injector.get(EnvService);
    this.config = injector.get(ConfigService);

    Object.assign(this, {
      currency: buyerCurrency,
      http: injector.get(CheckinHttpClient),
      urlCurrency: `${env.endpoint}/v2/booking/${this.config.payments.hash}/buyerCurrency`
    });

    this.set(this.currency);
  }

  public get(): string {
    return this.currency;
  }

  public set(currency: string, update?: boolean): void {
    this.currency = currency;
    if (update) {
      const req = this.http.post(this.urlCurrency, { currency }).pipe(
        catchError((error: any) => throwError(error || 'Server error'))
      ).subscribe(() => {
        window.location.reload();
        req.unsubscribe();
      });
    }
  }

  public default(): string {
    return 'EUR';
  }
}

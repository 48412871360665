import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';

const PREVENT_LOCALES = ['de_DE'];
const PREVENT_STRINGS = ['DNI'];

@Pipe({name: 'localeLowercase'})
export class LocaleLowercasePipe implements PipeTransform {
  constructor(private config: ConfigService) { }

  transform(str: string, locale?: string): string {
    locale = locale || this.config.locale.get() || 'en_GB';

    return str ? ((PREVENT_LOCALES.includes(locale) || PREVENT_STRINGS.includes(str)) ? str : str.toLowerCase()) : '';
  }
}

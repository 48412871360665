import { Injectable } from '@angular/core';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { map, catchError } from 'rxjs/operators';

import { OccupantListAdditionalNotesItem } from './occupant-list-additional-notes.interface';

@Injectable()
export class OccupantListAdditionalNotesService {
  private endpoint: string;
  private baseUrl: string;

  constructor(
    private http: CheckinHttpClient,
    private env: EnvService
  ) {
    this.endpoint = this.env.endpoint;
    this.baseUrl = `${this.endpoint}/v1/bookings/`;
  }

  deleteBookingComment(bookingId: number, commentId: number) {
    const url = `${this.baseUrl}${bookingId}/comments`;

    return this.http.put(url, { comment: commentId }).pipe(
      map((res: Response) => res),
      catchError((error: any) => {
        throw new Error(error || 'Server error');
      })
    );
  }

  addBookingComment(bookingId: number, comment: string) {
    const url = `${this.baseUrl}${bookingId}/comments`;

    return this.http.post(url, { comment }).pipe(
      map((res: OccupantListAdditionalNotesItem) => res),
      catchError((error: any) => {
        throw new Error(error || 'Server error');
      })
    );
  }
}

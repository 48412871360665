import {
  Component,
  Injector,
  Input,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import * as get from 'lodash.get';
import { IPaymentListItem, PaymentListItem } from './payment-list.model';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { slideOutDuration, overlayDuration } from 'src/app/shared/common/animations/slide-out';
import { TAnimationState, TSlideOutType } from 'src/app/shared/models/slide-out/slide-out.model';
import { Subscription } from 'rxjs';
import { PaymentsDetail } from './payments-detail.class';
import { CreditCardFormService } from '../../../components/credit-card-form/credit-card-form.service';

@Component({
  selector: 'app-payments-detail',
  templateUrl: './payments-detail.component.html',
  styleUrls: ['./payments-detail.component.scss']
})

export class PaymentsDetailComponent implements AfterViewInit, OnDestroy {
  @Input() fullBookingDetail: boolean;

  private configService: ConfigService;
  private translateService: TranslateService;
  private creditCardFromService: CreditCardFormService;
  private subscriptions: Subscription[];
  private translations: any;

  public payments: {
    all: IPaymentListItem[];
    detail: PaymentsDetail;
    sorted: {
      sort: (n: number) => void;
      value: IPaymentListItem[];
    };
    showMore: boolean;
  };
  public slider: {
    animation: TAnimationState;
    show: boolean;
    type: TSlideOutType;
  };

  constructor(private injector: Injector, private cdRef: ChangeDetectorRef) {
    Object.assign(this, {
      configService: this.injector.get(ConfigService),
      translateService: this.injector.get(TranslateService),
      creditCardFromService: this.injector.get(CreditCardFormService),
      slider: {
        animation: 'out',
        show: false,
        type: 'left'
      },
      subscriptions: []
    });

    this.setPayments();
    this.getPaymentsDetailTranslations();
  }

  private setPayments(): void {
    this.payments = {
      all: [],
      detail: new PaymentsDetail(),
      showMore: false,
      sorted: {
        value: [],
        sort: (s: number) => {
          const selected = this.payments.all.some((p) => p.selected);

          if (!selected) {
            this.payments.sorted.value = [...this.payments.all.slice(0, s)];

            return;
          }

          if (s === this.payments.all.length) {
            this.payments.sorted.value = [...this.payments.all];

            return;
          }

          this.payments.sorted.value = this.payments.all.reduce((sorted, payment, index, all) => {
            switch (true) {
              case (sorted.length >= s):
              case (!(sorted.some((p) => p.selected)) && !payment.selected):
              case (!payment.selected && payment.paid):
                break;
              case payment.selected:
                sorted = [...((all[index - 1] && (s > 1)) ? [all[index - 1]] : []), payment];
                break;
              default:
                sorted.push(payment);
                break;
            }

            return sorted;
          }, []);
        }
      }
    };
  }

  public ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  public ngAfterViewInit(): void {
    this.payments.detail.setElements();

    this.subscribe();
  }

  private subscribe(): void {
    this.setPaymentsSizeConfig();

    this.subscriptions.push(this.configService.screen.onWindowResizeChanges().subscribe(() => this.setPaymentsSizeConfig()));
    this.subscriptions.push(this.creditCardFromService.onChangesCardNumber().subscribe(() => {
      this.getPaymentsDetailTranslations();
      this.setPaymentsSizeConfig();
    }));
  }

  private setPaymentsSizeConfig() {
    if (!this.configService.screen.isLG) {
      const result = this.payments.detail.getSizeConfig(this.payments.all.length);

      this.payments.sorted.sort(result.showing);
      this.payments.showMore = result.showToggle;

      const maxHeight = result.maxHeight || (result.paymentHeight * this.payments.sorted.value.length);

      document
        .querySelector('.checkin__booking__detail__payments__list')
        .setAttribute('style', `max-height: ${ maxHeight }px`);

      this.cdRef.detectChanges();
    }
  }

  private getPaymentsDetailTranslations(): void {
    this.translateService.get(['payment.one', 'deposit', 'payment-rese', 'payment-lleg', 'payment-ante'])
      .subscribe((translations: { [key: string]: string }) => {
        this.translations = translations;

        this.setPaymentsDetailConfig();
      });
  }

  private setPaymentsDetailConfig(): void {
    const { paymentsDetail, deposit } = get(this.configService.booking, 'resume.payments', { paymentsDetail: [], deposit: [] });
    const { mcp } = this.configService.booking.resume.paymentConfig;

    const payment: (p: any, i: number) => PaymentListItem = (p: any, i: number) => new PaymentListItem({
      ...p,
      amount: mcp.enabled ? p.mcp.amount : p.amount,
      currency: mcp.enabled ? p.mcp.currency : p.currency,
      text: p.isSecurityDeposit ? this.translations.deposit : this.translations[`payment-${p.momentoPago}`]
    });
    const payments = [
      ...(paymentsDetail.length > 0 ? paymentsDetail : []),
      ...(deposit.length > 0 ? deposit : [])
    ].map((p, i) => payment(p, i));

    this.payments.all = [ ...payments ];
    this.payments.sorted.value = [ ...payments ];
  }

  public openSlideOutHandler(): void {
    this.slider.show = true;

    setTimeout(() => {
      this.slider.animation = 'in';
    });
  }

  public closeSlideOutHandler(): void {
    this.slider.animation = 'out';

    setTimeout(() => {
      this.slider.show = false;
    }, (Math.max(slideOutDuration, overlayDuration) + 50));
  }
}

import { Component } from '@angular/core';
import { ButtonBase } from '../button-base';
import { StylesService } from '../styles.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';

@Component({
  selector: 'app-button-secondary',
  templateUrl: './button-secondary.component.html',
  styleUrls: ['./button-secondary.component.scss']
})

export class ButtonSecondaryComponent extends ButtonBase {
  constructor() {
    super();

    this.setCustomStyles();
  }

  private setCustomStyles(): void {
    const service: StylesService = InjectorService.get().get(StylesService);

    service.set({
      'button.checkin__btn--secondary': {
        color: this.corporativeColor,

        '&:hover, &:focus': {
          color: this.corporativeColor.darken(.15)
        }
      }
    });
  }
}

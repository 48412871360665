import { NgModule } from '@angular/core';
import { TermsAndConditionsComponent } from './terms-and-conditions.component';
import { ServicesModule } from 'src/app/shared/services/services.module';

const modules = [
  TermsAndConditionsComponent
];

@NgModule({
  declarations: modules,
  imports: [ServicesModule]
})
export class TermsAndConditionsModule { }

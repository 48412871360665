import { Component } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';

@Component({
  selector: 'app-process-info-box',
  templateUrl: './process-info-box.component.html',
  styleUrls: ['./process-info-box.component.scss']
})
export class ProcessInfoBoxComponent {
  constructor(
    private config: ConfigService,
  ) {
  }

  public get isMobile(): boolean {
    return this.config.screen.isSM;
  }
}

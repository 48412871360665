var MainService = /** @class */ (function () {
    function MainService() {
    }
    MainService.prototype.toogleCardResponsive = function () {
        this.showCardResponsive = !this.showCardResponsive;
    };
    Object.defineProperty(MainService.prototype, "activePage", {
        get: function () {
            return this.activePageValue;
        },
        set: function (page) {
        },
        enumerable: true,
        configurable: true
    });
    MainService.prototype.initActivePage = function (defaultPage) {
    };
    return MainService;
}());
export { MainService };

import * as tslib_1 from "tslib";
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { EScanner } from 'src/app/checkin-app/modules/checkin/pages/wizard/pages/identity/pages/capture/enums/scanner.enum';
var Guest = /** @class */ (function () {
    // public showInvalidAlert?: boolean;
    function Guest(params) {
        this.hash = params.hash;
        Object.assign(this, this.sanitize(params.occupant));
    }
    Guest.prototype.sanitize = function (params) {
        var injector = InjectorService.get();
        var env = injector.get(EnvService);
        var completed = params.completed, frontDocument = params.frontDocument, backDocument = params.backDocument, selfie = params.selfie, showInvalidAlert = params.showInvalidAlert, rest = tslib_1.__rest(params, ["completed", "frontDocument", "backDocument", "selfie", "showInvalidAlert"]);
        return tslib_1.__assign({}, rest, { completed: {
                value: completed
            }, documents: {
                back: this.getImgPublicLink(env, backDocument),
                front: this.getImgPublicLink(env, frontDocument),
                selfie: this.getImgPublicLink(env, selfie)
            }, invalid: showInvalidAlert, signatures: {} });
    };
    Guest.prototype.getImgPublicLink = function (env, img) {
        return img ? env.endpoint + "/v2/files/" + this.hash + "/" + img + "?domain=" + env.defaultDomain : undefined;
    };
    Object.defineProperty(Guest.prototype, "isCompleted", {
        get: function () {
            return this.completed.value;
        },
        enumerable: true,
        configurable: true
    });
    Guest.prototype.removeAnimation = function () {
        delete this.completed.animate;
    };
    Guest.prototype.getDocument = function (doc) {
        return this.documents[doc];
    };
    Guest.prototype.setDocument = function (key, value) {
        this.documents[key] = value;
    };
    Guest.prototype.setSignature = function (key, value) {
        this.signatures[key] = value;
    };
    Guest.prototype.markAsDelegated = function (name, delegated) {
        this.delegated = delegated;
        this.name = {
            value: name,
            updated: true
        };
    };
    Guest.prototype.restoreDocuments = function () {
        var _this = this;
        [EScanner.FRONT, EScanner.BACK].forEach(function (d) { return _this.documents[d] = undefined; });
    };
    Guest.prototype.complete = function (name) {
        Object.assign(this, {
            completed: {
                animate: true,
                value: true
            },
            name: {
                value: name,
                updated: true
            }
        });
    };
    return Guest;
}());
export { Guest };

function getOrientation() {
  const existsOrientation = Boolean(screen.orientation);
  const isLandscape = existsOrientation && screen.orientation.type.indexOf('landscape');
  return (!existsOrientation || isLandscape) ? 'landscape' : 'portrait';
}

function getVPWidth() {
  return getOrientation() === 'landscape' ? screen.availHeight : screen.availWidth;
}

function getVPHeight() {
  return getOrientation() === 'landscape' ? screen.availWidth : screen.availHeight;
}

function getScreenWidth() {
  return window.innerWidth;
}

function getScreenHeight() {
  return window.innerHeight;
}

export function getRes(): string {
  return `${getScreenWidth()}|${getScreenHeight()}|${getVPWidth()}|${getVPHeight()}`;
}

export function checkScroll(element): boolean {
  const contentHeight = element.scrollHeight;
  const containerHeight = element.clientHeight;

  if (contentHeight > containerHeight) {
    return true;
  }

  return false;
}

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ComponentFactoryResolver,
  Injector
} from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';

import { INav } from 'src/app/shared/models/navbar/navbar.model';
import { AdDirective } from 'src/app/shared/common/directives/ad.directive';
import { InputLanguageComponent } from 'src/app/shared/inputs/input-language/input-language.component';
import { Project } from '../../../../../classes/projects/project.class';

const COMPONENTS: { [key: string]: any } = {
  INPUT_LANGUAGE: InputLanguageComponent
};

@Component({
  selector: 'app-navbar-aditional-info-item',
  templateUrl: './navbar-aditional-info-item.component.html',
  styleUrls: ['./navbar-aditional-info-item.component.scss']
})
export class NavbarAditionalInfoItemComponent implements OnInit {
  @ViewChild(AdDirective, { static: true }) appAdHost: AdDirective;
  @Input() item: INav;

  private config: ConfigService;
  private env: EnvService;
  private project: Project;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    const injector: Injector = InjectorService.get();
    const config: ConfigService = injector.get(ConfigService);

    Object.assign(this, {
      config,
      project: config[config.project],
      env: injector.get(EnvService)
    });
  }

  public ngOnInit(): void {
    this.loadComponent();
  }

  public onClick(item: INav): void {
    const { action, route } = item;
    if (!action && !route) {
      return;
    }

    action ? this[action]() : this.project.navigate(route);
  }

  public loadComponent() {
    const { component } = this.item;
    if (!component) {
      return;
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(COMPONENTS[component]);
    const viewContainerRef = this.appAdHost.viewContainerRef;

    viewContainerRef.clear();
    viewContainerRef.createComponent(componentFactory);
  }

  public navigateToIntranet(): void {
    const url = `${this.env.domain}/intranet/${this.config.booking.resume.localizer}`;
    window.open(url, '_blank');
  }
}

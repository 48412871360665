import { Input } from '@angular/core';
import * as Color from 'color';
import { ConfirmationRequestResultType } from 'src/app/shared/main/main.enum';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';

export class RequestInterfaceBase {
  @Input() align: string;
  @Input() result: ConfirmationRequestResultType;
  @Input() sizeLoader = 24;

  corporativeColor: Color;

  config: ConfigService;

  constructor() {
    this.config = InjectorService.get().get(ConfigService);
    this.corporativeColor = this.config.checkin.corporativeColor;
  }

  get modalTemplate(): string {
    return this.result || 'action';
  }

  get alignClass(): string {
    return this.align ? `checkin__request__interface__modal__${this.align}` : '';
  }
}

import * as tslib_1 from "tslib";
import { ButtonBase } from '../button-base';
import { StylesService } from '../styles.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
var ButtonSecondaryComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ButtonSecondaryComponent, _super);
    function ButtonSecondaryComponent() {
        var _this = _super.call(this) || this;
        _this.setCustomStyles();
        return _this;
    }
    ButtonSecondaryComponent.prototype.setCustomStyles = function () {
        var service = InjectorService.get().get(StylesService);
        service.set({
            'button.checkin__btn--secondary': {
                color: this.corporativeColor,
                '&:hover, &:focus': {
                    color: this.corporativeColor.darken(.15)
                }
            }
        });
    };
    return ButtonSecondaryComponent;
}(ButtonBase));
export { ButtonSecondaryComponent };

export enum ERoutes {
  GUESTS = 'guests',
  TRAVEL_INFO = 'travel-info',
  COMMENTS = 'comments',
  INBOX = 'inbox',
  GUEST_INFO = 'guest-info'
}

export enum ERoutesAnimations {
  GUESTS = 'Guests',
  TRAVEL_INFO = 'TravelInfo',
  COMMENTS = 'Comments',
  INBOX = 'Inbox',
  GUEST_INFO = 'GuestInfo'
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'highlight'})
export class HighLightPipe implements PipeTransform {
  constructor() {}

  transform(text: string, search?: string) {
    if (!search) {
      return text;
    }

    const sanitized = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const match = new RegExp(`(${sanitized})`, 'gi');
    return text.replace(match, `<span>${search}</span>`);
  }
}

import {
  Component,
  ViewEncapsulation,
  Input,
  HostListener,
  OnInit
} from '@angular/core';
import { InputBase } from '../input-base';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class InputTextComponent extends InputBase implements OnInit {
  @Input() pattern: string;
  @Input() uppercase: boolean;
  @Input() trimValue: boolean;

  private regex: RegExp;

  constructor() {
    super();
  }

  ngOnInit() {
    super.initInputBase();

    if (this.pattern) {
      this.regex = new RegExp(this.pattern, 'g');
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    if (this.pattern) {
      // If last character is [´˜], we wait for a vowel or n
      const lastChar = value[value.length - 1];
      if (['´', '˜'].includes(lastChar)) {
        return;
      }

      value = value.replace(this.regex, '');
    }

    if (this.uppercase) {
      value = value.toUpperCase();
    }

    if (this.trimValue) {
      value = value.trim();
    }

    this.form.get(this.control).setValue(value);
  }
}

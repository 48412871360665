import * as tslib_1 from "tslib";
import * as Color from 'color';
import { Router } from '@angular/router';
import { LocationStrategy, DOCUMENT } from '@angular/common';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { Translations } from 'src/app/shared/utils/translations';
import { EModulesRoutes } from 'src/app/app.enum';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { EModules } from 'src/app/app.enum';
import { Title } from '@angular/platform-browser';
var Project = /** @class */ (function () {
    function Project(params, company, project) {
        var injector = InjectorService.get();
        Object.assign(this, {
            document: injector.get(DOCUMENT),
            locationStrategy: injector.get(LocationStrategy),
            router: injector.get(Router),
            company: company,
            errorHandler: new ErrorHandler(),
            metaTitle: injector.get(Title)
        });
        this.init(params, project);
    }
    Project.prototype.init = function (params, project) {
        var _a = params.webConfig, favicon = _a.favicon, logo = _a.logo;
        var _b = params.webConfig.colors, colors = _b === void 0 ? {} : _b;
        colors = this.getColors(colors);
        var corporative = colors.corporative;
        Object.assign(this, tslib_1.__assign({ baseUrl: "" + this.document.location.origin + this.locationStrategy.getBaseHref(), baseCheckinUrl: "" + this.document.location.origin + this.locationStrategy.getBaseHref() + EModulesRoutes[project.toUpperCase()], colors: colors }, (corporative) && { corporativeColor: corporative }, { config: {}, favicon: favicon,
            logo: logo, loading: true, enabled: (project !== EModules.CHECKOUT) ? this.company.isEnabledModule(project) : true, modules: this.getModules() }));
    };
    Project.prototype.getColors = function (colors) {
        return Object.entries(colors).reduce(function (co, _a) {
            var _b;
            var key = _a[0], value = _a[1];
            return tslib_1.__assign({}, co, (_b = {}, _b[key] = Color(value), _b));
        }, { corporative: Color('#dc3776') });
    };
    Project.prototype.setNotification = function (paths, value) {
        [this.tabs, this.navbar].forEach(function (x) {
            var el = x.find(function (_a) {
                var route = _a.route;
                return paths.includes(route);
            });
            if (el) {
                el.notifications = value;
            }
        });
    };
    Project.prototype.setTranslations = function (cb) {
        var _this = this;
        new Translations([
            'occupant.one',
            'companion.one',
            'confirm-fields-and-accepts'
        ]).get(function (translations) {
            Object.assign(_this, { translations: translations });
            if (cb) {
                cb();
            }
        });
    };
    Project.prototype.goHome = function () {
        this.router.navigateByUrl(this.routeFallback);
    };
    Project.prototype.navigate = function (route) {
        this.router.navigateByUrl(this.routeFallback + "/" + route);
    };
    Project.prototype.getModules = function () {
        return {
            CHANGE_CARD: true,
            RESPONSIVE: {
                HIDE_HEADER: true
            },
            SIDEBAR: {
                CUSTOM_STATUS: true
            }
        };
    };
    Project.prototype.updateWebconfig = function (params) {
        var _a = params.webConfig, favicon = _a.favicon, logo = _a.logo;
        var _b = params.webConfig.colors, colors = _b === void 0 ? {} : _b;
        colors = this.getColors(colors);
        var corporative = colors.corporative;
        Object.assign(this, tslib_1.__assign({ colors: colors }, (corporative) && { corporativeColor: corporative }, { favicon: favicon,
            logo: logo }));
    };
    return Project;
}());
export { Project };

import { Component } from '@angular/core';
import { ButtonBase } from '../button-base';
import { StylesService } from '../styles.service';
import * as Color from 'color';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';

@Component({
  selector: 'app-button-primary',
  templateUrl: './button-primary.component.html',
  styleUrls: ['./button-primary.component.scss']
})

export class ButtonPrimaryComponent extends ButtonBase {
  hoverColor: Color;
  activeColor: Color;

  private stylesService: StylesService;

  constructor() {
    super();

    this.stylesService = InjectorService.get().get(StylesService);

    this.hoverColor = this.corporativeColor.darken(.15);
    this.activeColor = this.corporativeColor.darken(.2);

    this.stylesService.set({
      'button.checkin__btn__primary': {
        'background-color': this.corporativeColor,
        'border-color': this.corporativeColor,

        '&:hover, &:focus': {
          'background-color': this.hoverColor,
          'border-color': this.hoverColor
        },

        '&:active': {
          'background-color': this.activeColor,
          'border-color': this.activeColor
        }
      }
    });
  }
}

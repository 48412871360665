import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Modules
import { ArrivalInfoModule } from '../arrival-info/arrival-info.module';
import { ButtonsModule } from 'src/app/shared/common/components/buttons/buttons.module';
import { CommonModule } from '../../../common/common.module';
import { IconsModule } from 'src/app/shared/common/components/icons/icons.module';
import { InputsModule } from 'src/app/shared/common/components/inputs/inputs.module';
import { RequestInterfaceModule } from 'src/app/shared/common/components/request-interface/request-interface.module';
import { TooltipModule } from '../../../common/components/tooltip/tooltip.module';

// Components
import { OccupantListAdditionalNotesComponent } from './occupant-list-additional-notes/occupant-list-additional-notes.component';
import {
  OccupantListAdditionalNotesMessageComponent
} from './occupant-list-additional-notes/occupant-list-additional-notes-message/occupant-list-additional-notes-message.component';
import { OccupantListArrivalInfoComponent } from './occupant-list-arrival-info/occupant-list-arrival-info.component';
import {
  OccupantListArrivalInfoModalComponent
} from './occupant-list-arrival-info/occupant-list-arrival-info-modal/occupant-list-arrival-info-modal.component';
import { OccupantListSectionComponent } from './occupant-list-section/occupant-list-section.component';
import { OccupantListComponent } from './occupant-list.component';
import { OccupantListDetailComponent } from './occupant-list-detail/occupant-list-detail.component';
import { OccupantListDetailItemComponent } from './occupant-list-detail/occupant-list-detail-item/occupant-list-detail-item.component';
import { OccupantListItemComponent } from './occupant-list-item/occupant-list-item.component';
import { OccupantListExpiredCheckinComponent } from './occupant-list-expired-checkin/occupant-list-expired-checkin.component';
import { OccupantListDelegateComponent } from './occupant-list-delegate/occupant-list-delegate.component';
import {
  OccupantListDelegateFormComponent
} from './occupant-list-delegate/occupant-list-delegate-form/occupant-list-delegate-form.component';
import {
  OccupantListDelegateFeedbackComponent
} from './occupant-list-delegate/occupant-list-delegate-feedback/occupant-list-delegate-feedback.component';
import {
  OccupantListDelegateFormItemComponent
} from './occupant-list-delegate/occupant-list-delegate-form/occupant-list-delegate-form-item/occupant-list-delegate-form-item.component';

// Services
import { OccupantListAdditionalNotesService } from './occupant-list-additional-notes/occupant-list-additional-notes.service';
import { OccupantListDelegateService } from './occupant-list-delegate/occupant-list-delegate.service';
import { ServicesModule } from 'src/app/shared/services/services.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    OccupantListAdditionalNotesComponent,
    OccupantListAdditionalNotesMessageComponent,
    OccupantListArrivalInfoComponent,
    OccupantListArrivalInfoModalComponent,
    OccupantListSectionComponent,
    OccupantListComponent,
    OccupantListDetailComponent,
    OccupantListDetailItemComponent,
    OccupantListItemComponent,
    OccupantListExpiredCheckinComponent,
    OccupantListDelegateComponent,
    OccupantListDelegateFormComponent,
    OccupantListDelegateFeedbackComponent,
    OccupantListDelegateFormItemComponent
  ],
  imports: [
    AngularCommonModule,
    ArrivalInfoModule,
    ButtonsModule,
    SharedModule,
    CommonModule,
    FormsModule,
    IconsModule,
    InputsModule,
    ReactiveFormsModule,
    RequestInterfaceModule,
    TooltipModule,
    ServicesModule
  ],
  exports: [
    OccupantListAdditionalNotesComponent,
    OccupantListArrivalInfoComponent
  ],
  providers: [
    OccupantListAdditionalNotesService,
    OccupantListDelegateService
  ]
})
export class OccupantListModule { }

import { environment } from '../../../../environments/environment';
var getQueryParams = function () {
    var search = window.location.search || '';
    var pairs = search.substring(1).split('&');
    var result = {};
    pairs.forEach(function (pair) {
        if (pair.trim() === '') {
            return;
        }
        var keyVal = pair.split('=');
        result[keyVal[0]] = decodeURIComponent(keyVal[1]);
    });
    return result;
};
var ɵ0 = getQueryParams;
var EnvService = /** @class */ (function () {
    function EnvService() {
        this.env = environment;
        this.queryParams = getQueryParams();
    }
    Object.defineProperty(EnvService.prototype, "isProduction", {
        get: function () {
            return this.env.production;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnvService.prototype, "isDevelopment", {
        get: function () {
            return this.env.development;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnvService.prototype, "endpoint", {
        get: function () {
            return this.env.endpoint;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnvService.prototype, "defaultDomain", {
        get: function () {
            return this.env.defaultDomain;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnvService.prototype, "domain", {
        get: function () {
            var location = window.location.href;
            var match = location.match(/^https?\:\/\/(([a-z0-9\-]+)\.([a-z0-9\-\.]+)(\/[a-z]{2})?)\//);
            var domain = this.queryParams.return_url;
            if (!domain) {
                if (match && match[1]) {
                    var mainDomain = match[1];
                    if (!/\.(int|pre|local)$/.test(mainDomain)) {
                        domain = (mainDomain === 'secure.avantio.com') ? mainDomain : mainDomain.replace('secure.', '');
                    }
                }
                if (!this.isProduction) {
                    domain = this.env.defaultDomain;
                }
            }
            return domain;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnvService.prototype, "wsEndpoint", {
        get: function () {
            return this.env.wsEndpoint;
        },
        enumerable: true,
        configurable: true
    });
    return EnvService;
}());
export { EnvService };
export { ɵ0 };

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// Components
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { StaticPageLayoutComponent } from './static-page-layout/static-page-layout.component';

// Modules
import { IMPORTS } from 'src/app/shared/imports';
const layouts = [
  MainLayoutComponent,
  StaticPageLayoutComponent
];

@NgModule({
  declarations: layouts,
  imports: [
    ...IMPORTS.all,
    RouterModule
  ],
  exports: layouts
})
export class LayoutsModule { }

import { Input, Output, EventEmitter, ViewChild, OnInit, OnDestroy} from '@angular/core';
import * as Color from 'color';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { Subscription, fromEvent } from 'rxjs';
import { filter, throttleTime, tap} from 'rxjs/operators';

export class ButtonBase implements OnInit, OnDestroy {
  @Input() icon: string;
  @Input() customClass: string;
  @Input() disabled: boolean;
  @Input() text: string;
  @Input() translateParams: any;
  @Output() buttonClick: EventEmitter<boolean>;

  corporativeColor: Color;
  isHover: boolean;
  config: ConfigService;
  buttonSubscription: Subscription;
  @ViewChild('button', {static: true}) button;

  constructor() {
    this.config = InjectorService.get().get(ConfigService);
    this.buttonClick = new EventEmitter();
    this.translateParams = {};

    this.corporativeColor = this.config.checkin.corporativeColor;
  }

  ngOnInit() {
    this.buttonSubscription = fromEvent(this.button.nativeElement, 'click').pipe(
      filter(() => !this.disabled),
      throttleTime(500),
      tap(() => this.button.nativeElement.blur)
    ).subscribe(() => {
      this.buttonClick.emit();
    });
  }

  ngOnDestroy() {
    this.buttonSubscription.unsubscribe();
  }

  toggleIsHover() {
    this.isHover = !this.isHover;
  }
}

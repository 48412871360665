import { ConfigService } from 'src/app/shared/services/config/config.service';
var ProcessInfoBoxComponent = /** @class */ (function () {
    function ProcessInfoBoxComponent(config) {
        this.config = config;
    }
    Object.defineProperty(ProcessInfoBoxComponent.prototype, "isMobile", {
        get: function () {
            return this.config.screen.isSM;
        },
        enumerable: true,
        configurable: true
    });
    return ProcessInfoBoxComponent;
}());
export { ProcessInfoBoxComponent };

import { Subject } from 'rxjs';
import { ConfigService } from 'src/app/shared/services/config/config.service';
var OccupantDetailService = /** @class */ (function () {
    function OccupantDetailService(config) {
        this.config = config;
        this._activeStepSubject = new Subject();
        this._stepsSubject = new Subject();
        this.steps = [];
        this.disableProgressBarSubject = new Subject();
    }
    Object.defineProperty(OccupantDetailService.prototype, "activeStep", {
        get: function () {
            return this.activeStepValue;
        },
        set: function (step) {
            this.activeStepValue = step;
            this.setActiveStep(step);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OccupantDetailService.prototype, "checkinInitialized", {
        get: function () {
            return this.checkinInitializedValue;
        },
        set: function (initialized) {
            this.checkinInitializedValue = initialized;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OccupantDetailService.prototype, "steps", {
        get: function () {
            return this.stepsValue;
        },
        set: function (steps) {
            this.stepsValue = steps;
            this.setSteps(steps);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OccupantDetailService.prototype, "occupantCompletedName", {
        get: function () {
            return this._occupantCompletedName;
        },
        set: function (name) {
            this._occupantCompletedName = name;
        },
        enumerable: true,
        configurable: true
    });
    OccupantDetailService.prototype.markOccupantAsCompleted = function (occupantId) {
        this.occupantCompletedId = occupantId;
        var _a = this.config.booking.occupantsData.list, list = _a === void 0 ? [] : _a;
        var completedOccupant = list.find(function (occupant) { return occupant.id === occupantId; });
        completedOccupant.completed = true;
        completedOccupant.name = this.occupantCompletedName || completedOccupant.name;
        completedOccupant.nameUpdated = true;
        completedOccupant.showInvalidAlert = false;
        this.showCheckinCompletedTooltip = list.filter(function (occ) { return !occ.completed; }).length === 0;
    };
    OccupantDetailService.prototype.isProgressBarDisabled = function () {
        return this.disableProgressBarSubject.asObservable();
    };
    OccupantDetailService.prototype.disableProgressBar = function (disabled) {
        this.disableProgressBarSubject.next(disabled);
    };
    OccupantDetailService.prototype.setSteps = function (steps) {
        this._stepsSubject.next(steps.map(function (_, i) { return (i + 1); }));
    };
    OccupantDetailService.prototype.getSteps = function () {
        return this._stepsSubject.asObservable();
    };
    OccupantDetailService.prototype.setActiveStep = function (step) {
        this._activeStepSubject.next(step);
    };
    OccupantDetailService.prototype.getActiveStep = function () {
        return this._activeStepSubject.asObservable();
    };
    return OccupantDetailService;
}());
export { OccupantDetailService };

import * as _ from 'lodash';
import { Injector } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { EProjects } from 'src/app/shared/enums/projects.enum';
import { enumIncludesValue } from 'src/app/shared/enums';
import { CookieService } from 'src/app/shared/services/cookie/cookie.service';
import { ELocale } from 'src/app/shared/enums/locale.enum';
import { Title } from '@angular/platform-browser';
import * as i0 from "@angular/core";
var BaseGuard = /** @class */ (function () {
    function BaseGuard() {
        var injector = InjectorService.get();
        this.config = injector.get(ConfigService);
        this.cookieService = injector.get(CookieService);
        this.env = injector.get(EnvService);
        this.http = injector.get(CheckinHttpClient);
        this.metaTitle = injector.get(Title);
        this.error = new ErrorHandler();
    }
    BaseGuard.prototype.canActivate = function (route) {
        /**
         * TODO:: Esto debe ser una petición que se lance a un endpoint similar a este ${this.env.endpoint}/v2/config
         * Y se enviará como parametro la key asociada al pathname desde el que nos están llamando
         * route: ActivatedRouteSnapshot desde aquí podremos obtenerlo
         */
        var _this = this;
        var project = this.getProjectPath(route);
        try {
            if (!project || !enumIncludesValue(EProjects, project)) {
                var r = _.get(route, 'firstChild.url', []).map(function (_a) {
                    var path = _a.path;
                    return path;
                }).join('#');
                throw new Error("Unavailable project path for: " + window.location.href + " - " + r);
            }
            // TODO:: Improve this
            var locale = this.cookieService.get(ELocale.GUEST_AREA);
            var url = this.env.endpoint + "/v2/config/" + project + (locale ? "?locale=" + locale : '');
            this.setProjectTitle(project);
            return this.http.get(url).pipe(map(function (data) {
                // TODO:: Change to observable
                _this.config.init(project, data);
                // TODO:: Check with data and init later
                // TODO:: Maybe throw error if not enabled
                return _this.config[project].enabled;
            }), catchError(function (err) { return _this.error.handler(err); }));
        }
        catch (error) {
            this.error.handler(error);
        }
    };
    BaseGuard.prototype.getProjectPath = function (route) {
        return _.get(route, 'firstChild.routeConfig.path', '');
    };
    BaseGuard.prototype.setProjectTitle = function (project) {
        if (/payments|checkout/.test(project)) {
            var capitalizeProject = project[0].toUpperCase() + project.slice(1).toLowerCase();
            this.metaTitle.setTitle("Secure " + capitalizeProject);
            return;
        }
        this.metaTitle.setTitle("Online Check-in");
    };
    BaseGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BaseGuard_Factory() { return new BaseGuard(); }, token: BaseGuard, providedIn: "root" });
    return BaseGuard;
}());
export { BaseGuard };

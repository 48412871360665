import { Component, OnInit } from '@angular/core';
import { EnvService } from '../../services/env.service';

@Component({
  template: ''
})
export class FallbackDomainComponent implements OnInit {
  constructor(private env: EnvService) {}

  ngOnInit() {
    if (!this.env.isDevelopment) {
      window.location.href = window.location.origin;
    }
  }
}

export function fillRange(start: any, end: any, increasing = true) {
  return Array(rangeCondition(start, end, increasing))
    .fill(null).map((item, index) => rangeOperation(start, index, increasing));
}

function rangeCondition(x: number, y: number, increasing: boolean) {
  return (increasing ? y - x : x - y) + 1;
}

function rangeOperation(x: number, y: number, increasing: boolean) {
  return increasing ? x + y : x - y;
}

export function merge(arr1: any[], arr2: any[]) {
  const arr = arr1.concat(arr2);
  return arr.filter((item, pos) => arr.indexOf(item) === pos);
}

export function sortByBoolean(arr: { [key: string]: any }[], property: string) {
  return arr.sort((x, y) => (x[property] === y[property]) ? 0 : x[property] ? 1 : -1 );
}

import { Component, Input } from '@angular/core';
import { ICreditCardParams } from '../../../credit-card/components/credit-card/credit-card.class';

@Component({
  selector: 'app-billing-address-preview',
  templateUrl: './billing-address-preview.component.html',
  styleUrls: ['./billing-address-preview.component.scss']
})

export class BillingAddressPreviewComponent {
  @Input() creditCard: ICreditCardParams;

  constructor() { }
}

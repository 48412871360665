import { Routes } from '@angular/router';
import { FallbackDomainComponent } from '../shared/common/components/fallback-domain/fallback-domain.component';
import { BaseGuard } from '../shared/guards/base/base.guard';
import { AppComponent } from './app.component';
var ɵ0 = function () { return import("./modules/checkin/checkin.module.ngfactory").then(function (m) { return m.CheckinModuleNgFactory; }); }, ɵ1 = function () { return import("./../shared/modules/payments/payments.module.ngfactory").then(function (m) { return m.PaymentsModuleNgFactory; }); }, ɵ2 = function () { return import("./modules/inbox/inbox.module.ngfactory").then(function (m) { return m.InboxModuleNgFactory; }); };
var routes = [
    {
        path: '',
        canActivate: [BaseGuard],
        component: AppComponent,
        children: [
            {
                path: 'checkin',
                loadChildren: ɵ0
            },
            {
                path: 'payments',
                loadChildren: ɵ1
            },
            {
                path: 'inbox',
                loadChildren: ɵ2
            },
            { path: '**', component: FallbackDomainComponent }
            // All page modules must go before AppComponent
            // {
            //   path: ':hash',
            //   canActivate: [BookingGuard],
            //   component: AppComponent,
            //   children: [
            //     {
            //       path: '',
            //       component: LayoutComponent,
            //       children: MainRoutes
            //     },
            //     // {
            //     //   path: 'checkin',
            //     //   loadChildren: () => import('./projects/checkin/checkin.module').then(m => m.CheckinModule)
            //     // },
            //     // {
            //     //   path: 'inbox',
            //     //   loadChildren: () => import('./projects/inbox/inbox.module').then(m => m.InboxModule)
            //     // }
            //   ]
            // },
            // { path: '**', pathMatch: 'full', component: FallbackDomainComponent }
        ]
    }
];
var AppRouterModule = /** @class */ (function () {
    function AppRouterModule() {
    }
    return AppRouterModule;
}());
export { AppRouterModule };
export { ɵ0, ɵ1, ɵ2 };

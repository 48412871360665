import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';

// Modules
import { ButtonsModule } from '../buttons/buttons.module';
import { IconsModule } from '../icons/icons.module';

// Components
import { RequestInterfaceLoaderComponent } from './request-interface-loader/request-interface-loader.component';
import { RequestInterfaceConfirmationComponent } from './request-interface-confirmation/request-interface-confirmation.component';
import { RequestInterfaceInputComponent } from './request-interface-input/request-interface-input.component';
import { RequestInterfaceModalComponent } from './request-interface-modal/request-interface-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';

const allModules = [
  RequestInterfaceLoaderComponent,
  RequestInterfaceConfirmationComponent,
  RequestInterfaceInputComponent,
  RequestInterfaceModalComponent
];

@NgModule({
  declarations: allModules,
  exports: allModules,
  imports: [
    AngularCommonModule,
    ButtonsModule,
    SharedModule,
    IconsModule
  ],
  providers: [allModules]
})
export class RequestInterfaceModule { }

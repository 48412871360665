import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  Injector
} from '@angular/core';
import { InputBase } from '../input-base';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, ValidationErrors, Validators } from '@angular/forms';
import { fillRange } from 'src/app/shared/common/utils/array';
import { isLeapYear, isDateLessThanMinAge } from 'src/app/shared/common/utils/dates';
import { pad } from 'src/app/shared/common/utils/pad';
import { ConfigService } from 'src/app/shared/services/config/config.service';

const MONTHS_WITH_30_DAYS = [4, 6, 9, 11];
const fillRangePadded = (start: number, end: number) => fillRange(start, end).map((s) => pad(s, 2));

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss']
})

export class InputDateComponent extends InputBase implements OnInit {
  @Input() validateDateLowerCurrentDate: boolean;
  @Input() validateMinimumAge: number;
  @Input() preventFutureDates: boolean;
  @Input() allowFutureYears: boolean;
  @Output() isDateValid: EventEmitter<boolean>;

  private configService: ConfigService;
  public useUSFormat = false;
  years: number[];
  months: number[] | string[];
  days: number[] | string[];
  availableYears: number;

  form: FormGroup;
  dateForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private injector: Injector) {
    super();

    this.isDateValid = new EventEmitter();
    this.configService = this.injector.get(ConfigService);
    this.dateForm = formBuilder.group({
      day: new FormControl({ disabled: this.disabled }),
      month: new FormControl({ disabled: this.disabled }),
      year: new FormControl({ disabled: this.disabled })
    });
    this.setUseUSFormat();
  }

  ngOnInit() {
    if (!this.form) {
      console.warn(`InputDate: No 'form' provided`);
      return;
    }

    this.availableYears = 100;
    const addFutureYears = (this.allowFutureYears) ? 15 : 0;
    const date = new Date().getFullYear();

    this.months = fillRangePadded(1, 12);
    this.years = fillRange((date + addFutureYears), (date - this.availableYears), false);
    this.days = fillRangePadded(1, 31);

    if (this.allowFutureYears) {
      this.years.unshift(9999);
    }

    this.initDateForm();

    if (this.required) {
      const control = this.form.get(this.control);
      const { day, month, year } = this.dateForm.controls;

      this.setFormRequired([control, day, month, year]);
    }

    if (this.hasValue) {
      this.setDateValue();
    }

    this.isDateValid.emit(this.dateForm.valid);

    this.onMonthChange();
    this.onYearChange();
    this.onDateChange();
    this.onFormStatusChange(this.dateForm);
  }

  initDateForm(): void {
    const customValidators = [
      ... this.validateMinimumAge ? [this.minimumAge(this.validateMinimumAge)] : [],
      ... this.validateDateLowerCurrentDate ? [this.dateLowerCurrentDate()] : [],
      ... (this.required) ? [this.requiredFields()] : []
    ];
    const validators = customValidators.length > 0 ?
      { validators: Validators.compose(customValidators) } : {};
    const validator = this.required ? [Validators.required] : [];

    this.dateForm = this.formBuilder.group({
      day: new FormControl('', validator),
      month: new FormControl('', validator),
      year: new FormControl('', validator)
    }, validators);

    this.dateForm.updateValueAndValidity();
  }

  setDateValue() {
    let { value } = this.form.get(this.control);

    if (typeof value === 'string') {
      const [date] = value.split('T');
      value = new Date(date);
    }

    if (value instanceof Date) {
      const date = {
        day: pad(value.getDate(), 2),
        month: pad((value.getMonth() + 1), 2),
        year: value.getFullYear()
      };

      this.dateForm.patchValue(date);
    }

    this.dateForm.updateValueAndValidity();
  }

  onDateChange() {
    this.dateForm.valueChanges.subscribe((value) => {
      this.isDateValid.emit(this.dateForm.valid);

      if (this.dateForm.invalid) {
        return;
      }

      const { day, month, year } = value;
      this.form.get(this.control).setValue(new Date(Date.UTC(+year, +(month - 1), +day)));
    });
  }

  onYearChange() {
    this.dateForm.get('year').valueChanges.subscribe((value) => {
      if (this.isFebruary) {
        this.setFebruaryDays(value);
      }

      if (this.preventFutureDates) {
        const { month } = this.dateForm.value;
        this.months = fillRangePadded(1, 12);

        this.limitDate(value, month);
      }
    });
  }

  onMonthChange() {
    this.dateForm.get('month').valueChanges.subscribe((value) => {
      const month = parseInt(value, 10);
      const endMonth = MONTHS_WITH_30_DAYS.includes(month) ? 30 : 31;
      const { year } = this.dateForm.value;

      if (this.isFebruary) {
        return this.setFebruaryDays(year);
      }

      this.days = fillRangePadded(1, endMonth);

      if (this.preventFutureDates) {
        this.limitDate(year, month);
      }
    });
  }

  get isFebruary(): boolean {
    const { value } = this.dateForm.get('month');

    return parseInt(value, 10) === 2;
  }

  setFebruaryDays(year: number) {
    const endMonth = isLeapYear(year) ? 29 : 28;

    this.days = fillRangePadded(1, endMonth);
  }

  get hasDay(): boolean {
    const { value } = this.dateForm.get('day');
    return value;
  }

  get hasMonth(): boolean {
    const { value } = this.dateForm.get('month');
    return value;
  }

  get hasYear(): boolean {
    const { value } = this.dateForm.get('year');
    return value;
  }

  get formErrorMsg(): string {
    const errors = this.dateForm.errors || this.form.get(this.control).errors;

    return super.getErrorMessage(errors);
  }

  minimumAge(minimumAge: number): ValidatorFn {
    return (fg: FormGroup): ValidationErrors => {
      const { day, month, year } = fg.value;
      const birthDate = new Date(Date.UTC(+year, +(month - 1), +day));

      if (!year || !month || !day) {
        return { required: true };
      }

      return isDateLessThanMinAge(birthDate, minimumAge) ? { minimumAge: true } : null;
    };
  }

  dateLowerCurrentDate(): ValidatorFn {
    return (fg: FormGroup): ValidationErrors => {
      const { day, month, year } = fg.value;
      const date = new Date(Date.UTC(+year, +(month - 1), +day));

      if (!year || !month || !day) {
        return { required: true };
      }

      return date > new Date() ? { dateLowerCurrentDate: true } : null;
    };
  }

  private requiredFields(): ValidatorFn {
    return (fg: FormGroup): ValidationErrors => {
      const formValues = Object.values(fg.value);

      return formValues.length !== formValues.filter(Boolean).length ? { required: true } : null;
    };
  }

  private limitDate(year, month) {
    const date = new Date();
    const lastYear = date.getFullYear();

    if (parseInt(year, 0) === lastYear) {
      const lastMonth = date.getMonth() + 1;
      this.months = fillRangePadded(1, lastMonth);

      if (parseInt(month, 0) === lastMonth) {
        const lastDay = date.getDate();
        this.days = fillRangePadded(1, lastDay);
      }
    }
  }

  private setUseUSFormat(): void {
    this.useUSFormat = (this.configService.locale.get() === 'en_US');
  }

}

import { Component, Input } from '@angular/core';
import { PaymentListItem } from '../payment-list.model';

@Component({
  selector: 'app-payments-detail-item',
  templateUrl: './payments-detail-item.component.html',
  styleUrls: ['./payments-detail-item.component.scss']
})

export class PaymentsDetailItemComponent {
  @Input() payment: PaymentListItem;

  constructor() { }

  removeParentheses(date: string): string {
    return date ? date.replace(/[()]/g, '') : date;
  }
}

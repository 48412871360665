import { Injectable, Injector } from '@angular/core';
import { EnvService } from '../../common/services/env.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { getRes } from 'src/app/shared/common/utils/res';
import { InjectorService } from '../injector/injector.service';

@Injectable({ providedIn: 'root' })
export class CheckinHttpClient {
  private headers: { [key: string]: string; };
  private http: HttpClient;

  constructor() {
    const injector: Injector = InjectorService.get();
    const env: EnvService = injector.get(EnvService);
    this.http = injector.get(HttpClient);

    this.headers = {
      'X-API-Domain': env.domain || ''
    };
  }

  public setLocale(locale: string): void {
    this.headers['X-API-Locale'] = locale;
  }

  private setRes() {
    const res = getRes();

    this.headers['X-API-Res'] = `W/\"960-${btoa(res).replace(/=/gi, '')}\"`;
  }

  private getRequestOptions(withHeaders: boolean) {
    this.setRes();

    return {
      ...(withHeaders) && {
        headers: new HttpHeaders(this.headers)
      },
      withCredentials: true
    };
  }

  get(url: string, sendHeaders: boolean = true) {
    return this.http.get(url, this.getRequestOptions(sendHeaders));
  }

  patch(url: string, data: object, sendHeaders: boolean = true) {
    return this.http.patch(url, data, this.getRequestOptions(sendHeaders));
  }

  post(url: string, data: object, sendHeaders: boolean = true) {
    return this.http.post(url, data, this.getRequestOptions(sendHeaders));
  }

  put(url: string, data: object, sendHeaders: boolean = true) {
    return this.http.put(url, data, this.getRequestOptions(sendHeaders));
  }

  delete(url: string, sendHeaders: boolean = true) {
    return this.http.delete(url, this.getRequestOptions(sendHeaders));
  }
}

import * as tslib_1 from "tslib";
import { Project } from 'src/app/shared/classes/projects/project.class';
import { ERoutes } from 'src/app/shared/modules/payments/enums/routes.enum';
import { EModules } from '../../../../app.enum';
import { PaymentsConfig } from './payments-config.class';
var Payments = /** @class */ (function (_super) {
    tslib_1.__extends(Payments, _super);
    function Payments(params, company) {
        var _this = _super.call(this, params, company, EModules.PAYMENTS) || this;
        Object.assign(_this, {
            config: {}
        });
        return _this;
    }
    Payments.prototype.getNavbar = function () {
        return [{
                icon: 'avc-filled-house',
                label: {
                    slug: 'navbar-booking'
                },
                route: ERoutes.BOOKING
            },
            {
                icon: 'avc-filled-card',
                label: {
                    slug: 'payment.one',
                },
                route: '/'
            }];
    };
    Payments.prototype.update = function (data, update, cb) {
        try {
            if (!this.company.isEnabledModule(EModules.PAYMENTS)) {
                throw new Error('Payment module is not enable');
            }
            var extraConfig = data.extraConfig, _a = data.booking, locale = _a.locale, payments = _a.payments, companyName = data.company.name, secureUrl = data.secureUrl;
            var config = new PaymentsConfig(extraConfig, payments);
            this.updateWebconfig(data);
            this.metaTitle.setTitle("Secure Payments |\u00A0" + companyName);
            Object.assign(this, {
                config: config,
                expired: this.company.isExpiredModule(EModules.PAYMENTS),
                navbar: this.getNavbar(),
                navbarAditionalInfo: [],
                tabs: [],
                secureUrl: secureUrl
            });
            update({ locale: locale });
            this.setTranslations(cb);
        }
        catch (error) {
            this.errorHandler.handler(error);
        }
    };
    Payments.prototype.getModules = function () {
        return {
            CHANGE_CARD: false,
            RESPONSIVE: {
                HIDE_HEADER: false
            },
            SIDEBAR: {
                CUSTOM_STATUS: false
            }
        };
    };
    return Payments;
}(Project));
export { Payments };

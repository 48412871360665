import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as EXIF from 'exif-js';
import { getImgAspectRatio } from '../utils/images';
import * as Sentry from '@sentry/browser';

@Injectable()
export class ImagesService {
  constructor() {}

  public fileToBase64(file: File): Observable<string> {
    const reader: FileReader = new FileReader();

    return Observable.create((observer: any) => {
      try {
        reader.onload = () => {
          this.getCropedImage(file, `${reader.result}`, (cropedBase64: string) => {
            observer.next(cropedBase64);
            observer.complete();
          });
        };
        reader.readAsDataURL(file);
      } catch (err) {
        Sentry.captureException(err);
        Sentry.captureException(new Error(`${file} - ${typeof file} - ${JSON.stringify(file, null, 2)}`));
      }
    });
  }

  private getCropedImage(file: File, base64: string, cb: (img: string) => void) {
    this.createImage(
      base64,
      (img: any) => this.crop(
        img,
        cb
      )
    );
  }

  private getRotatedImage(file: File, base64: string, cb: (img: string) => void) {
    this.getOrientation(
      file,
      (orientation: number) => this.createImage(
        base64,
        (img: any) => this.rotate(
          img,
          orientation,
          cb
        )
      )
    );
  }

  private getOrientation(file: File, cb: (orientation: number) => void): void {
    const reader = new FileReader();

    reader.onload = () => {
      const exif = EXIF.readFromBinaryFile(reader.result);
      cb(exif.Orientation);
    };

    reader.readAsArrayBuffer(file);
  }

  private createImage(src: string, cb: (img: any) => void) {
    const img = new Image();

    img.onload = () => {
      cb(img);
    };

    img.src = src;
  }

  private rotate(img: any, orientation: number, cb: (base64: string) => void) {
    const canvas = document.createElement('canvas');
    const needsRotate = [5, 6, 7, 8].includes(orientation);
    const { height: imageHeight, width: imageWidth } = img;

    canvas.height = needsRotate ? imageWidth : imageHeight;
    canvas.width = needsRotate ? imageHeight : imageWidth;

    const ctx = canvas.getContext('2d');

    const map = {
      2: ctx.transform.bind(ctx, -1, 0, 0, 1, img.width, 0),
      3: ctx.transform.bind(ctx, -1, 0, 0, -1, img.width, img.height),
      4: ctx.transform.bind(ctx, 1, 0, 0, -1, 0, img.height),
      5: ctx.transform.bind(ctx, 0, 1, 1, 0, 0, 0),
      6: ctx.transform.bind(ctx, 0, 1, -1, 0, img.height, 0),
      7: ctx.transform.bind(ctx, 0, -1, -1, 0, img.height, img.width),
      8: ctx.transform.bind(ctx, 0, -1, 1, 0, 0, img.width)
    };

    const rotate = map[orientation] || ctx.transform.bind(ctx, 1, 0, 0, 1, 0, 0);
    rotate();

    ctx.drawImage(img, 0, 0, img.width, img.height);
  }

  private crop(img: any, cb: (base64: string) => void) {
    const canvas = document.createElement('canvas');
    const { height: imageHeight, width: imageWidth } = img;
    canvas.height = imageHeight;
    canvas.width = imageWidth;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, img.width, img.height);
    this.cropImage(canvas, cb);
  }

  private cropImage(canvas: HTMLCanvasElement, cb: (img: any) => void) {
    const { height, width } = getImgAspectRatio(canvas, null, 720);

    const c2 = document.createElement('canvas');
    c2.width = width;
    c2.height = height;
    const ctx = c2.getContext('2d');

    ctx.drawImage(canvas, 0, 0, width, height);
    cb(c2.toDataURL('image/jpeg'));
  }


}

import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Modules
import { CommonModule } from '../../common/common.module';

// Components
import { BookingDetailComponent } from './booking-detail/booking-detail.component';
import { CardDetailComponent } from './card-detail/card-detail.component';
import { PaymentsListComponent } from './booking-detail/payments-list/payments-list.component';
import { PaymentsListItemComponent } from './booking-detail/payments-list/payments-list-item/payments-list-item.component';
import {
  PaymentsListDetailComponent
} from './booking-detail/payments-list/payments-list-detail/payments-list-detail.component';
import {
  PaymentsListDepositComponent
} from './booking-detail/payments-list/payments-list-deposit/payments-list-deposit.component';
import { SidebarComponent } from './sidebar.component';
import { ButtonsModule } from 'src/app/shared/common/components/buttons/buttons.module';
import { SharedModule } from '../../shared.module';
import { BookingDetailDatesComponent } from './booking-detail/booking-detail-dates/booking-detail-dates.component';
import {
  BookingDetailDatesItemComponent
} from './booking-detail/booking-detail-dates/booking-detail-dates-item/booking-detail-dates-item.component';
import { PaymentsDetailComponent } from './booking-detail/payments-detail/payments-detail.component';
import { PaymentsDetailItemComponent } from './booking-detail/payments-detail/payments-detail-item/payments-detail-item.component';
import { BookingPriceComponent } from './booking-detail/booking-price/booking-price.component';
import { BookingCardComponent } from './booking-detail/booking-card/booking-card.component';
import { BookingStatusComponent } from './booking-detail/booking-status/booking-status.component';
import { ComponentsModule } from '../components/components.module';
import { PaymentsSlideoutComponent } from './booking-detail/payments-slideout/payments-slideout.component';

const allComponents = [
  BookingDetailComponent,
  CardDetailComponent,
  PaymentsListComponent,
  PaymentsListItemComponent,
  PaymentsListDetailComponent,
  PaymentsListDepositComponent,
  SidebarComponent,
  BookingDetailDatesComponent,
  BookingDetailDatesItemComponent,
  PaymentsDetailComponent,
  PaymentsDetailItemComponent,
  PaymentsSlideoutComponent,
  BookingPriceComponent,
  BookingCardComponent,
  BookingStatusComponent
];

@NgModule({
  declarations: allComponents,
  exports: allComponents,
  imports: [
    ComponentsModule,
    SharedModule,
    AngularCommonModule,
    ButtonsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SidebarModule { }

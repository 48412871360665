import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { wrapperAnimationParams } from './base-modal.animation';
import { BaseModal } from './base-modal.component.class';
var BaseModalComponent = /** @class */ (function () {
    function BaseModalComponent() {
    }
    BaseModalComponent.prototype.ngOnInit = function () {
        this.toggleBodyClass('add');
    };
    BaseModalComponent.prototype.ngOnDestroy = function () {
        this.toggleBodyClass('remove');
    };
    BaseModalComponent.prototype.toggleBodyClass = function (action) {
        document.body.classList[action]('checkin__prevent__scroll');
    };
    Object.defineProperty(BaseModalComponent.prototype, "getAnimationParams", {
        get: function () {
            return {
                value: true,
                params: tslib_1.__assign({}, wrapperAnimationParams[window.innerWidth < 780 ? 'responsive' : 'default'])
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseModalComponent.prototype, "baseModalAnimation", {
        get: function () { return true; },
        enumerable: true,
        configurable: true
    });
    return BaseModalComponent;
}());
export { BaseModalComponent };

import { Component } from '@angular/core';

@Component({
  selector: 'app-static-page-layout',
  templateUrl: './static-page-layout.component.html',
  styleUrls: ['./static-page-layout.component.scss']
})
export class StaticPageLayoutComponent {
  constructor() {}
}

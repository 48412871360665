import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ctaAnimations } from './cta.animations';

@Component({
  selector: 'app-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss'],
  animations: [ctaAnimations]
})

export class CtaComponent {
  @Input() backConfig: { [key: string]: any };
  @Input() loading: boolean;
  @Input() nextConfig: { [key: string]: any };
  @Input() showChangePayMethod = false;
  @Output() changeEvt: EventEmitter<boolean>;
  @Output() backEvt: EventEmitter<boolean>;
  @Output() nextEvt: EventEmitter<boolean>;

  public showBack: boolean;

  constructor() {
    Object.assign(this, {
      changeEvt: new EventEmitter<boolean>(),
      backEvt: new EventEmitter<boolean>(),
      nextEvt: new EventEmitter<boolean>()
    });
  }

  public back(): void {
    this.backEvt.emit(true);
  }

  public next(): void {
    this.nextEvt.emit(true);
  }

  public change(): void {
    this.changeEvt.emit(true);
  }
}

import * as _ from 'lodash';
import {
  Component,
  Injector,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';
import { creditCardAnimations } from './credit-card.animation';
import { CreditCard, ICreditCardOptions, ICreditCardParams } from './credit-card.class';
import { translations, ETranslations } from '../../assets/i18n/translations';
import { CreditCardFormService } from '../../../components/credit-card-form/credit-card-form.service';
import { ConfigService } from 'src/app/shared/services/config/config.service';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
  animations: creditCardAnimations
})
export class CreditCardComponent implements OnInit, OnChanges {
  @Input() value: ICreditCardParams;
  @Input() options: ICreditCardOptions;

  private creditCardFormService: CreditCardFormService;
  private configService: ConfigService;

  public creditCard: CreditCard;
  public labels: { [key: string]: string; };
  public worldPatternColors: { [key: string]: string; };
  public flipped: boolean;

  constructor(private injector: Injector) {
    this.creditCardFormService = this.injector.get(CreditCardFormService);
    this.configService = this.injector.get(ConfigService);

    this.worldPatternColors = {
      'american-express': '#1D5E3B',
      americanexpress: '#1D5E3B',
      default: '#a8a8a8',
      diners: '#637A88',
      jcb: '#B51721',
      mastercard: '#454545',
      maestro: '#094781',
      visa: '#3e397a'
    };
  }

  public ngOnInit(): void {
    const options: ICreditCardOptions = _.get(this, 'options', { lang: 'en_GB' });

    this.setLabels(options);
    this.setCard(options);
  }

  public ngOnChanges(changes: any): void {
    if (this.creditCard && changes.value) {
      this.creditCard.set(_.get(changes, 'value.currentValue', {}));

      this.notifyUpdatedCardNumber(changes);
    }
  }

  private notifyUpdatedCardNumber(changes: any): void {
    const { paymentConfig } = this.configService.booking.resume;

    if (changes.value.currentValue.pan &&
      paymentConfig.gatewayCode === 'datatrans') {
      this.configService.booking.resume.paymentConfig.mcp.enabled = (
        Boolean(paymentConfig.mcp.value)
      );

      this.creditCardFormService.updatedCardNumber();
    }
  }

  private setCard(options: ICreditCardOptions): void {
    this.creditCard = new CreditCard(_.get(this, 'value', {}), options);
  }

  private setLabels(options: ICreditCardOptions): void {
    const t = _.get(translations, options.lang, translations.en_GB);

    this.labels = {
      date: `${t[ETranslations.MONTH]}/${t[ETranslations.YEAR]}`
    };
  }

  toggle(card: string): void {
    if (this.creditCard.template === card) {
      return;
    }

    this.creditCard.icon = '';

    setTimeout(() => {
      this.creditCard.template = card as any;
    }, 100);
  }
}

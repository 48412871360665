import * as Color from 'color';
import * as _ from 'lodash';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { CreditCard } from 'src/app/shared/classes/credit-card/credit-card.class';
import { StylesService } from 'src/app/shared/common/components/buttons/styles.service';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { ICreditCardOptions, ICreditCardParams } from '../../credit-card/components/credit-card/credit-card.class';
import { FormGroup } from '@angular/forms';
import { combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { CreditCardFormService } from './credit-card-form.service';
import { EModules } from 'src/app/app.enum';

export enum EBillingAddressType {
  COMPANY = 'BASED_ON_COMPANY',
  OPTIONAL = 'OPTIONAL',
  REQUIRED = 'REQUIRED'
}

type CreditCardFormTemplate = 'preview' | 'billingForm' | 'cardForm';

@Component({
  selector: 'app-credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.scss']
})

export class CreditCardFormComponent implements OnInit, OnDestroy {
  @Input() options: {
    errors: {
      form: boolean;
    };
    prevent: {
      changeCard: boolean;
      changeTemplate: boolean;
    };
  };
  @Input() canChangePayMethod = false;
  @Output() layoutChangesEvt: EventEmitter<{ form: FormGroup; template: CreditCardFormTemplate; }>;

  public defaultCreditCard: ICreditCardParams;
  public creditCard: ICreditCardParams;
  public creditCardPreviewOptions: ICreditCardOptions;
  public form: FormGroup;
  public newPaymentsModule: boolean;

  private config: {
    [key: string]: any;
  };
  public layout: {
    [key: string]: any;
  };

  private configService: ConfigService;
  private creditCardFormService: CreditCardFormService;
  private subscribers: { [key: string]: Subscription; };

  constructor() {
    const injector: Injector = InjectorService.get();
    const configService: ConfigService = injector.get(ConfigService);

    Object.assign(this, {
      configService,
      creditCardPreviewOptions: {
        lang: configService.locale.get() as any
      },
      config: {},
      layout: {},
      creditCardFormService: injector.get(CreditCardFormService),
      layoutChangesEvt: new EventEmitter<{ form: FormGroup; template: CreditCardFormTemplate; }>(),
      subscribers: {
        creditCard: configService.booking.creditCard.onChanges().subscribe(() => {
          this.setDefaultCreditCard();
          this.layout.setTemplate('preview');
        })
      },
      template: 'cardForm',
      newPaymentsModule: configService.company.isEnabledModule(EModules.PAYMENTS_BREAKDOWN)
    });

    this.setCustomStyles(injector.get(StylesService));
    this.setDefaultCreditCard();
  }

  public ngOnInit(): void {
    this.build();
  }

  public ngOnDestroy(): void {
    Object.values(this.subscribers).forEach((s: Subscription) => (typeof s.unsubscribe === 'function') && s.unsubscribe());
  }

  private setDefaultCreditCard(): void {
    const { valid, owner, number, type, lastDigits, firstDigits }: CreditCard = this.configService.booking.creditCard;

    if (valid) {
      if (this.subscribers.form && typeof this.subscribers.form.unsubscribe === 'function') {
        this.subscribers.form.unsubscribe();
      }

      delete this.form;
    }

    const defaultCreditCard: ICreditCardParams = {
      address: _.get(owner, 'address'),
      valid,
      flipped: false,
      name: _.get(owner, 'name'),
      ...(lastDigits) && { lastDigits },
      ...(firstDigits) && { firstDigits },
      pan: number,
      date: number ? null : undefined,
      type: number ? type.toLocaleLowerCase() : 'default'
    } as ICreditCardParams;

    Object.assign(this, {
      creditCard: { ...defaultCreditCard },
      defaultCreditCard
    });
  }

  private setCustomStyles(service: StylesService): void {
    const color: Color = this.configService[this.configService.project].corporativeColor;
    const base = '.guest__area__credit__card__form';

    service.set({ [`${base}__cta`]: { color } });
  }

  public toggleCard(): void {
    const { flipped, ...rest } = this.creditCard;

    this.creditCard = { ...rest, flipped: !flipped };
  }

  private onCardChanges(value: { [key: string]: any; }): void {
    if (_.has(value, 'cvc')) {
      const name = value.name ? this.formatCardName(value.name) : undefined;
      const cvc = value.cvc ? this.formatCardCVC(value.cvc) : undefined;
      const pan = value.pan ? this.formatCardNumber(value.pan) : undefined;
      const date = value.month && value.year ? this.formatCardDate(value.month, value.year) : undefined;

      this.creditCard = {
        ...this.creditCard,
        cvv: cvc,
        date,
        pan,
        name,
        ...(pan) && { type: undefined }
      };
    }
  }

  private formatCardNumber(pan: string): string {
    let value = pan.replace(/\s+/g, '').replace(/[^0-9]/gi, '');

    if (value.length > 19) {
      value = value.substring(0, 19);
    }

    value = value.split('').reduce((r, n, i) => `${r}${((+i + 1) % 4 === 0) ? `${n} ` : `${n}`}`, '').trim();

    this.form.get('pan').setValue(value, { emitEvent: false });

    this.creditCardFormService.updatedCardNumber();

    return value;
  }

  private formatCardCVC(cvc: string): string {
    let value = cvc.replace(/\s+/g, '').replace(/[^0-9]/gi, '');

    if (value.length > 4) {
      value = value.substring(0, 4);
    }

    this.form.get('cvc').setValue(value, { emitEvent: false });

    return value;
  }

  private formatCardName(name: string): string {
    const value = `${name}`.toUpperCase();

    this.form.get('name').setValue(value, { emitEvent: false });

    return value;
  }

  private formatCardDate(month: string, year: string): string {
    return `${(`0${month}`).slice(-2)}/${year.slice(-2)}`;
  }

  private setFormByTemplate(template: CreditCardFormTemplate): void {
    // NOTE:: Sorry for this. This syntax requires an imported helper named '__spreadArray'
    // which does not exist in 'tslib'. Consider upgrading your version of 'tslib'.ts(2343)
    this.form = Object.entries(this.configService.booking.creditCard.filledForm.controls).reduce((form, [key, value]) => {
      if ({
        ...(template === 'cardForm') && {
          name: true,
          pan: true,
          cvc: true,
          month: true,
          year: true
        },
        ...(template === 'billingForm' || this.config.billingAddress.active) && {
          address: true,
          postalCode: true,
          phoneNumber: true,
          region: true,
          city: true,
          country: true,
          firstname: true,
          lastname: true,
          email: true
        }
      }[key]) {
        const hasSpecialCharacter = /[!#$%^&*(),.?":{}|<>_-]/.test(value.value) && !/[+@]/.test(value.value);
        if (!hasSpecialCharacter && value.value) {
          value.disable();
        }

        form.addControl(key, value);
      }

      return form;
    }, new FormGroup({}));

    this.subscribers.form = this.form.valueChanges
      .pipe(
        distinctUntilChanged((a: any, b: any) => JSON.stringify(a) === JSON.stringify(b)),
        filter((v) => Object.keys(_.omitBy(v, _.isNull)).length > 0)
      )
      .subscribe((v) => this.onCardChanges(v));
  }

  private build(): void {
    combineLatest([
      this.creditCardFormService.getBillingAddressConfig()
    ]).subscribe(([billingAddressConfig]) => {
      this.config = {
        billingAddress: {
          active: {
            [EBillingAddressType.REQUIRED]: true,
            [EBillingAddressType.OPTIONAL]: false,
            [EBillingAddressType.COMPANY]: billingAddressConfig.paymentsSCA
          }[billingAddressConfig.billingAddressType]
        }
      };

      const defaultTemplate = this.defaultCreditCard.valid ? 'preview' : 'cardForm';

      this.layout = {
        template: defaultTemplate,
        billingAddressForm: {
          active: this.config.billingAddress.active && defaultTemplate !== 'preview',
          build: (template: CreditCardFormTemplate) => {
            this.layout.billingAddressForm.active = this.config.billingAddress.active && template !== 'preview';
          }
        },
        preview: {
          active: ['preview', 'billingForm'].includes(defaultTemplate),
          ...(defaultTemplate === 'preview') && {
            billingAddressPreview: this.config.billingAddress.active,
            creditCardPreview: !this.config.billingAddress.active
          },
          build: (template: CreditCardFormTemplate) => {
            this.layout.preview.active = ['preview', 'billingForm'].includes(template);
            this.layout.preview.billingAddressPreview = this.config.billingAddress.active && template === 'preview';
            this.layout.preview.creditCardPreview = !this.config.billingAddress.active || template === 'billingForm';
          }
        },
        changeTemplateCTA: {
          setActive: () => {
            const validTemplate = this.layout.template === 'preview';
            this.layout.changeTemplateCTA.active = validTemplate;
          },
          active: true,
          label: 'change-address',
          fn: () => {
            this.layout.setTemplate('billingForm');
          },
          build: (template: CreditCardFormTemplate) => {
            this.layout.changeTemplateCTA.label = 'change-address';
          }
        },
        seePreviousCardCTA: {
          setActive: () => {
            const validTemplate = this.layout.template === 'cardForm';
            const validCard = _.get(this.defaultCreditCard, 'valid', false);

            this.layout.seePreviousCardCTA.active = validTemplate && validCard;
          },
          active: false,
          label: 'payment-see-previous-card',
          fn: () => this.layout.setTemplate('preview')
        },
        setTemplate: (template: CreditCardFormTemplate) => {
          this.layout.template = template;
          this.layout.preview.build(template);
          this.layout.billingAddressForm.build(template);
          this.layout.changeTemplateCTA.build(template);
          this.layout.changeTemplateCTA.setActive();
          this.layout.seePreviousCardCTA.setActive();

          switch (template) {
            case 'cardForm':
              this.creditCard = {} as ICreditCardParams;
              this.setFormByTemplate(template);

              break;
            case 'billingForm':
              this.setFormByTemplate(template);
              break;
            default:
              this.creditCard = { ...this.defaultCreditCard };
              this.subscribers.form.unsubscribe();
              delete this.form;
              break;
          }

          this.layoutChangesEvt.emit({
            form: this.form,
            template: this.layout.template
          });
        }
      };

      this.setFormByTemplate(this.layout.template);
      this.setDefaultCreditCard();

      this.layoutChangesEvt.emit({
        form: this.form,
        template: this.layout.template
      });
    });
  }

  public changeCard() {
    if (this.configService.checkin.secureUrl) {
      window.location.href = this.configService.checkin.secureUrl;

      return;
    }

    this.layout.setTemplate('cardForm');
  }
}

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { InputBase } from '../input-base';
var InputTextComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputTextComponent, _super);
    function InputTextComponent() {
        return _super.call(this) || this;
    }
    InputTextComponent.prototype.ngOnInit = function () {
        _super.prototype.initInputBase.call(this);
        if (this.pattern) {
            this.regex = new RegExp(this.pattern, 'g');
        }
    };
    InputTextComponent.prototype.onInput = function (value) {
        if (this.pattern) {
            // If last character is [´˜], we wait for a vowel or n
            var lastChar = value[value.length - 1];
            if (['´', '˜'].includes(lastChar)) {
                return;
            }
            value = value.replace(this.regex, '');
        }
        if (this.uppercase) {
            value = value.toUpperCase();
        }
        if (this.trimValue) {
            value = value.trim();
        }
        this.form.get(this.control).setValue(value);
    };
    return InputTextComponent;
}(InputBase));
export { InputTextComponent };

import * as Color from 'color';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { LocationStrategy, DOCUMENT } from '@angular/common';
import { IBaseGuard } from 'src/app/shared/guards/base/base.guard';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { ITabs, ITab } from 'src/app/checkin-app/modules/checkin/pages/guests/components/tabs/tabs.component';
import { INavbar, INav } from 'src/app/shared/models/navbar/navbar.model';
import { IProject, TProjectModules } from 'src/app/shared/models/projects/project.model';
import { Company } from 'src/app/shared/models/company/company.model';
import { Translations, ITranslations } from 'src/app/shared/utils/translations';
import { EModulesRoutes } from 'src/app/app.enum';
import { ErrorHandler } from 'src/app/shared/handlers/error.handler';
import { EModules } from 'src/app/app.enum';
import { IConfig } from 'src/app/app.interfaces';
import { Title } from '@angular/platform-browser';

export class Project implements IProject {
  private document: any;
  private locationStrategy: LocationStrategy;
  private router: Router;

  public error: boolean;
  public errorHandler: ErrorHandler;
  public loading: boolean;
  public guestType: string;
  public readonly colors: { [key: string]: Color; };
  public readonly completed?: boolean;
  public readonly company: Company;
  public readonly corporativeColor: Color;
  public readonly delegated?: boolean;
  public readonly expired: boolean;
  public readonly enabled: boolean;
  public readonly favicon: string;
  public readonly baseUrl?: string;
  public readonly baseCheckinUrl?: string;
  public readonly hash?: string;
  public readonly fallbackUrl?: string;
  public readonly routeFallback?: string;
  public readonly logo: string;
  public readonly tabs: ITabs;
  public readonly translations: { [key: string]: string };
  public readonly navbar: INavbar;
  public readonly navbarAditionalInfo: INavbar;
  public readonly metaTitle: Title;

  public readonly modules: {
    [key: string]: boolean | { [key: string]: boolean; };
  };

  constructor(params: IBaseGuard, company: Company, project: TProjectModules) {
    const injector: Injector = InjectorService.get();

    Object.assign(this, {
      document: injector.get(DOCUMENT),
      locationStrategy: injector.get(LocationStrategy),
      router: injector.get(Router),
      company,
      errorHandler: new ErrorHandler(),
      metaTitle: injector.get(Title)
    });

    this.init(params, project);
  }

  private init(params: IBaseGuard, project: TProjectModules): void {
    const {
      webConfig: {
        favicon,
        logo
      }
    } = params;

    let {
      webConfig: {
        colors = {}
      }
    } = params;

    colors = this.getColors(colors);

    const { corporative } = colors as any;

    Object.assign(this, {
      baseUrl: `${this.document.location.origin}${this.locationStrategy.getBaseHref()}`,
      baseCheckinUrl: `${this.document.location.origin}${this.locationStrategy.getBaseHref()}${EModulesRoutes[project.toUpperCase()]}`,
      colors,
      ...(corporative) && { corporativeColor: corporative },
      config: {},
      favicon,
      logo,
      loading: true,
      enabled: (project !== EModules.CHECKOUT) ? this.company.isEnabledModule(project) : true,
      modules: this.getModules()
    });
  }

  private getColors(colors): { [key: string]: Color; } {
    return Object.entries(colors).reduce((co, [key, value]) => {
      return {
        ...co,
        [key]: Color(value)
      };
    }, { corporative: Color('#dc3776') });
  }

  public setNotification(paths: any[], value: any): void {
    [this.tabs, this.navbar].forEach((x: any) => {
      const el: ITab | INav = x.find(({ route }) => paths.includes(route));

      if (el) {
        el.notifications = value;
      }
    });
  }

  public setTranslations(cb?: () => void): void {
    new Translations([
      'occupant.one',
      'companion.one',
      'confirm-fields-and-accepts'
    ]).get((translations: ITranslations) => {
      Object.assign(this, { translations });
      if (cb) {
        cb();
      }
    });
  }

  public goHome(): void {
    this.router.navigateByUrl(this.routeFallback);
  }

  public navigate(route: string): void {
    this.router.navigateByUrl(`${this.routeFallback}/${route}`);
  }

  public getModules(): Project['modules'] {
    return {
      CHANGE_CARD: true,
      RESPONSIVE: {
        HIDE_HEADER: true
      },
      SIDEBAR: {
        CUSTOM_STATUS: true
      }
    };
  }

  public updateWebconfig(params: IConfig) {
    const {
      webConfig: {
        favicon,
        logo
      }
    } = params;

    let {
      webConfig: {
        colors = {}
      }
    } = params;

    colors = this.getColors(colors);

    const { corporative } = colors as any;

    Object.assign(this, {
      colors,
      ...(corporative) && { corporativeColor: corporative },
      favicon,
      logo,
    });
  }
}

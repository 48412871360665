import { Component, ViewEncapsulation } from '@angular/core';
import { RequestInterfaceBase } from '../request-interface-base';
import { completedIconEnter } from '../../../animations/completed-icon-enter.animation';

@Component({
  selector: 'app-request-interface-loader',
  templateUrl: './request-interface-loader.component.html',
  styleUrls: ['./request-interface-loader.component.scss'],
  animations: [completedIconEnter],
  encapsulation: ViewEncapsulation.None
})

export class RequestInterfaceLoaderComponent extends RequestInterfaceBase {
  constructor() {
    super();
  }
}

import {
  Component,
  Injector,
  ViewEncapsulation
} from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { INavbar } from 'src/app/shared/models/navbar/navbar.model';
import { TProject } from 'src/app/shared/models/projects/project.model';

@Component({
  selector: 'app-navbar-aditional-info',
  templateUrl: './navbar-aditional-info.component.html',
  styleUrls: ['./navbar-aditional-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarAditionalInfoComponent {
  public items: INavbar;

  constructor() {
    const injector: Injector = InjectorService.get();
    const config: ConfigService = injector.get(ConfigService);
    const project: TProject = config[config.project];

    this.items = project.navbarAditionalInfo;
  }
}

import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ProjectConfig as IPaymentsConfig } from 'src/app/shared/classes/projects/project-config.class';
var PaymentsConfig = /** @class */ (function (_super) {
    tslib_1.__extends(PaymentsConfig, _super);
    function PaymentsConfig(params, payments) {
        var _this = _super.call(this, params) || this;
        _this.waysOfPayEnum = {
            CREDITCARD: [2, 3, 4, 20, 24, 25, 31, 38, 41, 43, 45, 52, 61],
            IDEAL: [56],
            SOFORT: [57],
            GOOGLE_PAY: [63],
            APPLE_PAY: [64],
            PAYPAL: [65],
            KLARNA: [66]
        };
        _this.setConfig(params, payments);
        return _this;
    }
    PaymentsConfig.prototype.setConfig = function (params, payments) {
        var selectedPayment = (_.get(payments, 'deposit', [])).concat((_.get(payments, 'paymentsDetail', []))).find(function (p) { return p.selected; });
        var status = {
            success: _.get(params, 'callback.result') === 'ok',
            error: _.get(params, 'callback.result') === 'ko',
            pending: !['ok', 'ko'].includes(_.get(params, 'callback.result', ''))
        };
        var waysOfPayOptions = _.get(params, 'waysOfPay', '');
        var waysOfPayConfig = Object.entries(this.waysOfPayEnum).reduce(function (config, _a) {
            var key = _a[0], value = _a[1];
            if (waysOfPayOptions.some(function (item) { return (value.includes(item)); })) {
                config.typesIncludes.push(key);
                config.numberOfMethods++;
            }
            return config;
        }, {
            typesIncludes: [],
            numberOfMethods: 0
        });
        this.config = {
            template: status.success ? 'success' : (selectedPayment ?
                (_.get({
                    IDEAL: 'purchase-ideal',
                    SOFORT: 'purchase-sofort'
                }, selectedPayment.method, 'purchase')) :
                'tokenize'),
            status: status,
            waysOfPay: waysOfPayConfig,
            selectedPayment: selectedPayment,
            sourceApp: 'payments'
        };
    };
    return PaymentsConfig;
}(IPaymentsConfig));
export { PaymentsConfig };

import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { IconsModule } from '../icons/icons.module';
import { PipesModule } from '../../pipes/pipes.module';
import { RequestInterfaceModule } from '../request-interface/request-interface.module';
import { TooltipModule } from '../tooltip/tooltip.module';

// Components
import { InputAutosuggestComponent } from './input-autosuggest/input-autosuggest.component';
import { InputAutoSuggestListComponent } from './input-autosuggest/input-autosuggest-list/input-autosuggest-list.component';
import { InputCheckComponent } from './input-check/input-check.component';
import { InputDateComponent } from './input-date/input-date.component';
import { InputDocumentTypeComponent } from './input-document-type/input-document-type.component';
import { InputLabelComponent } from './input-label/input-label.component';
import { InputPhoneComponent } from './input-phone/input-phone.component';
import { InputSelectComponent } from './input-select/input-select.component';
import { InputTextComponent } from './input-text/input-text.component';

// Services
import { SelectDataService } from '../../services/select-data.service';
import { StylesService } from '../buttons/styles.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { HOInputComponent } from './ho-input/ho-input.component';

const allModules = [
  // Components
  HOInputComponent,
  InputAutosuggestComponent,
  InputAutoSuggestListComponent,
  InputCheckComponent,
  InputDateComponent,
  InputDocumentTypeComponent,
  InputLabelComponent,
  InputPhoneComponent,
  InputSelectComponent,
  InputTextComponent
];

@NgModule({
  declarations: allModules,
  exports: allModules,
  imports: [
    AngularCommonModule,
    SharedModule,
    FormsModule,
    IconsModule,
    PipesModule,
    RequestInterfaceModule,
    ReactiveFormsModule,
    TooltipModule
  ],
  providers: [
    SelectDataService,
    StylesService
  ]
})
export class InputsModule { }

import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';

// Components
import { LoaderComponent } from './loader.component';

const allModules = [
  LoaderComponent
];

@NgModule({
  declarations: allModules,
  exports: allModules,
  imports: [
    AngularCommonModule
  ],
  providers: [allModules]
})
export class LoaderModule { }

import * as get from 'lodash.get';
import { IBaseGuard } from 'src/app/shared/guards/base/base.guard';
import { Title } from '@angular/platform-browser';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { EModules } from 'src/app/app.enum';
import { ICompanyData, IConfig } from 'src/app/app.interfaces';
import { ConfigService } from '../../services/config/config.service';

interface ICompany {
  copyright: string;
  email: string;
  id: number;
  languages: string[];
  locale: string;
  name: string;
  phone: string;
  privacyPolicy?: {
    legalAdvice?: string;
    termsAndConditions?: string;
  };
  title: string;
}

export class Company implements ICompany {
  public copyright: string;
  public email: string;
  public id: number;
  public languages: string[];
  public locale: string;
  public name: string;
  public modules: {
    [EModules.CHECKIN]: boolean;
    [EModules.INBOX]: boolean;
    [EModules.INTRANET]: boolean
    [EModules.INTRANET_FULL]: boolean
    [EModules.PAYMENTS]: boolean
    [EModules.PAYMENTS_BREAKDOWN]: boolean
  };
  public phone: string;
  public privacyPolicy?: {
    legalAdvice?: string;
    termsAndConditions?: string;
  };
  public title: string;

  constructor(params: IBaseGuard) {
    this.init(params);
  }

  private init(params: IBaseGuard): void {
    const {
      company: {
        email,
        locale,
        name,
        phone,
        privacyPolicy
      },
      languages,
      idCompany: id,
      webConfig: {
        title,
        footer: {
          copyright
        },
      }
    } = params;

    Object.assign(this, {
      copyright: this.getCopyright(copyright),
      email,
      id,
      languages,
      locale,
      name,
      modules: get(params, 'company.modules', { [EModules.PAYMENTS]: true }) as ICompanyData['modules'],
      phone,
      privacyPolicy,
      title
    });
  }

  public update(data: IConfig) {
    this.modules = get(data, 'company.modules', {
      [EModules.CHECKIN]: { enabled: false },
      [EModules.INBOX]: { enabled: false },
      [EModules.PAYMENTS]: { enabled: true, expired: false }
    }) as ICompanyData['modules'];

    const {
      company: {
        email,
        locale,
        name,
        phone,
        privacyPolicy
      },
      idCompany: id
    } = data;

    Object.assign(this, {
      email,
      id,
      locale,
      name,
      phone,
      privacyPolicy
    });
  }

  private getCopyright(copyright: string): string {
    const hasYear = /[0-9]{4}/.test(copyright);
    const hasSymbol = copyright.indexOf('©') >= 0;
​
    return `${hasSymbol ? '' : '© '}${copyright}${hasYear ? '' : ` ${(new Date().getFullYear())}`}`;
  }

  public isEnabledModule(module): boolean {
    return this.modules[module].enabled;
  }

  public isExpiredModule(module): boolean {
    return this.modules[module].expired;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { convertTimeToAmPm } from 'src/app/shared/common/utils/dates';
@Pipe({name: 'formatTime'})
export class FormatTimePipe implements PipeTransform {
  constructor(private config: ConfigService) { }

  transform(date: Date): string {
    const time = dayjs(date).format('HH:mm');

    return (this.config.locale.get() === 'en_US') ? convertTimeToAmPm(time) : time;
  }
}

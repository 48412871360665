import { TranslateService } from '@ngx-translate/core';
import { InjectorService } from '../services/injector/injector.service';

export interface ITranslations {
  [slug: string]: string;
}

export class Translations {
  private translations: string[];
  private service: TranslateService;

  constructor(translations: string[]) {
    Object.assign(this, {
      translations,
      service: InjectorService.get().get(TranslateService)
    });
  }

  public get(cb: (translations: ITranslations) => void): void {
    this.service.get(this.translations).subscribe((translations: ITranslations) => cb(translations));
  }
}

import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Subject, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { environment } from 'src/environments/environment';
import { EPaymentMethodTypes } from '../../payments/enums/payment-methods.enum';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/services/env.service";
import * as i2 from "../../../services/config/config.service";
import * as i3 from "../../../services/http/http-client.service";
var CreditCardFormService = /** @class */ (function () {
    function CreditCardFormService(env, config, http) {
        this.env = env;
        this.config = config;
        this.http = http;
        this.url = this.env.endpoint + "/v2/card/";
        this.onChangeCardNumberSubject = new Subject();
    }
    CreditCardFormService.prototype.getTokenizeParams = function (billingAddress) {
        var url = this.url + "payments/" + this.config[this.config.project].hash + "?" + new URLSearchParams(billingAddress).toString();
        return this.http.get(url);
    };
    CreditCardFormService.prototype.tokenize = function (params) {
        var _this = this;
        var billingAddress = params.billingAddress, cc = params.creditCard;
        return this.getTokenizeParams(billingAddress).pipe(switchMap(function (tokenizeParams) {
            var code = tokenizeParams.gatewayCode, keyId = tokenizeParams.gatewayKeyId, customer = tokenizeParams.customer, booking = tokenizeParams.booking;
            var creditCard = _.cloneDeep(cc);
            creditCard.number = creditCard.number.replace(/\s/g, '');
            var deviceId = window.MP_DEVICE_SESSION_ID;
            var body = tslib_1.__assign({ code: code,
                keyId: keyId,
                creditCard: creditCard,
                customer: customer,
                booking: booking, metadata: { app: 'INTRANET' } }, deviceId && {
                deviceId: deviceId
            });
            return _this.http.post(environment.payments.url + "/gateways/tokenize", body);
        }), switchMap(function (res) {
            _this.config.booking.creditCard.set({ id: res.id });
            return _this.http.post(_this.url + "payments/" + _this.config[_this.config.project].hash, { token: res.id, billingAddress: billingAddress }).pipe((map(function (r) { return r; })));
        }), catchError(function (error) {
            var errorMessage = JSON.parse(_.get(error, 'error').replace('Error tokenizing card: ', ''));
            return throwError(errorMessage.error.error.code || 'Server error');
        }));
    };
    CreditCardFormService.prototype.purchase = function (params) {
        if (params === void 0) { params = { paymentMethodType: EPaymentMethodTypes.CREDITCARD }; }
        var device = {
            navigator: {
                lang: this.config.locale.get(),
                javaEnabled: window.navigator.javaEnabled(),
                timezone: new Date().getTimezoneOffset()
            },
            screen: {
                colorDepth: window.screen.colorDepth,
                height: window.screen.height,
                width: window.screen.width
            }
        };
        var deviceId = window.MP_DEVICE_SESSION_ID;
        return this.http.post(this.url + "purchase/" + this.config[this.config.project].hash, tslib_1.__assign({ device: device, paymentMethodType: params.paymentMethodType }, (params.paymentMethodData) && {
            paymentMethodData: params.paymentMethodData
        }, params.mcp && params.mcp.enabled && {
            mcp: params.mcp
        }, (params.tokenId) && {
            tokenId: params.tokenId
        }, deviceId && {
            deviceId: deviceId
        })).pipe((map(function (res) { return res; }),
            catchError(function (error) { return throwError(error || 'Server error'); })));
    };
    CreditCardFormService.prototype.getBillingAddressConfig = function () {
        return this.http.get(this.url + "billing-address-config").pipe(map(function (data) { return data; }), catchError(function (error) { return throwError(error || 'Server error'); }));
    };
    CreditCardFormService.prototype.updateBillingAddress = function (params) {
        return this.http.post(this.url + "billing-address/" + this.config.booking.id, params).pipe((catchError(function (error) { return throwError(error || 'Server error'); })));
    };
    CreditCardFormService.prototype.onChangesCardNumber = function () {
        return this.onChangeCardNumberSubject.asObservable();
    };
    CreditCardFormService.prototype.updatedCardNumber = function () {
        this.onChangeCardNumberSubject.next(true);
    };
    CreditCardFormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreditCardFormService_Factory() { return new CreditCardFormService(i0.ɵɵinject(i1.EnvService), i0.ɵɵinject(i2.ConfigService), i0.ɵɵinject(i3.CheckinHttpClient)); }, token: CreditCardFormService, providedIn: "root" });
    return CreditCardFormService;
}());
export { CreditCardFormService };

import { Component, Input } from '@angular/core';
import * as Color from 'color';
import { ConfigService } from 'src/app/shared/services/config/config.service';

@Component({
  selector: 'app-page-index',
  templateUrl: './page-index.component.html',
  styleUrls: ['./page-index.component.scss']
})

export class PageIndexComponent {
  public corporativeColor: Color;
  @Input() pageIndex: string;
  @Input() backPage: string;

  constructor(
    private config: ConfigService,
  ) {
    this.corporativeColor = this.config[this.config.project].corporativeColor;
  }

  goHome(): void {
    this.config[this.config.project].goHome();
  }
}

import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { EventEmitter, Injector, OnDestroy, OnInit } from '@angular/core';
import { StylesService } from 'src/app/shared/common/components/buttons/styles.service';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { FormGroup } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { CreditCardFormService } from './credit-card-form.service';
import { EModules } from 'src/app/app.enum';
export var EBillingAddressType;
(function (EBillingAddressType) {
    EBillingAddressType["COMPANY"] = "BASED_ON_COMPANY";
    EBillingAddressType["OPTIONAL"] = "OPTIONAL";
    EBillingAddressType["REQUIRED"] = "REQUIRED";
})(EBillingAddressType || (EBillingAddressType = {}));
var CreditCardFormComponent = /** @class */ (function () {
    function CreditCardFormComponent() {
        var _this = this;
        this.canChangePayMethod = false;
        var injector = InjectorService.get();
        var configService = injector.get(ConfigService);
        Object.assign(this, {
            configService: configService,
            creditCardPreviewOptions: {
                lang: configService.locale.get()
            },
            config: {},
            layout: {},
            creditCardFormService: injector.get(CreditCardFormService),
            layoutChangesEvt: new EventEmitter(),
            subscribers: {
                creditCard: configService.booking.creditCard.onChanges().subscribe(function () {
                    _this.setDefaultCreditCard();
                    _this.layout.setTemplate('preview');
                })
            },
            template: 'cardForm',
            newPaymentsModule: configService.company.isEnabledModule(EModules.PAYMENTS_BREAKDOWN)
        });
        this.setCustomStyles(injector.get(StylesService));
        this.setDefaultCreditCard();
    }
    CreditCardFormComponent.prototype.ngOnInit = function () {
        this.build();
    };
    CreditCardFormComponent.prototype.ngOnDestroy = function () {
        Object.values(this.subscribers).forEach(function (s) { return (typeof s.unsubscribe === 'function') && s.unsubscribe(); });
    };
    CreditCardFormComponent.prototype.setDefaultCreditCard = function () {
        var _a = this.configService.booking.creditCard, valid = _a.valid, owner = _a.owner, number = _a.number, type = _a.type, lastDigits = _a.lastDigits, firstDigits = _a.firstDigits;
        if (valid) {
            if (this.subscribers.form && typeof this.subscribers.form.unsubscribe === 'function') {
                this.subscribers.form.unsubscribe();
            }
            delete this.form;
        }
        var defaultCreditCard = tslib_1.__assign({ address: _.get(owner, 'address'), valid: valid, flipped: false, name: _.get(owner, 'name') }, (lastDigits) && { lastDigits: lastDigits }, (firstDigits) && { firstDigits: firstDigits }, { pan: number, date: number ? null : undefined, type: number ? type.toLocaleLowerCase() : 'default' });
        Object.assign(this, {
            creditCard: tslib_1.__assign({}, defaultCreditCard),
            defaultCreditCard: defaultCreditCard
        });
    };
    CreditCardFormComponent.prototype.setCustomStyles = function (service) {
        var _a;
        var color = this.configService[this.configService.project].corporativeColor;
        var base = '.guest__area__credit__card__form';
        service.set((_a = {}, _a[base + "__cta"] = { color: color }, _a));
    };
    CreditCardFormComponent.prototype.toggleCard = function () {
        var _a = this.creditCard, flipped = _a.flipped, rest = tslib_1.__rest(_a, ["flipped"]);
        this.creditCard = tslib_1.__assign({}, rest, { flipped: !flipped });
    };
    CreditCardFormComponent.prototype.onCardChanges = function (value) {
        if (_.has(value, 'cvc')) {
            var name_1 = value.name ? this.formatCardName(value.name) : undefined;
            var cvc = value.cvc ? this.formatCardCVC(value.cvc) : undefined;
            var pan = value.pan ? this.formatCardNumber(value.pan) : undefined;
            var date = value.month && value.year ? this.formatCardDate(value.month, value.year) : undefined;
            this.creditCard = tslib_1.__assign({}, this.creditCard, { cvv: cvc, date: date,
                pan: pan,
                name: name_1 }, (pan) && { type: undefined });
        }
    };
    CreditCardFormComponent.prototype.formatCardNumber = function (pan) {
        var value = pan.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        if (value.length > 19) {
            value = value.substring(0, 19);
        }
        value = value.split('').reduce(function (r, n, i) { return "" + r + (((+i + 1) % 4 === 0) ? n + " " : "" + n); }, '').trim();
        this.form.get('pan').setValue(value, { emitEvent: false });
        this.creditCardFormService.updatedCardNumber();
        return value;
    };
    CreditCardFormComponent.prototype.formatCardCVC = function (cvc) {
        var value = cvc.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        if (value.length > 4) {
            value = value.substring(0, 4);
        }
        this.form.get('cvc').setValue(value, { emitEvent: false });
        return value;
    };
    CreditCardFormComponent.prototype.formatCardName = function (name) {
        var value = ("" + name).toUpperCase();
        this.form.get('name').setValue(value, { emitEvent: false });
        return value;
    };
    CreditCardFormComponent.prototype.formatCardDate = function (month, year) {
        return ("0" + month).slice(-2) + "/" + year.slice(-2);
    };
    CreditCardFormComponent.prototype.setFormByTemplate = function (template) {
        var _this = this;
        // NOTE:: Sorry for this. This syntax requires an imported helper named '__spreadArray'
        // which does not exist in 'tslib'. Consider upgrading your version of 'tslib'.ts(2343)
        this.form = Object.entries(this.configService.booking.creditCard.filledForm.controls).reduce(function (form, _a) {
            var key = _a[0], value = _a[1];
            if (tslib_1.__assign({}, (template === 'cardForm') && {
                name: true,
                pan: true,
                cvc: true,
                month: true,
                year: true
            }, (template === 'billingForm' || _this.config.billingAddress.active) && {
                address: true,
                postalCode: true,
                phoneNumber: true,
                region: true,
                city: true,
                country: true,
                firstname: true,
                lastname: true,
                email: true
            })[key]) {
                var hasSpecialCharacter = /[!#$%^&*(),.?":{}|<>_-]/.test(value.value) && !/[+@]/.test(value.value);
                if (!hasSpecialCharacter && value.value) {
                    value.disable();
                }
                form.addControl(key, value);
            }
            return form;
        }, new FormGroup({}));
        this.subscribers.form = this.form.valueChanges
            .pipe(distinctUntilChanged(function (a, b) { return JSON.stringify(a) === JSON.stringify(b); }), filter(function (v) { return Object.keys(_.omitBy(v, _.isNull)).length > 0; }))
            .subscribe(function (v) { return _this.onCardChanges(v); });
    };
    CreditCardFormComponent.prototype.build = function () {
        var _this = this;
        combineLatest([
            this.creditCardFormService.getBillingAddressConfig()
        ]).subscribe(function (_a) {
            var _b;
            var billingAddressConfig = _a[0];
            _this.config = {
                billingAddress: {
                    active: (_b = {},
                        _b[EBillingAddressType.REQUIRED] = true,
                        _b[EBillingAddressType.OPTIONAL] = false,
                        _b[EBillingAddressType.COMPANY] = billingAddressConfig.paymentsSCA,
                        _b)[billingAddressConfig.billingAddressType]
                }
            };
            var defaultTemplate = _this.defaultCreditCard.valid ? 'preview' : 'cardForm';
            _this.layout = {
                template: defaultTemplate,
                billingAddressForm: {
                    active: _this.config.billingAddress.active && defaultTemplate !== 'preview',
                    build: function (template) {
                        _this.layout.billingAddressForm.active = _this.config.billingAddress.active && template !== 'preview';
                    }
                },
                preview: tslib_1.__assign({ active: ['preview', 'billingForm'].includes(defaultTemplate) }, (defaultTemplate === 'preview') && {
                    billingAddressPreview: _this.config.billingAddress.active,
                    creditCardPreview: !_this.config.billingAddress.active
                }, { build: function (template) {
                        _this.layout.preview.active = ['preview', 'billingForm'].includes(template);
                        _this.layout.preview.billingAddressPreview = _this.config.billingAddress.active && template === 'preview';
                        _this.layout.preview.creditCardPreview = !_this.config.billingAddress.active || template === 'billingForm';
                    } }),
                changeTemplateCTA: {
                    setActive: function () {
                        var validTemplate = _this.layout.template === 'preview';
                        _this.layout.changeTemplateCTA.active = validTemplate;
                    },
                    active: true,
                    label: 'change-address',
                    fn: function () {
                        _this.layout.setTemplate('billingForm');
                    },
                    build: function (template) {
                        _this.layout.changeTemplateCTA.label = 'change-address';
                    }
                },
                seePreviousCardCTA: {
                    setActive: function () {
                        var validTemplate = _this.layout.template === 'cardForm';
                        var validCard = _.get(_this.defaultCreditCard, 'valid', false);
                        _this.layout.seePreviousCardCTA.active = validTemplate && validCard;
                    },
                    active: false,
                    label: 'payment-see-previous-card',
                    fn: function () { return _this.layout.setTemplate('preview'); }
                },
                setTemplate: function (template) {
                    _this.layout.template = template;
                    _this.layout.preview.build(template);
                    _this.layout.billingAddressForm.build(template);
                    _this.layout.changeTemplateCTA.build(template);
                    _this.layout.changeTemplateCTA.setActive();
                    _this.layout.seePreviousCardCTA.setActive();
                    switch (template) {
                        case 'cardForm':
                            _this.creditCard = {};
                            _this.setFormByTemplate(template);
                            break;
                        case 'billingForm':
                            _this.setFormByTemplate(template);
                            break;
                        default:
                            _this.creditCard = tslib_1.__assign({}, _this.defaultCreditCard);
                            _this.subscribers.form.unsubscribe();
                            delete _this.form;
                            break;
                    }
                    _this.layoutChangesEvt.emit({
                        form: _this.form,
                        template: _this.layout.template
                    });
                }
            };
            _this.setFormByTemplate(_this.layout.template);
            _this.setDefaultCreditCard();
            _this.layoutChangesEvt.emit({
                form: _this.form,
                template: _this.layout.template
            });
        });
    };
    CreditCardFormComponent.prototype.changeCard = function () {
        if (this.configService.checkin.secureUrl) {
            window.location.href = this.configService.checkin.secureUrl;
            return;
        }
        this.layout.setTemplate('cardForm');
    };
    return CreditCardFormComponent;
}());
export { CreditCardFormComponent };

import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
var Navbar = /** @class */ (function () {
    function Navbar() {
        Object.assign(this, {
            showSubject: new Subject()
        });
    }
    Object.defineProperty(Navbar.prototype, "show", {
        get: function () {
            return this._show;
        },
        set: function (value) {
            this._show = value;
            this.showSubject.next(this._show);
        },
        enumerable: true,
        configurable: true
    });
    Navbar.prototype.onShowChanges = function () {
        return this.showSubject.asObservable().pipe(distinctUntilChanged());
    };
    return Navbar;
}());
export { Navbar };

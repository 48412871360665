import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { baseModalAnimation, wrapperAnimationParams } from './base-modal.animation';
import { BaseModal } from './base-modal.component.class';

@Component({
  selector: 'app-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
  animations: baseModalAnimation
})

export class BaseModalComponent implements OnInit, OnDestroy {
  @Input() config: BaseModal;

  constructor() { }

  public ngOnInit(): void {
    this.toggleBodyClass('add');
  }

  public ngOnDestroy(): void {
    this.toggleBodyClass('remove');
  }

  private toggleBodyClass(action: 'add' | 'remove'): void {
    document.body.classList[action]('checkin__prevent__scroll');
  }

  public get getAnimationParams(): { [key: string]: any; } {
    return {
      value: true,
      params: { ...wrapperAnimationParams[window.innerWidth < 780 ? 'responsive' : 'default'] }
    };
  }

  @HostBinding('@baseModalAnimation') get baseModalAnimation(): boolean { return true; }
}

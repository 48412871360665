import * as tslib_1 from "tslib";
import { Project } from 'src/app/shared/classes/projects/project.class';
import { InboxConfig } from './inbox-config.class';
import { EModules } from '../../../../app.enum';
import { ERoutes } from '../../../enums/routes.enum';
import { ChatListMessages } from '../../../../checkin-app/modules/inbox/pages/chat/classes/chat-list-messages.class';
var Inbox = /** @class */ (function (_super) {
    tslib_1.__extends(Inbox, _super);
    function Inbox(params, company) {
        var _this = _super.call(this, params, company, EModules.INBOX) || this;
        Object.assign(_this, {
            messages: new ChatListMessages(),
            config: {}
        });
        return _this;
    }
    Inbox.prototype.getNavbar = function () {
        var routes = [{
                icon: 'avc-filled-house',
                label: {
                    slug: 'navbar-booking'
                },
                route: ERoutes.BOOKING
            },
            {
                icon: 'avc-chat',
                label: {
                    slug: 'inbox'
                },
                route: '/'
            },
            {
                icon: 'avc-icon-hmb',
                action: 'showAditionalInfo',
                label: {
                    slug: 'menu'
                }
            }];
        return routes;
    };
    Inbox.prototype.getNavbarAditionalInfo = function () {
        var routes = [
            // {
            //   icon: 'avc-icon-booking',
            //   label: {
            //     slug: 'navbar-aditional-info-booking'
            //   },
            //   action: 'navigateToIntranet'
            // },
            {
                icon: 'avc-icon-language',
                component: 'INPUT_LANGUAGE'
            }
            // , {
            //   icon: 'avc-icon-about',
            //   label: {
            //     slug: 'navbar-aditional-info-company',
            //     params: { company: this.company.name }
            //   }
            // }
        ];
        return routes;
    };
    Inbox.prototype.update = function (data, update, cb) {
        var extraConfig = data.extraConfig, locale = data.booking.locale, companyName = data.company.name;
        var config = new InboxConfig(extraConfig);
        this.metaTitle.setTitle("Inbox |\u00A0" + companyName);
        Object.assign(this, {
            config: config,
            expired: this.company.isExpiredModule(EModules.INBOX),
            navbar: this.getNavbar(),
            navbarAditionalInfo: this.getNavbarAditionalInfo(),
            tabs: []
        });
        update({ locale: locale });
        // Set translations must be after LocaleLoader
        this.setTranslations(cb);
    };
    return Inbox;
}(Project));
export { Inbox };

import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { OccupantIdentifyDocuments, OccupantIdentifyDocumentsType } from '../occupant-identify.interfaces';
import { OccupantForm } from '../../occupant-detail.interface';

const DOCUMENT_POSITION_MAP = {
  obverse: 'frontDocument',
  reverse: 'backDocument',
  selfie: 'selfie'
};

@Injectable()
export class OccupantIdentifyService {
  private baseUrl: string;
  private documentsValue: OccupantIdentifyDocuments;
  private mrzValues: OccupantForm;

  constructor(
    private http: CheckinHttpClient,
    private env: EnvService
  ) {
    this.baseUrl = `${this.env.endpoint}/v2/scan/`;
    this.documentsValue = {};
    this.mrz = [];
  }

  private sanitizeMrzValues(values: OccupantForm): OccupantForm {
    if (!Array.isArray(values) || values.length === 0) {
      return [];
    }

    return [...values].map((mrz) => {
      switch (mrz.id) {
        case 'clientGender':
          return {
            ...mrz,
            value: ['M', 'F'].includes(mrz.value) ? mrz.value : ''
          };
        default:
          return mrz;
      }
    });
  }

  set mrz(values: OccupantForm) {
    this.mrzValues = this.sanitizeMrzValues(values);
  }

  get mrz(): OccupantForm {
    return this.mrzValues;
  }

  public setDocument(document: OccupantIdentifyDocumentsType, value: string): void {
    this.documentsValue[DOCUMENT_POSITION_MAP[document]] = value;
  }

  public getDocument(document: OccupantIdentifyDocumentsType): string {
    return this.documentsValue[DOCUMENT_POSITION_MAP[document]];
  }

  public clearDocument(document: OccupantIdentifyDocumentsType): void {
    delete this.documentsValue[DOCUMENT_POSITION_MAP[document]];
  }

  get documents(): OccupantIdentifyDocuments {
    return this.documentsValue;
  }

  set documents(documents: OccupantIdentifyDocuments)  {
    this.documentsValue = documents;
  }

  scanDocument(image: string) {
    return this.http.post(this.baseUrl, { image }).pipe(
      map((res: any) => res),
      catchError((error: any) => {
        throw new Error(error || 'Server error');
      })
    );
  }
}

import { getImgAspectRatio } from '../../../common/utils/images';

type TLogoProperty = string | number;

interface ILogoSize {
  height: TLogoProperty;
  width: TLogoProperty;
}

interface ILogoRanges {
  break: number;
  default: ILogoSize;
  square: ILogoSize;
  vertical: ILogoSize;
}

export class LogoManager {
  private _url: string; // tslint:disable-line:variable-name
  private _size: ILogoSize; // tslint:disable-line:variable-name
  private _ranges: ILogoRanges[]; // tslint:disable-line:variable-name

  constructor(url: string, ranges: ILogoRanges[] = []) {
    this._url = url;
    this._ranges = [
      { break: 320, default: { height: 'auto', width: 200}, square: { height: 45, width: 'auto'}, vertical: { height: 110, width: 'auto'} },
      { break: 576, default: { height: 'auto', width: 200}, square: { height: 50, width: 'auto'}, vertical: { height: 110, width: 'auto'} },
      { break: 992, default: { height: 'auto', width: 200}, square: { height: 60, width: 'auto'}, vertical: { height: 110, width: 'auto'} },
      ...ranges
    ];

    this._size = {} as ILogoSize;

    this.getImageAspectRatio();
  }

  public get url(): string {
    return this._url;
  }

  public get height(): TLogoProperty {
    return this._size.height;
  }

  public get width(): TLogoProperty {
    return this._size.width;
  }

  private set size(size: ILogoSize) {
    this._size = {
      height: (typeof size.height === 'string') ? size.height : `${size.height}px`,
      width: (typeof size.width === 'string') ? size.width : `${size.width}px`
    };
  }

  public getImageAspectRatio(): void {
    const img = new Image();

    img.onload = () => {
      const { type, dimensions } = this._getNewSizeImage({ height: img.height, width: img.width });

      const newSize: () => ILogoSize = () => {
        if (type !== 'default') {
          return {
            height: (img.height > dimensions.height) ? dimensions.height : img.height,
            width: dimensions.width
          };
        }

        return {
          height: dimensions.height,
          width: (img.width > dimensions.width) ? dimensions.width : img.width,
        };
      };

      this.size = newSize();
    };

    img.src = this.url;
  }

  private _getNewSizeImage(size: ILogoSize): any {
    const { height, width } = size;
    const rest = (+height - +width);

    const type = rest >= 0 && rest <= 10 ? 'square' : rest > 10 ? 'vertical' : 'default' ;

    // TODO:: Use config.screen
    const range = this._ranges.find((r) => (window.innerWidth <= r.break)) || this._ranges[this._ranges.length - 1];

    return {
      type,
      dimensions: range[type]
    };
  }
}

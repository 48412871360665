import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { InputBase } from '../input-base';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SelectDataService } from 'src/app/shared/common/services/select-data.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
var InputPhoneComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputPhoneComponent, _super);
    function InputPhoneComponent(formBuilder) {
        var _this = _super.call(this) || this;
        _this.selectDataService = InjectorService.get().get(SelectDataService);
        _this.phoneCodes = [];
        _this.phoneForm = formBuilder.group({
            prefix: new FormControl('', [Validators.maxLength(4)]),
            phone: new FormControl('')
        });
        return _this;
    }
    InputPhoneComponent.prototype.ngOnInit = function () {
        this.setPhoneCodes();
        if (this.required) {
            var control = this.form.get(this.control);
            var _a = this.phoneForm.controls, prefix = _a.prefix, phone = _a.phone;
            this.setFormRequired([control, prefix, phone]);
        }
        if (this.hasValue) {
            this.setPhoneValue();
        }
        this.validatePhoneChars();
        this.onPhoneChange();
        this.onFormStatusChange(this.phoneForm);
    };
    InputPhoneComponent.prototype.setPhoneCodes = function () {
        var _this = this;
        this.selectDataService.get('prefixes').subscribe(function (res) { return _this.phoneCodes = res; });
    };
    InputPhoneComponent.prototype.onPrefixChange = function (evt) {
        this.phoneForm.get('prefix').setValue(evt);
    };
    InputPhoneComponent.prototype.onPhoneChange = function () {
        var _this = this;
        this.phoneForm.valueChanges.subscribe(function (value) {
            if (_this.phoneForm.invalid) {
                return;
            }
            var prefix = value.prefix, phone = value.phone;
            _this.form.get(_this.control).setValue("+" + prefix + " " + phone);
        });
    };
    InputPhoneComponent.prototype.setPhoneValue = function () {
        var value = this.form.get(this.control).value;
        var _a = this.parsePhoneValue(value), prefix = _a[0], phone = _a[1];
        this.phoneForm.patchValue({ prefix: prefix, phone: phone });
        this.selectedPrefix = prefix;
        if (prefix && phone) {
            this.phoneForm.get('prefix').disable();
            this.phoneForm.get('phone').disable();
        }
    };
    InputPhoneComponent.prototype.validatePhoneChars = function () {
        var phone = this.phoneForm.controls.phone;
        phone.valueChanges.subscribe(function (value) {
            phone.setValue(value.replace(/[^0-9]/g, ''), { emitEvent: false });
        });
    };
    InputPhoneComponent.prototype.parsePhoneValue = function (value) {
        if (value.startsWith('+')) {
            var _a = value.replace(/\+/, '').split(' '), prefix = _a[0], phoneArray = _a.slice(1);
            var phone = phoneArray.join('').replace(/\s/g, '');
            return [prefix, phone];
        }
        return [undefined, value.replace(/\s/g, '')];
    };
    Object.defineProperty(InputPhoneComponent.prototype, "hasPrefix", {
        get: function () {
            var value = this.phoneForm.get('prefix').value;
            return value;
        },
        enumerable: true,
        configurable: true
    });
    return InputPhoneComponent;
}(InputBase));
export { InputPhoneComponent };

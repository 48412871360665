import { IConfig } from 'src/app/app.interfaces';
import { IProjectConfig, TProjectConfig } from 'src/app/shared/models/projects/project-config.model';

export class ProjectConfig implements IProjectConfig {
  public readonly isCreditCardActivated: boolean;
  public readonly isCreditCardRequired: boolean;
  public readonly showCreditCardComponent: boolean;

  constructor(params: IConfig['extraConfig'], config?: TProjectConfig) {
    const {
      creditCardActivated: isCreditCardActivated,
      creditCardRequired: isCreditCardRequired,
      showCreditCardComponent
    } = params;

    const configBase = {
      isCreditCardActivated,
      isCreditCardRequired,
      showCreditCardComponent,
      ...config
    };

    Object.assign(this, configBase);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { SharedModule } from 'src/app/shared/shared.module';

// Components
import { ButtonPrimaryComponent } from './button-primary/button-primary.component';
import { ButtonSecondaryComponent } from './button-secondary/button-secondary.component';
import { ButtonTertiaryComponent } from './button-tertiary/button-tertiary.component';
import { ButtonRoundComponent } from './button-round/button-round.component';

// Services
import { StylesService } from './styles.service';

const allModules = [
  ButtonPrimaryComponent,
  ButtonSecondaryComponent,
  ButtonTertiaryComponent,
  ButtonRoundComponent
];

@NgModule({
  declarations: allModules,
  exports: allModules,
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [
    StylesService
  ]
})
export class ButtonsModule { }

import * as tslib_1 from "tslib";
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { map, catchError } from 'rxjs/operators';
import { ConfigService } from 'src/app/shared/services/config/config.service';
var TravelerDocumentsService = /** @class */ (function () {
    function TravelerDocumentsService(config, http, env) {
        this.config = config;
        this.http = http;
        this.env = env;
        this._hash = this.config.checkin.hash;
        this._baseUrl = this.env.endpoint + "/v2/occupant/sign/" + this._hash;
    }
    TravelerDocumentsService.prototype.getDocumentsToSign = function (occupantId) {
        var _this = this;
        var url = this._baseUrl + "/" + occupantId;
        return this.http.get(url).pipe(map(function (res) {
            return res.documents.filter(function (id) { return _this._canDocumentBeSigned(id); }).map(function (id) { return ({
                id: id,
                url: _this.getDocumentPreview(occupantId, id)
            }); });
        }), catchError(function (error) {
            throw new Error(error || 'Server error');
        }));
    };
    TravelerDocumentsService.prototype._canDocumentBeSigned = function (id) {
        var _a = this.config.checkin.config.documentsId, checkinReport = _a.checkinReport, rentalContract = _a.rentalContract, otherTemplates = tslib_1.__rest(_a, ["checkinReport", "rentalContract"]);
        var idsMap = {
            checkinReport: checkinReport,
            rentalContract: rentalContract
        };
        return Boolean(idsMap[id]) || Boolean(otherTemplates[id]);
    };
    TravelerDocumentsService.prototype.getDocumentPreview = function (occupantId, templateId) {
        var queryParams = Object.entries({
            domain: this.env.defaultDomain,
            locale: this.config.locale.get()
        }).map(function (_a) {
            var key = _a[0], value = _a[1];
            return key + "=" + value;
        }).join('&');
        return this._baseUrl + "/" + occupantId + "/preview/" + templateId + "?" + queryParams;
    };
    return TravelerDocumentsService;
}());
export { TravelerDocumentsService };

import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CURRENCIES_MAP, SIZE_MAP } from './currencies.static';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { Subscription } from 'rxjs';

interface CurrenciesType extends Array<{ code: string }> {}

@Component({
  selector: 'app-input-currency',
  templateUrl: './input-currency.component.html',
  styleUrls: ['./input-currency.component.scss']
})

export class InputCurrencyComponent implements AfterViewInit, OnDestroy {
  private subscriptions: Subscription[];
  private checkinCurrency: string;

  public form: FormGroup;
  public layout: { [key: string]: boolean; };
  public isResponsive: boolean;
  public options: CurrenciesType;
  public selectedSize: string;
  public showSelector: boolean;

  constructor(
    private config: ConfigService,
    fb: FormBuilder
  ) {
    const {
      currency
    } = this.config;

    this.checkinCurrency = currency.get();
    this.options = this.getParsedCurrencies();
    this.showSelector = this.options.length > 0;

    const defaultCurrency = currency.default();
    const currencyText = CURRENCIES_MAP[this.checkinCurrency] ? this.checkinCurrency : defaultCurrency;
    const companyHasSelectedCurrency = this.options.find(({ code }) => code === this.checkinCurrency);
    const formValue = Boolean(companyHasSelectedCurrency) ? this.checkinCurrency : defaultCurrency;

    this.setSelectedSize(currencyText);

    this.form = fb.group({
      currency: new FormControl(formValue)
    });

    this.subscriptions = [];

    this.setLayoutConfig(this.config.screen.isLG);
    this.setResponsive();
  }

  public ngAfterViewInit(): void {
    this.onCurrencyChange();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((e: Subscription) => e.unsubscribe());
  }

  private setSelectedSize(language: string): void {
    this.selectedSize = SIZE_MAP[language] || '135px';
  }

  private onCurrencyChange(): void {
    this.form.get('currency').valueChanges.subscribe((currency) => {
      this.config.currency.set(currency, true);
    });
  }

  private getParsedCurrencies(): CurrenciesType {
    return Object.entries(CURRENCIES_MAP).map((currency) => {
      const [ code ] = currency;
      return { code };
    }).filter(Boolean);
  }

  private setResponsive(): void {
    const s: Subscription = this.config.screen.onIsLGChanges().subscribe((i: boolean) => this.setLayoutConfig(i));

    this.subscriptions.push(s);
  }

  private setLayoutConfig(isResponsive: boolean): void {
    this.layout = {
      responsive: isResponsive && ((this.config[this.config.project].modules.RESPONSIVE as any).HIDE_HEADER)
    };
  }
}

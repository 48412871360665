import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

const getQueryParams = (): any => {
  const search = window.location.search || '';
  const pairs = search.substring(1).split('&');
  const result = {};

  pairs.forEach((pair) => {
    if (pair.trim() === '') {
      return;
    }

    const keyVal = pair.split('=');
    result[keyVal[0]] = decodeURIComponent(keyVal[1]);
  });

  return result;
};

@Injectable()
export class EnvService {
  env: any;
  private queryParams: any;

  constructor() {
    this.env = environment;
    this.queryParams = getQueryParams();
  }

  get isProduction() {
    return this.env.production;
  }

  get isDevelopment() {
    return this.env.development;
  }

  get endpoint() {
    return this.env.endpoint;
  }

  get defaultDomain(): string {
    return this.env.defaultDomain;
  }

  get domain() {
    const location = window.location.href;
    const match = location.match(/^https?\:\/\/(([a-z0-9\-]+)\.([a-z0-9\-\.]+)(\/[a-z]{2})?)\//);
    let domain = this.queryParams.return_url;

    if (!domain) {
      if (match && match[1]) {
        const mainDomain = match[1];
        if (!/\.(int|pre|local)$/.test(mainDomain)) {
          domain = (mainDomain === 'secure.avantio.com') ? mainDomain : mainDomain.replace('secure.', '');
        }
      }

      if (!this.isProduction) {
        domain = this.env.defaultDomain;
      }
    }

    return domain;
  }

  get wsEndpoint() {
    return this.env.wsEndpoint;
  }
}

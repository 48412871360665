import { Component, Injector } from '@angular/core';
import { MainService } from 'src/app/shared/main/main.service';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { ERoutes } from 'src/app/shared/enums/routes.enum';
import { IProject } from 'src/app/shared/models/projects/project.model';

@Component({
  selector: 'app-booking-card',
  templateUrl: './booking-card.component.html',
  styleUrls: ['./booking-card.component.scss']
})

export class BookingCardComponent {
  private mainService: MainService;
  private configService: ConfigService;
  private project: IProject;

  constructor(private injector: Injector) {
    Object.assign(this, {
      configService: this.injector.get(ConfigService),
      mainService: this.injector.get(MainService)
    });

    this.project = this.configService[this.configService.project];
  }

  public get creditCardNumber(): string {
    return this.configService.booking.creditCard.number || '—— —— —— ——';
  }

  public get ctaText(): string {
    const { creditCard } = this.configService.booking;

    if (creditCard.valid) {
      return creditCard.updatable ? 'change' : 'see';
    }

    return 'add';
  }

  public goToCard(): void {
    if (this.configService.checkin.secureUrl) {
      window.location.href = this.configService.checkin.secureUrl;

      return;
    }

    if (this.configService.screen.isLG) {
      this.mainService.toogleCardResponsive();
      return;
    }

    this.project.navigate(ERoutes.CARD);
  }
}

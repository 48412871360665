import { Injectable } from '@angular/core';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { ConfigService } from 'src/app/shared/services/config/config.service';

@Injectable()
export class UtilsService {
  private baseUrl: string;

  constructor(
    private config: ConfigService,
    private env: EnvService
  ) {
    this.baseUrl = `${this.env.endpoint}/v2/files/${this.config.checkin.hash}`;
  }

  getDocumentPublicLink(templateId: string): string {
    return `${this.baseUrl}/${templateId}?domain=${this.env.defaultDomain}`;
  }

  public obfuscateEmail(email: string): string {
    const [account, domain] = `${email}`.split('@');
    const shortedAccount = account.slice(0, Math.min(4, account.length));
    const shortedDomain = domain.slice(-(Math.min(8, domain.length)));

    return `${shortedAccount}****${shortedDomain}`;
  }
}

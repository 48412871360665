import { PipeTransform, Injector } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import * as dayjs from 'dayjs';
import { TranslateService } from '@ngx-translate/core';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
var FormatDatePipe = /** @class */ (function () {
    function FormatDatePipe() {
        var injector = InjectorService.get();
        Object.assign(this, {
            config: injector.get(ConfigService),
            translateService: injector.get(TranslateService)
        });
    }
    FormatDatePipe.prototype.transform = function (date, options, locale) {
        var _a;
        if (options === void 0) { options = {}; }
        locale = locale || this.config.locale.get() || 'en_GB';
        var lang = locale.replace('_', '-');
        if (typeof date === 'string' && date.includes('Z')) {
            date = new Date(date);
        }
        if (options.fromNow) {
            var current = dayjs(date).format('YYYY-MM-DD');
            var translations = (_a = {},
                _a[dayjs().format('YYYY-MM-DD')] = 'today',
                _a[dayjs().subtract(24, 'h').format('YYYY-MM-DD')] = 'yesterday',
                _a);
            if (translations[current]) {
                return this.translateService.instant(translations[current]);
            }
        }
        return (new Intl.DateTimeFormat(lang, options).format(date));
    };
    return FormatDatePipe;
}());
export { FormatDatePipe };

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RequestInterfaceBase } from '../request-interface-base';
var RequestInterfaceInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RequestInterfaceInputComponent, _super);
    function RequestInterfaceInputComponent(translateService) {
        var _this = _super.call(this) || this;
        _this.translateService = translateService;
        return _this;
    }
    RequestInterfaceInputComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(RequestInterfaceInputComponent.prototype, "tooltipSaveError", {
        get: function () {
            return this.translateService.instant('tooltip-save-error');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RequestInterfaceInputComponent.prototype, "tooltipSaveSuccess", {
        get: function () {
            return this.translateService.instant('tooltip-save-success');
        },
        enumerable: true,
        configurable: true
    });
    return RequestInterfaceInputComponent;
}(RequestInterfaceBase));
export { RequestInterfaceInputComponent };

import { Component } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { EBookingStatus } from 'src/app/shared/enums/booking-status.enum';
import { StylesService } from 'src/app/shared/common/components/buttons/styles.service';

@Component({
  selector: 'app-status-box',
  templateUrl: './status-box.component.html',
  styleUrls: ['./status-box.component.scss']
})
export class StatusBoxComponent {
  public params: {
    phone: string,
    email: string
  };
  public statusBoxInfo: any;
  public status: string;
  private isExpired: boolean;
  private allowedStates: string[];

  constructor(
    private config: ConfigService,
    private stylesService: StylesService
  ) {
    const {
      checkin: {
        corporativeColor: color
      },
      company
    } = this.config;

    this.params = {
      phone: `<b>${company.phone}</b>`,
      email: `<b><a href="mailto:${company.email}">${company.email}</a></b>`
    };

    this.stylesService.set({ '.checkin__status__box__description b, .checkin__status__box__description a': { color } });

    this.status = this.config.booking.resume.status;
    this.isExpired = this.config.checkin.expired;
    this.allowedStates = [EBookingStatus.CANCELLED, EBookingStatus.PETICION_DISPONIBILIDAD];

    if (this.isExpired && !this.allowedStates.includes(this.status)) {
      this.status = 'expired';
    }

    this.statusBoxInfo = {
      [EBookingStatus.CANCELLED]: {
        title: 'cancelled-booking-title',
        description: 'cancelled-booking-description'
      },
      [EBookingStatus.PETICION_DISPONIBILIDAD]: {
        title: 'pending-booking-title',
        description: 'pending-booking-description'
      },
      [EBookingStatus.CONFIRMADA]: {
        title: 'pending-booking-title',
        description: 'pending-booking-description'
      },
      expired: {
        title: 'expired-checkin-title',
        description: 'expired-checkin-description'
      }
    };
  }
}

import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { CommonModule } from 'src/app/shared/common/common.module';
import { InputsModule as LanguageModule } from '../../inputs/inputs.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared.module';
import { SectionTitleComponent } from './section-title/section-title.component';
import { StatusBoxComponent } from 'src/app/shared/modules/components/status-box/status-box.component';
import { ProcessInfoBoxComponent } from 'src/app/shared/modules/components/process-info-box/process-info-box.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NavbarAditionalInfoComponent } from './navbar/navbar-aditional-info/navbar-aditional-info.component';
import {
  NavbarAditionalInfoItemComponent
} from './navbar/navbar-aditional-info/navbar-aditional-info-item/navbar-aditional-info-item.component';
import { PageIndexComponent } from './page-index/page-index.component';
import { PageUpComponent } from './page-up/page-up.component';
import { FileComponent } from './file/file.component';
import { CtaComponent } from 'src/app/shared/modules/components/cta/cta.component';
import { LoaderModule } from '../loader/loader.module';
import { ButtonsModule } from '../../common/components/buttons/buttons.module';
import { CreditCardFormComponent } from './credit-card-form/credit-card-form.component';
import { CreditCardModule } from '../credit-card/credit-card.module';
import { InputsModule } from 'src/app/shared/common/components/inputs/inputs.module';
import { FeedbackModalComponent } from './modals/feedback-modal/feedback-modal.component';
import { BaseModalComponent } from './modals/base-modal/base-modal.component';
import { BillingAddressPreviewComponent } from './credit-card-form/billing-address-preview/billing-address-preview.component';
import { CreditCardPreviewComponent } from './credit-card-form/credit-card-preview/credit-card-preview.component';
import { RadioItemComponent } from './radio-item/radio-item.component';


const allModules = [
  FeedbackModalComponent,
  BaseModalComponent,
  CreditCardFormComponent,
  StatusBoxComponent,
  ProcessInfoBoxComponent,
  RadioItemComponent,
  NavbarAditionalInfoComponent,
  NavbarAditionalInfoItemComponent,
  HeaderComponent,
  FooterComponent,
  NavbarComponent,
  SectionTitleComponent,
  PageIndexComponent,
  PageUpComponent,
  FileComponent,
  CtaComponent,
  BillingAddressPreviewComponent,
  CreditCardPreviewComponent
];

@NgModule({
  declarations: allModules,
  imports: [
    AngularCommonModule,
    SharedModule,
    CommonModule,
    InputsModule,
    LanguageModule,
    RouterModule,
    LoaderModule,
    ButtonsModule,
    CreditCardModule
  ],
  exports: allModules
})
export class ComponentsModule { }

import { Pipe, PipeTransform, Injector } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import * as dayjs from 'dayjs';
import { TranslateService } from '@ngx-translate/core';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';

interface FormatDatePipeOptions {
  weekday?: string;
  year?: string;
  month?: string;
  day?: string;
  fromNow?: boolean;
}

@Pipe({name: 'formatDate'})
export class FormatDatePipe implements PipeTransform {
  private config: ConfigService;
  private translateService: TranslateService;

  constructor() {
    const injector: Injector = InjectorService.get();

    Object.assign(this, {
      config: injector.get(ConfigService),
      translateService: injector.get(TranslateService)
    });
  }

  transform(date: Date | string, options: FormatDatePipeOptions = {}, locale?: string): string {
    locale = locale || this.config.locale.get() || 'en_GB';
    const lang = locale.replace('_', '-');

    if (typeof date === 'string' && date.includes('Z')) {
      date = new Date(date);
    }

    if (options.fromNow) {
      const current: string = dayjs(date).format('YYYY-MM-DD');
      const translations: { [key: string]: string; } = {
        [dayjs().format('YYYY-MM-DD')]: 'today',
        [dayjs().subtract(24, 'h').format('YYYY-MM-DD')]: 'yesterday'
      };

      if (translations[current]) {
        return this.translateService.instant(translations[current]);
      }
    }

    return (new Intl.DateTimeFormat(lang, options).format(date as Date));
  }
}

import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { OccupantForm, UpdateCheckinFormResponse } from '../occupant-detail.interface';
import { parseCheckinForm } from './occupant-form.utils';
import { formatUrlQuery } from 'src/app/shared/common/utils/requests';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/shared/services/config/config.service';

@Injectable()
export class OccupantFormService {
  private baseUrl: string;
  private hash: string;
  private _updatedFormValues: OccupantForm; // tslint:disable-line:variable-name

  constructor(
    private config: ConfigService,
    private http: CheckinHttpClient,
    private env: EnvService
  ) {
    this.hash = this.config.checkin.hash;
    this.baseUrl = `${this.env.endpoint}/v2/config/checkin/form/${this.hash}`;
  }

  private updateCheckinFormRequest(url: string, controls: { [key: string]: any }, close: boolean) {
    const action = close ? 'patch' : 'put';
    return this.http[action](url, controls).pipe(
      map((res: any) => res),
      catchError((error: any) => { throw error; })
    );
  }

  getCheckinForm(occupantId: number, inputs: string[] = []): Observable<OccupantForm> {
    const url = `${this.baseUrl}/${occupantId}`;

    return this.http.get(formatUrlQuery(url, 'inputs', inputs)).pipe(
      map((res: { form: OccupantForm }) => res ? parseCheckinForm(res.form) : []),
      catchError((error: any) => { throw error; })
    );
  }

  updateCheckinForm(occupantId: number, controls: any): Observable<UpdateCheckinFormResponse> {
    const url = `${this.baseUrl}/${occupantId}`;
    return this.updateCheckinFormRequest(url, controls, false);
  }

  closeCheckinForm(occupantId: number, controls: any): Observable<UpdateCheckinFormResponse> {
    const url = `${this.baseUrl}/${occupantId}`;
    return this.updateCheckinFormRequest(url, controls, true);
  }

  public get updatedFormValues(): OccupantForm {
    return Array.isArray(this._updatedFormValues) ? this._updatedFormValues : [];
  }

  public set updatedFormValues(values: OccupantForm) {
    this._updatedFormValues = values;
  }
}

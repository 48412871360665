import { Injector } from '@angular/core';
import { MainService } from 'src/app/shared/main/main.service';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { ERoutes } from 'src/app/shared/enums/routes.enum';
var BookingCardComponent = /** @class */ (function () {
    function BookingCardComponent(injector) {
        this.injector = injector;
        Object.assign(this, {
            configService: this.injector.get(ConfigService),
            mainService: this.injector.get(MainService)
        });
        this.project = this.configService[this.configService.project];
    }
    Object.defineProperty(BookingCardComponent.prototype, "creditCardNumber", {
        get: function () {
            return this.configService.booking.creditCard.number || '—— —— —— ——';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BookingCardComponent.prototype, "ctaText", {
        get: function () {
            var creditCard = this.configService.booking.creditCard;
            if (creditCard.valid) {
                return creditCard.updatable ? 'change' : 'see';
            }
            return 'add';
        },
        enumerable: true,
        configurable: true
    });
    BookingCardComponent.prototype.goToCard = function () {
        if (this.configService.checkin.secureUrl) {
            window.location.href = this.configService.checkin.secureUrl;
            return;
        }
        if (this.configService.screen.isLG) {
            this.mainService.toogleCardResponsive();
            return;
        }
        this.project.navigate(ERoutes.CARD);
    };
    return BookingCardComponent;
}());
export { BookingCardComponent };

import * as _ from 'lodash';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy
} from '@angular/core';
import { SidebarData } from 'src/app/app.interfaces';
import { StylesService } from 'src/app/shared/common/components/buttons/styles.service';
import * as Color from 'color';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { Subscription } from 'rxjs';
import { EBookingStatus } from 'src/app/shared/enums/booking-status.enum';
import { PaymentsDetail } from './booking-detail.class';
import { ITranslations, Translations } from 'src/app/shared/utils/translations';

@Component({
  selector: 'app-booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrls: [
    '../sidebar-item.component.scss',
    './booking-detail.component.scss'
  ]
})

export class BookingDetailComponent implements AfterViewInit, OnDestroy {
  @Input() isResponsive: boolean;
  @Input() showCreditCard: boolean;

  public accImg: string;
  public contentUp: boolean;
  public sidebarData: SidebarData;

  private configService: ConfigService;
  private paymentsDetail: PaymentsDetail;
  private subscriber: Subscription;

  public showBookingCard: boolean;
  public status: string;
  public isStatusCustomTemplate: boolean;
  public datesAllowed: boolean;

  public showBookingPrice: boolean;
  public showPaymentsDetail: boolean;
  public sizesConfig: {
    minHeight: number;
    maxHeight: number;
  };

  public occupantsInfoLabel: string;
  public showModifyOccupants: boolean;
  public domainOLB: string;

  constructor(private injector: Injector, private cdRef: ChangeDetectorRef) {
    this.configService = this.injector.get(ConfigService);
    this.paymentsDetail = new PaymentsDetail();

    this.status = this.configService.booking.resume.status;
    this.status = this.configService.booking.resume.status;
    this.isStatusCustomTemplate = this.configService.booking.statusWithCustomTemplate.includes(this.status) &&
      (this.configService[this.configService.project].modules.SIDEBAR as any).CUSTOM_STATUS;

    this.datesAllowed = this.status !== EBookingStatus.PETICION_INFORMACION;

    new Translations([
      'adult.one',
      'child.one',
      'adult.other',
      'child.other'
    ]).get((translations: ITranslations) => Object.assign(this, this.setOccupants(translations)));

    this.setPaymentsConfig();
    this.setSidebarConfig();
    this.setCorporativeStyles();

    if (this.configService.project === 'checkout') {
      this.setCheckoutConfig();
    }
  }

  public ngAfterViewInit(): void {
    this.paymentsDetail.setElements();
    this.subscribe();
  }

  public ngOnDestroy(): void {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

  public redirectToOLB() {
    window.location.href = this.domainOLB;
  }

  private subscribe(): void {
    this.setBookingDetailSizeConfig();
    this.subscriber = this.configService.screen.onWindowResizeChanges().subscribe(() => this.setBookingDetailSizeConfig());
  }

  private setCorporativeStyles(): void {
    const corporativeColor: Color = this.configService.checkin.corporativeColor;

    this.injector.get(StylesService).set({
      'div.checkin__booking__detail__header__overlay::after': {
        'background-color': corporativeColor
      }
    });
  }

  private setSidebarConfig(): void {
    this.contentUp = !this.showPaymentsDetail || (!this.configService.screen.isLG);
    this.sidebarData = this.configService.booking.resume;
    this.accImg = this.sidebarData.img || 'assets/images/illustrations/acc-404.svg';
  }

  private setPaymentsConfig(): void {
    this.showBookingPrice = this.configService.booking.showBookingPrice;
    this.showPaymentsDetail = this.shouldShowPaymentsDetail();
  }

  private setOccupants(translations: ITranslations): void {
    let childrenNumber = this.configService.booking.resume.children;
    const adultsNumber = this.configService.booking.resume.adults;
    const babiesNumber = this.configService.booking.resume.babies;

    if (babiesNumber) {
      childrenNumber = childrenNumber + babiesNumber;
    }

    const adultsSlug = (adultsNumber === 1) ? 'adult.one' : 'adult.other';
    const childrenSlug = (childrenNumber === 1) ? 'child.one' : 'child.other';

    this.occupantsInfoLabel = `${(adultsNumber)} ${translations[adultsSlug]}, ${(childrenNumber)} ${translations[childrenSlug]}`;
  }

  private shouldShowPaymentsDetail(): boolean {
    const { payments } = this.configService.booking.resume;
    if (!payments) {
      return false;
    }

    const { paymentsDetail = [], deposit = [] } = payments;

    return (paymentsDetail.length > 0 || deposit.length > 0);
  }

  private setBookingDetailSizeConfig() {
    if (this.configService.screen.isXS) {
      this.sizesConfig = this.paymentsDetail.getSizeConfig({
        hasCard: this.showCreditCard,
        hasPayments: this.showPaymentsDetail,
        hasPrice: this.showBookingPrice
      });

      this.cdRef.detectChanges();
    }
  }

  private setCheckoutConfig(): void {
    const checkoutConfig =  _.get(this.configService.checkout, 'config.config');
    this.domainOLB = `${this.configService.return_url}${this.sidebarData.name}-${this.sidebarData.accId}.html`.replace(/\s+/g, '-');
    this.showModifyOccupants = checkoutConfig.sourceApp === 'olb';
  }
}

import { IChatListMessages, IChatMessage } from 'src/app/app.interfaces';
import * as dayjs from 'dayjs';
import { EMessagesTypes } from '../messages-types.enum';

export class ChatListMessages {
  public list: IChatListMessages[];
  public unread: number;

  constructor() {
    Object.assign(this, {
      list: [],
      unread: 0
    });
  }

  public exists(messageId: number): boolean {
    return this.list.some((day) => {
      return day.messages.some((message) => message.id === messageId);
    });
  }

  public length(): number {
    return this.list.reduce((prev, curr) => {
      prev = curr.messages.length + prev;

      return prev;
    }, 0);
  }

  public replace(temporalId: number, id: number): void {
    const filter: (arr: IChatMessage[]) => IChatMessage = (arr: IChatMessage[]) => arr.find((a) => a.id === temporalId);
    const day: IChatListMessages = this.list.find((el) => Boolean(el && filter(el.messages)));

    if (!day) {
      return;
    }

    const message: IChatMessage = filter(day.messages);

    if (!message) {
      return;
    }

    message.id = id;
  }

  public add(message: IChatMessage): ChatListMessages {
    if (this.exists(message.id)) {
      return this;
    }

    const index = this.list.findIndex(({ date }) => {
      return date.isSame(message.date, 'day');
    });

    if (index > -1) {
      this.list[index].messages.push(message);

      return this;
    }

    this.list.push({ date: dayjs(message.date), messages: [message] });

    return this;
  }

  public load(messages: IChatMessage[], onFinish: (unread: number) => void): void {
    this.unread = messages.reduce((unread, msg) => {
      this.add(msg);

      if (!msg.read && msg.from === EMessagesTypes.COMPANY) {
        unread++;
      }

      return unread;
    }, 0);

    onFinish(this.unread);
  }

  public setMessagesAsRead(): void {
    this.list.forEach(({ messages }) => messages.forEach((m) => m.read = true));
    this.unread = 0;
  }
}

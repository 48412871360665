import * as tslib_1 from "tslib";
var PaymentsDetail = /** @class */ (function () {
    function PaymentsDetail() {
    }
    PaymentsDetail.prototype.getElementsSize = function () {
        return Object.entries(this.elements).reduce(function (res, _a) {
            var _b;
            var key = _a[0], value = _a[1];
            return (tslib_1.__assign({}, res, (_b = {}, _b[key] = (typeof value.getBoundingClientRect === 'function') ? value.getBoundingClientRect() : value, _b)));
        }, {});
    };
    PaymentsDetail.prototype.setElements = function () {
        this.elements = {
            body: document.querySelector('body'),
            footer: document.querySelector('app-footer'),
            payment: document.querySelector('app-payments-detail-item').getBoundingClientRect(),
            sidebar: document.querySelector('.checkin__sidebar__wrapper')
        };
    };
    PaymentsDetail.prototype.getSizeConfig = function (paymentsLength) {
        if (!this.elements) {
            return { maxHeight: 'initial', showToggle: false, showing: paymentsLength };
        }
        var length = typeof this.showing === 'number' ? this.showing : paymentsLength;
        var _a = this.getElementsSize(), body = _a.body, footer = _a.footer, payment = _a.payment, sidebar = _a.sidebar;
        var bodyOffset = body.height - footer.height - (sidebar.top + sidebar.height - (payment.height * (length)));
        var paymentsListOffset = Math.round(bodyOffset / payment.height - 1);
        this.showing = (paymentsListOffset > 0 && paymentsListOffset <= paymentsLength) ? paymentsListOffset :
            (paymentsListOffset <= 0) ? 0 : paymentsLength;
        return {
            showing: this.showing,
            paymentHeight: payment.height,
            showToggle: this.showing < paymentsLength
        };
    };
    return PaymentsDetail;
}());
export { PaymentsDetail };

import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { Injector } from '@angular/core';
import { ELocale } from 'src/app/shared/enums/locale.enum';
import { CookieService } from 'src/app/shared/services/cookie/cookie.service';
import { TranslateService } from '@ngx-translate/core';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';

export class LocaleLoader {
  private companyLanguages: string[];
  private cookieService: CookieService;
  private finish: (l: string) => void;
  private http: CheckinHttpClient;
  private key: string;
  private translateService: TranslateService;

  constructor(key: string, companyLanguages: string[], finish: (l: string) => void) {
    const injector: Injector = InjectorService.get();

    Object.assign(this, {
      companyLanguages: companyLanguages || [],
      cookieService: injector.get(CookieService),
      key,
      http: injector.get(CheckinHttpClient),
      finish,
      translateService: injector.get(TranslateService)
    });
  }

  public load(locale: string, update?: boolean): void {
    const l: string = this.getLocale(locale);

    if (update || this.isEmptyCookieLocale()) {
      this.setCookieLocale(locale);
    }

    this.finish(l);
    this.loadServices(l);
  }

  private loadServices(locale: string): void {
    this.translateService.setDefaultLang(ELocale.DEFAULT);
    this.translateService.use(locale);

    if (this.getCookieLocale()) {
      this.http.setLocale(locale);
    }
  }

  private getLocale(locale: string): string {
    const l = (this.cookieService.check(this.key) && !this.isEmptyCookieLocale() ? this.getCookieLocale() : locale) || ELocale.DEFAULT;

    return this.checkLanguage(l);
  }

  private getCookieLocale(): string {
    return this.cookieService.get(this.key);
  }

  private setCookieLocale(locale: string): void {
    this.cookieService.set(this.key, locale);
  }

  private isEmptyCookieLocale(): boolean {
    const cookieLocale = this.getCookieLocale();
    return Boolean(!cookieLocale || cookieLocale === 'undefined');
  }

  private checkLanguage(locale: string): string {
    return this.companyLanguages.includes(locale) ? locale : ELocale.DEFAULT;
  }
}

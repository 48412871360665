import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RequestInterfaceBase } from '../request-interface-base';
import { bounceAnimation } from '../request-interface.animation';

@Component({
  selector: 'app-request-interface-input',
  templateUrl: './request-interface-input.component.html',
  styleUrls: ['./request-interface-input.component.scss'],
  animations: [bounceAnimation]
})
export class RequestInterfaceInputComponent extends RequestInterfaceBase implements OnInit {
  @Input() showLoadingSave: boolean;

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void { }

  get tooltipSaveError(): string {
    return this.translateService.instant('tooltip-save-error');
  }

  get tooltipSaveSuccess(): string {
    return this.translateService.instant('tooltip-save-success');
  }
}

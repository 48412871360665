import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { InputBase } from '../input-base';
import { SelectDataService } from 'src/app/shared/common/services/select-data.service';
import { ConfigService } from 'src/app/shared/services/config/config.service';
var InputSelectComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputSelectComponent, _super);
    function InputSelectComponent(changeDetector, dataService, config) {
        var _this = _super.call(this) || this;
        _this.changeDetector = changeDetector;
        _this.dataService = dataService;
        _this.config = config;
        _this.showPlaceholder = true;
        _this.disabledOption = false;
        _this.options = [];
        _this.iOS = _this.config.screen.isIOS;
        return _this;
    }
    InputSelectComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        if (!this.hasOptionsByDefault()) {
            this.getServiceOptions();
        }
    };
    InputSelectComponent.prototype.ngOnChanges = function (changes) {
        _super.prototype.ngOnChanges.call(this, changes);
        if (changes.serviceOption) {
            this.getServiceOptions();
        }
    };
    InputSelectComponent.prototype.hasOptionsByDefault = function () {
        return Array.isArray(this.options) && this.options.length > 0;
    };
    InputSelectComponent.prototype.setLoading = function (loading) {
        _super.prototype.setLoading.call(this, loading);
        this.changeDetector.detectChanges();
        this.form.get(this.control)[this.loadingSave ? 'disable' : 'enable']();
    };
    InputSelectComponent.prototype.getServiceOptions = function () {
        var _this = this;
        this.dataService.get(this.service, this.serviceOption).subscribe(function (options) {
            _this.options = options;
            if (_this.control === 'hour') {
                var currentValue_1 = _this.form.get(_this.control).value;
                var optionExists = _this.options.some(function (option) { return option.code === currentValue_1; });
                if (!optionExists && _this.options.length > 0) {
                    _this.form.get(_this.control).setValue(_this.options[0].code);
                }
            }
            if (_this.control === 'clientIdIssuedAt') {
                var optionToSet = _this.options.find(function (option) { return option.code === _this.form.get(_this.control).value.code; });
                _this.form.get(_this.control).setValue(optionToSet);
            }
        }, function (err) {
            console.log(err);
        });
    };
    return InputSelectComponent;
}(InputBase));
export { InputSelectComponent };

import { ConfirmationRequestResultType } from 'src/app/shared/main/main.enum';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
var RequestInterfaceBase = /** @class */ (function () {
    function RequestInterfaceBase() {
        this.sizeLoader = 24;
        this.config = InjectorService.get().get(ConfigService);
        this.corporativeColor = this.config.checkin.corporativeColor;
    }
    Object.defineProperty(RequestInterfaceBase.prototype, "modalTemplate", {
        get: function () {
            return this.result || 'action';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RequestInterfaceBase.prototype, "alignClass", {
        get: function () {
            return this.align ? "checkin__request__interface__modal__" + this.align : '';
        },
        enumerable: true,
        configurable: true
    });
    return RequestInterfaceBase;
}());
export { RequestInterfaceBase };

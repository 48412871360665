var TravelerDownloaderService = /** @class */ (function () {
    function TravelerDownloaderService() {
    }
    Object.defineProperty(TravelerDownloaderService.prototype, "downloader", {
        get: function () {
            return this._downloader;
        },
        set: function (downloader) {
            this._downloader = downloader;
        },
        enumerable: true,
        configurable: true
    });
    return TravelerDownloaderService;
}());
export { TravelerDownloaderService };

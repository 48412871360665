import { Injectable } from '@angular/core';

@Injectable()
export class StylesService {
  styles = {};

  constructor() {}

  private unwind(selector: string, styles: any): any {
    let toReturn = {};
    const deepSelectors = Object.keys(styles).filter((key) => key.indexOf('&') > -1);

    if (deepSelectors.length > 0) {
      deepSelectors.forEach((deepSelector) => {
        const parsedSelector = deepSelector.replace(/\&/gi, selector);
        const unwound = this.unwind(parsedSelector, styles[deepSelector]);
        toReturn = { ...toReturn, ...unwound };
        delete styles[deepSelector];
      });
    }

    toReturn[selector] = styles;
    return toReturn;
  }

  private parseStyles(styles: any) {
    return Object.keys(styles).reduce((obj, selector) => {
      const props = styles[selector];
      const unwound = this.unwind(selector, props);
      obj = { ...obj, ...unwound };
      return obj;
    }, {});
  }

  private parseToCss(styles: any): string {
    return Object.entries(styles).reduce((global, [selector, style]) => {
      if (!style) {
        return global;
      }

      const st = Object.entries(style).reduce((styleString, [propName, propValue]) => {
        return `${styleString}${propName}:${propValue};`;
      }, '');

      return `${global}
        ${selector} {
          ${st}
        }
      `;
    }, '');
  }

  set(styles: any) {
    const allStyles = this.parseStyles(styles);
    const hash = Object.keys(styles).sort().join();

    if (!this.styles[hash]) {
      const styleEl = document.createElement('style');
      document.body.appendChild(styleEl);
      this.styles[hash] = styleEl;
    }

    this.styles[hash].innerHTML = this.parseToCss(allStyles);
  }
}

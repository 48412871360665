import { RouterModule, Routes } from '@angular/router';

// Components
import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { FallbackDomainComponent } from '../shared/common/components/fallback-domain/fallback-domain.component';
import { BaseGuard } from '../shared/guards/base/base.guard';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [BaseGuard],
    component: AppComponent,
    children: [
      {
        path: 'checkin',
        loadChildren: () => import('./modules/checkin/checkin.module').then(m => m.CheckinModule)
      },
      {
        path: 'payments',
        loadChildren: () => import('../shared/modules/payments/payments.module').then(m => m.PaymentsModule)
      },
      {
        path: 'inbox',
        loadChildren: () => import('./modules/inbox/inbox.module').then(m => m.InboxModule)
      },
      { path: '**', component: FallbackDomainComponent }
      // All page modules must go before AppComponent
      // {
      //   path: ':hash',
      //   canActivate: [BookingGuard],
      //   component: AppComponent,
      //   children: [
      //     {
      //       path: '',
      //       component: LayoutComponent,
      //       children: MainRoutes
      //     },
      //     // {
      //     //   path: 'checkin',
      //     //   loadChildren: () => import('./projects/checkin/checkin.module').then(m => m.CheckinModule)
      //     // },
      //     // {
      //     //   path: 'inbox',
      //     //   loadChildren: () => import('./projects/inbox/inbox.module').then(m => m.InboxModule)
      //     // }
      //   ]
      // },
      // { path: '**', pathMatch: 'full', component: FallbackDomainComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [{
    provide: APP_BASE_HREF,
    useValue: '/guest-area/'
  }],
  exports: [RouterModule],
})
export class AppRouterModule {}

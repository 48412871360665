import { IBaseGuard } from 'src/app/shared/guards/base/base.guard';
import { Project } from 'src/app/shared/classes/projects/project.class';
import { IConfig } from 'src/app/app.interfaces';
import { IInbox } from 'src/app/shared/models/projects/inbox/inbox.model';
import { Company } from '../../../models/company/company.model';
import { INavbar } from 'src/app/shared/models/navbar/navbar.model';
import { InboxConfig } from './inbox-config.class';
import { EModules } from '../../../../app.enum';
import { ERoutes } from '../../../enums/routes.enum';
import { ChatListMessages } from '../../../../checkin-app/modules/inbox/pages/chat/classes/chat-list-messages.class';

export class Inbox extends Project implements IInbox {
  public readonly config: InboxConfig;
  public readonly messages: ChatListMessages;

  constructor(params: IBaseGuard, company: Company) {
    super(params, company, EModules.INBOX);

    Object.assign(this, {
      messages: new ChatListMessages(),
      config: {}
    });
  }

  private getNavbar(): INavbar {
    const routes = [{
      icon: 'avc-filled-house',
      label: {
        slug: 'navbar-booking'
      },
      route: ERoutes.BOOKING
    },
    {
      icon: 'avc-chat',
      label: {
        slug: 'inbox'
      },
      route: '/'
    },
    {
      icon: 'avc-icon-hmb',
      action: 'showAditionalInfo',
      label: {
        slug: 'menu'
      }
    }];

    return routes;
  }

  private getNavbarAditionalInfo(): INavbar {
    const routes = [
    // {
    //   icon: 'avc-icon-booking',
    //   label: {
    //     slug: 'navbar-aditional-info-booking'
    //   },
    //   action: 'navigateToIntranet'
    // },
    {
      icon: 'avc-icon-language',
      component: 'INPUT_LANGUAGE'
    }
    // , {
    //   icon: 'avc-icon-about',
    //   label: {
    //     slug: 'navbar-aditional-info-company',
    //     params: { company: this.company.name }
    //   }
    // }
    ];

    return routes;
  }

  public update(data: IConfig, update: (params: any) => void, cb: () => void): void {
    const {
      extraConfig,
      booking: {
        locale
      },
      company: {
        name: companyName
      }
    } = data;

    const config = new InboxConfig(extraConfig);
    this.metaTitle.setTitle(`Inbox | ${companyName}`);

    Object.assign(this, {
      config,
      expired: this.company.isExpiredModule(EModules.INBOX),
      navbar: this.getNavbar(),
      navbarAditionalInfo: this.getNavbarAditionalInfo(),
      tabs: []
    });


    update({ locale });
    // Set translations must be after LocaleLoader
    this.setTranslations(cb);
  }
}

import { Guest } from './guest.model';
import { OccupantsList } from 'src/app/app.interfaces';
import { ITranslations, Translations } from 'src/app/shared/utils/translations';

export interface IGuests {
  adults: number;
  children: number;
  completed: number;
  list: { [key: number]: Guest };
  mainGuestId: number;
  total: number;
}

export class Guests implements IGuests {
  public adults: number;
  public children: number;
  public completed: number;
  public list: { [key: number]: Guest; };
  public mainGuestId: number;
  public total: number;

  constructor(private params: {
    occupants: OccupantsList;
    hash: string;
  }) {
    new Translations([
      'occupant.one',
      'companion.one',
    ]).get((translations: ITranslations) => Object.assign(this, this.sanitize(this.params.occupants, translations)));
  }

  private sanitize(occupants: OccupantsList, translations: ITranslations): Guests {
    return [...occupants as any].sort((a, b) => (a.isMainOccupant as any) - (b.isMainOccupant as any)).reduce((res, g, i) => {
      const { id, isMainOccupant, isChild, completed } = g;
      const altName: string = isMainOccupant ? translations['occupant.one'] : `${translations['companion.one']} ${(i + 1)}`;

      res[isChild ? 'children' : 'adults']++;
      res.mainGuestId = isMainOccupant ? id : res.mainGuestId;
      res.completed = completed ? res.completed + 1 : res.completed;
      res.list[id] = new Guest({
        hash: this.params.hash,
        occupant: {
          ...g,
          name: {
            value: g.name || altName,
            updated: Boolean(g.name)
          }
        }
      });

      return res;
    }, {
      adults: 0,
      children: 0,
      completed: 0,
      list: {},
      mainGuestId: null,
      total: occupants.length
    });
  }

  public getMainGuest(): Guest {
    return this.getGuest(this.mainGuestId);
  }

  public updateGuestsDelegation(email, checkins): void {
    checkins.forEach((delegated) => {
      const guest = this.list[delegated.id];
      if (delegated) {
        guest.markAsDelegated(delegated.name, email);
      }
    });
  }

  public getGuest(id: number): Guest {
    return this.list[id];
  }

  public getCompanions(): Guest[] {
    const { [this.mainGuestId]: mainGuest, ...companions } = this.list;

    return Object.values(companions).sort((a, b) => {
      if (a.completed.value !== b.completed.value) {
        return a.completed.value ? 1 : -1;
      }

      if (!(a.completed.value && b.completed.value) && a.name.updated !== b.name.updated) {
        return a.name.updated ? -1 : 1;
      }

      if (a.name.value !== b.name.value) {
        return b.name.value > a.name.value ? -1 : 1;
      }
    });
  }
}

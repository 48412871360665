import * as tslib_1 from "tslib";
var PaymentsDetail = /** @class */ (function () {
    function PaymentsDetail() {
    }
    PaymentsDetail.prototype.getElementsSize = function () {
        var _this = this;
        return Object.entries(this.elements).reduce(function (res, _a) {
            var _b;
            var key = _a[0], value = _a[1];
            return (tslib_1.__assign({}, res, (_b = {}, _b[key] = value ? _this.getElementParams(value) : {}, _b)));
        }, {});
    };
    PaymentsDetail.prototype.setElements = function () {
        this.elements = {
            price: document.querySelector('.checkin__booking__price'),
            creditCard: document.querySelector('app-booking-card'),
            container: document.querySelector('.checkin__page__up__top--full--bottom')
        };
    };
    PaymentsDetail.prototype.getSizeConfig = function (sizeConfig) {
        if (!this.elements) {
            return { minHeight: null, maxHeight: null };
        }
        var _a = this.getElementsSize(), price = _a.price, creditCard = _a.creditCard, container = _a.container;
        var creditCardHeight = sizeConfig.hasCard ? creditCard.totalHeight : 0;
        var maxHeight = container.height - (price.totalHeight + creditCardHeight);
        return {
            minHeight: container.height - (sizeConfig.hasPrice && !sizeConfig.hasPayments ? price.totalHeight : 0),
            maxHeight: !sizeConfig.hasPayments ? maxHeight : null
        };
    };
    PaymentsDetail.prototype.getStyle = function (element, attribute) {
        if (!element) {
            return;
        }
        var elementAttribute = window.getComputedStyle(element)[attribute];
        if (!elementAttribute) {
            elementAttribute = element.style[attribute];
        }
        return parseInt(elementAttribute, 10) || 0;
    };
    PaymentsDetail.prototype.getElementParams = function (element) {
        var params = element.getBoundingClientRect();
        var margin = {
            top: this.getStyle(element, 'marginTop'),
            bottom: this.getStyle(element, 'marginBottom')
        };
        params.totalHeight = margin.top + margin.bottom + params.height;
        return params;
    };
    return PaymentsDetail;
}());
export { PaymentsDetail };

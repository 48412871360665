import { Component, OnInit } from '@angular/core';
import { InputBase } from '../input-base';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { SelectDataOptionsList } from 'src/app/shared/common/common.interfaces';
import { SelectDataService } from 'src/app/shared/common/services/select-data.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';

@Component({
  selector: 'app-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss']
})

export class InputPhoneComponent extends InputBase implements OnInit {
  phoneCodes: SelectDataOptionsList;
  phoneForm: FormGroup;
  selectDataService: SelectDataService;
  selectedPrefix: number;

  constructor(formBuilder: FormBuilder) {
    super();

    this.selectDataService = InjectorService.get().get(SelectDataService);

    this.phoneCodes = [];

    this.phoneForm = formBuilder.group({
      prefix: new FormControl('', [Validators.maxLength(4)]),
      phone: new FormControl('')
    });
  }

  ngOnInit() {
    this.setPhoneCodes();

    if (this.required) {
      const control = this.form.get(this.control);
      const { prefix, phone } = this.phoneForm.controls;

      this.setFormRequired([control, prefix, phone]);
    }

    if (this.hasValue) {
      this.setPhoneValue();
    }

    this.validatePhoneChars();
    this.onPhoneChange();
    this.onFormStatusChange(this.phoneForm);
  }

  private setPhoneCodes(): void {
    this.selectDataService.get('prefixes').subscribe((res) => this.phoneCodes = res);
  }

  onPrefixChange(evt: number) {
    this.phoneForm.get('prefix').setValue(evt);
  }

  onPhoneChange() {
    this.phoneForm.valueChanges.subscribe((value) => {
      if (this.phoneForm.invalid) {
        return;
      }

      const { prefix, phone } = value;
      this.form.get(this.control).setValue(`+${prefix} ${phone}`);
    });
  }

  setPhoneValue() {
    const { value } = this.form.get(this.control);
    const [prefix, phone] = this.parsePhoneValue(value);

    this.phoneForm.patchValue({ prefix, phone });
    this.selectedPrefix = prefix;

    if (prefix && phone) {
      this.phoneForm.get('prefix').disable();
      this.phoneForm.get('phone').disable();
    }
  }

  validatePhoneChars() {
    const { phone } = this.phoneForm.controls;
    phone.valueChanges.subscribe((value) => {
      phone.setValue(value.replace(/[^0-9]/g, ''), { emitEvent: false });
    });
  }

  parsePhoneValue(value) {
    if (value.startsWith('+')) {
      const [prefix, ...phoneArray] = value.replace(/\+/, '').split(' ');
      const phone = phoneArray.join('').replace(/\s/g, '');

      return [prefix, phone];
    }

    return [undefined, value.replace(/\s/g, '')];
  }

  get hasPrefix(): boolean {
    const { value } = this.phoneForm.get('prefix');
    return value;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

/**
 * USAGE: expression:number | pluralize:'PATH.TO.TRANSLATION.PLURAL.KEY' | async
 * @see https://github.com/ngx-translate/core/issues/150#issuecomment-256496889
 */

@Pipe({name: 'pluralize', pure: false})
export class PluralizePipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  // TODO: Improve this getting the locale plural function.
  // Currently this is all we need because the languages we support
  // have only one plural.
  getPluralCategory(expression: number) {
    return (expression === 1) ? 'one' : 'other';
  }

  transform(expression: number, key: string): Observable<string> {
    const category = this.getPluralCategory(expression);
    key = `${key}.${category}`;
    return this.translate.instant(key);
  }
}

import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ImagesService } from 'src/app/shared/common/services/images.service';
import { filter } from 'rxjs/operators';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { TCaptureActions } from 'src/app/checkin-app/modules/checkin/pages/wizard/pages/identity/pages/capture/capture.component';

export type TFileType = 'camera' | 'user';

@Component({
  selector: 'app-file',
  templateUrl: 'file.component.html',
  styleUrls: ['./file.component.scss']
})

export class FileComponent implements OnInit, OnDestroy {
  @ViewChild('inputDocument', { static: true }) inputDocument: ElementRef;
  @Input() actions: Subject<TCaptureActions>;
  @Input() type: TFileType;
  @Output() newPhotoEvt: EventEmitter<string>;

  private imagesService: ImagesService;
  private subscriber: Subscription;

  constructor() {
    Object.assign(this, {
      imagesService: InjectorService.get().get(ImagesService),
      newPhotoEvt: new EventEmitter<string>()
    });
  }

  public ngOnInit(): void {
    this.captureSubscriber();
  }

  public ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  private captureSubscriber(): void {
    this.subscriber = this.actions.asObservable().pipe(
      filter((action: TCaptureActions) => action === 'capture')
    ).subscribe(() => this.inputDocument.nativeElement.click());
  }

  public fileHandler(files: any): void {
    const [file] = files;

    this.imagesService.fileToBase64(file).subscribe((value) => {
      this.newPhotoEvt.emit(value);
      this.inputDocument.nativeElement.value = null;
    });
  }
}

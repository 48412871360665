import { Component, Injector, Input, OnDestroy } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { StylesService } from 'src/app/shared/common/components/buttons/styles.service';
import { PaymentConfig } from 'src/app/app.interfaces';
import { Subscription } from 'rxjs';
import { CreditCardFormService } from '../../../components/credit-card-form/credit-card-form.service';

@Component({
  selector: 'app-booking-price',
  templateUrl: './booking-price.component.html',
  styleUrls: ['./booking-price.component.scss']
})

export class BookingPriceComponent {
  @Input() full: boolean;

  private configService: ConfigService;
  private stylesService: StylesService;
  private creditCardFormSercvice: CreditCardFormService;
  private subscription: Subscription;

  public amount: number;
  public currency: string;
  public mcp: PaymentConfig['mcp'];
  public translations: {
    currencyInfoTitle: any;
    currencyInfoDescription: any;
  };

  constructor(private injector: Injector) {
    this.configService = this.injector.get(ConfigService);
    this.stylesService = this.injector.get(StylesService);
    this.creditCardFormSercvice = this.injector.get(CreditCardFormService);

    const corporativeColor = this.configService.checkin.corporativeColor.alpha(.12);

    this.stylesService.set({
      'div.checkin__booking__price--full': {
        'background-color': corporativeColor
      }
    });

    this.setConfig();
  }

  private setConfig(): void {
    const {
      amount,
      amountMCP,
      currency,
      currencyBuyer,
      paymentConfig
    } = this.configService.booking.resume;
    const { mcp } = paymentConfig;

    this.amount = mcp.enabled ? amountMCP : amount;
    this.currency = mcp.enabled ? currencyBuyer : currency;

    this.mcp = mcp;

    if (this.mcp.enabled) {
      this.setTranslations(currency);
    }

  }

  private setTranslations(currency): void {
    this.translations = {
      currencyInfoTitle: {
        slug: 'currency-info-mcp-title'
      },
      currencyInfoDescription: {
        slug: 'currency-info-mcp-description',
        params: {
          currency,
          ratio: this.mcp.value,
          symbol: this.mcp.symbol
        }
      }
    };
  }
}

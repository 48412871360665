import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
// TODO:: REFACTOR THIS
var ArrivalInfoUtils = /** @class */ (function () {
    function ArrivalInfoUtils() {
        this.config = InjectorService.get().get(ConfigService);
        var _a = this.config.checkin.config, specifyArrivalHour = _a.specifyArrivalTime, specifyDepartureHour = _a.specifyCheckoutTime, showTravelInfoOnWizard = _a.showTravelInfoOnWizard;
        Object.assign(this, {
            specifyArrivalHour: specifyArrivalHour,
            specifyDepartureHour: specifyDepartureHour,
            showTravelInfoOnWizard: showTravelInfoOnWizard
        });
    }
    Object.defineProperty(ArrivalInfoUtils.prototype, "travelInfoData", {
        get: function () {
            return this.config.booking.travelInfo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ArrivalInfoUtils.prototype, "isArrivalFulfilled", {
        get: function () {
            return Boolean(this.travelInfoData && this.travelInfoData.arrivalInfo);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ArrivalInfoUtils.prototype, "isDepartureFulfilled", {
        get: function () {
            return Boolean(this.travelInfoData && this.travelInfoData.departureInfo);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ArrivalInfoUtils.prototype, "shouldFullfilArrivalHour", {
        get: function () {
            return this.specifyArrivalHour && !this.isArrivalFulfilled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ArrivalInfoUtils.prototype, "shouldFullfilDepartureHour", {
        get: function () {
            return this.specifyDepartureHour && !this.isDepartureFulfilled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ArrivalInfoUtils.prototype, "showAdditionalInfo", {
        get: function () {
            return this.shouldFullfilArrivalHour || this.shouldFullfilDepartureHour;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ArrivalInfoUtils.prototype, "isTravelInfoCompleted", {
        get: function () {
            return !this.shouldFullfilArrivalHour || !this.shouldFullfilDepartureHour;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ArrivalInfoUtils.prototype, "isTravelInfoFulfilled", {
        get: function () {
            return this.showTravelInfoOnWizard && !this.isTravelInfoCompleted;
        },
        enumerable: true,
        configurable: true
    });
    return ArrivalInfoUtils;
}());
export { ArrivalInfoUtils };

import * as _ from 'lodash';
import { ValidatorFn, FormControl } from '@angular/forms';

export const whiteSpaceValidator: ValidatorFn = (control: FormControl) => {
  const value = (() => {
    if (typeof control.value === 'string') {
      return control.value;
    }

    return _.get(control, 'value.value', '');
  })();

  const whitespace = (value || '').trim().length === 0;

  return whitespace ? { whitespace: { value } } : null;
};

import { IBaseGuard } from 'src/app/shared/guards/base/base.guard';
import { Project } from 'src/app/shared/classes/projects/project.class';
import { IConfig } from 'src/app/app.interfaces';
import { Company } from '../../../models/company/company.model';
import { CheckoutConfig } from './checkout-config.class';
import { EModules } from '../../../../app.enum';
import { INavbar } from 'src/app/shared/models/navbar/navbar.model';
import { ICheckout } from 'src/app/shared/models/projects/checkout/checkout.model';
import { ERoutes } from 'src/app/shared/modules/payments/enums/routes.enum';

export class Checkout extends Project implements ICheckout {
  public readonly config: CheckoutConfig;

  constructor(params: IBaseGuard, company: Company) {
    super(params, company, EModules.CHECKOUT);

    Object.assign(this, {
      config: {}
    });
  }

  private getNavbar(): INavbar {
    return [{
      icon: 'avc-filled-house',
      label: {
        slug: 'navbar-booking'
      },
      route: ERoutes.BOOKING
    },
    {
      icon: 'avc-filled-card',
      label: {
        slug: 'payment.one',
      },
      route: '/'
    }];
  }

  public update(data: IConfig, update: (params: any) => void, cb: () => void): void {
    try {
      const {
        extraConfig,
        booking: {
          locale,
          payments
        },
        company: {
          name: companyName
        }
      } = data;
      const config = new CheckoutConfig(extraConfig, payments);
      this.metaTitle.setTitle(`Secure Checkout | ${companyName}`);

      Object.assign(this, {
        config,
        expired: false,
        navbar: this.getNavbar(),
        navbarAditionalInfo: [],
        tabs: []
      });

      update({ locale });
      this.setTranslations(cb);
    } catch (error) { this.errorHandler.handler(error); }
  }

  public getModules(): Checkout['modules'] {
    return {
      CHANGE_CARD: false,
      RESPONSIVE: {
        HIDE_HEADER: false
      },
      SIDEBAR: {
        CUSTOM_STATUS: false
      }
    };
  }
}

import { Injectable } from '@angular/core';
import { TravelerDownloader } from './traveler-downloader';

@Injectable()
export class TravelerDownloaderService {
  private _downloader: TravelerDownloader;

  constructor() {}

  public get downloader(): TravelerDownloader {
    return this._downloader;
  }

  public set downloader(downloader: TravelerDownloader) {
    this._downloader = downloader;
  }
}

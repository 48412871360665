import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Modules
import { ButtonsModule } from './components/buttons/buttons.module';
import { InputsModule } from './components/inputs/inputs.module';
import { RequestInterfaceModule } from './components/request-interface/request-interface.module';

// Components
import { FallbackDomainComponent } from './components/fallback-domain/fallback-domain.component';
import { GoBackComponent } from './components/go-back/go-back.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { SlideOutComponent } from './components/slide-out/slide-out.component';

// Services
import { EnvService } from './services/env.service';
import { GeoService } from './services/geo.service';
import { SelectDataService } from './services/select-data.service';

// Pipes
import { FormatCurrencyPipe } from './pipes/format-currency.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { ImagesService } from './services/images.service';
import { UtilsService } from './services/utils.service';
import { LocaleLowercasePipe } from './pipes/locale-lowercase.pipe';
import { SharedModule } from '../shared.module';
import { IconsModule } from './components/icons/icons.module';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

// Directives
import { AdDirective } from './directives/ad.directive';

const allModules = [
  // Modules
  FallbackDomainComponent,
  FormatCurrencyPipe,
  FormatDatePipe,
  FormatTimePipe,
  GoBackComponent,
  PluralizePipe,
  ProgressBarComponent,
  SectionHeaderComponent,
  SlideOutComponent,
  AdDirective,
  SafeHtmlPipe
];

@NgModule({
  declarations: allModules,
  exports: allModules,
  imports: [
    AngularCommonModule,
    ButtonsModule,
    SharedModule,
    FormsModule,
    IconsModule,
    InputsModule,
    ReactiveFormsModule,
    RequestInterfaceModule,
    RouterModule
  ],
  providers: [
    EnvService,
    FormatCurrencyPipe,
    FormatDatePipe,
    FormatTimePipe,
    GeoService,
    LocaleLowercasePipe,
    PluralizePipe,
    SelectDataService,
    ImagesService,
    UtilsService
  ]
})
export class CommonModule { }

import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { EnvService } from 'src/app/shared/common/services/env.service';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { TravelInfoData } from 'src/app/app.interfaces';
import { ConfigService } from 'src/app/shared/services/config/config.service';

@Injectable()
export class ArrivalInfoService {
  endpoint: string;

  constructor(
    private config: ConfigService,
    private env: EnvService,
    private http: CheckinHttpClient
  ) {
    const { checkin = { hash: '' } } = this.config;
    this.endpoint = `${this.env.endpoint}/v2/booking/${checkin.hash}/arrival-info`;
  }

  private getTravelInfoParams(travelInfo: TravelInfoData): { travelInfo: TravelInfoData } {
    const { arrivalHour, departureHour } = this.config.booking.travelInfo;

    if (arrivalHour && !travelInfo.arrivalHour) {
      travelInfo.arrivalHour = arrivalHour;
    }

    if (departureHour && !travelInfo.departureHour) {
      travelInfo.departureHour = departureHour;
    }

    return { travelInfo };
  }

  saveArrivalInfo(travelInfo: TravelInfoData) {
    return this.http.post(this.endpoint, this.getTravelInfoParams(travelInfo)).pipe(
      map((res: any) => res),
      catchError((error: any) => {
        return throwError(error || 'Server error');
      })
    );
  }

  updateArrivalInfo(travelInfo: TravelInfoData) {
    return this.http.patch(this.endpoint, this.getTravelInfoParams(travelInfo)).pipe(
      map((res: any) => res),
      catchError((error: any) => {
        return throwError(error || 'Server error');
      })
    );
  }

  setTravelInfoData(travelInfoData: TravelInfoData) {
    this.config.booking.travelInfo = travelInfoData;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';

@Pipe({name: 'formatCurrency'})
export class FormatCurrencyPipe implements PipeTransform {
  constructor(private config: ConfigService) { }

  transform(amount: number, currency: string, locale?: string): string {
    locale = locale || this.config.locale.get() || 'en_GB';
    currency = currency || 'EUR';
    const lang = locale.replace('_', '-');

    return (new Intl.NumberFormat(lang, { style: 'currency', currency }).format(amount));
  }
}

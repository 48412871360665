import {
  Component,
  Injector,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy
} from '@angular/core';
import * as Color from 'color';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { IProject } from 'src/app/shared/models/projects/project.model';
import { TSlideOutType } from 'src/app/shared/models/slide-out/slide-out.model';
import { NavbarAditionalInfoAnimation } from './navbar-aditional-info/navbar-aditional-info-animation.component';
import { navbar } from './navbar.animation';
import { INavbar, INav } from 'src/app/shared/models/navbar/navbar.model';
import { StylesService } from 'src/app/shared/common/components/buttons/styles.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [navbar]
})

export class NavbarComponent implements OnInit, OnDestroy {
  // TODO:: Refactor this
  @Input() preventCloseSlideOut: boolean;
  @Output() closeSlideOut: EventEmitter<boolean>;
  @Output() preventCloseSlideOutChange: EventEmitter<boolean>;

  private project: IProject;

  public navbarItems: INavbar;
  public aditionalInfoSlideOutType: TSlideOutType;
  public animations: NavbarAditionalInfoAnimation;

  constructor() {
    const injector: Injector = InjectorService.get();
    const configService: ConfigService = injector.get(ConfigService);
    const project = configService[configService.project];

    Object.assign(this, {
      project,
      navbarItems: project.navbar,
      animations: new NavbarAditionalInfoAnimation(),
      aditionalInfoSlideOutType: 'top'
    });

    this.setCustomStyles(injector.get(StylesService), project.corporativeColor);
  }

  public ngOnInit(): void {
    this.toggleClass('add');
  }

  public ngOnDestroy(): void {
    this.toggleClass('remove');
  }

  public onClick(n: INav): void {
    delete n.notifications;

    const { action, route } = n;
    action ? this[action]() : this.project.navigate(route);
  }

  public showAditionalInfo(): void {
    this.animations.showAnimation();
  }

  public closeSlideOutHandler(): void {
    this.animations.closeAnimation();
  }

  public preventCloseSlideOutHandler(prevent: boolean) {
    this.preventCloseSlideOut = prevent;
  }

  private toggleClass(action: 'add' | 'remove'): void {
    document.body.classList[action]('navbar--active');
  }

  private setCustomStyles(service: StylesService, color: Color): void {
    const wrapper = '.checkin__navbar__wrapper .checkin__navbar__item.active';

    service.set({ [`${wrapper} i, ${wrapper} .checkin__navbar__item__slug`]: { color } });
  }
}

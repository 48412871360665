import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OccupantTravelerSignatureService {
  private subject: Subject<string>;

  constructor() {
    this.subject = new Subject<string>();
  }

  emit(action: string): void {
    this.subject.next(action);
  }

  signatureObservable(): Observable<string> {
    return this.subject.asObservable();
  }
}

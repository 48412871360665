import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { QRCodeModule } from 'angularx-qrcode';

// Modules
import { ArrivalInfoModule } from '../arrival-info/arrival-info.module';
import { ButtonsModule } from 'src/app/shared/common/components/buttons/buttons.module';
import { CardModule } from '../card/card.module';
import { CommonModule } from '../../../common/common.module';
import { IconsModule } from 'src/app/shared/common/components/icons/icons.module';
import { InputsModule } from 'src/app/shared/common/components/inputs/inputs.module';
import { RequestInterfaceModule } from 'src/app/shared/common/components/request-interface/request-interface.module';

// Components
import { OccupantArrivalInfoComponent } from './occupant-arrival-info/occupant-arrival-info.component';
import { OccupantCardComponent } from './occupant-card/occupant-card.component';
import { OccupantDetailComponent } from './occupant-detail.component';
import { OccupantFormComponent } from './occupant-form/occupant-form.component';
import { OccupantFormPersonalComponent } from './occupant-form/occupant-form-personal/occupant-form-personal.component';
import { OccupantFormResidenceComponent } from './occupant-form/occupant-form-residence/occupant-form-residence.component';
import { OccupantIdentifyComponent } from './occupant-identify/occupant-identify.component';
import { OccupantIdentifyDocumentsComponent } from './occupant-identify/occupant-identify-documents/occupant-identify-documents.component';
import {
  OccupantIdentifyDocumentsItemComponent
} from './occupant-identify/occupant-identify-documents/occupant-identify-documents-item/occupant-identify-documents-item.component';
import { OccupantIdentifyScanComponent } from './occupant-identify/ocr/occupant-identify-scan/occupant-identify-scan.component';
import { OccupantIdentifyNoCameraComponent } from './occupant-identify/occupant-identify-no-camera/occupant-identify-no-camera.component';
import { OccupantTravelerDetailComponent } from './occupant-traveler-detail/occupant-traveler-detail.component';
import {
  OccupantTravelerSignatureComponent
} from './occupant-traveler-detail/occupant-traveler-signature/occupant-traveler-signature.component';
import {
  OccupantTravelerDocumentComponent
} from './occupant-traveler-detail/occupant-traveler-document/occupant-traveler-document.component';
import {
  OccupantTravelerSignatureModalComponent
} from './occupant-traveler-detail/occupant-traveler-signature-modal/occupant-traveler-signature-modal.component';
import { OccupantInvalidFormComponent } from './occupant-invalid-form/occupant-invalid-form.component';
import { OccupantIdentifyPreviewComponent } from './occupant-identify/occupant-identify-preview/occupant-identify-preview.component';
import {
  OccupantIdentifyMobileScanComponent
} from './occupant-identify/ocr/occupant-identify-mobile-scan/occupant-identify-mobile-scan.component';
import {
  OccupantIdentifyPreviewItemComponent
} from './occupant-identify/occupant-identify-preview/occupant-identify-preview-item/occupant-identify-preview-item.component';
import { OccupantIdentifyModalComponent } from './occupant-identify/occupant-identify-modal/occupant-identify-modal.component';
import {
  OccupantTravelerEnableSignatureComponent
} from './occupant-traveler-detail/occupant-traveler-enable-signature/occupant-traveler-enable-signature.component';
import { OccupantDetailIndexComponent } from './occupant-detail-index/occupant-detail-index.component';

// Services
import { OccupantDetailService } from './occupant-detail.service';
import { OccupantFormService } from './occupant-form/occupant-form.service';
import { OccupantIdentifyService } from './occupant-identify/ocr/occupant-identify-scan.service';
import {
  OccupantTravelerSignatureService
} from './occupant-traveler-detail/occupant-traveler-signature/occupant-traveler-singature.service';
import { OccupantIdentifyScanActionsService } from './occupant-identify/ocr/occupant-identify-scan/occupant-identify-scan-actions.service';

import { OccupantDetailUtils } from './occupant-detail.utils';
import { OccupantIdentifyInfoComponent } from './occupant-identify/occupant-identify-info/occupant-identify-info.component';
import {
  OccupantIdentifyInfoModalComponent
} from './occupant-identify/occupant-identify-info/occupant-identify-info-modal/occupant-identify-info-modal.component';
import { PipesModule } from 'src/app/shared/common/pipes/pipes.module';
import { TravelerDownloaderService } from './occupant-traveler-detail/traveler-downloader/traveler-downloader.service';
import { TravelerDocumentsService } from './occupant-traveler-detail/traveler-downloader/traveler-documents.service';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    OccupantArrivalInfoComponent,
    OccupantCardComponent,
    OccupantDetailComponent,
    OccupantFormComponent,
    OccupantFormPersonalComponent,
    OccupantFormResidenceComponent,
    OccupantIdentifyComponent,
    OccupantIdentifyDocumentsComponent,
    OccupantIdentifyDocumentsItemComponent,
    OccupantIdentifyScanComponent,
    OccupantIdentifyNoCameraComponent,
    OccupantTravelerDetailComponent,
    OccupantTravelerSignatureComponent,
    OccupantTravelerDocumentComponent,
    OccupantTravelerSignatureModalComponent,
    OccupantInvalidFormComponent,
    OccupantIdentifyPreviewComponent,
    OccupantIdentifyMobileScanComponent,
    OccupantIdentifyPreviewItemComponent,
    OccupantIdentifyModalComponent,
    OccupantIdentifyInfoComponent,
    OccupantIdentifyInfoModalComponent,
    OccupantTravelerEnableSignatureComponent,
    OccupantDetailIndexComponent
  ],
  imports: [
    AngularCommonModule,
    ArrivalInfoModule,
    ButtonsModule,
    CardModule,
    SharedModule,
    CommonModule,
    IconsModule,
    InputsModule,
    FormsModule,
    QRCodeModule,
    ReactiveFormsModule,
    RequestInterfaceModule,
    PipesModule,
    RouterModule
  ],
  providers: [
    OccupantDetailService,
    OccupantDetailUtils,
    OccupantFormService,
    OccupantIdentifyService,
    OccupantTravelerSignatureService,
    OccupantIdentifyScanActionsService,
    TravelerDocumentsService,
    TravelerDownloaderService
  ]
})
export class OccupantDetailModule { }

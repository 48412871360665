import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CookieService } from 'src/app/shared/services/cookie/cookie.service';
import { fadeTooltip } from './tooltip.animation';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  animations: [fadeTooltip]
})

export class TooltipComponent implements OnInit {
  @Input() arrow: string;
  @Input() class: string;
  @Input() click: boolean;
  @Input() closeText: string;
  @Input() disabled: boolean;
  @Input() hideUntilAppear: boolean;
  @Input() icon: string;
  @Input() preventClickAgain: boolean;
  @Input() text: string;
  @Input() title: string;
  @Input() translateParams: any;

  @Output() closeEvt: EventEmitter<boolean>;

  isTooltipVisible = true;
  isHoverTooltip = false;
  canClick: boolean;

  constructor(private cookieService: CookieService) {
    this.arrow = 'right';
    this.icon = 'avc-info';
    this.canClick = false;

    this.closeEvt = new EventEmitter();
  }

  public ngOnInit(): void {
    this.initTooltipConfig();
  }

  get classTooltip(): any {
    return `checkin__tooltip--${this.arrow}`;
  }

  get classArrow(): any {
    return `checkin__tooltip__arrow--${this.arrow}`;
  }

  hide(): void {
    this.isTooltipVisible = false;
    this.closeEvt.emit();

    setTimeout(() => {
      this.canClick = false;
      this.isTooltipVisible = true;
    }, 500);
  }

  show(): void {
    this.isTooltipVisible = true;
  }

  public toggleIsHoverTooltip(): void {
    this.isHoverTooltip = !this.isHoverTooltip;
  }

  private initTooltipConfig(): void {
    if (!this.click) {
      return;
    }

    if (!this.preventClickAgain) {
      this.canClick = true;
      this.isTooltipVisible = true;

      return;
    }

    this.setClickConfig();
  }

  private setClickConfig(): void {
    const params = JSON.stringify({
      ...this.translateParams || {},
      tooltipTitle: this.title,
      tooltipText: this.text
    });

    const hash = this.encodeStr(`${window.location.href}${this.class}`);
    const cookieParams = this.cookieService.get(hash);

    this.canClick = !(cookieParams && cookieParams === params);

    if (this.canClick) {
      this.isTooltipVisible = false;
      this.setConfigCookie(hash, params);
    }
  }

  private encodeStr(str: string): string {
    return btoa(unescape(encodeURIComponent(str)));
  }

  private setConfigCookie(hash: string, params: string): void {
    const expires = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    this.cookieService.set(hash, params, expires);
  }

  public appearHandler(): void {
    if (this.hideUntilAppear) {
      this.isTooltipVisible = true;
    }
  }
}

import {
  Component,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  Input,
  HostListener
} from '@angular/core';
import { slideOut } from '../../animations/slide-out';
import { TSlideOutType, TAnimationState, EAxis } from 'src/app/shared/models/slide-out/slide-out.model';

const BODY_CLASS = 'checkin__slide__out__wrapper';

@Component({
  selector: 'app-slide-out',
  templateUrl: './slide-out.component.html',
  styleUrls: ['./slide-out.component.scss'],
  animations: [slideOut]
})

export class SlideOutComponent implements OnInit, OnDestroy {
  @Input() slideOutType: TSlideOutType;
  @Input() animationState: TAnimationState;
  @Input() preventClose: boolean;
  @Output() closeSlideOut: EventEmitter<boolean>;

  public animationTemplate: string;
  public axis: string;

  constructor() {
    this.animationState = 'out';
    this.closeSlideOut = new EventEmitter();
  }

  @HostListener('document:keydown.escape') onEscapeKeydownHandler() {
    this.close();
  }

  public ngOnInit(): void {
    this.toggleBodyClass('add');
  }

  public ngOnDestroy(): void {
    this.toggleBodyClass('remove');
  }

  private toggleBodyClass(action: string): void {
    document.body.classList[action](BODY_CLASS);
  }

  public getAxis() {
    return EAxis[this.slideOutType];
  }

  public close(): void {
    if (this.preventClose) {
      return;
    }

    this.closeSlideOut.emit();
  }
}

import { Component } from '@angular/core';
import { RequestInterfaceBase } from '../request-interface-base';
import { completedIconEnter } from '../../../animations/completed-icon-enter.animation';

@Component({
  selector: 'app-request-interface-modal',
  templateUrl: './request-interface-modal.component.html',
  styleUrls: ['./request-interface-modal.component.scss'],
  animations: [completedIconEnter]
})

export class RequestInterfaceModalComponent extends RequestInterfaceBase {
  constructor() {
    super();
  }
}

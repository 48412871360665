import { TAnimationState } from 'src/app/shared/models/slide-out/slide-out.model';
import { slideOutDuration, overlayDuration } from 'src/app/shared/common/animations/slide-out';

export class NavbarAditionalInfoAnimation {
  private _animationState: TAnimationState;
  private _showSlideOut: boolean;

  constructor() {
    this._animationState = 'out';
    this._showSlideOut = false;
  }

  public get state(): TAnimationState {
    return this._animationState;
  }

  public set state(state: TAnimationState) {
    this._animationState = state;
  }

  public get showSlideOut(): boolean {
    return this._showSlideOut;
  }

  public set showSlideOut(show: boolean) {
    this._showSlideOut = show;
  }

  public showAnimation(): void {
    this.showSlideOut = true;

    setTimeout(() => {
      this.state = 'in';
    });
  }

  public closeAnimation(): void {
    this.state = 'out';

    setTimeout(() => {
      this.showSlideOut = false;
    }, (Math.max(slideOutDuration, overlayDuration) + 50));
  }
}

import {
  Component,
  Input,
  Injector,
  OnInit,
  OnDestroy
} from '@angular/core';
import { StylesService } from 'src/app/shared/common/components/buttons/styles.service';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-radio-item',
  templateUrl: './radio-item.component.html',
  styleUrls: ['./radio-item.component.scss']
})

export class RadioItemComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() key: string;
  @Input() label: string;
  @Input() icon: string;

  private subscription: Subscription;
  public selected: boolean;

  constructor() {}

  public ngOnInit(): void {
    this.setCustomStyles();
    this.setSubscription();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setSubscription(): void {
    const check: (k: string) => void = (k: string) => this.selected = (this.key === k);

    check(this.control.value);
    this.subscription = this.control.valueChanges.subscribe((d: string) => check(d));
  }

  private setCustomStyles(): void {
    const injector: Injector = InjectorService.get();
    const { corporativeColor: color } = injector.get(ConfigService).checkin;

    injector.get(StylesService).set({
      '.checkin__radio__item i.selected::after': {
        'background-color': color
      }
    });
  }

  public clickHandler(): void {
    if (this.control.value !== this.key) {
      this.control.setValue(this.key);
    }
  }
}

import * as tslib_1 from "tslib";
import { Injector, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import * as get from 'lodash.get';
import { PaymentListItem } from './payment-list.model';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { slideOutDuration, overlayDuration } from 'src/app/shared/common/animations/slide-out';
import { PaymentsDetail } from './payments-detail.class';
import { CreditCardFormService } from '../../../components/credit-card-form/credit-card-form.service';
var PaymentsDetailComponent = /** @class */ (function () {
    function PaymentsDetailComponent(injector, cdRef) {
        this.injector = injector;
        this.cdRef = cdRef;
        Object.assign(this, {
            configService: this.injector.get(ConfigService),
            translateService: this.injector.get(TranslateService),
            creditCardFromService: this.injector.get(CreditCardFormService),
            slider: {
                animation: 'out',
                show: false,
                type: 'left'
            },
            subscriptions: []
        });
        this.setPayments();
        this.getPaymentsDetailTranslations();
    }
    PaymentsDetailComponent.prototype.setPayments = function () {
        var _this = this;
        this.payments = {
            all: [],
            detail: new PaymentsDetail(),
            showMore: false,
            sorted: {
                value: [],
                sort: function (s) {
                    var selected = _this.payments.all.some(function (p) { return p.selected; });
                    if (!selected) {
                        _this.payments.sorted.value = _this.payments.all.slice(0, s).slice();
                        return;
                    }
                    if (s === _this.payments.all.length) {
                        _this.payments.sorted.value = _this.payments.all.slice();
                        return;
                    }
                    _this.payments.sorted.value = _this.payments.all.reduce(function (sorted, payment, index, all) {
                        switch (true) {
                            case (sorted.length >= s):
                            case (!(sorted.some(function (p) { return p.selected; })) && !payment.selected):
                            case (!payment.selected && payment.paid):
                                break;
                            case payment.selected:
                                sorted = ((all[index - 1] && (s > 1)) ? [all[index - 1]] : []).concat([payment]);
                                break;
                            default:
                                sorted.push(payment);
                                break;
                        }
                        return sorted;
                    }, []);
                }
            }
        };
    };
    PaymentsDetailComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    PaymentsDetailComponent.prototype.ngAfterViewInit = function () {
        this.payments.detail.setElements();
        this.subscribe();
    };
    PaymentsDetailComponent.prototype.subscribe = function () {
        var _this = this;
        this.setPaymentsSizeConfig();
        this.subscriptions.push(this.configService.screen.onWindowResizeChanges().subscribe(function () { return _this.setPaymentsSizeConfig(); }));
        this.subscriptions.push(this.creditCardFromService.onChangesCardNumber().subscribe(function () {
            _this.getPaymentsDetailTranslations();
            _this.setPaymentsSizeConfig();
        }));
    };
    PaymentsDetailComponent.prototype.setPaymentsSizeConfig = function () {
        if (!this.configService.screen.isLG) {
            var result = this.payments.detail.getSizeConfig(this.payments.all.length);
            this.payments.sorted.sort(result.showing);
            this.payments.showMore = result.showToggle;
            var maxHeight = result.maxHeight || (result.paymentHeight * this.payments.sorted.value.length);
            document
                .querySelector('.checkin__booking__detail__payments__list')
                .setAttribute('style', "max-height: " + maxHeight + "px");
            this.cdRef.detectChanges();
        }
    };
    PaymentsDetailComponent.prototype.getPaymentsDetailTranslations = function () {
        var _this = this;
        this.translateService.get(['payment.one', 'deposit', 'payment-rese', 'payment-lleg', 'payment-ante'])
            .subscribe(function (translations) {
            _this.translations = translations;
            _this.setPaymentsDetailConfig();
        });
    };
    PaymentsDetailComponent.prototype.setPaymentsDetailConfig = function () {
        var _this = this;
        var _a = get(this.configService.booking, 'resume.payments', { paymentsDetail: [], deposit: [] }), paymentsDetail = _a.paymentsDetail, deposit = _a.deposit;
        var mcp = this.configService.booking.resume.paymentConfig.mcp;
        var payment = function (p, i) { return new PaymentListItem(tslib_1.__assign({}, p, { amount: mcp.enabled ? p.mcp.amount : p.amount, currency: mcp.enabled ? p.mcp.currency : p.currency, text: p.isSecurityDeposit ? _this.translations.deposit : _this.translations["payment-" + p.momentoPago] })); };
        var payments = (paymentsDetail.length > 0 ? paymentsDetail : []).concat((deposit.length > 0 ? deposit : [])).map(function (p, i) { return payment(p, i); });
        this.payments.all = payments.slice();
        this.payments.sorted.value = payments.slice();
    };
    PaymentsDetailComponent.prototype.openSlideOutHandler = function () {
        var _this = this;
        this.slider.show = true;
        setTimeout(function () {
            _this.slider.animation = 'in';
        });
    };
    PaymentsDetailComponent.prototype.closeSlideOutHandler = function () {
        var _this = this;
        this.slider.animation = 'out';
        setTimeout(function () {
            _this.slider.show = false;
        }, (Math.max(slideOutDuration, overlayDuration) + 50));
    };
    return PaymentsDetailComponent;
}());
export { PaymentsDetailComponent };

export const environment = {
  defaultDomain: window.location.host,
  production: true,
  endpoint: '/api',
  defaultLanguage: 'en_GB',
  wsEndpoint: (window.location.protocol === 'https:') ? `wss://${window.location.host}/ws` : `ws://${window.location.host}/ws`,
  sentryDSN: '${SENTRY_DSN}',
  payments: {
    url: '${PAYMENTS_URL}'
  }
};

export class BookingStatus {
  private elements: {
    footer: any;
    sidebar: any;
  };

  public setElements(): void {
    this.elements = {
      footer: document.querySelector('app-footer'),
      sidebar: document.querySelector('.checkin__sidebar__wrapper')
    };
  }

  public getHeightSidebarStatus(is2k: boolean): number | string {
    if (is2k) {
      return 952;
    }

    const sidebar = this.elements.sidebar.getBoundingClientRect();
    const footer = this.elements.footer.getBoundingClientRect();
    const windowHeight  = window.innerHeight;

    const bodyOffset: number = windowHeight - footer.height - sidebar.top;

    return bodyOffset;
  }

}

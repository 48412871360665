import * as tslib_1 from "tslib";
import { ButtonBase } from '../button-base';
import { StylesService } from '../styles.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
var ButtonTertiaryComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ButtonTertiaryComponent, _super);
    function ButtonTertiaryComponent() {
        var _this = _super.call(this) || this;
        _this.stylesService = InjectorService.get().get(StylesService);
        _this.hoverColor = _this.corporativeColor.alpha(.25);
        _this.activeColor = _this.corporativeColor.alpha(.15);
        _this.stylesService.set({
            'button.checkin__btn__tertiary': {
                'background-color': 'white',
                'border-color': _this.corporativeColor,
                color: _this.corporativeColor,
                '&:hover, &:focus': {
                    'background-color': _this.hoverColor,
                    'border-color': _this.hoverColor,
                },
                '&:active': {
                    'background-color': _this.activeColor,
                    'border-color': _this.activeColor
                }
            }
        });
        return _this;
    }
    return ButtonTertiaryComponent;
}(ButtonBase));
export { ButtonTertiaryComponent };

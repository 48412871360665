import { OccupantListDetailItem } from 'src/app/shared/main/body/occupant-list/occupant-list.interface';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { Injector } from '@angular/core';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { EScanner } from 'src/app/checkin-app/modules/checkin/pages/wizard/pages/identity/pages/capture/enums/scanner.enum';
import { EDocuments } from 'src/app/checkin-app/modules/checkin/pages/wizard/pages/signature/enums/documents-enum';

export interface IGuest {
  age?: number;
  comment?: string;
  completed: {
    animate: boolean;
    value: boolean;
  };
  delegated?: string;
  documents: {
    back?: string;
    front: string;
    selfie?: string;
  };
  id: number;
  invalid: boolean;
  isChild?: boolean;
  isBaby?: boolean;
  isCompanion?: boolean;
  isMainOccupant?: boolean;
  label?: string;
  name?: {
    value: string;
    updated: boolean;
  };
  // showInvalidAlert?: boolean;
}

export class Guest implements IGuest {
  public age?: number;
  public comment?: string;
  public completed: {
    animate: boolean;
    value: boolean;
  };
  public delegated?: string;
  public documents: {
    back?: string;
    front: string;
    selfie?: string;
  };
  public id: number;
  public invalid: boolean;
  public isChild?: boolean;
  public isBaby?: boolean;
  public isCompanion?: boolean;
  public isMainOccupant?: boolean;
  public label?: string;
  public name?: IGuest['name'];
  public signatures: {
    [key: string]: string
  };
  public hash: string;
  public email?: string;
  public validated?: boolean;
  public idComprador?: number;
  // public showInvalidAlert?: boolean;

  constructor(params: {
    hash: string;
    occupant: OccupantListDetailItem;
  }) {
    this.hash = params.hash;
    Object.assign(this, this.sanitize(params.occupant));
  }

  private sanitize(params: OccupantListDetailItem): any {
    const injector: Injector = InjectorService.get();
    const env: EnvService = injector.get(EnvService);
    const { completed, frontDocument, backDocument, selfie, showInvalidAlert, ...rest } = params;

    return {
      ...rest,
      completed: {
        value: completed
      },
      documents: {
        back: this.getImgPublicLink(env, backDocument),
        front: this.getImgPublicLink(env, frontDocument),
        selfie: this.getImgPublicLink(env, selfie)
      },
      invalid: showInvalidAlert,
      signatures: {}
    };
  }

  private getImgPublicLink(env: EnvService, img: string): string {
    return img ? `${env.endpoint}/v2/files/${this.hash}/${img}?domain=${env.defaultDomain}` : undefined;
  }

  public get isCompleted(): boolean {
    return this.completed.value;
  }

  public removeAnimation(): void {
    delete this.completed.animate;
  }

  public getDocument(doc: EScanner): string {
    return this.documents[doc];
  }

  public setDocument(key: EScanner, value: string): void {
    this.documents[key] = value;
  }

  public setSignature(key: EDocuments, value: string): void {
    this.signatures[key] = value;
  }

  public markAsDelegated(name, delegated) {
    this.delegated = delegated;
    this.name = {
      value: name,
      updated: true
    };
  }

  public restoreDocuments(): void {
    [EScanner.FRONT, EScanner.BACK].forEach((d) => this.documents[d] = undefined);
  }

  public complete(name: string): void {
    Object.assign(this, {
      completed: {
        animate: true,
        value: true
      },
      name: {
        value: name,
        updated: true
      }
    });
  }
}

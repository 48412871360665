import * as tslib_1 from "tslib";
import * as Color from 'color';
import { IconBase } from '../icon-base';
import { ConfigService } from 'src/app/shared/services/config/config.service';
var LoaderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LoaderComponent, _super);
    function LoaderComponent(config) {
        var _this = _super.call(this) || this;
        _this.config = config;
        _this.corporativeColor = _this.config.checkin.corporativeColor;
        _this.size = 50;
        return _this;
    }
    Object.defineProperty(LoaderComponent.prototype, "loaderColor", {
        get: function () {
            return Color(this.color) || this.corporativeColor;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaderComponent.prototype, "loaderAlphaColor", {
        get: function () {
            return this.loaderColor.alpha(.4);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaderComponent.prototype, "loaderSize", {
        get: function () {
            return this.size + "px";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaderComponent.prototype, "wrapperSize", {
        get: function () {
            return this.size + 2 + "px";
        },
        enumerable: true,
        configurable: true
    });
    return LoaderComponent;
}(IconBase));
export { LoaderComponent };

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-interface-confirmation',
  templateUrl: './request-interface-confirmation.component.html',
  styleUrls: ['./request-interface-confirmation.component.scss']
})

export class RequestInterfaceConfirmationComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}

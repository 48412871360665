import { Component, OnDestroy } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnDestroy {
  private configService: ConfigService;
  private subscriptions: Subscription[];

  public isResponsive: boolean;

  constructor() {
    this.subscriptions = [];
    this.configService = InjectorService.get().get(ConfigService);

    this.setResponsive();
  }

  private setResponsive(): void {
    const s: Subscription = this.configService.screen.onIsLGChanges().subscribe((i: boolean) => this.isResponsive = i);

    this.isResponsive = this.configService.screen.isLG;
    this.subscriptions.push(s);
  }

  public get isLoading(): boolean {
    return false;
  }

  public get showNavbar(): boolean {
    // TODO:: Change to a subject
    const opened = this.configService.checkin.config.opened;
    const isPortal = this.configService.checkin.config.isPortal;

    if (!opened && isPortal) {
      return false;
    }

    return this.configService.navbar.show && !this.configService.navbar.block;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((e: Subscription) => e.unsubscribe());
  }
}

import * as _ from 'lodash';
import { translations, ETranslations } from '../../assets/i18n/translations';

export type TCreditCard = 'default' | 'visa' | 'mastercard' | 'american-express' | 'diners' | 'jcb' | 'maestro' | 'americanexpress';
export interface ICreditCardParams {
  address?: string;
  cvv?: string;
  date?: string;
  flipped: boolean;
  name?: string;
  pan?: string;
  type?: TCreditCard;
  valid?: boolean;
  lastDigits?: string;
  firstDigits?: string;
}
export interface ICreditCardOptions {
  lang: 'en_GB' | 'es_ES';
}

const CARD_ASSETS_MAP: { [key: string]: string; } = {
  'american-express': 'assets/images/logos/american-express.svg',
  americanexpress: 'assets/images/logos/american-express.svg',
  mastercard: 'assets/images/logos/mastercard.svg',
  maestro: 'assets/images/logos/maestro.svg',
  diners: 'assets/images/logos/diners.svg',
  jcb: 'assets/images/logos/jcb.svg',
  visa: 'assets/images/logos/visa.svg'
};

export class CreditCard {
  private lang: ICreditCardOptions['lang'];

  public cvv: string;
  public date: string;
  public flipped: boolean;
  public icon: string;
  public name: string;
  public pan: string;
  public template: TCreditCard;

  constructor(params: ICreditCardParams, options: ICreditCardOptions) {
    Object.assign(this, options);

    this._set(params);
  }

  private _set(params: ICreditCardParams): void {
    const t = _.get(translations, this.lang, translations.en_GB);
    const {
      cvv = t[ETranslations.CVV],
      date = `${t[ETranslations.MONTH_SHORT]}/${t[ETranslations.YEAR_SHORT]}`,
      name = t[ETranslations.NAME_AND_SURNAME],
      pan = '0000 0000 0000 0000',
      flipped
    } = params;
    const template = CARD_ASSETS_MAP[params.type] ? params.type : this.getTemplateByPan(params.firstDigits);

    Object.assign(this, {
      cvv,
      date,
      flipped,
      icon: CARD_ASSETS_MAP[template],
      name,
      pan,
      template
    });
  }

  public set(params: ICreditCardParams): void {
    this._set(params);
  }

  private getTemplateByPan(pan: string): string {
    const value = `${pan || ''}`.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const diners = new RegExp('^3(?:0[0-5]|[68][0-9])');
    const amex = new RegExp('^3[47]');
    const jcb = new RegExp('^(?:2131|1800|35\d{3})');
    const maestro = new RegExp('^(5018|5020|5038|6304|6759|6761|6763)');
    const visa = new RegExp('^4');
    const mastercard = new RegExp('^5[1-5]');

    switch (true) {
      case visa.test(value):
        return 'visa';
      case diners.test(value):
        return 'diners';
      case amex.test(value):
        return 'american-express';
      case mastercard.test(value):
        return 'mastercard';
      case maestro.test(value):
        return 'maestro';
      case jcb.test(value):
        return 'jcb';
      default:
        return 'default';
    }
  }
}

import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { CookieService } from 'src/app/shared/services/cookie/cookie.service';
var TooltipComponent = /** @class */ (function () {
    function TooltipComponent(cookieService) {
        this.cookieService = cookieService;
        this.isTooltipVisible = true;
        this.isHoverTooltip = false;
        this.arrow = 'right';
        this.icon = 'avc-info';
        this.canClick = false;
        this.closeEvt = new EventEmitter();
    }
    TooltipComponent.prototype.ngOnInit = function () {
        this.initTooltipConfig();
    };
    Object.defineProperty(TooltipComponent.prototype, "classTooltip", {
        get: function () {
            return "checkin__tooltip--" + this.arrow;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TooltipComponent.prototype, "classArrow", {
        get: function () {
            return "checkin__tooltip__arrow--" + this.arrow;
        },
        enumerable: true,
        configurable: true
    });
    TooltipComponent.prototype.hide = function () {
        var _this = this;
        this.isTooltipVisible = false;
        this.closeEvt.emit();
        setTimeout(function () {
            _this.canClick = false;
            _this.isTooltipVisible = true;
        }, 500);
    };
    TooltipComponent.prototype.show = function () {
        this.isTooltipVisible = true;
    };
    TooltipComponent.prototype.toggleIsHoverTooltip = function () {
        this.isHoverTooltip = !this.isHoverTooltip;
    };
    TooltipComponent.prototype.initTooltipConfig = function () {
        if (!this.click) {
            return;
        }
        if (!this.preventClickAgain) {
            this.canClick = true;
            this.isTooltipVisible = true;
            return;
        }
        this.setClickConfig();
    };
    TooltipComponent.prototype.setClickConfig = function () {
        var params = JSON.stringify(tslib_1.__assign({}, this.translateParams || {}, { tooltipTitle: this.title, tooltipText: this.text }));
        var hash = this.encodeStr("" + window.location.href + this.class);
        var cookieParams = this.cookieService.get(hash);
        this.canClick = !(cookieParams && cookieParams === params);
        if (this.canClick) {
            this.isTooltipVisible = false;
            this.setConfigCookie(hash, params);
        }
    };
    TooltipComponent.prototype.encodeStr = function (str) {
        return btoa(unescape(encodeURIComponent(str)));
    };
    TooltipComponent.prototype.setConfigCookie = function (hash, params) {
        var expires = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
        this.cookieService.set(hash, params, expires);
    };
    TooltipComponent.prototype.appearHandler = function () {
        if (this.hideUntilAppear) {
            this.isTooltipVisible = true;
        }
    };
    return TooltipComponent;
}());
export { TooltipComponent };

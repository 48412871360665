import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';

@Injectable()
export class ExpiredGuard implements CanActivate {
  private config: ConfigService;

  constructor() {
    this.config = InjectorService.get().get(ConfigService);
  }

  public canActivate(): boolean {
    if (!this.config.checkin.expired) {
      return true;
    }

    window.location.href = this.config.checkin.fallbackUrl;
  }
}

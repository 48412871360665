import { IConfig } from 'src/app/app.interfaces';
import { IBaseGuard } from 'src/app/shared/guards/base/base.guard';
import { CheckinConfig } from 'src/app/shared/classes/projects/checkin/checkin-config.class';
import { ITabs } from 'src/app/checkin-app/modules/checkin/pages/guests/components/tabs/tabs.component';
import { Project } from 'src/app/shared/classes/projects/project.class';
import { ICheckin } from 'src/app/shared/models/projects/checkin/checkin.model';
import { Company } from '../../../models/company/company.model';
import { EModules } from '../../../../app.enum';
import { ERoutes as EGuestRoutes } from 'src/app/checkin-app/modules/checkin/pages/guests/enums/routes.enum';
import { ERoutes } from 'src/app/shared/enums/routes.enum';
import { INavbar } from 'src/app/shared/models/navbar/navbar.model';
import { EProjects } from 'src/app/shared/enums/projects.enum';
import { EBookingStatus } from 'src/app/shared/enums/booking-status.enum';
import { CreditCard } from 'src/app/shared/classes/credit-card/credit-card.class';
import * as get from 'lodash.get';
import { Occupant } from 'src/app/app.interfaces';

export class Checkin extends Project implements ICheckin {
  public readonly completed?: boolean;
  public readonly config: CheckinConfig;
  public readonly delegated?: boolean;
  public readonly tabs: ITabs;
  public readonly secureUrl: string;

  constructor(params: IBaseGuard, company: Company) {
    super(params, company, EModules.CHECKIN);

    Object.assign(this, {
      tabs: {},
      config: {}
    });
  }

  public update(data: IConfig, update: (params: any) => void, cb: () => void): void {
    const {
      checkinDetails: {
        completed = false,
        delegated = false
      },
      extraConfig,
      booking: {
        locale,
        status
      },
      company: {
        name: companyName
      },
      secureUrl
    } = data;

    const config = new CheckinConfig({...extraConfig, delegated});
    const preventGuestOptions: boolean = (status === EBookingStatus.PETICION_INFORMACION);

    const creditCard = new CreditCard({
      number: get(data.booking.card, 'code'),
      updatable: get(data.booking.card, 'canChange'),
      type: get(data.booking.card, 'type'),
      ...((get(data.booking.card, 'name') || get(data.booking.card, 'billingAddress')) && {
        owner: {
          ...get(data.booking.card, 'billingAddress', {}),
          name: get(data.booking.card, 'name')
        }
      })
    });
    this.metaTitle.setTitle(`Online Check-in | ${companyName}`);

    Object.assign(this, {
      completed,
      delegated,
      expired: this.company.isExpiredModule(EModules.CHECKIN),
      config,
      tabs: this.getTabs(config, preventGuestOptions, data.booking.occupants),
      navbar: this.getNavbar(config, preventGuestOptions),
      creditCard,
      navbarAditionalInfo: this.getNavbarAditionalInfo(data.company, config, creditCard),
      secureUrl
    });

    update({ locale });

    // Set translations must be after LocaleLoader
    this.setTranslations(cb);
  }

  private getNavbarAditionalInfo(company: IConfig['company'], config: CheckinConfig, creditCard: CreditCard): INavbar {
    // tslint:disable-next-line:max-line-length
    const routes = [...((config.isCreditCardActivated && (!config.isCreditCardRequired || creditCard.valid) && config.showCreditCardComponent) ? [{
      icon: 'avc-icon-card',
      label: {
        slug: 'navbar-aditional-info-card'
      },
      route: ERoutes.CARD
    }] : []),
    // {
    //   icon: 'avc-icon-booking',
    //   label: {
    //     slug: 'navbar-aditional-info-booking'
    //   },
    //   action: 'navigateToIntranet'
    // },
    {
      icon: 'avc-icon-language',
      component: 'INPUT_LANGUAGE'
    }
    // , {
    //   icon: 'avc-icon-about',
    //   label: {
    //     slug: 'navbar-aditional-info-company',
    //     params: { company: company.name }
    //   }
    // }
    ];

    return routes;
  }

  private getNavbar(config: CheckinConfig, preventGuestOptions: boolean): INavbar {
    return [{
      icon: 'avc-filled-house',
      label: {
        slug: 'navbar-booking'
      },
      route: ERoutes.BOOKING
    },
    {
      deactivated: preventGuestOptions,
      icon: 'avc-check-in',
      label: {
        slug: 'navbar-checkin'
      },
      route: EGuestRoutes.GUESTS
    },
    {
      // TODO:: Replace this.getRouteComments() by this.inbox.enabled
      deactivated: (!config.showComments && this.getRouteComments() !== EGuestRoutes.INBOX),
      icon: 'avc-chat',
      label: {
        slug: 'chat'
      },
      route: this.getRouteComments()
    },
    {
      deactivated: (!config.showTravelInfo || preventGuestOptions),
      icon: 'avc-info',
      label: {
        slug: 'navbar-arrival-info'
      },
      route: EGuestRoutes.TRAVEL_INFO
    },
    {
      icon: 'avc-icon-hmb',
      action: 'showAditionalInfo',
      label: {
        slug: 'menu'
      }
    }].filter(({ deactivated }) => !deactivated) as INavbar;
  }

  private getTabs(config: CheckinConfig, preventGuestOptions: boolean, occupants: Array<Occupant>): ITabs {
    const routeComments: EGuestRoutes = this.getRouteComments();
    const inboxEnabled: boolean = this.company.isEnabledModule(EGuestRoutes.INBOX);
    let completado = false;
    let validado = false;

    for (const occupant of occupants) {
      if (occupant.idComprador) { completado = true; }
      if (occupant.validated) { validado = true; }
    }

    const guestOptions = !preventGuestOptions ? [
      {
        label: 'guests',
        route: EGuestRoutes.GUESTS
      },
      ...(config.showTravelInfo && !config.delegated ? [{
        label: 'organize-your-trip',
        route: EGuestRoutes.TRAVEL_INFO
      }] : [])
    ] : [];

    const chatOrCommentsTab = (config.showComments || inboxEnabled) && (!config.delegated || (completado && validado)) ? [{
      label: inboxEnabled ? 'chat' : 'comments',
      route: routeComments
    }] : [];

    return [
      ...guestOptions,
      ...chatOrCommentsTab
    ];
  }

  public getRouteComments(): EGuestRoutes {
    return this.company.isEnabledModule(EProjects.INBOX) ? EGuestRoutes.INBOX : EGuestRoutes.COMMENTS;
  }
}

import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';

import { EnvService } from 'src/app/shared/common/services/env.service';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { Observable } from 'rxjs';

@Injectable()
export class BookingLocalizerService {
  private endpoint: string;

  constructor(
    private env: EnvService,
    private http: CheckinHttpClient
  ) {
    this.endpoint = `${this.env.endpoint}/v2/config/checkin/login`;
  }

  public getHashFromLocalizer(localizer: string, firstName: string): Observable<string> {
    const url = `${this.endpoint}/${localizer}`;

    return this.http.get(`${url}?firstName=${firstName}`).pipe(
      map((res: any) => res.hash),
      catchError((error: any) => {
        throw new Error(error || 'Server error');
      })
    );
  }
}

import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { StylesService } from '../buttons/styles.service';
import { ErrorMessages } from '../../error/error-messages';
import { ConfirmationRequestResultType } from '../../../main/main.enum';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
var InputBase = /** @class */ (function () {
    function InputBase() {
        this.placeholder = '';
        this.showErrorText = true;
        this.blur = new EventEmitter();
        this.focus = new EventEmitter();
        var injector = InjectorService.get();
        this.config = injector.get(ConfigService);
        this.stylesService = injector.get(StylesService);
        this.borderColor = '#d8d8d8';
        this.corporativeColor = this.config.checkin.corporativeColor;
        this.maxLength = 1000;
        this.requestSaveDelay = 1500;
        this.translateParams = {};
        var inputStyles = {
            border: "1px solid " + this.borderColor,
            '&:focus': {
                'border-color': this.corporativeColor
            }
        };
        this.stylesService.set({
            '.checkin__input__container input.checkin__input': tslib_1.__assign({}, inputStyles),
            '.checkin__input__container select.checkin__input': tslib_1.__assign({}, inputStyles)
        });
    }
    InputBase.prototype.ngOnInit = function () {
        this.initInputBase();
    };
    InputBase.prototype.initInputBase = function () {
        if (!this.form) {
            console.warn("InputBase: No 'form' provided");
            return;
        }
        if (!this.form.get(this.control)) {
            console.warn("InputBase: No formControl (" + this.control + ") provided");
            return;
        }
        if (this.required) {
            var control = this.form.get(this.control);
            this.setFormRequired([control]);
        }
    };
    InputBase.prototype.setFormRequired = function (controls) {
        controls.forEach(function (fc) {
            var validator = [Validators.required];
            if (fc.validator) {
                validator = validator.concat([fc.validator]);
            }
            fc.setValidators(validator);
            fc.updateValueAndValidity();
        });
    };
    InputBase.prototype.onFormStatusChange = function (internalForm) {
        if (this.disabled) {
            internalForm.disable();
        }
        this.form.get(this.control).statusChanges.subscribe(function (status) {
            if (status === 'DISABLED' && internalForm.enabled) {
                return internalForm.disable();
            }
            if (internalForm.disabled) {
                internalForm.enable();
            }
        });
    };
    InputBase.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.requestSave && changes.requestSave.currentValue) {
            var requestSave = changes.requestSave;
            var currentType = requestSave.currentValue;
            if (this.requestSaveDebounce) {
                clearTimeout(this.requestSaveDebounce);
            }
            this.requestSaveType = currentType;
            this.loadingSave = this.requestSaveType === ConfirmationRequestResultType.LOADER;
            this.setLoading(true);
            if (this.requestSaveType !== ConfirmationRequestResultType.LOADER) {
                this.requestSaveDebounce = setTimeout(function () {
                    _this.setLoading(false);
                }, this.requestSaveDelay);
            }
        }
    };
    InputBase.prototype.setLoading = function (loading) {
        this.showLoadingSave = loading;
    };
    InputBase.prototype.onFocus = function () {
        return this.focus.emit(true);
    };
    InputBase.prototype.onBlur = function () {
        return this.blur.emit(true);
    };
    Object.defineProperty(InputBase.prototype, "hasValue", {
        get: function () {
            var _a = (this.form.get(this.control) || {}).value, value = _a === void 0 ? null : _a;
            return Boolean(value);
        },
        enumerable: true,
        configurable: true
    });
    InputBase.prototype.getErrorMessage = function (errors) {
        var errorKeys = Object.keys(errors || {});
        if (errorKeys.length > 0) {
            if (errors.required) {
                return ErrorMessages.required;
            }
            var error = errorKeys.sort()[0];
            return ErrorMessages[error] || this.customErrorMsg || '';
        }
        return '';
    };
    Object.defineProperty(InputBase.prototype, "errorMsg", {
        get: function () {
            var control = this.form.get(this.control);
            var errors = control.errors;
            return this.getErrorMessage(errors);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputBase.prototype, "formErrorMsg", {
        get: function () {
            var errors = this.form.errors;
            return this.getErrorMessage(errors);
        },
        enumerable: true,
        configurable: true
    });
    return InputBase;
}());
export { InputBase };

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from '../../../../common/services/env.service';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { IDelegationParams } from './occupants-delegation';
import { ConfigService } from 'src/app/shared/services/config/config.service';

@Injectable()
export class OccupantListDelegateService {
  /* tslint:disable */
  private _baseUrl: string;
  /* tslint:enable */

  constructor(
    private config: ConfigService,
    private http: CheckinHttpClient,
    private env: EnvService
  ) {
    this._baseUrl = `${this.env.endpoint}/v2/delegation/${this.config.checkin.hash}`;
  }

  public delegate(params: IDelegationParams): Observable<any> {
    return this.http.post(this._baseUrl, params);
  }
}

import * as tslib_1 from "tslib";
import { Subject } from 'rxjs';
import { newDate } from 'src/app/shared/common/utils/dates';
import { parsePaymentsList } from 'src/app/shared/modules/sidebar/booking-detail/payments-list/payments-list-utils';
import { Guests } from '../guests/guests.model';
import * as get from 'lodash.get';
import { EBookingStatus } from 'src/app/shared/enums/booking-status.enum';
import { CreditCard } from 'src/app/shared/classes/credit-card/credit-card.class';
import { ERoutes } from 'src/app/shared/enums/routes.enum';
import { EModules } from 'src/app/app.enum';
var Booking = /** @class */ (function () {
    function Booking(params, project, module) {
        this.project = project;
        this.module = module;
        this.init(params);
    }
    Booking.prototype.init = function (params) {
        var _a = params.booking, _b = _a.acc, accId = _b.id, city = _b.city, img = _b.img, name = _b.name, accType = _b.type, country = _b.country, adults = _a.adults, children = _a.children, babies = _a.babies, amount = _a.amount, amountMCP = _a.amountMCP, currency = _a.currency, currencyBuyer = _a.currencyBuyer, from = _a.from, to = _a.to, id = _a.id, localizer = _a.localizer, payments = _a.payments, paymentConfig = _a.paymentConfig, card = _a.card, mainOccupantId = _a.mainOccupantId, status = _a.status, idCompany = _a.idCompany, code = _a.code, _c = params.extraConfig, showBookingPrice = _c.showBookingPrice, showCurrencySelector = _c.showCurrencySelector;
        var comments = get(params, 'booking.comments', []);
        var occupants = get(params, 'booking.occupants', null);
        var arrivalFormInfo = get(params, 'booking.arrivalForm', {});
        var travelInfo = get(params, 'travelInfo', {});
        Object.assign(this, {
            acc: {
                country: country
            },
            arrivalFormInfo: arrivalFormInfo,
            comments: comments.reverse(),
            id: id,
            guests: occupants ? new Guests({
                occupants: occupants,
                hash: this.project.hash
            }) : occupants,
            travelInfo: travelInfo,
            mainOccupantId: mainOccupantId,
            bk: code,
            resume: tslib_1.__assign({ accId: accId,
                accType: accType,
                adults: adults,
                amount: amount,
                amountMCP: amountMCP,
                children: children,
                babies: babies,
                currency: currency,
                currencyBuyer: currencyBuyer, dateFrom: newDate(from), dateTo: newDate(to), img: img,
                city: city }, (country && country.name) && {
                country: country.name
            }, { localizer: localizer,
                name: name, payments: this.buildPayments(payments), paymentConfig: paymentConfig,
                status: status,
                idCompany: idCompany, bookingId: id }),
            creditCard: new CreditCard(tslib_1.__assign({ id: get(card, 'tokenId'), number: get(card, 'code'), firstDigits: get(card, 'firstDigits'), updatable: get(card, 'canChange'), type: get(card, 'type') }, ((get(card, 'name') || get(card, 'billingAddress')) && {
                owner: tslib_1.__assign({}, get(card, 'billingAddress', {}), { name: get(card, 'name') })
            }))),
            occupantsDataSubject: new Subject(),
            showBookingPrice: (this.module === EModules.CHECKOUT) ? true : showBookingPrice,
            showCurrencySelector: showCurrencySelector,
            statusWithCustomTemplate: [
                EBookingStatus.CANCELLED,
                EBookingStatus.PETICION_INFORMACION,
                EBookingStatus.PETICION_DISPONIBILIDAD
            ].concat((!showBookingPrice ? [
                EBookingStatus.CONFIRMADA,
                EBookingStatus.CONFIRMED,
                EBookingStatus.FACTURADA,
                EBookingStatus.PAID,
                EBookingStatus.UNPAID
            ] : []))
        });
        this.updateNavbarAdditionalInfo();
        this.project.loading = false;
    };
    Booking.prototype.buildPayments = function (payments) {
        if (payments) {
            var paymentsDetail = payments.paymentsDetail, deposit = payments.deposit;
            return {
                paymentsDetail: parsePaymentsList(paymentsDetail),
                deposit: parsePaymentsList(deposit)
            };
        }
        return { paymentsDetail: [], deposit: [] };
    };
    Object.defineProperty(Booking.prototype, "occupantsData", {
        get: function () {
            return this._occupantsData;
        },
        set: function (occupantsData) {
            this._occupantsData = occupantsData;
            this.occupantsDataSubject.next(occupantsData);
        },
        enumerable: true,
        configurable: true
    });
    Booking.prototype.occupantsDataChanged = function () {
        return this.occupantsDataSubject.asObservable();
    };
    Booking.prototype.updateNavbarAdditionalInfo = function () {
        var card = this.project.navbarAditionalInfo.find(function (i) { return i.route === ERoutes.CARD; });
        var update = function (c) { return c.label = { slug: 'see-card', params: {} }; };
        if (!card) {
            return;
        }
        if (this.creditCard.valid) {
            update(card);
            return;
        }
        this.creditCard.onChanges().subscribe(function () { return update(card); });
    };
    return Booking;
}());
export { Booking };

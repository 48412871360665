import * as get from 'lodash.get';
import { EModules } from 'src/app/app.enum';
var Company = /** @class */ (function () {
    function Company(params) {
        this.init(params);
    }
    Company.prototype.init = function (params) {
        var _a;
        var _b = params.company, email = _b.email, locale = _b.locale, name = _b.name, phone = _b.phone, privacyPolicy = _b.privacyPolicy, languages = params.languages, id = params.idCompany, _c = params.webConfig, title = _c.title, copyright = _c.footer.copyright;
        Object.assign(this, {
            copyright: this.getCopyright(copyright),
            email: email,
            id: id,
            languages: languages,
            locale: locale,
            name: name,
            modules: get(params, 'company.modules', (_a = {}, _a[EModules.PAYMENTS] = true, _a)),
            phone: phone,
            privacyPolicy: privacyPolicy,
            title: title
        });
    };
    Company.prototype.update = function (data) {
        var _a;
        this.modules = get(data, 'company.modules', (_a = {},
            _a[EModules.CHECKIN] = { enabled: false },
            _a[EModules.INBOX] = { enabled: false },
            _a[EModules.PAYMENTS] = { enabled: true, expired: false },
            _a));
        var _b = data.company, email = _b.email, locale = _b.locale, name = _b.name, phone = _b.phone, privacyPolicy = _b.privacyPolicy, id = data.idCompany;
        Object.assign(this, {
            email: email,
            id: id,
            locale: locale,
            name: name,
            phone: phone,
            privacyPolicy: privacyPolicy
        });
    };
    Company.prototype.getCopyright = function (copyright) {
        var hasYear = /[0-9]{4}/.test(copyright);
        var hasSymbol = copyright.indexOf('©') >= 0;
        return "" + (hasSymbol ? '' : '© ') + copyright + (hasYear ? '' : " " + (new Date().getFullYear()));
    };
    Company.prototype.isEnabledModule = function (module) {
        return this.modules[module].enabled;
    };
    Company.prototype.isExpiredModule = function (module) {
        return this.modules[module].expired;
    };
    return Company;
}());
export { Company };

import {
  trigger,
  transition,
  animate,
  style,
  state
} from '@angular/animations';

export const slideOutDuration = 350;
export const overlayDuration = 200;

export const slideOut = [
  trigger('slideOut', [
    state('in', style({
      transform: 'translate{{ axis }}(0%)'
    }), { params: { axis: 'X' }}),
    state('out', style({
      transform: 'translate{{ axis }}(110%)'
    }), { params: { axis: 'X' }}),
    transition('in => out', animate(`${slideOutDuration}ms ease-in-out`)),
    transition('out => in', animate(`${slideOutDuration}ms ease-in-out`))
  ]),
  trigger('slideOutOverlay', [
    state('in', style({
      opacity: 1
    })),
    state('out', style({
      opacity: 0,
      'pointer-events': 'none'
    })),
    transition('in => out', animate(`${overlayDuration}ms ease-in-out`)),
    transition('out => in', animate(`${overlayDuration}ms ease-in-out`))
  ])
];

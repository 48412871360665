import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export interface INav {
  action?: string;
  component?: string;
  icon: string;
  label?: {
    slug: string;
    params?: { [key: string]: string };
  };
  route?: string;
  notifications?: any;
}

export interface INavbar extends Array<INav> {}

export class Navbar {
  public block: boolean;
  private _show: boolean;
  private showSubject: Subject<boolean>;

  constructor() {
    Object.assign(this, {
      showSubject: new Subject<boolean>()
    });
  }

  get show(): boolean {
    return this._show;
  }

  set show(value: boolean) {
    this._show = value;
    this.showSubject.next(this._show);
  }

  public onShowChanges(): Observable<boolean> {
    return this.showSubject.asObservable().pipe(distinctUntilChanged());
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputLanguageComponent } from './input-language/input-language.component';
import { InputCurrencyComponent } from './input-currency/input-currency.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

const allModules = [
  InputLanguageComponent,
  InputCurrencyComponent
];

@NgModule({
  declarations: allModules,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  exports: allModules,
  entryComponents: [
    InputLanguageComponent,
    InputCurrencyComponent
  ]
})
export class InputsModule { }

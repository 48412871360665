export interface IBookingElementsConfig {
  hasPrice?: boolean;
  hasPayments?: boolean;
  hasCard?: boolean;
}

export class PaymentsDetail {
    private elements: {
      price: any;
      creditCard: any;
      container: any;
    };

    constructor() {}

    private getElementsSize(): {
      price: { height: number; top: number, totalHeight: number };
      creditCard: { height: number; top: number, totalHeight: number };
      container: { height: number; top: number, totalHeight: number };
    } {
      return Object.entries(this.elements).reduce((res, [key, value]) => ({
        ...res,
        [key]: value ? this.getElementParams(value) : {}
      }), {}) as any;
    }

    public setElements(): void {
      this.elements = {
        price: document.querySelector('.checkin__booking__price'),
        creditCard: document.querySelector('app-booking-card'),
        container: document.querySelector('.checkin__page__up__top--full--bottom')
      };
    }

    public getSizeConfig(sizeConfig: IBookingElementsConfig): { minHeight: number; maxHeight: number; } {
      if (!this.elements) {
        return { minHeight: null, maxHeight: null };
      }

      const { price, creditCard, container } = this.getElementsSize();

      const creditCardHeight = sizeConfig.hasCard ? creditCard.totalHeight : 0;
      const maxHeight = container.height - ( price.totalHeight + creditCardHeight);

      return {
        minHeight: container.height - (sizeConfig.hasPrice && !sizeConfig.hasPayments ? price.totalHeight : 0),
        maxHeight: !sizeConfig.hasPayments ? maxHeight : null
      };
    }

    private getStyle(element: any, attribute: string): any {
      if (!element) {
        return;
      }

      let elementAttribute: any = window.getComputedStyle(element)[attribute];

      if (!elementAttribute) {
        elementAttribute = element.style[attribute];
      }

      return parseInt(elementAttribute, 10) || 0;
    }

    private getElementParams(element: any): any {
      const params = element.getBoundingClientRect();
      const margin = {
        top: this.getStyle(element, 'marginTop'),
        bottom: this.getStyle(element, 'marginBottom')
      };

      params.totalHeight = margin.top + margin.bottom + params.height;

      return params;
    }
  }

import { Booking } from 'src/app/shared/models/booking/booking.model';
import { Component, OnDestroy } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { Screen } from 'src/app/shared/models/screen/screen.model';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/shared/classes/projects/project.class';
import { Router, NavigationEnd, Event } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnDestroy {
  private isCreditCardActivated: boolean;
  private isCreditCardRequired: boolean;
  private showCreditCardComponent: boolean;
  private modules: Project['modules'];
  private subscribers: Subscription[];

  public hasCreditCard: boolean;
  public isResponsive: boolean;
  public status: string;
  public isStatusCustomTemplate: boolean;
  public showCreditCard: boolean;
  public project: string;

  constructor(
    private config: ConfigService,
    private router: Router) {
    const {
      [this.config.project]: {
        config: {
          isCreditCardActivated,
          isCreditCardRequired,
          showCreditCardComponent
        },
        modules
      },
      booking,
      screen
    } = this.config;

    this.status = this.config.booking.resume.status;
    this.isStatusCustomTemplate = this.config.booking.statusWithCustomTemplate.includes(this.status);
    this.project = this.config.project;

    Object.assign(this, {
      hasCreditCard: booking.creditCard.valid,
      isCreditCardActivated,
      isCreditCardRequired,
      showCreditCardComponent,
      isResponsive: screen.isLG,
      modules,
      subscribers: []
    });

    this.showCreditCard = this.getShowCreditCard();

    this.creditCardSubscriber(booking);
    this.responsiveSubscriber(screen);

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd ) {
        this.showCreditCard = this.getShowCreditCard();
      }
    });
  }

  private getShowCreditCard(): boolean {
    // tslint:disable-next-line:max-line-length
    return this.modules.CHANGE_CARD && !this.router.url.includes('card') && (this.isCreditCardActivated && (!this.isCreditCardRequired || this.hasCreditCard) && this.showCreditCardComponent);
  }

  private responsiveSubscriber(screen: Screen): void {
    this.subscribers.push(screen.onIsLGChanges().subscribe((i: boolean) => this.isResponsive = i));
  }

  private creditCardSubscriber(booking: Booking): void {
    this.subscribers.push(booking.creditCard.onChanges().subscribe((h: boolean) => {
      this.hasCreditCard = h;
      this.showCreditCard = this.getShowCreditCard();
    }));
  }

  public ngOnDestroy(): void {
    this.subscribers.forEach((s) => s && s.unsubscribe());
  }
}

import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateModuleConfig, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { VERSION } from 'src/environments/version';
import { ServicesModule } from './services/services.module';

export function HttpLoaderFactory(http: HttpClient, baseHref: string) {
  return new TranslateHttpLoader(http, `${baseHref}assets/i18n/`, `.json${ (environment.production && `?${VERSION}`) || '' }`);
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ServicesModule
  ],
  exports: [
    CommonModule,
    TranslateModule
  ],
  providers: [
    TranslateService
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return (
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          // tslint:disable-next-line:max-line-length
          useFactory: HttpLoaderFactory,
          deps: [HttpClient, APP_BASE_HREF]
        },
        isolate: false
      } as TranslateModuleConfig)
    );
  }
}

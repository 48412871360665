import { OnDestroy } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
var MainLayoutComponent = /** @class */ (function () {
    function MainLayoutComponent() {
        this.subscriptions = [];
        this.configService = InjectorService.get().get(ConfigService);
        this.setResponsive();
    }
    MainLayoutComponent.prototype.setResponsive = function () {
        var _this = this;
        var s = this.configService.screen.onIsLGChanges().subscribe(function (i) { return _this.isResponsive = i; });
        this.isResponsive = this.configService.screen.isLG;
        this.subscriptions.push(s);
    };
    Object.defineProperty(MainLayoutComponent.prototype, "isLoading", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MainLayoutComponent.prototype, "showNavbar", {
        get: function () {
            // TODO:: Change to a subject
            var opened = this.configService.checkin.config.opened;
            var isPortal = this.configService.checkin.config.isPortal;
            if (!opened && isPortal) {
                return false;
            }
            return this.configService.navbar.show && !this.configService.navbar.block;
        },
        enumerable: true,
        configurable: true
    });
    MainLayoutComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (e) { return e.unsubscribe(); });
    };
    return MainLayoutComponent;
}());
export { MainLayoutComponent };

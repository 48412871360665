import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { CreditCard } from './card.interface';
import { ConfigService } from 'src/app/shared/services/config/config.service';

@Injectable()
export class CardService {
  endpoint: string;

  constructor(
    private env: EnvService,
    private config: ConfigService,
    private http: CheckinHttpClient
  ) {
    this.endpoint = `${this.env.endpoint}/v2/card/checkin/${this.config.checkin.hash}`;
  }

  saveCard(creditCard: CreditCard) {
    return this.http.post(this.endpoint, { creditCard }).pipe(
      map((res: any) => res),
      catchError((error: any) => {
        return throwError(error || 'Server error');
      })
    );
  }
}

import * as tslib_1 from "tslib";
import { ProjectConfig } from '../project-config.class';
var CheckinConfig = /** @class */ (function (_super) {
    tslib_1.__extends(CheckinConfig, _super);
    function CheckinConfig(params) {
        var _this = this;
        var _a = params.checkinReportMinAgeRequired, minAgeRequired = _a === void 0 ? 0 : _a, _b = params.ocrActivated, isOcrActivated = _b === void 0 ? false : _b, optionalDocumentId = params.optionalDocumentId, hasSelfie = params.hasSelfie, travelerDocumentId = params.travelerDocumentId, _c = params.travelInfoRequired, showTravelInfoOnWizard = _c === void 0 ? false : _c, showComments = params.showComments, specifyArrivalTime = params.specifyArrivalTime, specifyArrivalInfo = params.specifyArrivalInfo, specifyCheckoutTime = params.specifyCheckoutTime, specifyCheckoutInfo = params.specifyCheckoutInfo, childrenMaxAge = params.childrenMaxAge, delegated = params.delegated, showCreditCardComponent = params.showCreditCardComponent, opened = params.opened, brokerConfig = params.brokerConfig, checkInWithBabies = params.checkInWithBabies, checkinOthersInCountry = params.checkinOthersInCountry;
        var config = {
            documentsId: {
                checkinReport: travelerDocumentId,
                rentalContract: optionalDocumentId
            },
            isOcrActivated: isOcrActivated,
            hasSelfie: hasSelfie,
            minAgeRequired: minAgeRequired,
            showTravelInfoOnWizard: showTravelInfoOnWizard,
            specifyArrivalTime: specifyArrivalTime,
            specifyArrivalInfo: specifyArrivalInfo,
            specifyCheckoutTime: specifyCheckoutTime,
            specifyCheckoutInfo: specifyCheckoutInfo,
            childrenMaxAge: childrenMaxAge,
            showTravelInfo: true,
            showComments: showComments,
            delegated: delegated,
            showCreditCardComponent: showCreditCardComponent,
            opened: opened,
            isPortal: false,
            brokerConfig: brokerConfig,
            checkInWithBabies: checkInWithBabies,
            checkinOthersInCountry: checkinOthersInCountry
        };
        _this = _super.call(this, params, config) || this;
        return _this;
    }
    CheckinConfig.prototype.isSignatureActivated = function (isMainOccupant) {
        var _a = this.documentsId, checkinReport = _a.checkinReport, rentalContract = _a.rentalContract;
        return Boolean(isMainOccupant ? (rentalContract || checkinReport) : checkinReport);
    };
    return CheckinConfig;
}(ProjectConfig));
export { CheckinConfig };

import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// Modules
import { ButtonsModule } from 'src/app/shared/common/components/buttons/buttons.module';
import { CommonModule } from 'src/app/shared/common/common.module';
import { InputsModule } from 'src/app/shared/common/components/inputs/inputs.module';
import { RequestInterfaceModule } from '../../../common/components/request-interface/request-interface.module';
import { TooltipModule } from 'src/app/shared/common/components/tooltip/tooltip.module';
import { SharedModule } from 'src/app/shared/shared.module';

// Components
import { ArrivalInfoFormComponent } from './arrival-info-form/arrival-info-form.component';
import { ArrivalInfoDetailComponent } from './arrival-info-detail/arrival-info-detail.component';

// Services
import { ArrivalInfoService } from './arrival-info.service';
import { ArrivalInfoUtils } from './arrival-info.utils';

@NgModule({
  declarations: [
    ArrivalInfoFormComponent,
    ArrivalInfoDetailComponent
  ],
  imports: [
    AngularCommonModule,
    ButtonsModule,
    SharedModule,
    CommonModule,
    FormsModule,
    InputsModule,
    ReactiveFormsModule,
    RequestInterfaceModule,
    TooltipModule
  ],
  providers: [
    ArrivalInfoService,
    ArrivalInfoUtils
  ],
  exports: [
    ArrivalInfoFormComponent,
    ArrivalInfoDetailComponent
  ]
})
export class ArrivalInfoModule { }

import { ICheckinConfig } from 'src/app/shared/models/projects/checkin/checkin-config.model';
import {
  IConfig,
  IBrokerConfig
} from '../../../../app.interfaces';
import { ProjectConfig } from '../project-config.class';

export class CheckinConfig extends ProjectConfig implements ICheckinConfig {
  public readonly documentsId?: {
    checkinReport?: number;
    rentalContract?: number;
  };
  public readonly hasSelfie: boolean;
  public readonly isOcrActivated: boolean;
  public readonly minAgeRequired: number;
  public readonly showTravelInfoOnWizard: boolean;
  public readonly showTravelInfo: boolean;
  public readonly specifyArrivalTime: boolean;
  public readonly specifyArrivalInfo: boolean;
  public readonly specifyCheckoutTime: boolean;
  public readonly specifyCheckoutInfo: boolean;
  public readonly showComments: boolean;
  public readonly delegated: boolean;
  public readonly childrenMaxAge: number;
  public readonly showCreditCardComponent: boolean;
  public opened: boolean;
  public isPortal: boolean;
  public brokerConfig: IBrokerConfig;
  public checkInWithBabies: boolean;
  public checkinOthersInCountry: boolean;

  constructor(params: IConfig['extraConfig']) {
    const {
      checkinReportMinAgeRequired: minAgeRequired = 0,
      ocrActivated: isOcrActivated = false,
      optionalDocumentId,
      hasSelfie,
      travelerDocumentId,
      travelInfoRequired: showTravelInfoOnWizard = false,
      showComments,
      specifyArrivalTime,
      specifyArrivalInfo,
      specifyCheckoutTime,
      specifyCheckoutInfo,
      childrenMaxAge,
      delegated,
      showCreditCardComponent,
      opened,
      brokerConfig,
      checkInWithBabies,
      checkinOthersInCountry
    } = params;

    const config = {
      documentsId: {
        checkinReport: travelerDocumentId,
        rentalContract: optionalDocumentId
      },
      isOcrActivated,
      hasSelfie,
      minAgeRequired,
      showTravelInfoOnWizard,
      specifyArrivalTime,
      specifyArrivalInfo,
      specifyCheckoutTime,
      specifyCheckoutInfo,
      childrenMaxAge,
      showTravelInfo: true,
      showComments,
      delegated,
      showCreditCardComponent,
      opened,
      isPortal: false,
      brokerConfig,
      checkInWithBabies,
      checkinOthersInCountry
    };

    super(params, config as CheckinConfig);
  }

  public isSignatureActivated(isMainOccupant: boolean): boolean {
    const { checkinReport, rentalContract } = this.documentsId;

    return Boolean(isMainOccupant ? (rentalContract || checkinReport) : checkinReport);
  }
}

import { OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Navbar } from '../navbar/navbar.model';

type TWindowEvents = { event: string; cb: (event: any) => void }[];
interface TWindowSize { height: number; width: number; }
type TWindowOrientation = 'portrait' | 'landscape';

export class Screen implements OnDestroy {
  private _height: number;
  private _isMobileDevice: boolean;
  private _isXXXS: boolean;
  private _isXXS: boolean;
  private _isXS: boolean;
  private _isSM: boolean;
  private _isMD: boolean;
  private _isLG: boolean;
  private _isXL: boolean;
  private _width: number;
  private breakpoints: { [key: string]: number };
  // TODO:: Improve this to an unique subject with filters
  private isXXXSSubject: Subject<boolean>;
  private isXXSSubject: Subject<boolean>;
  private isXSSubject: Subject<boolean>;
  private isSMSubject: Subject<boolean>;
  private isMDSubject: Subject<boolean>;
  private isLGSubject: Subject<boolean>;
  private isXLSubject: Subject<boolean>;
  private navbar: Navbar;
  private windowEvents: TWindowEvents;
  private windowOrientationSubject: Subject<TWindowOrientation>;
  private windowResizeSubject: Subject<TWindowSize>;
  private _isIOS: boolean;

  constructor(params: { navbar: Navbar }) {
    Object.assign(this, {
      navbar: params.navbar,
      windowOrientationSubject: new Subject<TWindowOrientation>(),
      windowResizeSubject: new Subject<TWindowSize>(),
      windowEvents: [
        { event: 'resize', cb: this.onWindowResize.bind(this) }
      ]
    });

    this.setBreakpointsConfig();
    this.setWidth();
    this.setWindowEvents();
    this.setIsMobileDevice();
    this.setIsIOS();
  }

  public ngOnDestroy(): void {
    this.removeWindowEvents();
  }

  public get isMobileDevice(): boolean {
    return this._isMobileDevice;
  }

  public get isIOS(): boolean {
    return this._isIOS;
  }

  public get isXL(): boolean {
    return this._isXL;
  }

  public get isLG(): boolean {
    return this._isLG;
  }

  public get isSM(): boolean {
    return this._isSM;
  }

  public get isMD(): boolean {
    return this._isMD;
  }

  public get isXS(): boolean {
    return this._isXS;
  }

  public get isXXS(): boolean {
    return this._isXXS;
  }

  public get isXXXS(): boolean {
    return this._isXXXS;
  }

  public get height(): number {
    return this._height;
  }

  public get width(): number {
    return this._width;
  }

  private setWindowEvents(): void {
    this.windowEvents.forEach(({ event, cb }) => {
      window.addEventListener(event, (evt) => cb(evt));
    });
  }

  private removeWindowEvents(): void {
    this.windowEvents.forEach(({ event, cb }) => {
      window.removeEventListener(event, (evt) => cb(evt));
    });
  }

  private onWindowResize(event: Event | any): void {
    Object.assign(this, {
      _height: event.target.innerHeight,
      _width: event.target.innerWidth
    });

    this.windowResizeSubject.next({ height: this._height, width: this._width });
    this.windowOrientationSubject.next(this.getWindowOrientation());
    this.updateBreakpointsConfig();
  }

  private setBreakpointsConfig(): void {
    this.breakpoints = {
      XXXS: 375,
      XXS: 475,
      XS: 576,
      SM: 768,
      MD: 992,
      LG: 1200,
      XL: 1920
    };

    Object.entries(this.breakpoints).forEach(([key, value]) => {
      const k = `is${key}`;
      this[`_${k}`] = window.innerWidth <= value;
      this.updateHandler(k);
      this[`${k}Subject`] = new Subject<boolean>();
    });
  }

  private setWidth(): void {
    this._width = window.innerWidth;
  }

  private updateHandler(key: string): void {
    switch (key) {
      case 'isLG':
        this.navbar.show = this._isLG;
        break;
      default:
        break;
    }
  }

  private updateBreakpointsConfig(): void {
    Object.entries(this.breakpoints).forEach(([key, value]) => {
      const k = `is${key}`;

      this[`_${k}`] = window.innerWidth <= value;
      this.updateHandler(k);
      this[`${k}Subject`].next(this[k]);
    });
  }

  public onIsXXXSChanges(): Observable<boolean> {
    return this.isXXXSSubject.asObservable().pipe(distinctUntilChanged());
  }

  public onIsXXSChanges(): Observable<boolean> {
    return this.isXXSSubject.asObservable().pipe(distinctUntilChanged());
  }

  public onIsXSChanges(): Observable<boolean> {
    return this.isXSSubject.asObservable().pipe(distinctUntilChanged());
  }

  public onIsSMChanges(): Observable<boolean> {
    return this.isSMSubject.asObservable().pipe(distinctUntilChanged());
  }

  public onIsMDChanges(): Observable<boolean> {
    return this.isMDSubject.asObservable().pipe(distinctUntilChanged());
  }

  public onIsLGChanges(): Observable<boolean> {
    return this.isLGSubject.asObservable().pipe(distinctUntilChanged());
  }

  public onIsXLChanges(): Observable<boolean> {
    return this.isXLSubject.asObservable().pipe(distinctUntilChanged());
  }

  public onWindowResizeChanges(): Observable<TWindowSize> {
    return this.windowResizeSubject.asObservable().pipe(distinctUntilChanged());
  }

  public onWindowOrientationChanges(): Observable<TWindowOrientation> {
    return this.windowOrientationSubject.asObservable().pipe(distinctUntilChanged());
  }

  private getWindowOrientation(): TWindowOrientation {
    return this._height > this._width ? 'portrait' : 'landscape';
  }

  private setIsMobileDevice(): void {
    const mobileDevices = /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i;

    this._isMobileDevice = Boolean(navigator.userAgent.match(mobileDevices)) ||
    (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2); // iPadOS
  }

  public setIsIOS(): void {
    this._isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
  }
}

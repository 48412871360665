export const phoneCodes = {
  // mssql countryId - prefix
  27: 93,
  267: 358,
  28: 355,
  29: 213,
  30: 1684,
  4: 376,
  31: 244,
  32: 1264,
  33: 672,
  34: 1268,
  35: 54,
  36: 374,
  37: 297,
  38: 61,
  10: 43,
  39: 994,
  40: 1242,
  41: 973,
  42: 880,
  43: 1246,
  44: 375,
  9: 32,
  45: 501,
  46: 229,
  47: 1441,
  48: 975,
  49: 591,
  50: 387,
  51: 267,
  52: 55,
  19: 55,
  54: 246,
  55: 1284,
  56: 673,
  57: 359,
  58: 226,
  59: 257,
  60: 855,
  61: 237,
  62: 1,
  64: 238,
  65: 1345,
  66: 236,
  67: 235,
  68: 56,
  69: 86,
  70: 61,
  71: 61891,
  72: 57,
  73: 269,
  74: 242,
  75: 243,
  76: 682,
  77: 506,
  80: 225,
  17: 385,
  78: 53,
  79: 357,
  21: 420,
  22: 45,
  81: 253,
  82: 767,
  83: 809,
  86: 593,
  87: 20,
  88: 503,
  89: 240,
  90: 291,
  91: 372,
  92: 251,
  93: 500,
  94: 298,
  95: 679,
  96: 358,
  5: 33,
  97: 594,
  98: 689,
  99: 262,
  101: 241,
  102: 220,
  103: 995,
  6: 49,
  104: 233,
  105: 350,
  106: 30,
  107: 299,
  108: 1473,
  109: 590,
  110: 1,
  111: 502,
  112: 44,
  113: 224,
  114: 245,
  115: 592,
  116: 509,
  117: 334,
  118: 504,
  119: 852,
  120: 36,
  121: 354,
  122: 91,
  123: 62,
  124: 98,
  125: 964,
  126: 353,
  127: 44,
  24: 972,
  8: 39,
  128: 876,
  129: 81,
  130: 44,
  132: 962,
  133: 7,
  134: 254,
  135: 686,
  136: 965,
  137: 996,
  138: 856,
  139: 371,
  140: 961,
  141: 266,
  142: 231,
  143: 218,
  144: 423,
  145: 370,
  11: 352,
  146: 853,
  147: 389,
  148: 261,
  149: 265,
  150: 60,
  151: 960,
  152: 223,
  153: 356,
  154: 692,
  155: 596,
  156: 222,
  3: 230,
  157: 262,
  18: 52,
  159: 691,
  161: 373,
  162: 377,
  163: 976,
  164: 382,
  165: 1664,
  12: 212,
  166: 258,
  167: 95,
  168: 264,
  169: 674,
  7: 31,
  170: 977,
  171: 599,
  173: 687,
  174: 64,
  175: 505,
  176: 227,
  177: 234,
  178: 683,
  179: 672,
  180: 850,
  182: 1670,
  183: 47,
  184: 968,
  186: 92,
  187: 680,
  188: 970,
  189: 507,
  191: 675,
  192: 595,
  194: 51,
  195: 63,
  196: 64,
  197: 48,
  2: 351,
  198: 1,
  199: 974,
  203: 262,
  200: 40,
  201: 7,
  202: 250,
  204: 590,
  205: 290,
  206: 1869,
  207: 1758,
  208: 590,
  209: 508,
  210: 1784,
  211: 685,
  212: 39,
  234: 239,
  213: 966,
  214: 221,
  215: 381,
  217: 248,
  218: 232,
  219: 65,
  220: 421,
  221: 386,
  222: 677,
  223: 252,
  224: 27,
  225: 500,
  226: 82,
  1: 34,
  227: 94,
  228: 249,
  229: 597,
  230: 47,
  231: 268,
  232: 46,
  20: 41,
  233: 963,
  235: 886,
  236: 992,
  237: 255,
  238: 66,
  239: 670,
  240: 228,
  241: 690,
  242: 676,
  243: 868,
  244: 216,
  245: 90,
  246: 993,
  247: 1,
  248: 688,
  249: 246,
  251: 1,
  252: 256,
  253: 380,
  25: 971,
  26: 44,
  23: 1,
  255: 598,
  256: 998,
  257: 678,
  258: 379,
  259: 58,
  260: 84,
  262: 681,
  263: 212,
  264: 967,
  265: 260,
  266: 263
};

import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, Injector } from '@angular/core';
import { InputBase } from '../input-base';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { fillRange } from 'src/app/shared/common/utils/array';
import { isLeapYear, isDateLessThanMinAge } from 'src/app/shared/common/utils/dates';
import { pad } from 'src/app/shared/common/utils/pad';
import { ConfigService } from 'src/app/shared/services/config/config.service';
var MONTHS_WITH_30_DAYS = [4, 6, 9, 11];
var fillRangePadded = function (start, end) { return fillRange(start, end).map(function (s) { return pad(s, 2); }); };
var ɵ0 = fillRangePadded;
var InputDateComponent = /** @class */ (function (_super) {
    tslib_1.__extends(InputDateComponent, _super);
    function InputDateComponent(formBuilder, injector) {
        var _this = _super.call(this) || this;
        _this.formBuilder = formBuilder;
        _this.injector = injector;
        _this.useUSFormat = false;
        _this.isDateValid = new EventEmitter();
        _this.configService = _this.injector.get(ConfigService);
        _this.dateForm = formBuilder.group({
            day: new FormControl({ disabled: _this.disabled }),
            month: new FormControl({ disabled: _this.disabled }),
            year: new FormControl({ disabled: _this.disabled })
        });
        _this.setUseUSFormat();
        return _this;
    }
    InputDateComponent.prototype.ngOnInit = function () {
        if (!this.form) {
            console.warn("InputDate: No 'form' provided");
            return;
        }
        this.availableYears = 100;
        var addFutureYears = (this.allowFutureYears) ? 15 : 0;
        var date = new Date().getFullYear();
        this.months = fillRangePadded(1, 12);
        this.years = fillRange((date + addFutureYears), (date - this.availableYears), false);
        this.days = fillRangePadded(1, 31);
        if (this.allowFutureYears) {
            this.years.unshift(9999);
        }
        this.initDateForm();
        if (this.required) {
            var control = this.form.get(this.control);
            var _a = this.dateForm.controls, day = _a.day, month = _a.month, year = _a.year;
            this.setFormRequired([control, day, month, year]);
        }
        if (this.hasValue) {
            this.setDateValue();
        }
        this.isDateValid.emit(this.dateForm.valid);
        this.onMonthChange();
        this.onYearChange();
        this.onDateChange();
        this.onFormStatusChange(this.dateForm);
    };
    InputDateComponent.prototype.initDateForm = function () {
        var customValidators = (this.validateMinimumAge ? [this.minimumAge(this.validateMinimumAge)] : []).concat(this.validateDateLowerCurrentDate ? [this.dateLowerCurrentDate()] : [], (this.required) ? [this.requiredFields()] : []);
        var validators = customValidators.length > 0 ?
            { validators: Validators.compose(customValidators) } : {};
        var validator = this.required ? [Validators.required] : [];
        this.dateForm = this.formBuilder.group({
            day: new FormControl('', validator),
            month: new FormControl('', validator),
            year: new FormControl('', validator)
        }, validators);
        this.dateForm.updateValueAndValidity();
    };
    InputDateComponent.prototype.setDateValue = function () {
        var value = this.form.get(this.control).value;
        if (typeof value === 'string') {
            var date = value.split('T')[0];
            value = new Date(date);
        }
        if (value instanceof Date) {
            var date = {
                day: pad(value.getDate(), 2),
                month: pad((value.getMonth() + 1), 2),
                year: value.getFullYear()
            };
            this.dateForm.patchValue(date);
        }
        this.dateForm.updateValueAndValidity();
    };
    InputDateComponent.prototype.onDateChange = function () {
        var _this = this;
        this.dateForm.valueChanges.subscribe(function (value) {
            _this.isDateValid.emit(_this.dateForm.valid);
            if (_this.dateForm.invalid) {
                return;
            }
            var day = value.day, month = value.month, year = value.year;
            _this.form.get(_this.control).setValue(new Date(Date.UTC(+year, +(month - 1), +day)));
        });
    };
    InputDateComponent.prototype.onYearChange = function () {
        var _this = this;
        this.dateForm.get('year').valueChanges.subscribe(function (value) {
            if (_this.isFebruary) {
                _this.setFebruaryDays(value);
            }
            if (_this.preventFutureDates) {
                var month = _this.dateForm.value.month;
                _this.months = fillRangePadded(1, 12);
                _this.limitDate(value, month);
            }
        });
    };
    InputDateComponent.prototype.onMonthChange = function () {
        var _this = this;
        this.dateForm.get('month').valueChanges.subscribe(function (value) {
            var month = parseInt(value, 10);
            var endMonth = MONTHS_WITH_30_DAYS.includes(month) ? 30 : 31;
            var year = _this.dateForm.value.year;
            if (_this.isFebruary) {
                return _this.setFebruaryDays(year);
            }
            _this.days = fillRangePadded(1, endMonth);
            if (_this.preventFutureDates) {
                _this.limitDate(year, month);
            }
        });
    };
    Object.defineProperty(InputDateComponent.prototype, "isFebruary", {
        get: function () {
            var value = this.dateForm.get('month').value;
            return parseInt(value, 10) === 2;
        },
        enumerable: true,
        configurable: true
    });
    InputDateComponent.prototype.setFebruaryDays = function (year) {
        var endMonth = isLeapYear(year) ? 29 : 28;
        this.days = fillRangePadded(1, endMonth);
    };
    Object.defineProperty(InputDateComponent.prototype, "hasDay", {
        get: function () {
            var value = this.dateForm.get('day').value;
            return value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputDateComponent.prototype, "hasMonth", {
        get: function () {
            var value = this.dateForm.get('month').value;
            return value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputDateComponent.prototype, "hasYear", {
        get: function () {
            var value = this.dateForm.get('year').value;
            return value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputDateComponent.prototype, "formErrorMsg", {
        get: function () {
            var errors = this.dateForm.errors || this.form.get(this.control).errors;
            return _super.prototype.getErrorMessage.call(this, errors);
        },
        enumerable: true,
        configurable: true
    });
    InputDateComponent.prototype.minimumAge = function (minimumAge) {
        return function (fg) {
            var _a = fg.value, day = _a.day, month = _a.month, year = _a.year;
            var birthDate = new Date(Date.UTC(+year, +(month - 1), +day));
            if (!year || !month || !day) {
                return { required: true };
            }
            return isDateLessThanMinAge(birthDate, minimumAge) ? { minimumAge: true } : null;
        };
    };
    InputDateComponent.prototype.dateLowerCurrentDate = function () {
        return function (fg) {
            var _a = fg.value, day = _a.day, month = _a.month, year = _a.year;
            var date = new Date(Date.UTC(+year, +(month - 1), +day));
            if (!year || !month || !day) {
                return { required: true };
            }
            return date > new Date() ? { dateLowerCurrentDate: true } : null;
        };
    };
    InputDateComponent.prototype.requiredFields = function () {
        return function (fg) {
            var formValues = Object.values(fg.value);
            return formValues.length !== formValues.filter(Boolean).length ? { required: true } : null;
        };
    };
    InputDateComponent.prototype.limitDate = function (year, month) {
        var date = new Date();
        var lastYear = date.getFullYear();
        if (parseInt(year, 0) === lastYear) {
            var lastMonth = date.getMonth() + 1;
            this.months = fillRangePadded(1, lastMonth);
            if (parseInt(month, 0) === lastMonth) {
                var lastDay = date.getDate();
                this.days = fillRangePadded(1, lastDay);
            }
        }
    };
    InputDateComponent.prototype.setUseUSFormat = function () {
        this.useUSFormat = (this.configService.locale.get() === 'en_US');
    };
    return InputDateComponent;
}(InputBase));
export { InputDateComponent };
export { ɵ0 };

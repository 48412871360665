import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ProjectConfig as ICheckoutConfig } from 'src/app/shared/classes/projects/project-config.class';
var CheckoutConfig = /** @class */ (function (_super) {
    tslib_1.__extends(CheckoutConfig, _super);
    function CheckoutConfig(params, payments) {
        var _this = _super.call(this, params) || this;
        _this.setConfig(params, payments);
        return _this;
    }
    CheckoutConfig.prototype.setConfig = function (params, payments) {
        var selectedPayment = (_.get(payments, 'deposit', [])).concat((_.get(payments, 'paymentsDetail', []))).find(function (p) { return p.selected; });
        this.config = {
            selectedPayment: selectedPayment,
            sourceApp: params.sourceApp,
            waysOfPay: {
                typesIncludes: [],
                numberOfMethods: 0
            }
        };
    };
    return CheckoutConfig;
}(ICheckoutConfig));
export { CheckoutConfig };

import * as tslib_1 from "tslib";
var StylesService = /** @class */ (function () {
    function StylesService() {
        this.styles = {};
    }
    StylesService.prototype.unwind = function (selector, styles) {
        var _this = this;
        var toReturn = {};
        var deepSelectors = Object.keys(styles).filter(function (key) { return key.indexOf('&') > -1; });
        if (deepSelectors.length > 0) {
            deepSelectors.forEach(function (deepSelector) {
                var parsedSelector = deepSelector.replace(/\&/gi, selector);
                var unwound = _this.unwind(parsedSelector, styles[deepSelector]);
                toReturn = tslib_1.__assign({}, toReturn, unwound);
                delete styles[deepSelector];
            });
        }
        toReturn[selector] = styles;
        return toReturn;
    };
    StylesService.prototype.parseStyles = function (styles) {
        var _this = this;
        return Object.keys(styles).reduce(function (obj, selector) {
            var props = styles[selector];
            var unwound = _this.unwind(selector, props);
            obj = tslib_1.__assign({}, obj, unwound);
            return obj;
        }, {});
    };
    StylesService.prototype.parseToCss = function (styles) {
        return Object.entries(styles).reduce(function (global, _a) {
            var selector = _a[0], style = _a[1];
            if (!style) {
                return global;
            }
            var st = Object.entries(style).reduce(function (styleString, _a) {
                var propName = _a[0], propValue = _a[1];
                return "" + styleString + propName + ":" + propValue + ";";
            }, '');
            return global + "\n        " + selector + " {\n          " + st + "\n        }\n      ";
        }, '');
    };
    StylesService.prototype.set = function (styles) {
        var allStyles = this.parseStyles(styles);
        var hash = Object.keys(styles).sort().join();
        if (!this.styles[hash]) {
            var styleEl = document.createElement('style');
            document.body.appendChild(styleEl);
            this.styles[hash] = styleEl;
        }
        this.styles[hash].innerHTML = this.parseToCss(allStyles);
    };
    return StylesService;
}());
export { StylesService };

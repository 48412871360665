import { Injectable } from '@angular/core';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { EnvService } from 'src/app/shared/common/services/env.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IDocumentsResponse, TDocumentsResponseId } from './traveler-downloader';
import { ConfigService } from 'src/app/shared/services/config/config.service';

@Injectable()
export class TravelerDocumentsService {
  private _baseUrl: string;
  private _hash: string;

  constructor(
    private config: ConfigService,
    private http: CheckinHttpClient,
    private env: EnvService
  ) {
    this._hash = this.config.checkin.hash;

    this._baseUrl = `${this.env.endpoint}/v2/occupant/sign/${this._hash}`;
  }

  public getDocumentsToSign(occupantId: number): Observable<IDocumentsResponse[]> {
    const url = `${this._baseUrl}/${occupantId}`;

    return this.http.get(url).pipe(
      map((res: { documents: TDocumentsResponseId[]; }) => {
        return res.documents.filter((id) => this._canDocumentBeSigned(id)).map((id) => ({
          id,
          url: this.getDocumentPreview(occupantId, id)
        }));
      }),
      catchError((error: any) => {
        throw new Error(error || 'Server error');
      })
    );
  }

  private _canDocumentBeSigned(id: TDocumentsResponseId): boolean {
    const { checkinReport, rentalContract, ...otherTemplates } = this.config.checkin.config.documentsId;
    const idsMap = {
      checkinReport,
      rentalContract
    };

    return Boolean(idsMap[id]) || Boolean(otherTemplates[id]);
  }

  getDocumentPreview(occupantId: number, templateId: string): string {
    const queryParams = Object.entries({
      domain: this.env.defaultDomain,
      locale: this.config.locale.get()
    }).map(([key, value]) => `${key}=${value}`).join('&');

    return `${this._baseUrl}/${occupantId}/preview/${templateId}?${queryParams}`;
  }
}

import { Injector, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { NavbarAditionalInfoAnimation } from './navbar-aditional-info/navbar-aditional-info-animation.component';
import { StylesService } from 'src/app/shared/common/components/buttons/styles.service';
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent() {
        var injector = InjectorService.get();
        var configService = injector.get(ConfigService);
        var project = configService[configService.project];
        Object.assign(this, {
            project: project,
            navbarItems: project.navbar,
            animations: new NavbarAditionalInfoAnimation(),
            aditionalInfoSlideOutType: 'top'
        });
        this.setCustomStyles(injector.get(StylesService), project.corporativeColor);
    }
    NavbarComponent.prototype.ngOnInit = function () {
        this.toggleClass('add');
    };
    NavbarComponent.prototype.ngOnDestroy = function () {
        this.toggleClass('remove');
    };
    NavbarComponent.prototype.onClick = function (n) {
        delete n.notifications;
        var action = n.action, route = n.route;
        action ? this[action]() : this.project.navigate(route);
    };
    NavbarComponent.prototype.showAditionalInfo = function () {
        this.animations.showAnimation();
    };
    NavbarComponent.prototype.closeSlideOutHandler = function () {
        this.animations.closeAnimation();
    };
    NavbarComponent.prototype.preventCloseSlideOutHandler = function (prevent) {
        this.preventCloseSlideOut = prevent;
    };
    NavbarComponent.prototype.toggleClass = function (action) {
        document.body.classList[action]('navbar--active');
    };
    NavbarComponent.prototype.setCustomStyles = function (service, color) {
        var _a;
        var wrapper = '.checkin__navbar__wrapper .checkin__navbar__item.active';
        service.set((_a = {}, _a[wrapper + " i, " + wrapper + " .checkin__navbar__item__slug"] = { color: color }, _a));
    };
    return NavbarComponent;
}());
export { NavbarComponent };
